import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToastContext } from "contexts/ToastContext";
import * as AUTH_API from "api/auth";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import styles from "./AccountStatus.module.css";
import {
  IoCheckmarkCircle,
  IoWarning,
  IoBan,
  IoArrowBack,
  IoTrash,
  IoTime,
  IoRefresh,
  IoCloseCircle,
  IoMail,
  IoPersonAdd,
  IoHourglassOutline,
} from "react-icons/io5";
import Button from "components/Button/Button";

// Dummy data for testing
const DUMMY_DATA = {
  active: {
    status: "active",
    reason: "Your account is in good standing.",
  },
  suspended: {
    status: "suspended",
    reason:
      "Your account has been temporarily suspended due to multiple reports of inappropriate behavior. This suspension will last for 48 hours.",
  },
  banned: {
    status: "banned",
    reason:
      "Your account has been permanently banned for violating our community guidelines regarding harassment and hate speech.",
  },
};

const AccountStatus = () => {
  const [searchParams] = useSearchParams();
  const { addToast } = useToastContext();
  const navigate = useNavigate();
  const [accountInfo, setAccountInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const message = searchParams.get("message");
    if (message) {
      addToast(decodeURIComponent(message), "warning");
    }
  }, [searchParams, addToast]);

  useEffect(() => {
    const fetchAccountStatus = async () => {
      try {
        const data = await AUTH_API.getAccountStatus();
        setAccountInfo(data);
      } catch (err) {
        setError(err.message);
        addToast(err.message, "error");
      } finally {
        setLoading(false);
      }
    };

    fetchAccountStatus();
  }, [addToast]);

  const getStatusIcon = (status) => {
    switch (status) {
      case "active":
        return <IoCheckmarkCircle className={styles.statusIcon} />;
      case "suspended":
        return <IoWarning className={styles.statusIcon} />;
      case "banned":
        return <IoBan className={styles.statusIcon} />;
      case "deleted":
        return <IoTrash className={styles.statusIcon} />;
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const renderStatusContent = () => {
    if (!accountInfo) return null;

    switch (accountInfo.status) {
      case "deleted":
        return (
          <>
            <div className={styles.warningSection}>
              <IoWarning className={styles.warningIcon} />
              <div>
                <p className={styles.warningTitle}>Account Deleted</p>
                <p className={styles.warningText}>
                  Your account has been deleted and is pending permanent
                  removal.
                </p>
              </div>
            </div>

            <div className={styles.infoSection}>
              <div className={styles.infoItem}>
                <IoTrash className={styles.infoIcon} />
                <div>
                  <h3>Deletion Details</h3>
                  <p className={styles.infoDate}>
                    Deleted on: {formatDate(accountInfo.deletedAt)}
                  </p>
                  <p className={styles.reason}>{accountInfo.reason}</p>
                  <p className={styles.type}>
                    Type: {accountInfo.deletionType}
                  </p>
                </div>
              </div>

              {accountInfo.isRecoverable && (
                <div className={styles.infoItem}>
                  <IoTime className={styles.infoIcon} />
                  <div>
                    <h3>Recovery Window</h3>
                    <p className={styles.deadline}>
                      Available until:{" "}
                      {formatDate(accountInfo.recoveryDeadline)}
                    </p>
                    <p className={styles.note}>
                      After this deadline, your account will be permanently
                      deleted.
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.actions}>
              {accountInfo.isRecoverable && (
                <Button
                  onClick={() => navigate("/reactivate")}
                  variant="primary"
                  className={styles.reactivateButton}
                >
                  <IoRefresh /> Recover Account
                </Button>
              )}
              <Button
                variant="secondary"
                onClick={() => navigate("/lobby")}
                className={styles.cancelButton}
              >
                <IoArrowBack /> Return to Lobby
              </Button>
            </div>
          </>
        );

      case "suspended":
        return (
          <>
            <div className={styles.warningSection}>
              <IoWarning className={styles.warningIcon} />
              <div>
                <p className={styles.warningTitle}>Account Suspended</p>
                <p className={styles.warningText}>
                  Your account has been temporarily suspended.
                </p>
              </div>
            </div>

            <div className={styles.infoSection}>
              <div className={styles.infoItem}>
                <IoWarning className={styles.infoIcon} />
                <div>
                  <h3>Suspension Details</h3>
                  <p className={styles.reason}>{accountInfo.reason}</p>
                  {accountInfo.suspensionDuration && (
                    <p className={styles.type}>
                      <IoTime className={styles.typeIcon} />
                      Duration: {accountInfo.suspensionDuration}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>
        );

      case "banned":
        return (
          <div className={styles.infoSection}>
            <div className={styles.infoItem}>
              <IoBan className={styles.infoIcon} />
              <div>
                <h3>Account Banned</h3>
                <p className={styles.reason}>{accountInfo.reason}</p>
                {accountInfo.actionDate && (
                  <p className={styles.type}>
                    <IoTime className={styles.typeIcon} />
                    Banned on: {formatDate(accountInfo.actionDate)}
                  </p>
                )}
              </div>
            </div>
          </div>
        );

      default:
        return accountInfo.reason ? (
          <div className={styles.infoSection}>
            <div className={styles.infoItem}>
              <IoCheckmarkCircle className={styles.infoIcon} />
              <div>
                <h3>Account Active</h3>
                <p className={styles.reason}>{accountInfo.reason}</p>
              </div>
            </div>
          </div>
        ) : null;
    }
  };

  const renderRegistrationStatus = () => {
    if (!accountInfo) return null;

    // Define the registration steps in order
    const statuses = [
      {
        step: 1,
        label: "Registration",
        isComplete: accountInfo.isRegistered,
        isActive: true, // Always active as it's the first step
        icon: <IoPersonAdd />,
        message: accountInfo.isRegistered
          ? "Account successfully registered"
          : "Complete your registration to access all features",
        action: !accountInfo.isRegistered && (
          <Button
            onClick={() => navigate("/register")}
            variant="primary"
            size="sm"
            className={styles.actionButton}
          >
            Complete Registration
          </Button>
        ),
      },
      {
        step: 2,
        label: "Email Verification",
        isComplete: accountInfo.isEmailVerified,
        isActive: accountInfo.isRegistered, // Only active if registered
        icon: <IoMail />,
        message: accountInfo.isEmailVerified
          ? "Email verified"
          : accountInfo.isRegistered
          ? "Please verify your email address"
          : "Complete registration first",
        action: !accountInfo.isEmailVerified && accountInfo.isRegistered && (
          <Button
            onClick={() => AUTH_API.resendVerificationEmail()}
            variant="secondary"
            size="sm"
            className={styles.actionButton}
          >
            Resend Verification
          </Button>
        ),
      },
      {
        step: 3,
        label: "Admin Approval",
        isComplete: accountInfo.isAdminApproved,
        isActive: accountInfo.isEmailVerified, // Only active if email is verified
        icon: <IoHourglassOutline />,
        message: accountInfo.isAdminApproved
          ? "Account approved by admin"
          : accountInfo.isEmailVerified
          ? "Waiting for admin approval"
          : "Complete previous steps first",
      },
    ];

    return (
      <div className={styles.registrationProgress}>
        <h2 className={styles.sectionTitle}>Registration Progress</h2>
        <div className={styles.statusGrid}>
          {statuses.map((status, index) => (
            <div
              key={index}
              className={`${styles.statusCard} 
                ${status.isComplete ? styles.complete : styles.incomplete}
                ${!status.isActive ? styles.disabled : ""}
              `}
            >
              <div className={styles.statusHeader}>
                <span className={styles.stepNumber}>Step {status.step}</span>
              </div>
              <h3 className={styles.statusLabel}>{status.label}</h3>
              <div className={styles.statusIcon}>{status.icon}</div>
              <div className={styles.statusContent}>
                <p className={styles.statusMessage}>{status.message}</p>
                {status.action && (
                  <div className={styles.statusAction}>{status.action}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.loadingContainer}>
          <LoadingMessage message="Fetching account status..." />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.container}>
          <h1 className={styles.title}>Error Loading Account Status</h1>
          <p className={styles.reason}>{error}</p>
          <Button color="primary" onClick={() => navigate("/lobby")}>
            <IoArrowBack /> Return to Lobby
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Account Status</h1>
        </div>

        <div className={styles.content}>
          {renderRegistrationStatus()}
          {renderStatusContent()}
        </div>
      </div>
    </div>
  );
};

export default AccountStatus;
