// packages/client/src/pages/Register/StepUsername.jsx
import React, { useContext, useEffect, useRef, useState } from "react";
import validator from "validator";
import common from "../Register.module.css";
import self from "./StepUsername.module.css";
import { motion } from "framer-motion";
import cn from "classnames";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import * as USERS_API from "api/users";
import { ToastContext } from "contexts/ToastContext";

import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";

const InputWrapper = ({
  children,
  valid,
  helperText,
  isLoading = false,
  status,
}) => {
  const getStatusIcon = () => {
    if (isLoading) {
      return (
        <motion.div
          className={self.icon}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <LoadingSpinner />
        </motion.div>
      );
    }

    if (status === "success") {
      return (
        <motion.div
          className={cn(self.icon, self.success)}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <FaCheckCircle />
        </motion.div>
      );
    }

    if (status === "error") {
      return (
        <motion.div
          className={cn(self.icon, self.error)}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <FaTimesCircle />
        </motion.div>
      );
    }

    return null;
  };

  return (
    <div className={self.fieldContainer}>
      <div className={cn(self.inputWrapper, self[status])}>
        {children}
        {getStatusIcon()}
      </div>
      {helperText && (
        <motion.p
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className={cn(self.helperText, self[status])}
        >
          {helperText}
        </motion.p>
      )}
    </div>
  );
};

export const StepUsername = ({
  data,
  onSave,
  handleStepValidity,
  isCurrentStepValid,
}) => {
  const [username, setUsername] = useState(data.username || "");

  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { addToast, toastList, removeToast } = useContext(ToastContext);

  const [steps, setSteps] = useState([
    {
      target: '[data-tour="username-input"]',
      title: "Choose a Safe Username",
      content: (
        <div>
          <p>
            Your username should be unique but not reveal your real identity:
          </p>
          <ul
            style={{
              marginTop: "8px",
              paddingLeft: "20px",
              textAlign: "start",
            }}
          >
            <li>❌ No real names (e.g., "john.smith")</li>
            <li>❌ No birthdays or years (e.g., "sarah1990")</li>
            <li>❌ No locations (e.g., "nyc_guy")</li>
            <li>❌ No school/work info (e.g., "harvard2024")</li>
            <li>✅ Creative combinations (e.g., "dancing_potato")</li>
            <li>✅ Random interests (e.g., "coffee_lover_42")</li>
            <li>✅ Fun phrases (e.g., "cloud_surfer")</li>
          </ul>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: '[data-tour="username-input"]',
      title: "You Can Always Change It Later",
      content: (
        <div>
          <p>Don't stress about picking the perfect username!</p>
          <p style={{ marginTop: "8px" }}>
            You can change it anytime from your profile settings. Just make sure
            it's safe and appropriate for now.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
  ]);

  const [validationStatus, setValidationStatus] = useState("idle"); // 'idle' | 'success' | 'error'

  useEffect(() => {
    handleStepValidity(
      username.length > 0 && isUsernameAvailable && isAlphanumeric(username)
    );
  }, [username, isUsernameAvailable]);

  useEffect(() => {
    return () => {
      handleStepValidity(false, "reset");
    };
  }, []);

  const isAlphanumeric = (value) =>
    validator.isAlphanumeric(value, "en-US", { ignore: "_" });

  useEffect(() => {
    if (username.length === 0) {
      setMessage("");
      setValidationStatus("idle");
      return;
    }

    if (!isAlphanumeric(username)) {
      setMessage(
        "Username must contain only letters, numbers, and underscores"
      );
      setValidationStatus("error");
      return;
    }

    setIsLoading(true);
    const timer = setTimeout(() => {
      checkUsernameAvailability(username);
    }, 500);

    return () => clearTimeout(timer);
  }, [username]);

  useEffect(() => {
    if (toastList.length > 1) {
      removeToast(toastList[0].id); // Remove the previous toast
    }
  }, [toastList]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value.toLowerCase());
  };

  const checkUsernameAvailability = async (username) => {
    try {
      setIsLoading(true);
      const data = await USERS_API.checkAvailability(username);

      setIsUsernameAvailable(data.available);
      setMessage(data.message);
      setValidationStatus(data.available ? "success" : "error");

      if (data.available) {
        onSave({ username });
      }
    } catch (error) {
      setMessage(error.message || "Error checking username availability");
      setValidationStatus("error");
    } finally {
      setIsLoading(false);
    }
  };

  const inputRef = useRef(null);

  return (
    <>
      <JoyrideWrapper runOnInit steps={steps} />

      <div className={common.header}>
        <h1>What should people call you?</h1>
      </div>
      <div className={common["input-group"]}>
        <div className={common["label-tooltip"]}>
          <label>Username</label>
          <InfoTooltip content="Choose a unique username that will identify you in the community. Avoid using personal information." />
        </div>

        <InputWrapper
          isLoading={isLoading}
          status={validationStatus}
          helperText={message}
        >
          <input
            className={self["username-input"]}
            ref={inputRef}
            autoFocus
            required
            type="text"
            placeholder="Choose a username (lowercase only)"
            value={username}
            onChange={handleUsernameChange}
            data-tour="username-input"
          />
        </InputWrapper>
      </div>
    </>
  );
};
