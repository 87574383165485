// hooks/useInitializeChat.js
import { useCallback, useEffect, useRef, useState } from "react";
import * as CHATS_API from "api/chats";
import Chat from "classes/Chat";
import { useSocketContext } from "contexts/SocketContext"; // Import SocketContext

/**
 * Custom hook for managing chat loading and initialization.
 * This hook provides functionality to load user chats from the server
 * and initialize specific chats by their ID.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.userData - The user data object containing user information.
 * @param {Function} params.setChats - Function to update the chats state.
 * @param {Function} params.addToast - Function to display toast notifications.
 * @param {Function} params.navigate - Function to navigate to different routes.
 *
 * @returns {Object} An object containing the loadChats, fetchAndLoadChats, and initializeChat functions.
 *
 * @throws {ErrorResponse} Throws an error if the chat fetching fails during initialization.
 */
export const useChat = ({ userData, setChats, addToast, navigate }) => {
  const { socket } = useSocketContext(); // Use SocketContext
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [isInitializingChat, setIsInitializingChat] = useState(false); // New state for initializing chat

  /**
   * Loads chats from the server and updates the state with new instances of Chat.
   * This function ensures that any old chats are overwritten to prevent missing updates
   * between the current frontend state and the latest data from the server.
   * Previous chats can be fetched separately using the fetch more functionality.
   *
   * @param {Array<Object>} userChats - An array of chat objects received from the server.
   * @returns {void}
   */
  const loadChats = useCallback(
    (userChats) => {
      try {
        setIsLoadingChats(true); // Set loading state to true
        if (userChats.length > 0) {
          setChats((prevChats) => {
            const updatedChats = { ...prevChats };
            userChats.forEach((chat) => {
              updatedChats[chat._id] = new Chat(chat, userData?._id);
            });
            return updatedChats;
          });
        } else {
          setChats({});
        }
      } catch (error) {
        console.error("Error loading chats:", error);
        setChats({});
      } finally {
        setIsLoadingChats(false); // Reset loading state
      }
    },
    [userData?._id, setChats]
  );

  /**
   * A reference to track if chats are currently being loaded.
   * This ref holds a boolean value that indicates whether the loading process
   * for the user's chats is currently in progress. It prevents multiple simultaneous
   * loading requests for the same user.
   */
  const isFetchingChatsRef = useRef(false);

  const fetchAndLoadChats = useCallback(async () => {
    if (isFetchingChatsRef.current) {
      return;
    }
    isFetchingChatsRef.current = true;
    setIsLoadingChats(true); // Set loading state to true
    try {
      const userChats = await CHATS_API.fetchChats();
      loadChats(userChats);
    } catch (error) {
      console.error("Error fetching chats from server:", error);
      setChats({});
    } finally {
      isFetchingChatsRef.current = false;
      setIsLoadingChats(false); // Reset loading state
    }
  }, [loadChats, setChats]);

  /**
   * A reference to track the initialization state of chats.
   * This Set holds chat IDs that are currently being initialized to prevent
   * multiple simultaneous initialization requests for the same chat.
   * It ensures that if an initialization request for a chat is already in progress,
   * subsequent requests for that chat will not initiate another fetch until the
   * first one completes.
   */
  const isInitializingChatRef = useRef(new Set());

  /**
   * Initializes a specific chat by its chatId.
   * This function fetches the chat from the server and updates the local state.
   *
   * @param {string} chatId - The ID of the chat to initialize.
   * @returns {Promise<boolean>} Returns a promise that resolves to true if the chat is initialized successfully, false otherwise.
   *
   * @throws {ErrorResponse} Throws an error if the chat fetching fails.
   */
  const initializeChat = useCallback(
    async (chatId) => {
      if (isInitializingChatRef.current.has(chatId)) {
        return false;
      }

      isInitializingChatRef.current.add(chatId);
      setIsInitializingChat(true); // Set initializing state to true

      try {
        const chatData = await CHATS_API.fetchChatById(chatId);

        setChats((prevChats) => {
          if (prevChats[chatId]) {
            prevChats[chatId].update(chatData);
            return { ...prevChats };
          } else {
            const newChatInstance = new Chat(chatData, userData._id);
            return {
              ...prevChats,
              [chatId]: newChatInstance,
            };
          }
        });

        return true;
      } catch (error) {
        console.error("Failed to fetch chat", error);
        addToast(error.message, "error");
        if (error.redirect) {
          navigate(error.redirect);
        }
      } finally {
        isInitializingChatRef.current.delete(chatId);
        setIsInitializingChat(false); // Reset initializing state
      }
    },
    [userData?._id, setChats, addToast, navigate]
  );

  useEffect(() => {
    try {
      if (socket) {
        socket.on("latestChatData", (chats) => {
          loadChats(chats); // Call loadChats with the received data
        });

        return () => {
          socket.off("latestChatData");
        };
      }
    } catch (error) {
      console.error("Error setting up listener for latest chat data:", error);
    }
  }, [socket, loadChats]);

  return {
    loadChats,
    fetchAndLoadChats,
    initializeChat,
    isLoadingChats,
    isInitializingChat,
  }; // Return loading states
};
