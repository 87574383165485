import React, { createContext, useContext } from "react";
import { useWebRTC } from "hooks/useWebRTC";
import * as typedefs from "typedefs";
/**
 * @typedef {Object} CallContextType
 * @property {Function} initiateCall - Function to initiate a call.
 * @property {Function} acceptCall - Function to accept an incoming call.
 * @property {Function} endCall - Function to end the current call.
 * @property {string | null} callStatus - Status of the current call.
 * @property {string | null} callChatId - The chat ID associated with the current call.
 * @property {MediaStream | null} localStream - The local media stream.
 * @property {MediaStream | null} remoteStream - The remote media stream.
 * @property {number | null} callDuration - Duration of the current call in seconds.
 * @property {Function} cleanupChat - Function to clean up the chat resources after a call.
 * @property {typedefs.RTCOffer | null} receivedRTCOffer - The received RTC offer, if any.
 */

/** @type {React.Context<CallContextType | undefined>} */
const CallContext = createContext();

/**
 * Provides call-related functionalities and state through context.
 * @param {Object} props
 * @param {React.ReactNode} props.children - The child components that can access the context.
 * @returns {JSX.Element}
 */
export const CallContextProvider = ({ children }) => {
  const {
    initiateCall,
    acceptCall,
    endCall,
    callStatus,
    callChatId,
    localStream,
    remoteStream,
    callDuration,
    cleanupChat,
    receivedRTCOffer,
    rejectCall,
  } = useWebRTC();

  return (
    <CallContext.Provider
      value={{
        initiateCall,
        acceptCall,
        endCall,
        callStatus,
        receivedRTCOffer,
        callChatId,
        callDuration,
        localStream,
        remoteStream,
        cleanupChat,
        rejectCall,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};

/**
 * Hook to access call context values.
 * @returns {CallContextType} The call context value.
 */
export const useCallContext = () => {
  const context = useContext(CallContext);
  if (!context) {
    throw new Error("useCallContext must be used within a CallContextProvider");
  }
  return context;
};
