// packages/client/src/pages/Lobby/Lobby.jsx
import React, { useState, useEffect } from "react";
import { LobbyContextProvider } from "contexts/LobbyContext";

import styles from "./Lobby.module.css";

import PageWrapper from "components/PageWrapper/PageWrapper";

import FilterRoom from "./FilterRoom/FilterRoom";
import WaitingRoom from "./WaitingRoom/WaitingRoom";
import MatchModal from "./MatchModal/MatchModal";

import { useSocketContext } from "contexts/SocketContext";

const Lobby = () => {
  const {
    matchedUser,
    matchAccepted,
    acceptMatch,
    rejectMatch,
    error,
    matchedUserResponse,
    cleanupMatchState,
    matchComplete,
  } = useSocketContext();

  const [step, setStep] = useState(1);
  const [showMatchModal, setShowMatchModal] = useState(false);

  useEffect(() => {
    setShowMatchModal(!!matchedUser || !!error);
  }, [matchedUser, error]);

  const handleClose = () => {
    cleanupMatchState();
    setShowMatchModal(false);
  };

  return (
    <LobbyContextProvider>
      <PageWrapper ticker={true}>
        <div id="lobby" className={styles.lobby}>
          <FilterRoom step={step} setStep={setStep} />
          <WaitingRoom step={step} setStep={setStep} />
          {showMatchModal && (
            <MatchModal
              matchedUser={matchedUser}
              handleAccept={acceptMatch}
              handleReject={rejectMatch}
              matchedUserResponse={matchedUserResponse}
              matchAccepted={matchAccepted}
              error={error}
              onClose={handleClose}
              cleanupMatchState={cleanupMatchState}
              matchComplete={matchComplete}
            />
          )}
        </div>
      </PageWrapper>
    </LobbyContextProvider>
  );
};

export default Lobby;
