import React, { useEffect, useContext, useState } from "react";
import { LobbyContext } from "contexts/LobbyContext";
import cn from "classnames";
import styles from "../Lobby.module.css";
import self from "./WaitingRoom.module.css";

import Button from "components/Button/Button";
import Tag from "components/Tag/Tag";
import QuoteCarousel from "components/QuoteCarousel/QuoteCarousel";
import { ChatContext } from "contexts/ChatContext";
import { ToastContext } from "contexts/ToastContext";
import { useSocketContext } from "contexts/SocketContext";

const SelectedOption = ({ label }) => {
  return <div className={self.selectedOption}>{label}</div>;
};

const WaitingRoom = ({ step, setStep }) => {
  const active = step === 2;
  const { isSearching, startSearch, stopSearch } = useSocketContext();
  const { addToast } = useContext(ToastContext);
  const { story, selectedTags, selectedAgeRanges, selectedGenders } =
    useContext(LobbyContext);

  const handleBack = () => {
    stopSearch();

    setStep(1);
  };

  const handleSearch = () => {
    try {
      if (isSearching) {
        stopSearch();
      } else {
        startSearch({
          tags: selectedTags,
          story,
          ageRanges: selectedAgeRanges,
          genders: selectedGenders,
        });
      }
    } catch (error) {
      console.error(error.message); // Handle the error here
      addToast(error.message, "error");
      setStep(1);
    }
  };

  return (
    <div
      className={cn(styles.room, styles.waitingRoom, {
        [styles.active]: active,
        [styles.hidden]: !active,
      })}
    >
      <div className={styles.body}>
        <div className={styles.story}>
          {story.length > 0 ? (
            <p>{story}</p>
          ) : (
            <p className={styles.noStory}>No story provided</p>
          )}
        </div>
        <div className={styles.listContainer}>
          <div className={styles.listTitle}>Tags</div>
          <div className={self.list}>
            {selectedTags.length > 0 ? (
              selectedTags.map((tag, i) => (
                <SelectedOption key={i} label={tag.name} />
              ))
            ) : (
              <p className={styles.none}>Any</p>
            )}
          </div>
          <div className={styles.listTitle}>Age Ranges</div>
          <div className={self.list}>
            {selectedAgeRanges.length > 0 ? (
              selectedAgeRanges.map((range, i) => (
                <SelectedOption key={i} label={range.label} />
              ))
            ) : (
              <p className={styles.none}>Any</p>
            )}
          </div>
          <div className={styles.listTitle}>Genders</div>
          <div className={self.list}>
            {selectedGenders.length > 0 ? (
              selectedGenders.map((gender, i) => (
                <SelectedOption key={i} label={gender.label} />
              ))
            ) : (
              <p className={styles.none}>Any</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.btn}>
        <Button onClick={handleBack}>
          <p>Back</p>
        </Button>
        {!isSearching ? (
          <Button onClick={handleSearch}>
            <p>Start Search</p>
          </Button>
        ) : (
          <Button className={styles.stopSearch} onClick={handleSearch}>
            <p>Stop Search</p>
          </Button>
        )}
      </div>
      <div className={styles.quotes}>
        <QuoteCarousel tags={selectedTags} />
      </div>
    </div>
  );
};

export default WaitingRoom;
