import React, { Component } from "react";
import { fetchUserData } from "api/auth";
import Button from "components/Button/Button";
import PageWrapper from "components/PageWrapper/PageWrapper";
import ErrorMessage from "components/ErrorMessage/ErrorMessage"; // Import ErrorMessage
import styles from "./ErrorBoundary.module.css";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" }; // Added errorMessage to state
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true, errorMessage: error.message }; // Store error message
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  handleReload = async () => {
    this.setState({ hasError: false, errorMessage: "" }); // Reset errorMessage on reload
    try {
      const user = await fetchUserData(); // Fetch user data to check if user is still logged
      const currentPath = window.location.pathname;

      if (currentPath.includes("/user")) {
        window.location.href = `/user/${user.username}`; // Redirect to home if on user page
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error while reloading page", error);
      this.setState({ hasError: true, errorMessage: error.message });
    }
  };

  handleGoHome = () => {
    this.setState({ hasError: false, errorMessage: "" }); // Reset errorMessage on go home
    window.location.href = "/";
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <PageWrapper>
          <div className={styles.container}>
            <ErrorMessage
              message={this.state.errorMessage || "Something went wrong."}
            />{" "}
            {/* Display error message */}
            <div className={styles.actions}>
              <Button onClick={this.handleReload}>Reload Page</Button>
              <Button onClick={this.handleGoHome}>Go to Home</Button>
            </div>
          </div>
        </PageWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
