import React from "react";
import cn from "classnames";
import styles from "./LoadingSpinner.module.css";
import { FaSpinner } from "react-icons/fa";

const LoadingSpinner = ({ className }) => {
  return (
    <div className={cn(styles.spinnerContainer, className)}>
      <FaSpinner className={styles.spinner} />
    </div>
  );
};

export default LoadingSpinner;
