import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux"; // Ensure this is imported
import store from "./store/store"; // Ensure this is the correct path to your store
import App from "./App";
import "./styles.css"; //global styles
import "./tailwind.css";
import "./constants/colors.css";
import "./constants/heights.css";
import "./constants/breakpoints.css";
import "./constants/boxshadows.css";
import "./constants/zindex.css";
import { UserContextProvider } from "contexts/UserContext";
import { ChatContextProvider } from "contexts/ChatContext";
import { ToastContextProvider } from "contexts/ToastContext";
import { SoundContextProvider } from "contexts/SoundContext";
import { SocketContextProvider } from "contexts/SocketContext";
import { NotificationProvider } from "contexts/NotificationContext";
import { CallContextProvider } from "contexts/CallContext";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    {" "}
    {/* Ensure the store is wrapped with Provider */}
    <BrowserRouter>
      <SoundContextProvider>
        <ToastContextProvider>
          <SocketContextProvider>
            <NotificationProvider>
              <UserContextProvider>
                <ChatContextProvider>
                  <CallContextProvider>
                    <App />
                  </CallContextProvider>
                </ChatContextProvider>
              </UserContextProvider>
            </NotificationProvider>
          </SocketContextProvider>
        </ToastContextProvider>
      </SoundContextProvider>
    </BrowserRouter>
  </Provider>
);
