import { useState, useEffect, useRef, useCallback } from "react";
import {
  initializePeerConnection,
  getPeerConnection,
  closePeerConnection,
  addIceCandidate,
  processIceCandidateQueue,
} from "../../webrtc";
import { useSocketContext } from "contexts/SocketContext";
import { useToastContext } from "contexts/ToastContext";
import { useSoundContext } from "contexts/SoundContext";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";

export const useWebRTC = () => {
  const { socket } = useSocketContext();
  const { addToast } = useToastContext();
  const { userData } = useUserContext();
  const { callingSound, receivingCallSound } = useSoundContext();
  const navigate = useNavigate();

  /** @type {["idle" | "calling" | "receivingCall" | "inCall" | "callEnded", Function]} */
  const [callStatus, setCallStatusRaw] = useState("idle");
  /**
   * Sets the current call status.
   *
   * @param {string} newStatus - The new status to set. Acceptable statuses are:
   *   "idle" | "calling" | "receivingCall" | "inCall" | "callEnded".
   */
  const setCallStatus = useCallback((newStatus) => {
    const valid = ["idle", "calling", "receivingCall", "inCall", "callEnded"];
    if (valid.includes(newStatus)) {
      setCallStatusRaw(newStatus);
      callStatusRef.current = newStatus;
    } else {
      console.error(`Invalid call status: ${newStatus}`);
    }
  }, []);

  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(new MediaStream());

  /**
   * @enum {string}
   * @typedef {"new" | "connecting" | "connected" | "disconnected" | "failed" | "closed"} connectionState - The state of the WebRTC connection.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState}
   */

  /** @type {[connectionState, Function]} */
  const [connectionState, setConnectionState] = useState(null);

  /**
   * useEffect hook to monitor changes in the WebRTC connection state.
   * It updates the call status and displays toast notifications based on the connection state.
   *
   * - If the connection state is "connected", it sets the call status to "inCall"
   *   and shows a success notification indicating that the connection is established.
   * - If the connection state is "closed", it displays a notification indicating that the call has ended.
   */
  useEffect(() => {
    if (connectionState === "connected") {
      setCallStatus("inCall");
      addToast("Connected", "success");
    }

    if (connectionState === "closed") {
      addToast("Call ended", "orange");
    }
  }, [connectionState, addToast, setCallStatus]);

  /**
   * The ID of the chat associated with the current call.
   * This is set when there is an existing call to the chat ID from which the call originates.
   * It is used to identify the specific chat session for the ongoing call.
   */
  const [callChatId, setCallChatId] = useState(null);
  const [callDuration, setCallDuration] = useState(null);
  const [receivedRTCOffer, setReceivedRTCOffer] = useState({
    offer: null,
    chatId: null,
    from: null,
  });
  const [receivedRTCAnswer, setReceivedRTCAnswer] = useState({
    answer: null,
    chatId: null,
    from: null,
  });

  /**
   * Resets the received RTC offer state.
   */
  const resetReceivedRTCOffer = useCallback(() => {
    setReceivedRTCOffer({
      offer: null,
      chatId: null,
      from: null,
    });
  }, []);

  /**
   * Resets the received RTC answer state.
   */
  const resetReceivedRTCAnswer = useCallback(() => {
    setReceivedRTCAnswer({
      answer: null,
      chatId: null,
      from: null,
    });
  }, []);

  const [isSearching, setIsSearching] = useState(false);
  const [matchedUser, setMatchedUser] = useState(null);
  const [matchAccepted, setMatchAccepted] = useState(false);
  const [matchedUserResponse, setMatchedUserResponse] = useState(null);
  const [error, setError] = useState("");

  const callStatusRef = useRef(callStatus);

  /**
   * Initializes the media stream for the local user.
   *
   * @param {Object} options - Options for media stream.
   * @param {boolean} options.audio - Whether to include audio.
   * @param {boolean} options.video - Whether to include video.
   * @returns {Promise<MediaStream>} - The initialized media stream.
   */
  const initLocalStream = useCallback(
    async (options = { audio: true, video: false }) => {
      try {
        if (localStream) return localStream;

        const { video, audio } = options;
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video,
          audio,
        });
        setLocalStream(mediaStream);
        return mediaStream;
      } catch (error) {
        console.error("Error accessing media devices.", error);
        throw new Error("Error accessing media devices.");
      }
    },
    [localStream]
  );

  /**
   * Initiates a call to a specified chat ID.
   *
   * @param {string} chatId - The ID of the chat to call.
   */
  const initiateCall = async (chatId) => {
    try {
      let pc = initializePeerConnection();

      // If there is localStream, add it to the WebRTC connection
      if (localStream) {
        localStream.getTracks().forEach((track) => {
          pc.addTrack(track, localStream);
        });
      } else {
        const stream = await initLocalStream({ audio: true, video: false });
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });
      }

      // Set up a listener to monitor the WebRTC's connection state and add it to context state
      pc.onconnectionstatechange = () => {
        setConnectionState(pc.connectionState);
      };

      // Set up a listener to monitor for new tracks from the remote peer
      // (triggered when receiving a track from the remote peer)
      pc.ontrack = (event) => {
        const newRemoteStream = new MediaStream();
        event.streams[0].getTracks().forEach((track) => {
          newRemoteStream.addTrack(track);
        });
        setRemoteStream(newRemoteStream);
      };

      if (!receivedRTCOffer.offer) {
        setCallStatus("calling");
        setCallChatId(chatId);
        await createOffer(chatId);
      }
    } catch (error) {
      console.error("Error initiating call:", error);
      setError("Error initiating call.");
    }
  };

  /**
   * Creates an offer for a call and sends it via the socket.
   *
   * @param {string} chatId - The ID of the chat for the call.
   */
  const createOffer = async (chatId) => {
    try {
      let pc = getPeerConnection();

      /**
       * Event that fires off when a new offer ICE candidate is created.
       * This event is triggered whenever a new ICE candidate is generated
       * during the process of establishing a WebRTC connection.
       *
       * @param {RTCPeerConnectionIceEvent} event - The ICE candidate event containing the candidate.
       */
      pc.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("sendIceCandidate", {
            candidate: event.candidate,
            chatId,
          });
        }
      };

      const offer = await pc.createOffer();

      // This triggers the onicecandidate event when a new ICE candidate is generated.
      await pc.setLocalDescription(offer);

      socket.emit("requestCall", {
        offer: pc.localDescription,
        chatId,
      });
    } catch (error) {
      console.error("Error creating offer:", error);
      setError("Error creating offer.");
    }
  };

  useEffect(() => {
    /**
     * Listens for the "requestCall" event from the socket.
     * This event is triggered when the callee receives an incoming call request.
     *
     * @param {Object} data - The parameters from the requestCall event.
     * @param {Object} data.offer - The RTC offer for the call.
     * @param {string} data.chatId - The ID of the chat associated with the call.
     * @param {Object} data.from - The user who is initiating the call.
     * @param {string} data.from.username - The username of the user initiating the call.
     */
    const handleRequestCall = async (data) => {
      const { offer, chatId, from } = data;
      addToast(`${from.username} is calling...`, "orange");
      if (callStatusRef.current === "idle") {
        setCallStatus("receivingCall");
        setCallChatId(chatId);
        setReceivedRTCOffer({ offer, chatId, from });
      } else {
        socket.emit("isBusy", { chatId });
      }
    };

    /**
     * Listens for the "receiveIceCandidate" event from the socket.
     * This event is triggered when an ICE candidate is received for the WebRTC connection,
     * applicable for both the caller and callee sides.
     *
     * @param {Object} data - The parameters from the receiveIceCandidate event.
     * @param {RTCIceCandidate} data.candidate - The ICE candidate to be added to the connection.
     * @param {string} data.chatId - The ID of the chat associated with the ICE candidate.
     */
    const handleReceiveIceCandidate = async (data) => {
      const { candidate, chatId } = data;
      await addIceCandidate(candidate, chatId);
    };

    if (socket) {
      socket.on("requestCall", handleRequestCall);
      socket.on("receiveIceCandidate", handleReceiveIceCandidate);

      return () => {
        socket.off("requestCall", handleRequestCall);
        socket.off("receiveIceCandidate", handleReceiveIceCandidate);
      };
    }
  }, [
    socket,
    addToast,
    setCallChatId,
    setCallStatus,
    setReceivedRTCOffer,
    callStatusRef,
  ]);

  /**
   * Creates an answer to an incoming call and sends it via the socket.
   *
   * @param {string} chatId - The ID of the chat for the call.
   */
  const createAnswer = useCallback(
    async (receivedRTCOffer) => {
      try {
        const { chatId } = receivedRTCOffer;

        let pc = getPeerConnection();

        // Event that fires off when a new answer ICE candidate is created
        pc.onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit("sendIceCandidate", {
              candidate: event.candidate,
              chatId,
            });
          }
        };

        // Set the remote description using the received RTC offer
        await pc.setRemoteDescription(receivedRTCOffer.offer);

        // Process any queued ICE candidates for the chat
        processIceCandidateQueue(chatId);

        // Create an answer to the call
        const answer = await pc.createAnswer();

        // Set the local description with the created answer
        await pc.setLocalDescription(answer);

        // Emit the answer back to the socket
        socket.emit("answerCall", {
          answer: pc.localDescription,
          chatId,
        });
      } catch (error) {
        console.error("Error creating answer:", error);
        setError("Error creating answer.");
      }
    },
    [socket]
  );

  /**
   * Accepts an incoming call for a specified chat ID.
   *
   * @param {string} chatId - The ID of the chat to accept the call for.
   */
  const acceptCall = useCallback(
    async (chatId) => {
      try {
        let pc = initializePeerConnection();

        // If there is a localStream, add it to the WebRTC connection
        if (localStream) {
          localStream.getTracks().forEach((track) => {
            pc.addTrack(track, localStream);
          });
        } else {
          // If no local stream, initialize a new one
          const stream = await initLocalStream({ audio: true, video: false });
          // Add each track from the newly initialized stream to the peer connection
          stream.getTracks().forEach((track) => {
            pc.addTrack(track, stream);
          });
        }

        // Monitor the connection state of the peer connection
        pc.onconnectionstatechange = () => {
          setConnectionState(pc.connectionState);
        };

        // Set up a listener to monitor for new tracks from the remote peer
        // Triggered when receiving a track from the remote peer
        pc.ontrack = (event) => {
          const newRemoteStream = new MediaStream();
          event.streams[0].getTracks().forEach((track) => {
            newRemoteStream.addTrack(track);
          });
          // Set the new remote stream to the state
          setRemoteStream(newRemoteStream);
        };

        if (receivedRTCOffer?.offer) {
          await createAnswer(receivedRTCOffer);
        }
      } catch (error) {
        console.error("Error accepting call:", error);
        setError("Error accepting call.");
      }
    },
    [createAnswer, initLocalStream, localStream, receivedRTCOffer]
  );

  /**
   * Adds an answer to the peer connection.
   *
   * @param {RTCSessionDescriptionInit} answer - The RTC session description to set as the remote description.
   * @param {string} chatId - The ID of the chat for the call.
   * @param {Object} from - The user who accepted the call.
   */
  const addAnswer = useCallback(async ({ answer, chatId, from }) => {
    try {
      let pc = getPeerConnection();
      if (!pc.currentRemoteDescription) {
        await pc.setRemoteDescription(answer);
        processIceCandidateQueue(chatId);
      }
    } catch (error) {
      console.error("Error adding answer:", error);
      setError("Error accepting call.");
    }
  }, []);

  const rejectCall = useCallback(
    (chatId) => {
      try {
        if (socket) {
          socket.emit("rejectCall", { chatId });
        }
      } catch (error) {
        console.error("Error rejecting call:", error);
      }
    },
    [socket]
  );
  /**
   * Ends the current call for a specified chat ID.
   *
   * @param {string} chatId - The ID of the chat to end the call for.
   */
  const endCall = useCallback(
    async (chatId) => {
      try {
        if (callStatus === "receivingCall" && callChatId === chatId) {
          rejectCall();
        } else {
          setCallStatus("callEnded");
          socket.emit("callEnded", { chatId });
        }
      } catch (error) {
        console.error("Error ending call:", error);
        setError("Error ending call.");
      }
    },
    [socket, setCallStatus, callStatus, callChatId, rejectCall]
  );

  useEffect(() => {
    /**
     * Handles the "callAccepted" event from the socket.
     * This event is triggered when the callee (the user receiving the call) accepts the call.
     *
     * @param {Object} data - The event parameters from the callAccepted event.
     * @param {Object} data.answer - The RTC answer for the call.
     * @param {string} data.chatId - The ID of the chat associated with the call.
     * @param {Object} data.from - The user who accepted the call.
     * @param {string} data.from.username - The username of the user who accepted the call.
     */
    const handleCallAccepted = async (data) => {
      const { answer, chatId, from } = data;
      let receivedRTCAnswer = { answer, chatId, from };
      setReceivedRTCAnswer(receivedRTCAnswer);
      await addAnswer(receivedRTCAnswer);
    };

    /**
     * Handles the event triggered when a call is rejected.
     *
     * @param {Object} data - The parameters from the call rejection event.
     * @param {string} data.chatId - The ID of the chat associated with the rejected call.
     * @param {Object} data.from - The user who rejected the call.
     * @param {string} data.from.username - The username of the user who rejected the call.
     */
    const handleCallRejected = async (data) => {
      const { chatId, from } = data;
      addToast(`${from.username} has rejected the call.`);
      setCallStatus("callEnded");
      setCallChatId(null);
    };

    /**
     * Listens for the "isBusy" event from the socket.
     * This event is triggered when the user is currently busy and cannot accept a call.
     * It is sent from the caller side when the user is engaged in another call.
     *
     * @param {Object} data - The parameters from the isBusy event.
     * @param {string} data.chatId - The ID of the chat associated with the busy status.
     * @param {Object} data.from - The user who is busy.
     * @param {string} data.from.username - The username of the user who is busy.
     */
    const handleIsBusy = async (data) => {
      const { chatId, from } = data;
      addToast(`${from.username} is currently busy.`);
      setCallStatus("callEnded");
      setCallChatId(null);
    };

    /**
     * Listens for the "callEnded" event from the socket.
     * This event is triggered when the call is ended, applicable for both the caller and callee sides.
     *
     * @param {Object} data - The parameters from the endCall event.
     * @param {string} data.chatId - The ID of the chat associated with the call.
     * @param {Object} data.from - The user who ended the call.
     */
    const handleEndCall = async (data) => {
      const { chatId, from } = data;
      setCallStatus("callEnded");
      setCallChatId(null); // Reset the chat ID when the call ends
      addToast(`${from.username} has ended the call.`); // Notify the user
    };

    if (socket) {
      socket.on("callAccepted", handleCallAccepted);
      socket.on("callRejected", handleCallRejected); // Listen for call rejection
      socket.on("isBusy", handleIsBusy);
      socket.on("callEnded", handleEndCall);

      return () => {
        socket.off("callAccepted", handleCallAccepted);
        socket.off("callRejected", handleCallRejected); // Clean up call rejection listener
        socket.off("isBusy", handleIsBusy);
        socket.off("callEnded", handleEndCall); // Clean up end call listener
      };
    }
  }, [
    socket,
    addAnswer,
    setReceivedRTCAnswer,
    addToast,
    setCallStatus,
    setCallChatId,
  ]);

  /**
   * Cleans up the local media stream by stopping all tracks.
   */
  const cleanupLocalStream = useCallback(() => {
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
    }
  }, [localStream]);

  const cleanupRemoteStream = useCallback(() => {
    try {
      if (remoteStream) {
        remoteStream.getTracks().forEach((track) => track.stop());
        setRemoteStream(null);
      }
    } catch (error) {
      console.error("Error cleaning up remote stream:", error);
    }
  }, [remoteStream]);

  /**
   * Cleans up the current call state and resources.
   */
  const cleanupCall = useCallback(async () => {
    try {
      cleanupLocalStream();
      cleanupRemoteStream();
      setCallStatus("idle");
      setCallChatId(null);
      closePeerConnection();
      resetReceivedRTCOffer();
      resetReceivedRTCAnswer();
      setConnectionState(null);
    } catch (error) {
      console.error("Error cleaning up call:", error);
      setError("Error cleaning up call.");
    }
  }, [
    cleanupLocalStream,
    cleanupRemoteStream,
    setCallStatus,
    setCallChatId,
    resetReceivedRTCOffer,
    resetReceivedRTCAnswer,
    setConnectionState,
  ]);

  useEffect(() => {
    /**
     * Handles the "matchFound" event from the socket.
     * This event is triggered when a match is found.
     *
     * @param {Object} matchedUser - The matched user object.
     */
    const handleMatchFound = (matchedUser) => {
      setMatchedUser(matchedUser);
      setIsSearching(false);
    };

    /**
     * Handles the "pendingAcceptance" event from the socket.
     * This event indicates that the matched user is pending acceptance.
     */
    const handlePendingAcceptance = () => {
      setMatchedUserResponse("pending");
    };

    /**
     * Handles the "matchedUserAccepted" event from the socket.
     * This event is triggered when the matched user accepts the match.
     *
     * @param {Object} matchedUserData - The data of the matched user.
     */
    const handleMatchedUserAccepted = (matchedUserData) => {
      setMatchedUser(matchedUserData);
      setMatchedUserResponse("accepted");
    };

    /**
     * Handles the "matchedUserRejected" event from the socket.
     * This event is triggered when the matched user rejects the match.
     */
    const handleMatchedUserRejected = () => {
      setMatchedUser(null);
      setMatchedUserResponse("rejected");
      setIsSearching(true);
      setError("rejected");
    };

    /**
     * Handles the "joinChat" event from the socket.
     * This event is triggered when a user joins a chat.
     *
     * @param {string} chatId - The ID of the chat that the user is joining.
     */
    const handleJoinChat = (chatId) => {
      const type = "activeChats"; // Hardcoded as a chat
      navigate(`/chats?type=${type}&id=${chatId}`);
    };

    /**
     * Handles the "matchDisconnected" event from the socket.
     * This event is triggered when a match is disconnected.
     */
    const handleMatchDisconnected = () => {
      cleanupRemoteStream();
      setMatchedUser(null);
      setIsSearching(true);
      setError("disconnected");
    };

    if (socket) {
      socket.on("matchFound", handleMatchFound);
      socket.on("pendingAcceptance", handlePendingAcceptance);
      socket.on("matchedUserAccepted", handleMatchedUserAccepted);
      socket.on("matchedUserRejected", handleMatchedUserRejected);
      socket.on("joinChat", handleJoinChat);
      socket.on("matchDisconnected", handleMatchDisconnected);

      return () => {
        socket.off("matchFound", handleMatchFound);
        socket.off("pendingAcceptance", handlePendingAcceptance);
        socket.off("matchedUserAccepted", handleMatchedUserAccepted);
        socket.off("matchedUserRejected", handleMatchedUserRejected);
        socket.off("joinChat", handleJoinChat);
        socket.off("matchDisconnected", handleMatchDisconnected);
      };
    }
  }, [socket, navigate, cleanupRemoteStream]);

  /**
   * Starts searching for a match.
   *
   * @param {Array} tags - Tags for the search.
   * @param {string} story - Story for the search.
   */
  const startSearch = useCallback(
    (tags, story) => {
      if (userData?.isRegistered) {
        socket.emit(`startSearch`, { tags, story });
        setIsSearching(true);
      } else {
        throw new Error("User is not registered. Cannot start search.");
      }
    },
    [socket, userData]
  );

  /**
   * Stops searching for a match.
   */
  const stopSearch = useCallback(() => {
    socket.emit(`stopSearch`);
    setIsSearching(false);
    setMatchedUser(null);
  }, [socket]);

  /**
   * Accepts a match.
   */
  const acceptMatch = useCallback(() => {
    setMatchAccepted(true);
    setIsSearching(false);
    socket.emit("acceptMatch");
    setMatchedUser(null);
  }, [socket]);

  /**
   * Rejects a match.
   */
  const rejectMatch = useCallback(() => {
    setMatchAccepted(false);
    setIsSearching(false);
    socket.emit("rejectMatch");
    setMatchedUser(null);
  }, [socket]);

  /**
   * Cleans up the chat resources.
   */
  const cleanupChat = () => {
    setMatchedUser(null);

    cleanupLocalStream();
    cleanupRemoteStream();
  };

  useEffect(() => {
    let intervalId;
    let callTimeoutId;
    let callEndedId;

    if (callStatus === "calling") {
      callingSound.play();
    } else {
      callingSound.pause();
    }

    if (callStatus === "receivingCall") {
      receivingCallSound.play();
    } else {
      receivingCallSound.pause();
    }

    if (callStatus === "calling" || callStatus === "inCall") {
      setCallDuration(0);
      intervalId = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }

    if (callStatus === "calling") {
      callTimeoutId = setTimeout(() => {
        if (callStatusRef.current === "calling") {
          addToast("User not answering. Try again later.", "orange");
          endCall(callChatId);
        }
      }, 15500);
    }

    if (callStatus === "callEnded") {
      setConnectionState("closed");
      callEndedId = setTimeout(() => {
        cleanupCall();
      }, 2000);

      if (intervalId) clearInterval(intervalId);
      if (callTimeoutId) clearTimeout(callTimeoutId);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (callTimeoutId) clearTimeout(callTimeoutId);
      if (callEndedId) clearTimeout(callEndedId);
    };
  }, [
    callStatus,
    callChatId,
    addToast,
    cleanupCall,
    endCall,
    callingSound,
    receivingCallSound,
  ]);

  return {
    startSearch,
    stopSearch,
    acceptMatch,
    rejectMatch,
    matchAccepted,
    matchedUser,
    setMatchedUser,
    matchedUserResponse,
    setMatchedUserResponse,
    cleanupChat,
    callDuration,
    callStatus,
    localStream,
    remoteStream,
    connectionState,
    initiateCall,
    acceptCall,
    callChatId,
    endCall,
    cleanupCall,
    isSearching,
    setIsSearching,
    error,
    setError,
  };
};
