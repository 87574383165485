import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./StoryForm.module.css";
import { debounce } from "lodash";
import cn from "classnames";
import Button from "components/Button/Button";
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { FaEllipsis } from "react-icons/fa6";
import { useInteractions } from "@floating-ui/react";
import { useDismiss } from "@floating-ui/react";
import { FloatingArrow } from "@floating-ui/react";
import useTagsSearch from "hooks/useTagsSearch";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchTagByHyphenatedName } from "api/tags";
import { AnimatePresence, motion } from "framer-motion";
import Portal from "components/Portal/Portal";
import * as API from "api/stories";
import { ToastContext } from "contexts/ToastContext";
import { Tooltip } from "react-tooltip";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu"; // Import ReusableActionMenu
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import { FaInfoCircle } from "react-icons/fa";
import {
  Tooltip as ReusableTooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";
import ToggleButton from "components/Button/ToggleButton/ToggleButton";

/**
 * Moves the cursor to the end of the provided element.
 * @param {Element} element - The element to move the cursor to the end of.
 */
const moveCursorToEnd = (element) => {
  // Check if the element has content
  if (element && document.createRange) {
    // Create a new range
    const range = document.createRange();
    range.selectNodeContents(element);
    range.collapse(false); // Collapse the range to the end point. false means end of the content

    // Get the selection object
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges(); // Remove all ranges from the selection
      selection.addRange(range); // Add the new range
    }
  }
};

const TagInput = ({ selectedTags, setSelectedTags }) => {
  const [open, setOpen] = useState(false);
  const [tagQuery, setTagQuery] = useState("");

  const { tags, loading } = useTagsSearch(tagQuery, 1, 5); // Assuming you want to fetch the first page of results

  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    placement: "bottom-start",
    middleware: [
      offset(5),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
        padding: 5,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  // Add an onClick handler to the tagInputWrapper
  const handleWrapperClick = () => {
    // Focus on the .tag .input div
    refs.reference.current.focus();
  };

  useEffect(() => {
    if (tagQuery) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [tagQuery]);

  const clearTextContentAndState = () => {
    setTagQuery(""); // Clear the tag query state
    if (refs.reference.current) {
      refs.reference.current.textContent = ""; // Directly clear the contentEditable div's content
    }
  };

  const handleTagSelect = (tag) => {
    if (!selectedTags.find((t) => t._id === tag._id)) {
      // Prevent adding duplicates
      setSelectedTags((prev) => [...prev, tag]);
    }
    clearTextContentAndState();
  };

  const clearTagInput = (e) => {
    e.stopPropagation(); // Prevent click from propagating
    clearTextContentAndState();
    setOpen(false);
    setSelectedTags([]);
  };

  // Animation variants for the selected tag
  const tagVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, x: -100 },
  };

  const removeTag = (tagToRemove) => {
    setSelectedTags(selectedTags.filter((tag) => tag._id !== tagToRemove._id));
  };

  // Filter out selected tags from the dropdown
  const availableTags = tags.filter(
    (tag) => !selectedTags.find((selected) => selected._id === tag._id)
  );

  return (
    <>
      <div className={styles.tagInputWrapper} onClick={handleWrapperClick}>
        <AnimatePresence mode="sync">
          {selectedTags.map((tag) => (
            <motion.div
              key={tag._id}
              variants={tagVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5 }}
              className={styles.selectedTag}
            >
              {tag.name}
              <span className={styles.removeTag} onClick={() => removeTag(tag)}>
                ×
              </span>
            </motion.div>
          ))}

          {selectedTags.length === 3 ? ( // Limit the number of tags to 3
            <div />
          ) : (
            <div
              ref={refs.setReference}
              className={cn(styles.input, styles.tag)}
              placeholder="Tag"
              value={tagQuery}
              contentEditable="true"
              onFocus={(e) => moveCursorToEnd(e.currentTarget)}
              onInput={(e) => setTagQuery(e.currentTarget.textContent)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              // {...getReferenceProps()}
            />
          )}
        </AnimatePresence>
        {(tagQuery || selectedTags?.length > 0) && (
          <span className={styles.button} onClick={clearTagInput}>
            &times;
          </span>
        )}
      </div>
      {open && (loading || availableTags.length > 0) && (
        <Portal>
          <ul
            ref={refs.setFloating}
            className={styles.dropdown}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {availableTags.length > 0 &&
              availableTags.map((tag) => (
                <li
                  key={tag._id}
                  className={styles.dropdownItem}
                  onClick={() => handleTagSelect(tag)}
                >
                  {tag.name}
                </li>
              ))}
            {loading && (
              <li className={styles.dropdownItem}>
                <LoadingSpinner />
              </li>
            )}
            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill="white"
              tipRadius="3"
            />
          </ul>
        </Portal>
      )}
    </>
  );
};

// Renamed ToggleButton to EditorToggleButton for MenuBar
const EditorToggleButton = ({ onPressedChange, pressed, children }) => {
  return (
    <button
      onClick={onPressedChange}
      className={pressed ? styles.isActive : ""}
    >
      {children}
    </button>
  );
};

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className={styles.controlGroup}>
      <div className={styles.buttonGroup}>
        <EditorToggleButton
          onPressedChange={() => editor.chain().focus().toggleBold().run()}
          pressed={editor.isActive("bold")}
        >
          B
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() => editor.chain().focus().toggleItalic().run()}
          pressed={editor.isActive("italic")}
        >
          I
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() => editor.chain().focus().toggleUnderline().run()}
          pressed={editor.isActive("underline")}
        >
          U
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          pressed={editor.isActive("heading", { level: 1 })}
        >
          H1
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          pressed={editor.isActive("heading", { level: 2 })}
        >
          H2
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          pressed={editor.isActive("heading", { level: 3 })}
        >
          H3
        </EditorToggleButton>
        <EditorToggleButton
          onPressedChange={() =>
            editor.chain().focus().toggleBlockquote().run()
          }
          pressed={editor.isActive("blockquote")}
        >
          " "
        </EditorToggleButton>
      </div>
    </div>
  );
};

const TiptapEditor = ({ initialData, content, setContent }) => {
  const extensions = [
    StarterKit.configure({ strike: false }),
    Placeholder.configure({
      placeholder: "Write your story...",
    }),
    Underline,
  ];

  const editor = useEditor(
    {
      extensions,
      content: content ? content : initialData?.body,
      onUpdate: ({ editor }) => {
        setContent(editor.getHTML());
      },
    },
    [initialData]
  );

  return (
    <>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} className={styles.body} />
    </>
  );
};

const TitleInput = ({ value, onChange, placeholder }) => {
  const defaultValue = useRef(value);

  const handleInput = (event) => {
    if (onChange) {
      onChange(event.target.innerHTML);
    }
  };

  return (
    <div
      contentEditable
      onInput={handleInput}
      className={cn(styles.input, styles.title)}
      placeholder={placeholder}
      dangerouslySetInnerHTML={{ __html: defaultValue.current }}
    />
  );
};

function StoryForm({ initialData, onSubmit, status, statusMessage }) {
  const [title, setTitle] = useState(initialData?.title || "");
  const [bodyContent, setBodyContent] = useState(initialData?.body || "");
  const [selectedTags, setSelectedTags] = useState(initialData?.tags || []);
  const [titleTooltip, setTitleTooltip] = useState({
    open: false,
    message: "",
  });
  const [tagTooltip, setTagTooltip] = useState({ open: false, message: "" });
  const [bodyTooltip, setBodyTooltip] = useState({ open: false, message: "" });

  const location = useLocation(); // Use useLocation hook to access the current location

  useEffect(() => {
    // Load initial data if available
    if (initialData) {
      setTitle(initialData.title || "");
      setBodyContent(initialData.body || "");
      setSelectedTags(initialData.tags || []);
    }
  }, [initialData]);

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  useEffect(() => {
    // Function to fetch tag details
    const fetchTagDetails = async () => {
      const queryParams = new URLSearchParams(location.search);
      const tagParam = queryParams.get("tag");
      if (tagParam) {
        try {
          const response = await fetchTagByHyphenatedName(tagParam);
          setSelectedTags([response]); // Assuming the API directly returns the tag object
        } catch (error) {
          console.error("Error fetching tag details:", error);
          setSelectedTags([]); // Set selectedTag to undefined if there's an error
        }
      }
    };

    fetchTagDetails();
  }, [location.search]);

  const handlePostClick = (event) => {
    // Check if the clicked element or its parents have the .title or .tag class
    let element = event.target;
    let isTitleOrTagClicked = false;
    while (element && element !== document) {
      if (
        element.classList.contains(styles.title) ||
        element.classList.contains(styles.tag)
      ) {
        isTitleOrTagClicked = true;
        break;
      }
      element = element.parentNode;
    }

    // If neither .title nor .tag is clicked, and the click is within the .post div, focus on the latest .body element
    if (!isTitleOrTagClicked && event.currentTarget === event.target) {
      const bodies = document.querySelectorAll(`.${styles.body}`);
      const lastBody = bodies[bodies.length - 1];
      if (lastBody) lastBody.focus();
    }
  };

  // Function to show tooltip
  const showTooltip = (setter, message) => {
    setter({ open: true, message });
    setTimeout(() => setter({ open: false, message: "" }), 3000); // Hide after 3 seconds
  };

  const { addToast } = useContext(ToastContext);

  const handlePublish = async () => {
    // Validate inputs
    const validationErrors = [
      {
        condition: !title.trim(),
        message: "Title is required.",
        setter: setTitleTooltip,
      },
      {
        condition: selectedTags?.length === 0,
        message: "Tag is required.",
        setter: setTagTooltip,
      },
      {
        condition: !bodyContent.trim(),
        message: "Body is required.",
        setter: setBodyTooltip,
      },
    ];

    for (const { condition, message, setter } of validationErrors) {
      if (condition) {
        showTooltip(setter, message);
        return;
      }
    }

    try {
      const tagIds = selectedTags.map((tag) => tag._id);

      if (typeof onSubmit === "function") {
        await onSubmit({
          title,
          body: bodyContent,
          tags: tagIds,
          anonymized: true,
        });
      }
    } catch (error) {
      addToast("Failed to publish story.", "error");
    }
  };

  const dropdownOptions = [{ label: "Set Tags", onClick: () => {} }];

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <LoadingContainer>
          {status === "submitting" && (
            <LoadingMessage message={statusMessage} />
          )}
          {status === "error" && <p>{statusMessage}</p>}
          {status === "success" && <p>{statusMessage}</p>}
        </LoadingContainer>
        <Button
          onClick={handlePublish}
          disabled={
            !title.trim() ||
            !bodyContent.replace(/<[^>]*>/g, "").trim() ||
            status === "submitting"
          }
        >
          {status === "submitting" ? "Submitting..." : "Publish"}
        </Button>
        <ReusableActionMenu options={dropdownOptions} icon={FaEllipsis} />
      </div>
      <div className={styles.post} onClick={handlePostClick}>
        <section data-tooltip-id="title">
          <TitleInput
            value={initialData?.title || ""}
            onChange={handleTitleChange}
            placeholder="Title"
          />
        </section>
        <Portal>
          <Tooltip
            id="title"
            isOpen={titleTooltip.open}
            content={titleTooltip.message}
            className={styles.tooltip}
            place="bottom"
          ></Tooltip>
        </Portal>
        <section data-tooltip-id="tag">
          <TagInput
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        </section>
        <Portal>
          <Tooltip
            id="tag"
            isOpen={tagTooltip.open}
            content={tagTooltip.message}
            className={styles.tooltip}
            place="bottom"
          ></Tooltip>
        </Portal>
        <section data-tooltip-id="body">
          <TiptapEditor
            initialData={initialData}
            content={bodyContent}
            setContent={setBodyContent}
          />
        </section>
        <Portal>
          <Tooltip
            id="body"
            isOpen={bodyTooltip.open}
            content={bodyTooltip.message}
            className={styles.tooltip}
            place="bottom"
          ></Tooltip>
        </Portal>
      </div>
    </div>
  );
}

export default StoryForm;
