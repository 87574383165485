import { useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { useAdmin } from "hooks/useAdmin";

import styles from "./Overview.module.css";

const Overview = () => {
  const { users, fetchAllUsers, reports, fetchAllReports } = useAdmin();

  useEffect(() => {
    fetchAllUsers();
    fetchAllReports();
  }, []);

  const data = [
    { name: "January", users: 40 },
    { name: "February", users: 20 },
    { name: "March", users: 70 },
    { name: "April", users: 50 },
    { name: "May", users: 30 },
    { name: "June", users: 90 },
  ];

  return (
    <div>
      <div className={styles.overviewCards}>
        <div className={styles.registersCard}>
          <span>{users.length}</span>
          Registered Users
        </div>
        <div className={styles.reportsCard}>
          <span>{reports.length}</span>
          Reports
        </div>
      </div>
      <div className={styles.registrationsGraphContainer}>
        <h1>User Registrations</h1>
        <div className={styles.registrationsGraph}>
          <LineChart width={800} height={400} data={data}>
            <Line type="monotone" dataKey="users" stroke="white" />
            <CartesianGrid stroke="#ccc" vertical={false} />
            <XAxis dataKey="name" stroke="white" />
            <YAxis stroke="white" />
            <Tooltip />
          </LineChart>
        </div>
      </div>
    </div>
  );
};

export default Overview;
