import { useState, useCallback } from 'react';
import { fetchAllReviews } from 'api/reviews';

export const useReviews = () => {
  const [reviewsByAuthor, setReviewsByAuthor] = useState([]);
  const [reviewsBySubject, setReviewsBySubject] = useState([]);

  const fetchReviews = useCallback(async () => {
    try {
      const reviews = await fetchAllReviews();
      setReviewsByAuthor(reviews);

    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  }, [fetchAllReviews, setReviewsByAuthor]);

  const fetchReviewsBySubject = useCallback(async (username) => {
    try {
      const subjectReviews = await fetchAllReviews();

      const reviews = subjectReviews.filter((review) => review.subject && review.subject.username === username);
      setReviewsBySubject(reviews);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  }, []);

  return {
    reviewsByAuthor,
    fetchReviews,
    reviewsBySubject,
    fetchReviewsBySubject,
  }
}