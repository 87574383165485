import React, { useState, useEffect } from "react";
import Button from "components/Button/Button";
import { deleteUserById, checkAvailability, changeUsername } from "api/users";
import { fetchUserData } from "api/auth";
import styles from "./Settings.module.css";
import Modal from "components/Modal/Modal";
import ModalHeader from "components/Modal/ModalHeader";
import ModalBody from "components/Modal/ModalBody";
import ModalButtons from "components/Modal/ModalButtons";
import InputWrapper from "components/InputWrapper/InputWrapper";
import ToggleButton from "components/Button/ToggleButton/ToggleButton";

const Settings = () => {
  const [profile, setProfile] = useState({});
  const [username, setUsername] = useState("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(undefined);
  const [confirmDeleteText, setConfirmDeleteText] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false);
  const [lastUsernameChange, setLastUsernameChange] = useState(null);
  const [daysToChangeUsername, setDaysToChangeUsername] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [changeUsernameStatus, setChangeUsernameStatus] = useState("initial"); // 'initial' | 'loading' | 'success'
  const [emailNotifications, setEmailNotifications] = useState(false);

  const handleAccountDelete = async (profileUsername, confirmText) => {
    if (profileUsername !== confirmText) {
      throw new Error("Username does not match");
    }
    await deleteUserById(profile._id);
  };

  const handleCheckUsernameAvailability = async (username, profile) => {
    const response = await checkAvailability(username);

    setIsUsernameAvailable(response.available);

    if (username === profile.username) {
      setIsUsernameAvailable(undefined);
    }
  };

  const addTwoWeeks = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 14);

    return newDate;
  };

  const daysFromNow = (date) => {
    const now = new Date();
    const targetDate = new Date(date);
    const timeDifference = targetDate - now;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const handleChangeUsername = async (userId, username) => {
    const response = await changeUsername(userId, { username: username });
    return response;
  };

  const handleApplyChanges = async () => {
    if (isUsernameAvailable) {
      try {
        setChangeUsernameStatus("loading");
        const response = await handleChangeUsername(profile._id, username);
        setChangeUsernameStatus("success");

        // Wait a moment to show success state before redirecting
        setTimeout(() => {
          window.location.href = `/user/${response.user.username}/settings`;
        }, 1000);
      } catch (error) {
        setChangeUsernameStatus("initial");
        // Optionally handle error (show toast, etc)
      }
    }
  };

  const handleEmailNotificationsToggle = () => {
    const newValue = !emailNotifications;
    setEmailNotifications(newValue);
    // TODO: API call to update user preferences
  };

  useEffect(() => {
    if (!isDeleteModalOpen) {
      setConfirmDeleteText("");
    }
  }, [isDeleteModalOpen]);

  useEffect(() => {
    const fetchProfileData = async () => {
      const data = await fetchUserData();
      setProfile(data);
      setUsername(data.username);
      setLastUsernameChange(data.lastUsernameChange);
      setIsAdmin(data.role === "admin");
      setEmailNotifications(data.settings?.notifications?.email ?? false);
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    const dateChange = new Date(lastUsernameChange);
    const newDate = addTwoWeeks(dateChange);
    const days = daysFromNow(newDate);
    setDaysToChangeUsername(days);
  }, [lastUsernameChange]);

  const validateUsername = (username) => {
    if (!username || username.trim() === "") {
      return "Username cannot be empty";
    }
    if (username.length < 3) {
      return "Username must be at least 3 characters long";
    }
    if (username.length > 30) {
      return "Username must be less than 30 characters";
    }
    if (!/^[a-zA-Z0-9_-]+$/.test(username)) {
      return "Username can only contain letters, numbers, underscores, and hyphens";
    }
    return "";
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);

    // Clear previous errors
    setUsernameError("");
    setIsUsernameAvailable(undefined);

    // Validate username format
    const error = validateUsername(newUsername);
    if (error) {
      setUsernameError(error);
      return;
    }

    // Check availability if validation passes
    setIsCheckingUsername(true);
    handleCheckUsernameAvailability(newUsername, profile).finally(() =>
      setIsCheckingUsername(false)
    );
  };

  const isUsernameChangeDisabled = () => {
    if (isAdmin) return false; // Admins can always change username
    return daysToChangeUsername > 0;
  };

  return (
    <div className={styles.settingsWrapper}>
      <div className={styles.settingSection}>
        <h2 className={styles.settingHeader}>Change username</h2>
        <Button
          className={styles.changeUsernameButton}
          color="orange"
          size="small"
          shape="square"
          onClick={() => setIsUsernameModalOpen(true)}
        >
          Change Username
        </Button>
        <Modal
          isOpen={isUsernameModalOpen}
          onClose={() => setIsUsernameModalOpen(false)}
          showCloseButton={true}
        >
          <ModalHeader>Change Username</ModalHeader>
          <ModalBody>
            <InputWrapper
              valid={!usernameError && isUsernameAvailable}
              helperText={
                usernameError ||
                (!isAdmin && daysToChangeUsername > 0
                  ? `${daysToChangeUsername} days remaining until the username can be changed again.`
                  : isAdmin
                  ? "As an admin, you can change your username at any time."
                  : isUsernameAvailable !== undefined
                  ? isUsernameAvailable
                    ? "Username available"
                    : "Username already taken"
                  : "Changing your username can have unintended side effects.")
              }
              isLoading={isCheckingUsername}
            >
              <input
                className={styles.settingInput}
                value={username}
                onChange={handleUsernameChange}
                disabled={isUsernameChangeDisabled()}
              />
            </InputWrapper>
          </ModalBody>
          <ModalButtons>
            <Button
              color="orange"
              size="medium"
              shape="square"
              onClick={() => handleApplyChanges()}
              disabled={
                isUsernameChangeDisabled() ||
                !isUsernameAvailable ||
                !!usernameError ||
                isCheckingUsername ||
                changeUsernameStatus === "success" ||
                changeUsernameStatus === "loading"
              }
              fetchState={changeUsernameStatus}
            >
              {changeUsernameStatus === "success"
                ? "Username changed!"
                : "Apply Changes"}
            </Button>
            <Button
              color="gray"
              size="medium"
              shape="square"
              onClick={() => setIsUsernameModalOpen(false)}
              disabled={changeUsernameStatus === "loading"}
            >
              Cancel
            </Button>
          </ModalButtons>
        </Modal>
      </div>

      <div className={styles.settingSection}>
        <h2 className={styles.settingHeader}>Notifications</h2>
        <div className={styles.settingRow}>
          <div className={styles.settingLabel}>
            <span>Email notifications</span>
            <p className={styles.settingHint}>
              Receive email notifications about new matches and messages
            </p>
          </div>
          <ToggleButton
            isOn={emailNotifications}
            handleToggle={handleEmailNotificationsToggle}
          />
        </div>
      </div>

      <div className={styles.settingSection}>
        <h2 className={styles.settingHeader}>Delete Account</h2>
        <p className={styles.settingHint}>
          Once you delete your account, there is no going back. Please be
          certain.
        </p>
        <Button
          className={styles.deleteButton}
          color="error"
          size="small"
          shape="square"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete Account
        </Button>
      </div>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        showCloseButton={false}
      >
        <ModalHeader>Are you sure you want to delete your account?</ModalHeader>
        <ModalBody>
          <p className={styles.modalText}>This action cannot be undone.</p>
          <p className={styles.modalText}>
            To confirm account deletion please input your username:
          </p>
          <input
            className={styles.deleteInputField}
            value={confirmDeleteText}
            onChange={(e) => setConfirmDeleteText(e.target.value)}
          />
        </ModalBody>
        <ModalButtons>
          <div className={styles.modalButtons}>
            <Button
              color="error"
              size="medium"
              shape="square"
              onClick={() =>
                handleAccountDelete(profile.username, confirmDeleteText)
              }
              disabled={confirmDeleteText !== profile.username}
            >
              Confirm Delete
            </Button>
            <Button
              color="gray"
              size="medium"
              shape="square"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </ModalButtons>
      </Modal>
    </div>
  );
};

export default Settings;
