import React from "react";
import cn from "classnames";
import styles from "./ChatWindow.module.css";

const LastMessageDisplay = ({
  lastMessage,
  currentUserId,
  otherParticipantName,
}) => {
  if (!lastMessage) {
    return <div className={styles.noMessages}>No messages yet</div>;
  }

  const isUnread = !lastMessage.read?.includes(currentUserId);
  const isSystemMessage = lastMessage.type === "system";

  const renderContent = () => {
    const contentType = lastMessage.content.type;
    const contentData = lastMessage.content.data;

    switch (contentType) {
      case "text":
        return `${getSenderName()}: ${contentData.text}`;
      case "image":
        return `${getSenderName()} sent an image`;
      case "video":
        return `${getSenderName()} sent a video`;
      case "file":
        return `${getSenderName()} sent a file`;
      case "sharedtags":
        const tags = contentData.sharedTags.map((tag) => tag.name).join(", ");
        return `${getSenderName()} shared tags: ${tags}`;
      case "reachout":
        return getSenderName() === "You"
          ? `You reached out to ${otherParticipantName}`
          : `${otherParticipantName} reached out to you`;
      case "reachoutAccepted":
        const { recipient, sender } = contentData.reachoutAccepted;
        if (recipient._id === currentUserId) {
          return `You accepted a reachout from ${sender.username}`;
        } else {
          return `${sender.username} accepted your reachout`;
        }
      case "connectionAdded":
        const { connectionAdded } = contentData;
        if (connectionAdded.reachoutData) {
          return `New connection added: "${connectionAdded.reachoutData.resourceLabel}"`;
        }
        if (connectionAdded.matchData) {
          return `New match connection added with ${
            connectionAdded.matchData.user1.user._id === currentUserId
              ? connectionAdded.matchData.user2.pseudonym
              : connectionAdded.matchData.user1.pseudonym
          }`;
        }
        return "New connection added";
      case "matchCreated":
        const sharedTags = contentData.matchCreated.sharedTags
          .map((tag) => tag.name)
          .join(", ");
        return `You've matched with someone over the following tags: ${sharedTags}`;
      default:
        return contentData.text || "New message";
    }
  };

  const getSenderName = () => {
    if (isSystemMessage) {
      return "System";
    }
    const senderName =
      lastMessage.sender._id === currentUserId ? "You" : otherParticipantName;

    return senderName;
  };

  const content = renderContent();

  return (
    <div
      className={cn(styles.lastMessage, {
        [styles.unread]: isUnread,
        [styles.systemMessage]: isSystemMessage,
        [styles.reachOutMessage]: lastMessage.content.type === "reachout",
      })}
    >
      <span className={styles.content}>{content}</span>
    </div>
  );
};

export default LastMessageDisplay;
