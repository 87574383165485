import React from "react";
import styles from "./AuthOverlay.module.css";

/**
 * A reusable overlay component that can display different content while blurring the background
 *
 * @component
 * @param {Object} props
 * @param {React.ReactNode} props.children - The content to show behind the overlay
 * @param {React.ReactNode} props.overlayContent - The content to show in the overlay
 * @param {string} [props.className] - Additional CSS class for the modal
 *
 * @example
 * // Basic auth overlay
 * <AuthOverlay overlayContent={<LoginPrompt />}>
 *   <ProtectedContent />
 * </AuthOverlay>
 *
 * @example
 * // Email verification overlay
 * <AuthOverlay overlayContent={<VerifyEmailRequired />}>
 *   <FeaturePage />
 * </AuthOverlay>
 */
const AuthOverlay = ({ children, overlayContent, className }) => {
  return (
    <>
      <div className={styles.overlay}>
        <div className={`${styles.modal} ${className || ""}`}>
          {overlayContent}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </>
  );
};

export default AuthOverlay;
