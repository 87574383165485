import { useState, useEffect, useRef, useCallback } from "react";
import {
  initializePeerConnection,
  getPeerConnection,
  closePeerConnection,
  addIceCandidate,
  processIceCandidateQueue,
} from "webrtc";
import { useToastContext } from "contexts/ToastContext";
import { useSoundContext } from "contexts/SoundContext";
import * as typedefs from "typedefs";

import socket from "socket";

export const useWebRTC = () => {
  const instanceId = useRef(Date.now());

  const { addToast } = useToastContext();

  const { callingSound, receivingCallSound } = useSoundContext();

  /** @type {["idle" | "calling" | "receivingCall" | "inCall" | "callEnded", Function]} */
  const [callStatus, setCallStatusRaw] = useState("idle");

  useEffect(() => {}, [callStatus]);
  /**
   * Sets the current call status.
   *
   * @param {string} newStatus - The new status to set. Acceptable statuses are:
   *   "idle" | "calling" | "receivingCall" | "inCall" | "callEnded".
   */
  const setCallStatus = useCallback((newStatus) => {
    const valid = ["idle", "calling", "receivingCall", "inCall", "callEnded"];
    if (valid.includes(newStatus)) {
      setCallStatusRaw(newStatus);
      callStatusRef.current = newStatus;
    } else {
      console.error(`Invalid call status: ${newStatus}`);
    }
  }, []);

  const callStatusRef = useRef(callStatus);

  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(new MediaStream());

  /**
   * @enum {string}
   * @typedef {"new" | "connecting" | "connected" | "disconnected" | "failed" | "closed"} connectionState - The state of the WebRTC connection.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState}
   */

  /** @type {[connectionState, Function]} */
  const [connectionState, setConnectionState] = useState(null);

  /**
   * useEffect hook to monitor changes in the WebRTC connection state.
   * It updates the call status and displays toast notifications based on the connection state.
   *
   * - If the connection state is "connected", it sets the call status to "inCall"
   *   and shows a success notification indicating that the connection is established.
   * - If the connection state is "closed", it displays a notification indicating that the call has ended.
   */
  useEffect(() => {
    if (connectionState === "connected") {
      setCallStatus("inCall");
      addToast("Connected", "success");
    }

    if (connectionState === "closed") {
      addToast("Call ended", "orange");
    }
  }, [connectionState, addToast, setCallStatus]);

  /**
   * The ID of the chat associated with the current call.
   * This is set when there is an existing call to the chat ID from which the call originates.
   * It is used to identify the specific chat session for the ongoing call.
   */
  const callChatIdRef = useRef(null); // Ref to store the latest callChatId

  // Update callChatId state and ref
  const [callChatId, setCallChatId] = useState(null);
  useEffect(() => {
    callChatIdRef.current = callChatId;
  }, [callChatId]);

  const [callDuration, setCallDuration] = useState(null);

  /**
   * @type {[typedefs.RTCOffer, React.Dispatch<React.SetStateAction<typedefs.RTCOffer>>]}
   */
  const [receivedRTCOffer, setReceivedRTCOffer] = useState({
    offer: null,
    chatId: null,
    from: null,
  });
  const [receivedRTCAnswer, setReceivedRTCAnswer] = useState({
    answer: null,
    chatId: null,
    from: null,
  });

  /**
   * Resets the received RTC offer state.
   */
  const resetReceivedRTCOffer = useCallback(() => {
    setReceivedRTCOffer({
      offer: null,
      chatId: null,
      from: null,
    });
  }, []);

  /**
   * Resets the received RTC answer state.
   */
  const resetReceivedRTCAnswer = useCallback(() => {
    setReceivedRTCAnswer({
      answer: null,
      chatId: null,
      from: null,
    });
  }, []);

  const [error, setError] = useState("");

  /**
   * Initializes the media stream for the local user.
   *
   * @param {Object} options - Options for media stream.
   * @param {boolean} options.audio - Whether to include audio.
   * @param {boolean} options.video - Whether to include video.
   * @returns {Promise<MediaStream>} - The initialized media stream.
   */
  const initLocalStream = useCallback(
    async (options = { audio: true, video: false }) => {
      try {
        if (localStream) return localStream;

        const { video, audio } = options;
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video,
          audio,
        });
        setLocalStream(mediaStream);
        return mediaStream;
      } catch (error) {
        console.error("Error accessing media devices.", error);
        throw new Error("Error accessing media devices.");
      }
    },
    [localStream]
  );

  /**
   * Initiates a call to a specified chat ID.
   *
   * @param {string} chatId - The ID of the chat to call.
   */
  const initiateCall = async (chatId) => {
    try {
      let pc = initializePeerConnection();

      // If there is localStream, add it to the WebRTC connection
      if (localStream) {
        localStream.getTracks().forEach((track) => {
          pc.addTrack(track, localStream);
        });
      } else {
        const stream = await initLocalStream({ audio: true, video: false });
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });
      }

      // Set up a listener to monitor the WebRTC's connection state and add it to context state
      pc.onconnectionstatechange = () => {
        setConnectionState(pc.connectionState);
      };

      // Set up a listener to monitor for new tracks from the remote peer
      pc.ontrack = (event) => {
        const newRemoteStream = new MediaStream();
        event.streams[0].getTracks().forEach((track) => {
          newRemoteStream.addTrack(track);
        });
        setRemoteStream(newRemoteStream);
      };

      if (!receivedRTCOffer.offer) {
        setCallStatus("calling");
        setCallChatId(chatId);
        await createOffer(chatId);
      }
    } catch (error) {
      console.error("Error initiating call:", error);
      setError("Error initiating call.");
    }
  };

  /**
   * Creates an offer for a call and sends it via the socket.
   *
   * @param {string} chatId - The ID of the chat for the call.
   */
  const createOffer = async (chatId) => {
    try {
      let pc = getPeerConnection();

      /**
       * Event that fires off when a new offer ICE candidate is created.
       * This event is triggered whenever a new ICE candidate is generated
       * during the process of establishing a WebRTC connection.
       *
       * @param {RTCPeerConnectionIceEvent} event - The ICE candidate event containing the candidate.
       */
      pc.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("sendIceCandidate", {
            candidate: event.candidate,
            chatId,
          });
        }
      };

      const offer = await pc.createOffer();

      // This triggers the onicecandidate event when a new ICE candidate is generated.
      await pc.setLocalDescription(offer);

      socket.emit("initiateCall", {
        offer: pc.localDescription,
        chatId,
      });
    } catch (error) {
      console.error("Error creating offer:", error);
      setError("Error creating offer.");
    }
  };

  useEffect(() => {
    /**
     * Listens for the "initiateCall" event from the socket.
     * This event is triggered when the callee receives an incoming call request.
     *
     * @param {Object} data - The parameters from the requestCall event.
     * @param {Object} data.offer - The RTC offer for the call.
     * @param {string} data.chatId - The ID of the chat associated with the call.
     * @param {Object} data.from - The user who is initiating the call.
     * @param {string} data.from.username - The username of the user initiating the call.
     */
    const handleInitiateCall = async (data) => {
      const { offer, chatId, from } = data;
      addToast(`${from.username} is calling...`, "orange");
      if (callStatusRef.current === "idle") {
        setCallStatus("receivingCall");
        setCallChatId(chatId);
        setReceivedRTCOffer({ offer, chatId, from });
      }
    };

    /**
     * Listens for the "receiveIceCandidate" event from the socket.
     * This event is triggered when an ICE candidate is received for the WebRTC connection,
     * applicable for both the caller and callee sides.
     *
     * @param {Object} data - The parameters from the receiveIceCandidate event.
     * @param {RTCIceCandidate} data.candidate - The ICE candidate to be added to the connection.
     * @param {string} data.chatId - The ID of the chat associated with the ICE candidate.
     */
    const handleReceiveIceCandidate = async (data) => {
      const { candidate, chatId } = data;
      await addIceCandidate(candidate, chatId);
    };

    if (socket) {
      socket.on("initiateCall", handleInitiateCall);
      socket.on("receiveIceCandidate", handleReceiveIceCandidate);

      return () => {
        socket.off("initiateCall", handleInitiateCall);
        socket.off("receiveIceCandidate", handleReceiveIceCandidate);
      };
    }
  }, [
    addToast,
    setCallChatId,
    setCallStatus,
    setReceivedRTCOffer,
    callStatusRef,
  ]);

  /**
   * Creates an answer to an incoming call and sends it via the socket.
   *
   * @param {string} chatId - The ID of the chat for the call.
   */
  const createAnswer = useCallback(async (receivedRTCOffer) => {
    try {
      const { chatId } = receivedRTCOffer;

      let pc = getPeerConnection();

      // Event that fires off when a new answer ICE candidate is created
      pc.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("sendIceCandidate", {
            candidate: event.candidate,
            chatId,
          });
        }
      };

      // Set the remote description using the received RTC offer

      await pc.setRemoteDescription(receivedRTCOffer.offer);

      // Process any queued ICE candidates for the chat

      processIceCandidateQueue(chatId);

      // Create an answer to the call

      const answer = await pc.createAnswer();

      // Set the local description with the created answer

      await pc.setLocalDescription(answer);

      // Emit the answer back to the socket

      socket.emit("acceptCall", {
        answer: pc.localDescription,
        chatId,
      });
    } catch (error) {
      console.error("Error creating answer:", error);
      setError("Error creating answer.");
    }
  }, []);

  /**
   * Accepts an incoming call for a specified chat ID.
   *
   * @param {string} chatId - The ID of the chat to accept the call for.
   */
  const acceptCall = useCallback(
    async (chatId) => {
      try {
        let pc = initializePeerConnection();

        // If there is a localStream, add it to the WebRTC connection
        if (localStream) {
          localStream.getTracks().forEach((track) => {
            pc.addTrack(track, localStream);
          });
        } else {
          // If no local stream, initialize a new one

          const stream = await initLocalStream({ audio: true, video: false });

          // Add each track from the newly initialized stream to the peer connection
          stream.getTracks().forEach((track) => {
            pc.addTrack(track, stream);
          });
        }

        // Monitor the connection state of the peer connection
        pc.onconnectionstatechange = () => {
          setConnectionState(pc.connectionState);
        };

        // Set up a listener to monitor for new tracks from the remote peer
        // Triggered when receiving a track from the remote peer
        pc.ontrack = (event) => {
          const newRemoteStream = new MediaStream();
          event.streams[0].getTracks().forEach((track) => {
            newRemoteStream.addTrack(track);
          });
          // Set the new remote stream to the state
          setRemoteStream(newRemoteStream);
        };

        if (receivedRTCOffer?.offer) {
          await createAnswer(receivedRTCOffer);
        }
      } catch (error) {
        console.error("Error accepting call:", error);
        setError("Error accepting call.");
      }
    },
    [createAnswer, initLocalStream, localStream, receivedRTCOffer]
  );

  /**
   * Adds an answer to the peer connection.
   *
   * @param {RTCSessionDescriptionInit} answer - The RTC session description to set as the remote description.
   * @param {string} chatId - The ID of the chat for the call.
   * @param {Object} from - The user who accepted the call.
   */
  const addAnswer = useCallback(async ({ answer, chatId, from }) => {
    try {
      let pc = getPeerConnection();

      // Check if the signaling state is not "stable"
      if (pc.signalingState === "stable" || pc.currentRemoteDescription) {
        console.warn(
          "Remote description already set or signaling state is stable. Skipping addAnswer."
        );
        return;
      }

      await pc.setRemoteDescription(answer);
      processIceCandidateQueue(chatId);
    } catch (error) {
      console.error("Error adding answer:", error);
      setError("Error accepting call.");
    }
  }, []);

  const rejectCall = useCallback(
    (chatId) => {
      try {
        if (socket) {
          socket.emit("rejectCall", { chatId });
          setCallStatus("callEnded");
          setCallChatId(null);
          addToast("Call rejected.", "orange");
        }
      } catch (error) {
        console.error("Error rejecting call:", error);
      }
    },
    [setCallStatus, setCallChatId, addToast]
  );
  /**
   * Ends the current call for a specified chat ID.
   *
   * @param {string} chatId - The ID of the chat to end the call for.
   */
  const endCall = useCallback(
    async (chatId) => {
      try {
        socket.emit("endCall", { chatId });
        setCallStatus("callEnded");
        setCallChatId(null);
      } catch (error) {
        console.error("Error ending call:", error);
        setError("Error ending call.");
      }
    },
    [setCallStatus, setCallChatId]
  );

  /**
   * Cleans up the local media stream by stopping all tracks.
   */
  const cleanupLocalStream = useCallback(() => {
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
    }
  }, [localStream]);

  const cleanupRemoteStream = useCallback(() => {
    try {
      if (remoteStream) {
        remoteStream.getTracks().forEach((track) => track.stop());
        setRemoteStream(null);
      } else {
      }
    } catch (error) {
      console.error("Error cleaning up remote stream:", error);
    }
  }, [remoteStream]);

  /**
   * Cleans up the current call state and resources.
   */
  const cleanupCall = useCallback(async () => {
    try {
      cleanupLocalStream();
      cleanupRemoteStream();
      setCallStatus("idle");
      setCallChatId(null);
      closePeerConnection();
      resetReceivedRTCOffer();
      resetReceivedRTCAnswer();
      setConnectionState(null);
      setError("");
    } catch (error) {
      console.error("Error cleaning up call:", error);
      setError("Error cleaning up call.");
    }
  }, [
    cleanupLocalStream,
    cleanupRemoteStream,
    setCallStatus,
    setCallChatId,
    resetReceivedRTCOffer,
    resetReceivedRTCAnswer,
    setConnectionState,
  ]);

  /**
   * Cleans up the chat resources.
   */
  const cleanupChat = () => {
    cleanupLocalStream();
    cleanupRemoteStream();
  };

  const listenersAttachedRef = useRef(false);

  // Create refs for handlers
  const handleCallAcceptedRef = useRef();
  const handleCallRejectedRef = useRef();
  const handleEndCallRef = useRef();
  const handleCallErrorRef = useRef();
  const handleParticipantDisconnectedRef = useRef();

  // Define handler functions
  const handleCallAccepted = useCallback(
    async (data) => {
      const { answer, chatId, from } = data;

      setReceivedRTCAnswer({ answer, chatId, from });
      await addAnswer({ answer, chatId, from });
    },
    [addAnswer, setReceivedRTCAnswer]
  );

  const handleCallRejected = useCallback(
    (data) => {
      const { chatId, from } = data;
      if (callChatIdRef.current === chatId) {
        addToast(`${from.username} has rejected the call.`);
        setCallStatus("callEnded");
        setCallChatId(null);
      }
    },
    [addToast, setCallStatus, setCallChatId]
  );

  const handleEndCall = useCallback(
    (data) => {
      const { chatId, from, message } = data;

      if (callChatIdRef.current === chatId) {
        setCallStatus("callEnded");
        setCallChatId(null);
        const toastMessage = "Call ended.";

        addToast(toastMessage);
      } else {
      }
    },
    [addToast, setCallStatus, setCallChatId]
  );

  const handleCallError = useCallback(
    (data) => {
      console.error("Call error received:", data.message);
      addToast(data.message, "error");
      setCallStatus("callEnded");
      setCallChatId(null);
      cleanupCall();
    },
    [addToast, cleanupCall, setCallStatus, setCallChatId]
  );

  const handleParticipantDisconnected = useCallback(
    (data) => {
      const { username, reason } = data;

      setCallStatus("callEnded");
      addToast(`${username} has disconnected.`, "info");
    },
    [addToast, setCallStatus]
  );

  // Store handlers in refs
  handleCallAcceptedRef.current = handleCallAccepted;
  handleCallRejectedRef.current = handleCallRejected;
  handleEndCallRef.current = handleEndCall;
  handleCallErrorRef.current = handleCallError;
  handleParticipantDisconnectedRef.current = handleParticipantDisconnected;

  // Effect to manage socket listeners
  useEffect(() => {
    if (!socket || listenersAttachedRef.current) return;

    socket.on("callAccepted", handleCallAcceptedRef.current);

    socket.on("callRejected", handleCallRejectedRef.current);

    socket.on("callEnded", handleEndCallRef.current);

    socket.on("callError", handleCallErrorRef.current);

    socket.on(
      "callParticipantDisconnected",
      handleParticipantDisconnectedRef.current
    );

    listenersAttachedRef.current = true;

    // Cleanup function
    return () => {
      socket.off("callAccepted", handleCallAcceptedRef.current);
      socket.off("callRejected", handleCallRejectedRef.current);
      socket.off("callEnded", handleEndCallRef.current);
      socket.off("callError", handleCallErrorRef.current);
      socket.off(
        "callParticipantDisconnected",
        handleParticipantDisconnectedRef.current
      );
    };
  }, []);

  useEffect(() => {
    let intervalId;
    let callTimeoutId;
    let callEndedId;

    if (callStatus === "calling") {
      callingSound.play();
    } else {
      callingSound.pause();
    }

    if (callStatus === "receivingCall") {
      receivingCallSound.play();
    } else {
      receivingCallSound.pause();
    }

    if (callStatus === "calling" || callStatus === "inCall") {
      setCallDuration(0);
      intervalId = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }

    if (callStatus === "calling") {
      callTimeoutId = setTimeout(() => {
        if (callStatusRef.current === "calling") {
          addToast("User not answering. Try again later.", "orange");
          endCall(callChatId);
        }
      }, 15500);
    }

    if (callStatus === "callEnded") {
      setConnectionState("closed");
      cleanupCall();

      if (intervalId) clearInterval(intervalId);
      if (callTimeoutId) clearTimeout(callTimeoutId);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (callTimeoutId) clearTimeout(callTimeoutId);
      if (callEndedId) clearTimeout(callEndedId);
    };
  }, [
    callStatus,
    callChatId,
    addToast,
    cleanupCall,
    endCall,
    callingSound,
    receivingCallSound,
  ]);

  return {
    cleanupChat,
    callDuration,
    callStatus,
    receivedRTCOffer,
    localStream,
    remoteStream,
    connectionState,
    initiateCall,
    callChatId,
    rejectCall,
    acceptCall,
    endCall,
    cleanupCall,
    error,
    setError,
  };
};
