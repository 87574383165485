import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";

import PageWrapper from "components/PageWrapper/PageWrapper";
import { IoPersonCircle, IoEllipsisHorizontal } from "react-icons/io5";
import { fetchProfileSummary, fetchPublicProfile } from "api/users";
import { fetchUserData } from "api/auth";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";

import { searchStories } from "api/stories";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import { searchLifeExperiences } from "api/lifeExperiences";
import LifeExperienceContent from "./LifeExperienceContent";
import StoriesContent from "./StoriesContent";
import Reviews from "./Reviews";
import Settings from "./Settings";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { formatDate } from "utils/dates";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ErrorDisplay from "components/ErrorDisplay/ErrorDisplay"; // Import ErrorDisplay
import ScoreMetadataDisplay from "components/ScoreMetadataDisplay/ScoreMetadataDisplay";
import { useUserContext } from "contexts/UserContext";
import ReportModal from "components/ReportModal/ReportModal";
import { useToastContext } from "contexts/ToastContext";
import * as REPORTS_API from "api/reports";

const SummaryContent = ({ summaryData }) => {
  if (!summaryData) {
    return <LoadingMessage message="Loading summary..." />;
  }

  return (
    <div className={styles.summary}>
      <div className={styles.section}>
        <h3>Life Experiences</h3>
        <div className={styles.tags}>
          {summaryData.lifeExperienceTags.map((tag) => (
            <div key={tag._id} className={styles.tag}>
              {tag.name} ({tag.count})
            </div>
          ))}
        </div>
      </div>
      <div className={styles.section}>
        <h3>Stories I've Written About</h3>
        <div className={styles.tags}>
          {summaryData.storyTags.map((tag) => (
            <div key={tag._id} className={styles.tag}>
              {tag.name} ({tag.count})
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const QuotesContent = ({ quotes }) => {
  if (!quotes || quotes.length === 0) {
    return <div className={styles.placeholder}>No quotes available.</div>;
  }

  return (
    <div className={styles.quotes}>
      {quotes.map((quote, index) => (
        <div key={index} className={styles.quote}>
          <div>{quote.text}</div>
          <div>- {quote.author}</div>
        </div>
      ))}
    </div>
  );
};

const Profile = () => {
  const { userData: currentUser } = useUserContext();
  const { username } = useParams();
  const [profileData, setProfileData] = useState({});
  const [summaryData, setSummaryData] = useState(null);
  const [activeTab, setActiveTab] = useState("summary");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToastContext();

  const isOwnProfile = currentUser?.username === username;

  // Prevent accessing settings tab for other users' profiles
  useEffect(() => {
    if (!isOwnProfile && activeTab === "settings") {
      navigate(`/user/${username}`);
    }
  }, [isOwnProfile, activeTab, username, navigate]);

  // Load profile data
  useEffect(() => {
    const loadProfileData = async () => {
      try {
        const data = await fetchPublicProfile(username);

        // Check if the user is active
        if (!data.isActive) {
          if (data.isDeleted) {
            throw new Error("This account has been deleted.");
          } else if (data.isSuspended) {
            throw new Error("This account is suspended.");
          }
        }

        setProfileData(data);

        // Fetch stories and life experiences
        const [storiesData, lifeExperiencesData] = await Promise.all([
          searchStories({ userId: data._id }),
          searchLifeExperiences({ user: data._id }),
        ]);

        setProfileData((prev) => ({
          ...prev,
          stories: storiesData.results,
          undiscoveredStoriesCount: storiesData.undiscoveredCount,
          lifeExperiences: lifeExperiencesData.results,
          undiscoveredLifeExperiencesCount:
            lifeExperiencesData.undiscoveredCount,
        }));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadProfileData();
  }, [username]);

  useEffect(() => {
    if (profileData) {
      const fetchSummaryData = async () => {
        try {
          const summaryData = await fetchProfileSummary(username);
          setSummaryData(summaryData);
        } catch (error) {
          setError(error); // Set error state
        }
      };
      fetchSummaryData();
    }
  }, [profileData, username]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    switch (lastSegment) {
      case "experiences":
        setActiveTab("lifeExperiences");
        break;
      case "stories":
        setActiveTab("stories");
        break;
      case "quotes":
        setActiveTab("quotes");
        break;
      case "reviews":
        setActiveTab("reviews");
        break;
      case "settings":
        setActiveTab("settings");
        break;
      default:
        setActiveTab("summary");
    }
  }, [location]);

  const handleTabClick = (tab) => {
    navigate(`/user/${username}/${tab}`);
  };

  const handleLifeExperiencesChange = (
    updatedExperiences,
    undiscoveredCount = 0
  ) => {
    setProfileData((prevData) => ({
      ...prevData,
      lifeExperiences: updatedExperiences,
      undiscoveredLifeExperiencesCount: undiscoveredCount,
    }));
  };

  const handleStoriesChange = (updatedStories, undiscoveredCount = 0) => {
    setProfileData((prevData) => ({
      ...prevData,
      stories: updatedStories,
      undiscoveredStoriesCount: undiscoveredCount,
    }));
  };

  const getActionMenuOptions = () => {
    if (isOwnProfile) {
      return [
        {
          label: "Edit Profile",
          onClick: () => handleTabClick("settings"),
        },
      ];
    }

    return [
      {
        label: "Report User",
        onClick: () => setIsReportModalOpen(true),
        className: styles.dangerAction,
      },
    ];
  };

  const renderTabs = () => {
    const tabs = [
      { id: "summary", label: "Summary", path: "" },
      { id: "lifeExperiences", label: "Life Experiences", path: "experiences" },
      { id: "stories", label: "Stories", path: "stories" },
      { id: "quotes", label: "Quotes", path: "quotes" },
      { id: "reviews", label: "Reviews", path: "reviews" },
    ];

    // Add settings tab only for own profile
    if (isOwnProfile) {
      tabs.push({ id: "settings", label: "Settings", path: "settings" });
    }

    return (
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${styles.tab} ${activeTab === tab.id ? styles.activeTab : ""
              }`}
            onClick={() => handleTabClick(tab.path)}
          >
            {tab.label}
          </div>
        ))}
      </div>
    );
  };

  if (error) {
    return (
      <ErrorDisplay
        message={error.message}
        onRetry={() => window.location.reload()}
      />
    );
  }

  const renderTabContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    switch (activeTab) {
      case "summary":
        return <SummaryContent summaryData={summaryData} />;
      case "lifeExperiences":
        return (
          <LifeExperienceContent
            profileData={profileData}
            experiences={profileData?.lifeExperiences}
            undiscoveredCount={
              profileData?.undiscoveredLifeExperiencesCount || 0
            } // Pass undiscoveredCount to LifeExperienceContent
            onExperiencesChange={handleLifeExperiencesChange}
          />
        );
      case "stories":
        return (
          <StoriesContent
            profileData={profileData}
            stories={profileData?.stories}
            undiscoveredCount={profileData?.undiscoveredStoriesCount || 0} // Pass undiscoveredCount to StoriesContent
            onStoriesChange={handleStoriesChange}
          />
        );
      case "settings":
        return <Settings />;
      case "quotes":
        return <QuotesContent quotes={profileData?.quotes} />;
      case "reviews":
        return <Reviews username={username} />;
      default:
        return null;
    }
  };

  const handleReportUser = async (reportData) => {
    return REPORTS_API.reportUser({
      targetId: profileData._id,
      ...reportData,
    });
  };

  return (
    <PageWrapper>
      <div id="profile" className={styles.wrapper}>
        {/* Header Section */}
        <div className={styles.header}>
          {loading ? (
            <LoadingMessage message="Loading profile..." />
          ) : (
            <>
              <div className={styles.avatar}>
                {profileData?.profileImage ? (
                  <img
                    src={profileData.profileImage}
                    alt="Profile"
                    className={styles.profileImage}
                  />
                ) : (
                  <IoPersonCircle className={styles.defaultAvatar} />
                )}
              </div>
              <div className={styles.userInfo}>
                <div className={styles.username}>{profileData?.username}</div>
                <div className={styles.infoLine}>
                  {profileData?.ageRange || "Age range not available"}
                  &nbsp;·&nbsp;
                  {profileData?.gender
                    ? profileData.gender.charAt(0).toUpperCase() +
                    profileData.gender.slice(1)
                    : "N/A"}
                  &nbsp;·&nbsp;
                  {profileData?.location?.name || "Location not available"}
                </div>
                <div className={styles.infoLine}>
                  Joined on {formatDate(profileData?.createdAt)}
                </div>
                <ScoreMetadataDisplay
                  scores={{
                    trustScore: profileData?.scores.trustScore,
                    warmthScore: profileData?.scores.warmthScore,
                    totalReviews: profileData?.scores.totalReviews,
                  }}
                />
              </div>
              <div className={styles.actionMenu}>
                <ReusableActionMenu
                  options={getActionMenuOptions()}
                  icon={IoEllipsisHorizontal}
                />
              </div>
            </>
          )}
        </div>

        {/* Tabs */}
        {renderTabs()}

        {/* Tab Content */}
        <div className={styles.tabContent}>{renderTabContent()}</div>

        {/* Report Modal */}
        {isReportModalOpen && (
          <ReportModal
            isOpen={isReportModalOpen}
            onSubmit={handleReportUser}
            onClose={() => setIsReportModalOpen(false)}
            targetType="User"
            targetData={profileData}
            targetId={profileData._id}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default Profile;
