import React, { useState, useRef } from "react";
import styles from "./ReportModal.module.css";
import Modal from "components/Modal/Modal";
import ModalHeader from "components/Modal/ModalHeader";
import ModalBody from "components/Modal/ModalBody";
import Button from "components/Button/Button";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import cn from "classnames";
import { useToastContext } from "contexts/ToastContext";

const REPORT_REASONS = [
  { value: "harassment", label: "Harassment" },
  { value: "hate_speech", label: "Hate Speech" },
  { value: "inappropriate_content", label: "Inappropriate Content" },
  { value: "spam", label: "Spam" },
  { value: "impersonation", label: "Impersonation" },
  { value: "misinformation", label: "Misinformation" },
  { value: "other", label: "Other" },
];

const ReportModal = ({
  targetType,
  targetData,
  targetId,
  onSubmit,
  onClose,
  isOpen,
}) => {
  const { addToast } = useToastContext();
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const [errors, setErrors] = useState({});
  const [fetchState, setFetchState] = useState("initial");

  const textAreaRef = useRef();
  const selectRef = useRef();

  const validate = () => {
    const newErrors = {};

    if (!reason) {
      newErrors.reason = "Please select a reason for reporting.";
    }

    if (!details || details.trim() === "") {
      newErrors.details = "Please provide details about your report.";
    }

    setErrors(newErrors);

    setTimeout(() => {
      setErrors({});
    }, 3000);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setFetchState("loading");

    try {
      await onSubmit({ reason, details });
      setFetchState("success");
      addToast("Report submitted successfully", "success");
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      console.error("Failed to submit report:", err);
      setFetchState("error");
      addToast(err.message || "Failed to submit report", "error");
      setTimeout(() => {
        setFetchState("initial");
      }, 2000);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        Report{" "}
        {targetType === "LifeExperience" ? "Life Experience" : targetType}
      </ModalHeader>
      <ModalBody className={styles.body}>
        <p>
          This report will be reviewed by our moderation team. Please provide as
          much detail as possible.
        </p>

        <div
          className={cn(styles.formContainer, {
            [styles.disabled]: fetchState === "success",
          })}
        >
          <div className={styles.formField}>
            <label className={styles.formFieldLabel}>Reason:</label>
            <select
              ref={selectRef}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              disabled={fetchState === "success"}
            >
              <option value="">Select a reason...</option>
              {REPORT_REASONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          {errors.reason && (
            <FloatingTooltip anchorRef={selectRef} show={errors.reason}>
              <p style={{ color: "white" }}>{errors.reason}</p>
            </FloatingTooltip>
          )}

          <div className={styles.formField}>
            <label className={styles.formFieldLabel}>Details:</label>
            <textarea
              ref={textAreaRef}
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              disabled={fetchState === "success"}
              placeholder="Please provide specific details about your report..."
              maxLength={1000}
            />
          </div>

          {errors.details && (
            <FloatingTooltip anchorRef={textAreaRef} show={errors.details}>
              <p style={{ color: "white" }}>{errors.details}</p>
            </FloatingTooltip>
          )}

          <Button
            onClick={handleSubmit}
            disabled={fetchState === "success"}
            fetchState={fetchState}
            size="small"
            shape="rounded"
          >
            {fetchState === "loading"
              ? "Submitting..."
              : fetchState === "success"
              ? "Report submitted"
              : fetchState === "error"
              ? "Failed to submit"
              : "Submit Report"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReportModal;
