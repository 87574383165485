import React, { useState, useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import { debounce } from "lodash";
import styles from "./PublicSearchSection.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import { searchPublicLifeExperiences } from "api/public";

const PublicSearchSection = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query.trim()) {
        setSearchResults([]);
        setHasSearched(false);
        return;
      }

      setIsSearching(true);
      setHasSearched(true);

      try {
        const { results } = await searchPublicLifeExperiences({ query });
        setSearchResults(results);
      } catch (error) {
        console.error("Search failed:", error);
      } finally {
        setIsSearching(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const renderSearchResults = () => {
    if (!hasSearched) return null;

    if (isSearching) {
      return (
        <LoadingMessage
          message="Searching for similar experiences..."
          className={styles.searchLoading}
        />
      );
    }

    if (searchResults.length === 0) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={styles.noResults}
        >
          <p>No stories found matching your search.</p>
          <p className={styles.noResultsSuggestion}>
            Try using different words or be the first to share your experience.
          </p>
          <button
            className={styles.primaryButton}
            onClick={() => (window.location.href = "/login")}
          >
            Share Your Story
          </button>
        </motion.div>
      );
    }

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={styles.searchResults}
      >
        <h4>Found {searchResults.length} stories</h4>
        <div className={styles.resultsList}>
          {searchResults.map((experience) => (
            <LifeExperienceCard key={experience._id} lifeExpData={experience} />
          ))}
        </div>
      </motion.div>
    );
  };

  return (
    <section className={styles.publicSearch}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.8 }}
        className={styles.searchContainer}
      >
        <h3>See if others have shared your experience</h3>
        <div className={styles.searchInputGroup}>
          <input
            type="text"
            placeholder="Search stories (e.g., cancer, grief, divorce)"
            className={styles.searchInput}
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
      </motion.div>

      {renderSearchResults()}
    </section>
  );
};

export default PublicSearchSection;
