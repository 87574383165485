import React, { forwardRef, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ChatMessage.module.css";
import { motion, AnimatePresence } from "framer-motion";
import cn from "classnames";
import dayjs from "dayjs"; // Import dayjs
import { formatRelativeTime } from "utils/dates";
import { UserContext } from "contexts/UserContext";
import * as typedefs from "../../typedefs";

/**
 * ChatMessage component that displays a chat message.
 * @typedef {Object} Props - The component props.
 * @property {string} messageId - The ID of the chat message.
 * @property {{_id: string, username: string}} otherParticipant - Indicates if the message is from the other participant.
 * @property {React.Ref} ref - The ref object forwarded to the component.
 * @returns {JSX.Element} The rendered chat message component.
 */
const ChatMessage = forwardRef(({ message, otherParticipant }, ref) => {
  const [showTimestamp, setShowTimestamp] = useState(false);
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const toggleTimestamp = () => {
    setShowTimestamp(!showTimestamp);
  };

  // Use dayjs to format the timestamp
  const formattedTimestamp = dayjs(message.createdAt).format(
    "MMMM D, YYYY h:mm A"
  );
  const relativeTime = formatRelativeTime(message.createdAt);

  const handleStoryClick = useCallback(
    (id) => {
      navigate(`/stories/${id}`);
    },
    [navigate]
  );

  const renderSharedTags = useCallback(() => {
    if (
      message.content.data.sharedTags &&
      message.content.data.sharedTags.length > 0
    ) {
      return (
        <div className={styles.sharedTags}>
          {message.content.data.sharedTags.map((tag) => (
            <span key={tag._id} className={styles.tag}>
              {tag.name}
            </span>
          ))}
        </div>
      );
    }
    return null;
  }, [message.content.data.sharedTags]);

  // Determine message sender type based on the userId
  const messageSenderType =
    message.type === "system"
      ? "system"
      : message.sender._id === userData._id
      ? "currentUser"
      : otherParticipant && message.sender._id === otherParticipant._id
      ? "otherUser"
      : message.type; // Use message.type for system messages

  const renderReachout = useCallback(() => {
    const reachout = message.content.data.reachout;
    if (reachout) {
      const isCurrentUserSender = messageSenderType === "currentUser";
      const reachoutMessage = isCurrentUserSender
        ? `You reached out to ${otherParticipant?.username}:`
        : `${otherParticipant?.username} reached out to you:`;

      return (
        <div className={styles.reachoutContainer}>
          <div className={styles.reachoutHeader}>{reachoutMessage}</div>
          <div className={styles.reachoutBody}>
            <div className={styles.reachoutMessage}>{reachout.message}</div>
          </div>
        </div>
      );
    }
    return null;
  }, [
    message.content.data.reachout,
    otherParticipant?.username,
    messageSenderType,
  ]);

  const renderImage = useCallback(
    () => (
      <img
        src={message.content.data.url}
        alt="message"
        className={styles.image}
      />
    ),
    [message.content.data.url]
  );

  const renderVideo = useCallback(
    () => (
      <video controls className={styles.video}>
        <source src={message.content.data.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ),
    [message.content.data.url]
  );

  const renderFile = useCallback(
    () => (
      <a href={message.content.data.url} download className={styles.file}>
        Download File
      </a>
    ),
    [message.content.data.url]
  );

  const renderReachoutAccepted = useCallback(() => {
    const { _id, recipient, sender } = message.content.data.reachoutAccepted;

    const otherParticipant =
      recipient._id === userData._id ? sender : recipient;

    const navigateToReachout = () => {
      // Assuming you have a navigate function from react-router or similar
      navigate(`/chats?type=reachouts&id=${_id}`);
    };

    return (
      <div className={styles.systemMessage}>
        {recipient._id === userData._id ? (
          <div>
            You've accepted a{" "}
            <span onClick={navigateToReachout} className={styles.link}>
              reachout
            </span>{" "}
            and stumbled into {otherParticipant.username}.
          </div>
        ) : (
          <div>
            Your{" "}
            <span onClick={navigateToReachout} className={styles.link}>
              reachout
            </span>{" "}
            was accepted and you've stumbled into {otherParticipant.username}.
          </div>
        )}
      </div>
    );
  }, [message.content.data.reachoutAccepted, userData, navigate]);

  const renderConnectionAdded = useCallback(() => {
    const { connectionAdded } = message.content.data;
    if (!connectionAdded) return null;

    const otherParticipant = connectionAdded.participants.find(
      (participant) => participant._id !== userData._id
    );

    if (connectionAdded.reachoutData) {
      return (
        <div className={cn(styles.systemMessage, styles.connectionAdded)}>
          <div>
            <p>
              A new connection was added to your chat with{" "}
              {otherParticipant.username}{" "}
            </p>
            <p className={styles.resourceText}>
              "{connectionAdded.reachoutData.resourceLabel}"
            </p>
          </div>
        </div>
      );
    }

    if (connectionAdded.matchData) {
      const isUser1 = connectionAdded.matchData.user1.user._id === userData._id;
      const currentUser = isUser1
        ? connectionAdded.matchData.user1
        : connectionAdded.matchData.user2;
      const otherUser = isUser1
        ? connectionAdded.matchData.user2
        : connectionAdded.matchData.user1;

      return (
        <div className={cn(styles.systemMessage, styles.connectionAdded)}>
          <div>
            <p>
              A new connection was added to your chat with{" "}
              {otherParticipant.username}
            </p>
            <p className={styles.resourceText}>
              "Match with {otherUser.pseudonym}"
            </p>
            <div className={styles.stories}>
              <div className={styles.story}>
                <span className={styles.label}>Their story:</span>{" "}
                {otherUser.story}
              </div>
              <div className={styles.story}>
                <span className={styles.label}>Your story:</span>{" "}
                {currentUser.story}
              </div>
            </div>
            <div className={styles.sharedTags}>
              {connectionAdded.matchData.sharedTags.map((tag) => (
                <span key={tag._id} className={styles.tag}>
                  {tag.name}
                </span>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return null;
  }, [message.content.data, userData._id]);

  const renderMatchCreated = useCallback(() => {
    const { matchCreated } = message.content.data;
    if (!matchCreated) return null;

    // Find if current user is user1 or user2 by checking user ID
    const isUser1 = matchCreated.participants.user1.user === userData._id;
    const currentUser = isUser1
      ? matchCreated.participants.user1
      : matchCreated.participants.user2;
    const otherUser = isUser1
      ? matchCreated.participants.user2
      : matchCreated.participants.user1;

    return (
      <div className={cn(styles.systemMessage, styles.matchCreated)}>
        <div>
          <p>
            You've matched with{" "}
            <span className={styles.pseudonym}>{otherUser.pseudonym}</span>
          </p>
          <div className={styles.stories}>
            <div className={styles.story}>
              <span className={styles.label}>Their story:</span>{" "}
              {otherUser.story}
            </div>
            <div className={styles.story}>
              <span className={styles.label}>Your story:</span>{" "}
              {currentUser.story}
            </div>
          </div>
          <div className={styles.sharedTags}>
            {matchCreated.sharedTags.map((tag) => (
              <span key={tag._id} className={styles.tag}>
                {tag.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }, [message.content.data, userData._id]);

  const renderMatchAccepted = useCallback(() => {
    const { matchAccepted } = message.content.data;
    if (!matchAccepted) return null;

    // Find if current user is user1 or user2 by checking user ID
    const isUser1 = matchAccepted.participants.user1.user === userData._id;
    const currentUser = isUser1
      ? matchAccepted.participants.user1
      : matchAccepted.participants.user2;
    const otherUser = isUser1
      ? matchAccepted.participants.user2
      : matchAccepted.participants.user1;

    // Check who accepted based on the acceptedAt field
    const didCurrentUserAccept = currentUser.acceptedAt !== undefined;

    return (
      <div className={cn(styles.systemMessage, styles.matchAccepted)}>
        {didCurrentUserAccept ? (
          <p>
            You've accepted the match with{" "}
            <span className={styles.pseudonym}>{otherUser.pseudonym}</span>
          </p>
        ) : (
          <p>
            <span className={styles.pseudonym}>{otherUser.pseudonym}</span> has
            accepted your match
          </p>
        )}
      </div>
    );
  }, [message.content.data, userData._id]);

  const renderMatchCompleted = useCallback(() => {
    const { matchCompleted } = message.content.data;
    if (!matchCompleted) return null;

    // Find if current user is user1 or user2 by checking user ID
    const isUser1 =
      matchCompleted.matchId.participants.user1.user._id === userData._id;

    const currentUser = isUser1
      ? matchCompleted.matchId.participants.user1
      : matchCompleted.matchId.participants.user2;

    const otherUser = isUser1
      ? matchCompleted.matchId.participants.user2
      : matchCompleted.matchId.participants.user1;

    const isAcceptingUser = matchCompleted.acceptingUserId._id === userData._id;

    const navigateToChat = () => {
      navigate(`/chats?type=chats&id=${message.chatId}`);
    };

    const navigateToMatch = () => {
      navigate(`/chats?type=matches&id=${message.matchId}`);
    };

    return (
      <div className={cn(styles.systemMessage, styles.matchCompleted)}>
        {isAcceptingUser ? (
          <p>
            You have accepted the{" "}
            <span onClick={navigateToMatch} className={styles.link}>
              match
            </span>{" "}
            and{" "}
            <span onClick={navigateToChat} className={styles.link}>
              stumbled
            </span>{" "}
            into{" "}
            <span className={styles.username}>{otherUser.user.username}</span>
          </p>
        ) : (
          <p>
            <span className={styles.pseudonym}>{otherUser.pseudonym}</span> has
            accepted the{" "}
            <span onClick={navigateToMatch} className={styles.link}>
              match
            </span>{" "}
            and you have{" "}
            <span onClick={navigateToChat} className={styles.link}>
              stumbled
            </span>{" "}
            into{" "}
            <span className={styles.username}>{otherUser.user.username}</span>
          </p>
        )}
      </div>
    );
  }, [message.chatId, message.matchId, userData._id, navigate]);

  const renderReachoutCreated = useCallback(() => {
    const { reachoutCreated } = message.content.data;
    if (!reachoutCreated) return null;

    const isCurrentUserSender = reachoutCreated.senderId === userData._id;
    const isCurrentUserRecipient = reachoutCreated.recipientId === userData._id;

    const senderName = isCurrentUserSender
      ? "You"
      : reachoutCreated.senderPseudonym;
    const recipientName = isCurrentUserRecipient
      ? "you"
      : reachoutCreated.recipientPseudonym;

    const resourceTypeMap = {
      LifeExperience: "life experience",
      Story: "story",
    };

    const reachoutMessage = isCurrentUserSender
      ? `You reached out to ${recipientName} over their ${
          resourceTypeMap[reachoutCreated.resourceType]
        } "${reachoutCreated.resourceData.label}"`
      : `${senderName} reached out to you over your ${
          resourceTypeMap[reachoutCreated.resourceType]
        } "${reachoutCreated.resourceData.label}"`;

    return (
      <div className={styles.reachoutContainer}>
        <div className={styles.reachoutHeader}>
          {reachoutCreated.resourceType === "Story" ? (
            <>
              {reachoutMessage.split('"')[0]}
              <a
                href={`/stories/${reachoutCreated.resourceId}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.resourceLink}
              >
                "{reachoutCreated.resourceData.label}"
              </a>
            </>
          ) : (
            reachoutMessage
          )}
        </div>
        <div className={styles.reachoutBody}>
          <div className={styles.reachoutMessage}>
            {reachoutCreated.message}
          </div>
        </div>
      </div>
    );
  }, [message.content.data, userData._id]);

  const renderContent = useCallback(() => {
    switch (message.content.type) {
      case "text":
        return <div>{message.content.data.text}</div>;
      case "image":
        return renderImage();
      case "video":
        return renderVideo();
      case "file":
        return renderFile();
      case "sharedtags":
        return (
          <div>
            {message.content.data.text} {renderSharedTags()}
          </div>
        );
      case "reachout":
        return renderReachout();
      case "reachoutAccepted":
        return renderReachoutAccepted();
      case "connectionAdded":
        return renderConnectionAdded();
      case "matchCreated":
        return renderMatchCreated();
      case "matchAccepted":
        return renderMatchAccepted();
      case "matchCompleted":
        return renderMatchCompleted();
      case "reachoutCreated":
        return renderReachoutCreated();
      default:
        return <div>{message.content.data.text}</div>;
    }
  }, [
    message.content.type,
    message.content.data.text,
    renderFile,
    renderImage,
    renderVideo,
    renderSharedTags,
    renderReachoutAccepted,
    renderConnectionAdded,
    renderMatchCreated,
    renderMatchAccepted,
    renderMatchCompleted,
    renderReachoutCreated,
  ]);

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut" },
    },
    exit: {
      y: -10,
      opacity: 0,
      transition: { duration: 0.5, ease: "easeIn" },
    },
  };

  return (
    <div
      ref={ref}
      className={cn(styles.message, {
        [styles.currentUser]: messageSenderType === "currentUser",
        [styles.otherUser]: messageSenderType === "otherUser",
        [styles.system]: messageSenderType === "system",
        [styles.reachout]: message.content.type === "reachout",
      })}
    >
      <div className={styles.body} onClick={toggleTimestamp}>
        {renderContent()}
      </div>
      <AnimatePresence>
        {showTimestamp && (
          <motion.div
            key={formattedTimestamp}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.25 }}
            className={styles.timestamp}
          >
            {relativeTime}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default ChatMessage;
