import { useState, useRef, useCallback } from "react";
import { fetchPublicProfile } from "api/users"; // Assuming this is the API call to fetch user profiles

const CACHE_DURATION = 5 * 60 * 1000; // Example cache duration, adjust as needed

export const useUserProfiles = () => {
  const [userProfiles, setUserProfiles] = useState({});
  const ongoingProfileFetches = useRef({});

  const isProfileStale = useCallback(
    (userId) => {
      const profile = userProfiles[userId];
      if (!profile) {
        return true;
      }
      const now = Date.now();
      const isStale = now - profile.timestamp > CACHE_DURATION;

      return isStale;
    },
    [userProfiles]
  );

  const fetchAndCacheUserProfile = useCallback(
    async (userId, forceRefresh = false) => {
      if (ongoingProfileFetches.current[userId]) {
        return ongoingProfileFetches.current[userId];
      }

      if (!userProfiles[userId] || isProfileStale(userId) || forceRefresh) {
        const fetchPromise = (async () => {
          try {
            const profile = await fetchPublicProfile(userId);
            setUserProfiles((prev) => ({
              ...prev,
              [userId]: { ...profile, timestamp: Date.now() },
            }));
            return profile;
          } catch (error) {
            console.error("Error fetching user profile:", error);
            throw error; // Ensure the error is propagated
          } finally {
            delete ongoingProfileFetches.current[userId];
          }
        })();

        ongoingProfileFetches.current[userId] = fetchPromise;
        return fetchPromise;
      }

      return userProfiles[userId];
    },
    [userProfiles, isProfileStale]
  );

  const refreshUserProfile = useCallback(
    (userId) => {
      return fetchAndCacheUserProfile(userId, true);
    },
    [fetchAndCacheUserProfile]
  );

  return {
    fetchAndCacheUserProfile,
    refreshUserProfile,
    userProfiles,
  };
};
