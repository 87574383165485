import React, { useEffect, useRef, useState } from "react";
import styles from "./RecommendedTags.module.css";
import cn from "classnames";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";

const Tag = ({ tag, onSelect, isSelected }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isClicked, setIsClicked] = useState(false); // State to manage the clicked visual feedback
  const tagRef = useRef(null);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 100); // Remove feedback after 200ms
    if (isSelected) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 3000); // Hide tooltip after 3 seconds
    } else {
      onSelect(tag);
    }
  };

  return (
    <>
      <div
        ref={tagRef}
        className={cn(styles["option-badge"], { [styles.clicked]: isClicked })} // Apply the clicked class based on isClicked state
        onClick={handleClick}
      >
        {tag.name}
      </div>
      <FloatingTooltip anchorRef={tagRef} show={showTooltip}>
        <span>This tag has already been selected</span>
      </FloatingTooltip>
    </>
  );
};

const RecommendedTags = ({
  recommendedTags,
  isLoading,
  onSelect,
  selected,
  isTagSelected,
  className,
}) => {
  const [fetchedOnce, setFetchedOnce] = useState(false);

  const handleSelectTag = (tag) => {
    // Directly use isTagSelected to check if the tag is already selected
    if (!isTagSelected(tag)) {
      onSelect(tag);
    }
    // No else block needed. If the tag is already selected, we do nothing.
  };

  useEffect(() => {
    if (!isLoading) {
      setFetchedOnce(true);
    }
  }, [isLoading]);

  return (
    <div className={cn(styles.container, className)}>
      {isLoading ? (
        <LoadingMessage message="Loading recommended tags..." />
      ) : (
        recommendedTags.map((tag) => (
          <Tag
            key={tag._id}
            tag={tag}
            onSelect={handleSelectTag}
            isSelected={isTagSelected(tag)}
          />
        ))
      )}
    </div>
  );
};

export default RecommendedTags;
