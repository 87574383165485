// packages/client/src/pages/Chat/Chat.jsx
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Chat.module.css";
import Button from "components/Button/Button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import useMediaQuery from "hooks/useMediaQuery";

import cn from "classnames";

import { HiOutlineInformationCircle } from "react-icons/hi";
import ChatMessage from "components/ChatMessage/ChatMessage";

import { useUserContext } from "contexts/UserContext";
import { ToastContext } from "contexts/ToastContext";

import { FaPhone } from "react-icons/fa6";
import { FaPhoneSlash } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ReviewModal from "components/ReviewModal/ReviewModal";
import {
  offset,
  useFloating,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";

import { HiOutlineArrowLeft } from "react-icons/hi";

import * as typedefs from "../../typedefs";
import { useDispatch, useSelector } from "react-redux";
import {
  initMessages,
  fetchMoreMessages,
  initRoom,
  fetchChatReviews,
} from "store/actions";
import { selectMessagesByChatId, selectReviewsByChatId } from "store/selectors";
import * as USERS_API from "api/users";
import { FaArrowDown } from "react-icons/fa";
import { useCallContext } from "contexts/CallContext";
import { useSocketContext } from "contexts/SocketContext";
import ChatInput from "components/ChatInput/ChatInput";
import UserProfileModal from "components/UserProfileModal/UserProfileModal";

/**
 * @typedef {import("classes/ChatMessage").default} ChatMessage
 * @typedef {import("classes/Chat").default} Chat
 */

const SideMenu = ({
  isSideMenuVisible,
  openReviewModal,
  otherParticipant,
  chatData,
  userData,
  toggleSideMenu,
  isMobileView,
  openProfileModal,
}) => {
  const profilePageRoute = `/user/${otherParticipant?.username}`;

  const renderConnectionDetails = () => {
    if (!chatData?.connections?.length) return null;

    // Group connections by type
    const connectionsByType = chatData.connections.reduce((acc, connection) => {
      if (!acc[connection.type]) {
        acc[connection.type] = [];
      }
      acc[connection.type].push(connection);
      return acc;
    }, {});

    return (
      <div className={styles.sideMenuSection}>
        <p className={styles.sectionTitle}>Connection History</p>
        {Object.entries(connectionsByType).map(([type, connections]) => (
          <div key={type} className={styles.connectionGroup}>
            <h3 className={styles.connectionTypeTitle}>
              {type === "match"
                ? "Matches"
                : `${type.charAt(0).toUpperCase() + type.slice(1)}s`}{" "}
              ({connections.length})
            </h3>
            {connections.map((connection) => {
              switch (type) {
                case "match":
                  const isUser1 =
                    connection.matchData.user1.user === userData._id;
                  const userStory = isUser1
                    ? connection.matchData.user1.story
                    : connection.matchData.user2.story;
                  const otherStory = isUser1
                    ? connection.matchData.user2.story
                    : connection.matchData.user1.story;

                  return (
                    <div key={connection._id} className={styles.connectionItem}>
                      <div className={styles.matchDetails}>
                        <div className={styles.storiesContainer}>
                          <div className={styles.storySection}>
                            <p className={styles.storyLabel}>Your Story:</p>
                            <p className={styles.storyText}>{userStory}</p>
                          </div>
                          <div className={styles.storySection}>
                            <p className={styles.storyLabel}>Their Story:</p>
                            <p className={styles.storyText}>{otherStory}</p>
                          </div>
                        </div>
                        <div className={styles.tagsContainer}>
                          <p className={styles.tagsLabel}>Matched on:</p>
                          <div className={styles.tags}>
                            {connection.matchData.sharedTags.map((tag) => (
                              <span key={tag._id} className={styles.tagName}>
                                {tag.name}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className={styles.connectionDate}>
                          {new Date(connection.createdAt).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  );
                case "reachout":
                  return (
                    <div key={connection._id} className={styles.connectionItem}>
                      <div className={styles.reachoutDetails}>
                        <p className={styles.connectionInfo}>
                          {connection.reachoutData.resourceOwner._id ===
                          userData._id
                            ? `${otherParticipant?.username} reached out about `
                            : `You reached out about `}
                          {connection.reachoutData.resourceType === "Story" ? (
                            <Link
                              to={`/stories/${connection.reachoutData.resourceId}`}
                              className={styles.resourceLink}
                            >
                              "{connection.reachoutData.resourceLabel}"
                            </Link>
                          ) : (
                            `"${connection.reachoutData.resourceLabel}"`
                          )}
                        </p>
                        <div className={styles.connectionDate}>
                          {new Date(connection.createdAt).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={cn(styles.sideMenu, {
        [styles.sideMenuVisible]: isSideMenuVisible,
        [styles.sideMenuMobile]: isMobileView,
      })}
    >
      {isMobileView && (
        <div className={styles.mobileBackButton} onClick={toggleSideMenu}>
          <HiOutlineArrowLeft />
          <span>Back to chat</span>
        </div>
      )}
      <div className={styles.sideMenuSection}>
        <Link
          to={`/user/${otherParticipant?.username}`}
          className={styles.profileLink}
        >
          View Profile
        </Link>
      </div>

      {renderConnectionDetails()}

      <div className={styles.reportContainer}>
        <Button onClick={openReviewModal} className={styles.reportButton}>
          Leave Review
        </Button>
      </div>
    </div>
  );
};

const Toolbar = ({
  handleInitiateCall,
  toggleSideMenu,
  otherParticipant,
  callStatus,
  isMobileView,
  isSideMenuVisible,
  onBack,
  isHandlingCall,
  openProfileModal,
}) => {
  return (
    <div className={styles.toolbar}>
      {isMobileView && (
        <div className={styles.backButton} onClick={onBack}>
          <HiOutlineArrowLeft />
        </div>
      )}
      {!(isMobileView && isSideMenuVisible) && (
        <div className={styles.username} onClick={openProfileModal}>
          {otherParticipant?.username || <LoadingSpinner />}
        </div>
      )}
      <div className={styles.buttonMenu}>
        {callStatus === "idle" && (
          <div
            className={cn(styles.button, styles.callButton, {
              [styles.isHandlingCall]: isHandlingCall,
            })}
            onClick={handleInitiateCall}
          >
            <FaPhone />
          </div>
        )}
        <div className={styles.button} onClick={toggleSideMenu}>
          <HiOutlineInformationCircle />
        </div>
      </div>
    </div>
  );
};

const CallStatusBar = ({
  callStatus,
  otherParticipant,
  callChatId,
  chatId,
  handleAcceptCall,
  handleEndCall,
  isHandlingCall,
  callDuration,
}) => {
  const getMessage = () => {
    switch (callStatus) {
      case "calling":
        return `Calling ${otherParticipant?.username}...`;
      case "receivingCall":
        return `Incoming call from ${otherParticipant?.username}...`;
      case "inCall":
        return `In call with ${otherParticipant?.username}`;
      case "callEnded":
        return `Call ended`;
      default:
        return null;
    }
  };

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const parts = [
      hours > 0 ? hours.toString().padStart(2, "0") : null,
      minutes.toString().padStart(2, "0"),
      remainingSeconds.toString().padStart(2, "0"),
    ].filter(Boolean);

    return parts.join(":");
  };

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3, ease: "easeOut" },
    },
    exit: {
      y: -50,
      opacity: 0,
      transition: { duration: 0.2, ease: "easeIn" },
    },
  };

  return (
    <AnimatePresence>
      {callChatId === chatId && (
        <motion.div
          key="callStatusBar"
          initial="exit"
          animate="enter"
          exit="exit"
          variants={variants}
          className={styles.callStatusBar}
        >
          <div className={styles.callStatus}>{getMessage()}</div>
          {callStatus !== "idle" && callStatus !== "receivingCall" && (
            <div className={styles.callDuration}>
              {formatDuration(callDuration)}
            </div>
          )}
          <div className={styles.buttonMenu}>
            {callStatus === "receivingCall" && !isHandlingCall && (
              <div
                className={cn(styles.button, styles.acceptCallBtn, {
                  [styles.isHandlingCall]: isHandlingCall,
                })}
                onClick={handleAcceptCall}
              >
                <FaPhone />
                Accept
              </div>
            )}
            <div
              className={cn(styles.button, styles.endCallBtn, {
                [styles.isHandlingCall]: isHandlingCall,
              })}
              onClick={handleEndCall}
            >
              <FaPhoneSlash />
              {callStatus === "receivingCall" ? "Reject" : "End"}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Chat = ({ onBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const { userData } = useUserContext();

  const { sendMessage } = useSocketContext();

  const {
    remoteStream,
    initiateCall,
    callStatus,
    callChatId,
    endCall,
    acceptCall,
    callDuration,
    cleanupChat,
  } = useCallContext();

  const [searchParams] = useSearchParams();

  // --- State Hooks ---
  const [chatId, setChatId] = useState();
  const [otherParticipant, setOtherParticipant] = useState();

  const [newMessage, setNewMessage] = useState("");
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState();
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(null);
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [retry, setRetry] = useState(false); // For retry logic
  const [errors, setErrors] = useState({
    initError: null,
    messageError: null,
    callError: null,
  });
  const [loadingStates, setLoadingStates] = useState({
    isInitializingRoom: false,
    isLoadingMessages: false,
    isLoadingOtherParticipant: true,
    isLoadingMoreMessages: false,
    isHandlingCall: false,
    isSendingMessage: false,
  });

  const [isInitialized, setIsInitialized] = useState(false);

  // --- Refs ---
  const [lastMessageRef, setLastMessageRef] = useState(null); // Making it state so its reactive
  const sentinelRef = useRef(null);

  const audioRef = useRef();

  const { refs, floatingStyles } = useFloating({
    open: isScrollButtonVisible,
    onOpenChange: setIsScrollButtonVisible,
    middleware: [offset(10)],
    placement: "top",
    whileElementsMounted: autoUpdate,
  });

  // --- Redux State Hooks ---
  const messages = useSelector((state) =>
    selectMessagesByChatId(state, chatId)
  );

  const chatData = useSelector((state) => state.chats.byId[chatId]);

  const reviews = useSelector((state) => selectReviewsByChatId(state, chatId));

  // --- Fetch and Set Chat Data ---
  useEffect(() => {
    const id = searchParams.get("id");
    setChatId(id);
  }, [searchParams]);

  // Function to update specific loading states
  const setLoadingState = (key, value) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Function to update specific error states
  const setError = (key, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    const initializeChat = async () => {
      if (chatId && !isInitialized) {
        setLoadingState("isInitializingRoom", true);
        setError("initError", null);
        try {
          await dispatch(initRoom(chatId, "chat"));
          await dispatch(initMessages(chatId, "chat"));
          await dispatch(fetchChatReviews(chatId));
          setIsInitialized(true); // Set initialization flag
        } catch (err) {
          console.error("Error initializing room or fetching messages:", err);

          // Display the error message as a toast notification
          addToast(err.message, "error");

          // Check if there's a redirect URL and navigate to it
          if (err.redirect) {
            navigate(err.redirect);
          } else {
            setError(
              "initError",
              "Unable to load the chat. Please check your connection or try again later."
            );
          }
        } finally {
          setLoadingState("isInitializingRoom", false);
        }
      }
    };

    initializeChat();
  }, [dispatch, chatId, isInitialized, addToast, navigate]);

  // --- Handle Retry ---
  const handleRetry = () => {
    setRetry((prev) => !prev); // Toggle retry to re-trigger API calls
  };

  // --- Load More Messages ---
  const isDebounced = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (!entry.isIntersecting) return;

        if (loadingStates.isLoadingMoreMessages) {
          return;
        }

        if (!hasMore) {
          setError(
            "messageError",
            "You've reached the end of the conversation."
          );
          return;
        }

        if (!isDebounced.current) {
          isDebounced.current = true;

          setLoadingState("isLoadingMoreMessages", true);
          setError("messageError", null);

          try {
            const firstMessage = messages[0];
            const firstMessageId = firstMessage?._id;

            if (firstMessageId) {
              const hasMore = await dispatch(
                fetchMoreMessages(chatId, "chat", firstMessageId)
              );
              // Handle newMessages and update hasMore accordingly
              setHasMore(hasMore); // Update hasMore based on new messages
            }
          } catch (err) {
            console.error("Error loading more messages:", err);
            setError(
              "messageError",
              "Failed to load more messages. Please try again."
            );
          } finally {
            setLoadingState("isLoadingMoreMessages", false);
            isDebounced.current = false;
          }
        }
      },
      { threshold: 1.0 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [
    messages,
    hasMore,
    chatId,
    loadingStates.isLoadingMoreMessages,
    dispatch,
  ]);

  // --- Scroll Button Visibility ---
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        setIsScrollButtonVisible(!entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (lastMessageRef) {
      observer.observe(lastMessageRef);
    }

    return () => {
      if (lastMessageRef) {
        observer.unobserve(lastMessageRef);
      }
    };
  }, [messages, lastMessageRef]);

  // --- Scroll to Last Message ---
  useEffect(() => {
    if (lastMessageRef) {
      lastMessageRef.scrollIntoView({ behavior: "auto" });
    }
  }, [messages, lastMessageRef]);

  const isMobileView = useMediaQuery("sm");

  // --- Fetch Other Participant ---

  const isFetchingRef = useRef(false); // Track if the API call is ongoing

  // Helper function to get connection details based on type
  const getConnectionDetails = (connection) => {
    switch (connection.type) {
      case "match":
        return {
          type: "match",
          user1: {
            username: connection.matchData.user1.user.username,
            story: connection.matchData.user1.story,
            pseudonym: connection.matchData.user1.pseudonym,
          },
          user2: {
            username: connection.matchData.user2.user.username,
            story: connection.matchData.user2.story,
            pseudonym: connection.matchData.user2.pseudonym,
          },
          sharedTags: connection.matchData.sharedTags,
        };
      case "reachout":
      case "selfShare":
        return {
          type: connection.type,
          resource: {
            title: connection.reachoutData.resourceId.title,
            story: connection.reachoutData.resourceId.story,
          },
          owner: connection.reachoutData.resourceOwner.username,
          recipient: connection.participants.find(
            (p) => p._id !== connection.reachoutData.resourceOwner._id
          )?.username,
        };
      default:
        return null;
    }
  };

  // Update the fetchOtherParticipant effect to handle different connection types
  useEffect(() => {
    const fetchOtherParticipant = async () => {
      if (chatData && userData) {
        const currentUserId = userData._id; // Assuming userData contains the current user's ID

        const otherParticipant = chatData.participants.find(
          (participant) => participant._id !== currentUserId
        );

        try {
          const profile = await USERS_API.fetchPublicProfile(
            otherParticipant._id,
            { chatId: chatData._id }
          );
          setOtherParticipant({ ...otherParticipant, ...profile });
        } catch (error) {
          console.error("Error fetching other participant profile:", error);
        } finally {
          setLoadingStates((prev) => ({
            ...prev,
            isLoadingOtherParticipant: false,
          }));
        }
      }
    };

    fetchOtherParticipant();
  }, [chatData, userData]);

  // --- Handle Remote Stream ---
  useEffect(() => {
    if (audioRef.current && remoteStream) {
      audioRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  // --- Render Messages ---
  const renderMessages = () => {
    return (
      <>
        {messages?.length > 0 &&
          messages.map((message, index) => (
            <ChatMessage
              key={`${chatId}:${message._id}`}
              message={message} // Pass the entire message object
              otherParticipant={otherParticipant}
              ref={(el) => {
                if (index === messages.length - 1) {
                  setLastMessageRef(el);
                }
              }}
            />
          ))}
      </>
    );
  };

  // --- Message Input Handlers ---
  const onEnterPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && newMessage.trim()) {
      e.preventDefault();
      sendMessage(chatId, newMessage);
      setNewMessage("");
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      setLoadingState("isSendingMessage", true);
      setError("messageError", null); // Clear any previous errors
      try {
        await sendMessage(chatId, newMessage);
        setNewMessage("");
      } catch (err) {
        addToast("Error sending message: " + err.message, "error");
        setError("messageError", "Failed to send message. Please try again.");
      } finally {
        setLoadingState("isSendingMessage", false);
      }
    }
  };

  // --- Utility Functions ---
  const scrollToBottom = () => {
    if (lastMessageRef) {
      lastMessageRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleSideMenu = () => setIsSideMenuVisible((prev) => !prev);

  const handleLeaveChat = () => navigate("/lobby");

  const handleInitiateCall = async () => {
    setLoadingState("isHandlingCall", true);
    setError("callError", null); // Clear any previous errors
    try {
      await initiateCall(chatId);
    } catch (err) {
      addToast("Error initiating call: " + err.message, "error");
      setError("callError", "Failed to initiate call. Please try again later.");
    } finally {
      setLoadingState("isHandlingCall", false);
    }
  };

  const handleAcceptCall = async () => {
    setLoadingState("isHandlingCall", true);
    setError("callError", null); // Clear any previous errors
    try {
      await acceptCall(chatId);
    } catch (err) {
      addToast("Error accepting call: " + err.message, "error");
      setError(
        "callError",
        "Failed to accept call. Please check your connection and try again."
      );
    } finally {
      setLoadingState("isHandlingCall", false);
    }
  };

  const handleEndCall = async () => {
    setLoadingState("isHandlingCall", true);
    setError("callError", null); // Clear any previous errors
    try {
      await endCall(chatId);
    } catch (err) {
      addToast("Error ending call: " + err.message, "error");
      setError(
        "callError",
        "Failed to end call. The call may have already ended."
      );
    } finally {
      setLoadingState("isHandlingCall", false);
    }
  };

  // --- Modals and UI Controls ---
  const openReviewModal = () => setIsReviewModalVisible(true);
  const openProfileModal = () => setIsProfileModalVisible(true);
  const closeProfileModal = () => setIsProfileModalVisible(false);

  return (
    <>
      <div className={styles.chat}>
        <div
          className={cn(styles.chatContent, {
            [styles.chatContentContracted]: isSideMenuVisible,
          })}
        >
          <Toolbar
            toggleSideMenu={toggleSideMenu}
            otherParticipant={otherParticipant}
            handleInitiateCall={handleInitiateCall}
            callStatus={callStatus}
            isMobileView={isMobileView}
            isSideMenuVisible={isSideMenuVisible}
            onBack={onBack}
            isHandlingCall={loadingStates.isHandlingCall}
            openProfileModal={openProfileModal}
          />

          <CallStatusBar
            callStatus={callStatus}
            chatId={chatId}
            callChatId={callChatId}
            otherParticipant={otherParticipant}
            handleEndCall={handleEndCall}
            handleAcceptCall={handleAcceptCall}
            isHandlingCall={loadingStates.isHandlingCall}
            callDuration={callDuration}
          />

          <audio ref={audioRef} autoPlay playsInline />
          <div className={styles.messageContainer}>
            {loadingStates.isInitializingRoom ? (
              <div className={styles.status}>
                <LoadingMessage message="Initializing room..." />
              </div>
            ) : errors?.initError ? (
              <div className={styles.errorContainer}>
                <h2>{errors.initError}</h2>
                <button onClick={handleRetry}>Retry</button>
                <button onClick={() => navigate("/matches")}>
                  Go Back to Matches
                </button>
              </div>
            ) : loadingStates.isLoadingMessages ||
              loadingStates.isLoadingOtherParticipant ? (
              <div className={styles.status}>
                <LoadingMessage message="Loading messages..." />
              </div>
            ) : (
              <>
                {renderMessages()}

                <AnimatePresence>
                  {isScrollButtonVisible && (
                    <FloatingPortal>
                      <motion.div
                        key="scroll-btn"
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, zIndex: "2" }}
                        className={styles.scrollToBottom}
                        onClick={scrollToBottom}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.1 }}
                      >
                        <FaArrowDown />
                      </motion.div>
                    </FloatingPortal>
                  )}
                </AnimatePresence>
              </>
            )}
          </div>
          <div ref={refs.setReference} style={{ width: "100%" }}></div>

          <ChatInput
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            onEnterPress={onEnterPress}
            handleSendMessage={handleSendMessage}
            isSendingMessage={loadingStates.isSendingMessage}
            isScrollButtonVisible={isScrollButtonVisible}
            setIsScrollButtonVisible={setIsScrollButtonVisible}
            scrollToBottom={scrollToBottom}
            ref={refs.setReference}
          />
        </div>
        <SideMenu
          isSideMenuVisible={isSideMenuVisible}
          openReviewModal={openReviewModal}
          otherParticipant={otherParticipant}
          chatData={chatData}
          userData={userData}
          toggleSideMenu={toggleSideMenu}
          isMobileView={isMobileView}
        />
      </div>
      <AnimatePresence>
        {isScrollButtonVisible && (
          <FloatingPortal>
            <motion.div
              key="scroll-btn"
              ref={refs.setFloating}
              style={{ ...floatingStyles, zIndex: "100" }}
              className={styles.scrollToBottom}
              onClick={scrollToBottom}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <FaArrowDown />
            </motion.div>
          </FloatingPortal>
        )}
      </AnimatePresence>
      {chatData && otherParticipant && (
        <ReviewModal
          chat={chatData}
          subject={otherParticipant} // Pass otherParticipant as subject
          reviews={reviews} // Pass reviews
          isOpen={isReviewModalVisible}
          onClose={() => setIsReviewModalVisible(false)}
        />
      )}

      <UserProfileModal
        isOpen={isProfileModalVisible}
        onClose={closeProfileModal}
        className={styles.userModalStyles}
        otherParticipant={otherParticipant}
      />
    </>
  );
};

export default Chat;
