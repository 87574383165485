import React, { useState } from "react";
import cn from "classnames";
import { HiCheckCircle, HiXCircle } from "react-icons/hi";
import styles from "./UserProfileModal.module.css";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ScoreMetadataDisplay from "components/ScoreMetadataDisplay/ScoreMetadataDisplay";
import formatGender from "utils/formatGender";
import Modal from "components/Modal/Modal";

const UserProfileModal = ({ otherParticipant, isOpen, onClose, className }) => {
  const [loading, setLoading] = useState(false);

  const renderProfile = () => {
    return (
      <>
        <div className={styles.usernameContainer}>
          <h2 className={styles.username}>
            {otherParticipant?.username === "anonymous"
              ? otherParticipant?.pseudonym
              : otherParticipant?.username}
          </h2>
        </div>
        <div className={styles.profileDetails}>
          <span>{otherParticipant?.ageRange}</span> <span>·</span>
          <span>
            {otherParticipant?.gender
              ? formatGender(otherParticipant?.gender)
              : "Not set"}
          </span>
          <span>·</span>
          <span
            className={cn(styles.badge, {
              [styles.valid]: otherParticipant?.isEmailVerified,
              [styles.invalid]: !otherParticipant?.isEmailVerified,
            })}
          >
            {otherParticipant?.isEmailVerified ? (
              <HiCheckCircle />
            ) : (
              <HiXCircle />
            )}
            {otherParticipant?.isEmailVerified
              ? "Email Verified"
              : "Email Un-verified"}
          </span>
        </div>
        {otherParticipant?.scores && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ScoreMetadataDisplay scores={otherParticipant.scores} />
          </div>
        )}
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={className}>
      <div className={styles.profileContainer}>
        {loading ? (
          <div style={{ padding: "10px 0" }}>
            <LoadingSpinner />
          </div>
        ) : (
          renderProfile()
        )}
      </div>
    </Modal>
  );
};
export default UserProfileModal;
