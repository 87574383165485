import React, { useState, useEffect, useMemo } from "react";
import {
  useFloating,
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";
import LifeExperienceForm from "./LifeExperienceForm";
import styles from "./LifeExperienceModal.module.css"; // Import the CSS module
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";

/**
 * LifeExperienceModal component for displaying a modal to edit or create a life experience.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.lifeExperience - The life experience object.
 * @param {boolean} props.isOpen - Flag to control the visibility of the modal.
 * @param {Function} props.onClose - Callback function to close the modal.
 * @param {string} props.mode - The mode of the modal, either "edit" or "new".
 * @param {Function} [props.onCreate] - Callback function to handle creating a new life experience. Required when mode is "new".
 * @param {Function} [props.onUpdate] - Callback function to handle updating a life experience. Required when mode is "edit".
 * @param {Function} [props.onDelete] - Callback function to handle deleting a life experience. Only applicable when mode is "edit".
 * @param {string} [props.error] - Error message, if any.
 * @param {string} props.status - Status of the life experience.
 * @param {string} props.statusMessage - Status message of the life experience.
 * @param {string} [props.initialStory] - Initial story for the new life experience.
 *
 * @returns {JSX.Element|null} The rendered modal or null if not open.
 */
const LifeExperienceModal = ({
  lifeExperience,
  isOpen,
  onClose,
  mode = "new",
  onSave,
  onCreate,
  onUpdate,
  onDelete,
  error,
  status,
  statusMessage,
  initialData = {},
}) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, {
    outsidePress: (event) => !event.target.closest('[data-joyride="true"]'),
    outsidePressEvent: "mousedown",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    // dismiss,
  ]);

  // Normalize data at modal level
  const normalizedData = useMemo(
    () => ({
      story: initialData.story || lifeExperience?.story || "",
      tags: lifeExperience?.tags || [],
      date: lifeExperience?.date || {},
      anonymized: lifeExperience?.anonymized ?? true,
      searchable: lifeExperience?.searchable ?? true,
    }),
    [initialData, lifeExperience]
  );

  const handleUpdate = async (formData) => {
    if (!onUpdate) {
      throw new Error("onUpdate prop is required when mode is 'edit'");
    }
    await onUpdate(formData);
  };

  const handleCreate = async (formData) => {
    if (!onCreate) {
      throw new Error("onCreate prop is required when mode is 'new'");
    }
    await onCreate(formData);
  };

  const handleDelete = async () => {
    if (mode === "edit") {
      if (!onDelete) {
        throw new Error("onDelete prop is required when mode is 'edit'");
      }
      await onDelete();
    }
  };

  if (!isOpen) return null;

  return (
    <FloatingOverlay lockScroll className={styles.overlay}>
      <FloatingFocusManager context={context}>
        <div
          ref={refs.setFloating}
          className={styles.modal}
          {...getFloatingProps()}
        >
          <button
            onClick={onClose}
            className={styles.closeButton}
            aria-label="Close modal"
          >
            ×
          </button>
          {status === "loading" ? (
            <LoadingMessage
              message={statusMessage}
              className={styles.loading}
            />
          ) : (
            <>
              <LifeExperienceForm
                lifeExperience={lifeExperience}
                initialData={normalizedData} // Pass normalized data to form
                mode={mode}
                onSave={onSave}
                onCreate={handleCreate}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
              />
            </>
          )}
        </div>
      </FloatingFocusManager>
    </FloatingOverlay>
  );
};

export default LifeExperienceModal;
