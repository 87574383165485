import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Write.module.css";
import { RiQuillPenLine, RiBookOpenLine } from "react-icons/ri";
import { BsFeather } from "react-icons/bs";
import LifeExperienceModal from "components/LifeExperienceModal/LifeExperienceModal";
import { fetchLifeExperiences } from "api/lifeExperiences";
import { fetchStories } from "api/stories";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useToastContext } from "contexts/ToastContext";
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import StoryCard from "components/StoryCard/StoryCard";
import { useUserContext } from "contexts/UserContext";

const Write = () => {
  const location = useLocation();
  const [isLifeExperienceModalOpen, setIsLifeExperienceModalOpen] = useState(
    location.state?.openLifeExperience || false
  );
  const initialStory = location.state?.initialStory || "";
  const [lifeExperiences, setLifeExperiences] = useState([]);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const { userData } = useUserContext();

  useEffect(() => {
    const fetchUserContent = async () => {
      try {
        const [experiencesData, storiesData] = await Promise.all([
          fetchLifeExperiences(),
          fetchStories(),
        ]);

        // Sort by updatedAt in descending order (newest first)
        const sortedExperiences = experiencesData.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        const sortedStories = storiesData.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        setLifeExperiences(sortedExperiences);
        setStories(sortedStories);
      } catch (error) {
        addToast("Error loading content", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchUserContent();
  }, [addToast]);

  // Clear the location state after using it
  useEffect(() => {
    if (location.state?.openLifeExperience) {
      window.history.replaceState({}, document.title);
    }
  }, []);

  const handleCreateLifeExperience = async (formData) => {
    try {
      // API call would go here
      setIsLifeExperienceModalOpen(false);
      addToast("Life experience created successfully", "success");
      // Refresh life experiences
      const experiencesData = await fetchLifeExperiences();
      setLifeExperiences(experiencesData);
    } catch (error) {
      addToast("Error creating life experience", "error");
    }
  };

  const handleLifeExperienceUpdate = (updatedExperience) => {
    setLifeExperiences((prev) =>
      prev.map((exp) =>
        exp._id === updatedExperience._id ? updatedExperience : exp
      )
    );
  };

  const handleLifeExperienceDelete = (deletedId) => {
    setLifeExperiences((prev) => prev.filter((exp) => exp._id !== deletedId));
  };

  const ContentSection = ({ title, items, emptyMessage, icon: Icon, type }) => (
    <div className={styles.contentSection}>
      <div className={styles.sectionHeader}>
        <Icon className={styles.sectionIcon} />
        <h3>{title}</h3>
      </div>
      {items.length > 0 ? (
        <div className={styles.contentList}>
          {items.map((item) =>
            type === "lifeExperience" ? (
              <LifeExperienceCard
                key={item._id}
                lifeExpData={item}
                userData={userData}
                onUpdate={handleLifeExperienceUpdate}
                onDelete={handleLifeExperienceDelete}
              />
            ) : (
              <StoryCard key={item._id} story={item} userData={userData} />
            )
          )}
        </div>
      ) : (
        <p className={styles.emptyMessage}>{emptyMessage}</p>
      )}
    </div>
  );

  return (
    <div className={styles.container}>
      <h1>Share Your Journey</h1>

      <div className={styles.optionsContainer}>
        <button
          className={styles.optionCard}
          onClick={() => setIsLifeExperienceModalOpen(true)}
        >
          <BsFeather className={styles.icon} />
          <h2>Add Life Experience</h2>
          <p>Share significant moments that have shaped your journey</p>
        </button>

        <button
          className={styles.optionCard}
          onClick={() => navigate("/stories/new")}
        >
          <RiBookOpenLine className={styles.icon} />
          <h2>Write a Story</h2>
          <p>Tell your story in detail and connect with others</p>
        </button>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.recentContent}>
          <ContentSection
            title="Your Life Experiences"
            items={lifeExperiences}
            emptyMessage="No life experiences yet"
            icon={BsFeather}
            type="lifeExperience"
          />
          <ContentSection
            title="Your Stories"
            items={stories}
            emptyMessage="No stories yet"
            icon={RiBookOpenLine}
            type="story"
          />
        </div>
      )}

      <LifeExperienceModal
        isOpen={isLifeExperienceModalOpen}
        onClose={() => setIsLifeExperienceModalOpen(false)}
        mode="new"
        onCreate={handleCreateLifeExperience}
        initialData={{ story: initialStory }}
      />
    </div>
  );
};

export default Write;
