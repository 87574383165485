import api from "api";
import handleAxiosError from "./handleAxiosError";
import * as typedefs from "typedefs";

/**
 *
 * @typedef {typedefs.NotificationData} NotificationData
 */

/**
 * Fetches notifications for the authenticated user with pagination.
 * @param {number} limit - The number of notifications to fetch.
 * @param {number} skip - The number of notifications to skip.
 * @returns {Promise<Array<NotificationData>>} A promise that resolves to an array of notification objects.
 */
export const fetchNotifications = async (limit = 10, skip = 0) => {
  try {
    const response = await api.get("/notifications", {
      params: { limit, skip },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Marks a notification as read.
 * @param {string} notificationId - The ID of the notification to mark as read.
 * @returns {Promise<NotificationData>} A promise that resolves to the updated notification object.
 */
export const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await api.put(`/notifications/${notificationId}/read`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Deletes a notification.
 * @param {string} notificationId - The ID of the notification to delete.
 * @returns {Promise<void>} A promise that resolves when the notification is deleted.
 */
export const deleteNotification = async (notificationId) => {
  try {
    await api.delete(`/notifications/${notificationId}`);
  } catch (error) {
    handleAxiosError(error);
  }
};
