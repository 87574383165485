const { useRef, useEffect } = require("react");

const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
/**
 * A custom hook that logs the changes in dependencies for useEffect.
 *
 * @param {Function} effectHook - The effect function to run.
 * @param {Array} dependencies - The array of dependencies for the effect.
 * @param {Array} [dependencyNames=[]] - Optional array of names for the dependencies, used for logging.
 *
 * @example
 * useEffectDebugger(() => {
 *   // Your effect logic here
 * }, [dependency1, dependency2], ['Dependency 1', 'Dependency 2']);
 *
 * @see {@link https://stackoverflow.com/questions/55187563/determine-which-dependency-array-variable-caused-useeffect-hook-to-fire}
 */
const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  // if (Object.keys(changedDeps).length) {

  // }

  useEffect(effectHook, dependencies);
};

export default useEffectDebugger;
