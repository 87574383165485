import React from "react";
import styles from "./ToggleButton.module.css";
import cn from "classnames";

const ToggleButton = ({ isOn, handleToggle, className, styles: customStyles }) => {
  return (
    <div
      className={cn(styles.toggleSwitch, className, {
        [styles.on]: isOn,
        [styles.off]: !isOn,
      })}
      onClick={handleToggle}
      style={customStyles}
    >
      <div
        className={cn(styles.switch, {
          [styles.on]: isOn,
          [styles.off]: !isOn,
        })}
      ></div>
    </div>
  );
};

export default ToggleButton;
