import React from "react";
import styles from "./ErrorMessage.module.css"; // Import the CSS module
import { FaExclamationTriangle } from "react-icons/fa";
import cn from "classnames";

const ErrorMessage = ({ message, className }) => {
  return (
    <div className={cn(styles.errorMessage, className)}>
      <FaExclamationTriangle className={styles.errorIcon} />
      <div className={styles.errorContent}>
        <h2 className={styles.errorTitle}>Oops! Something went wrong</h2>
        <p className={styles.errorText}>{message}</p>
      </div>
    </div>
  );
};

export default ErrorMessage;
