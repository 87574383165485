import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./Match.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectMessagesByMatchId, selectMatchById } from "store/selectors";
import ChatMessage from "components/ChatMessage/ChatMessage";
import Button from "components/Button/Button";
import ChatInput from "components/ChatInput/ChatInput";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { useUserContext } from "contexts/UserContext";
import {
  initMessages,
  fetchMoreMessages,
  initRoom,
  acceptMatch,
} from "store/actions";
import * as USERS_API from "api/users";
import { useSocketContext } from "contexts/SocketContext";
import {
  HiOutlineInformationCircle,
  HiOutlineRefresh,
  HiOutlineArrowLeft,
  HiCheckCircle,
  HiXCircle,
} from "react-icons/hi";

import cn from "classnames";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import formatGender from "utils/formatGender";
import ReportModal from "components/ReportModal/ReportModal";
import useMediaQuery from "hooks/useMediaQuery";
import { FaArrowDown } from "react-icons/fa";

import { motion, AnimatePresence } from "framer-motion";
import {
  offset,
  useFloating,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";
import UserProfileModal from "components/UserProfileModal/UserProfileModal";
import { useToastContext } from "contexts/ToastContext";

const Toolbar = ({
  toggleSideMenu,
  otherParticipant,
  openProfileModal,
  isMobileView,
  isSideMenuVisible,
  onBack,
  matchData,
}) => {
  return (
    <div className={styles.toolbar}>
      {isMobileView && (
        <div className={styles.backButton} onClick={onBack}>
          <HiOutlineArrowLeft />
        </div>
      )}
      {!(isMobileView && isSideMenuVisible) &&
        (otherParticipant?.pseudonym ? (
          <div className={styles.username} onClick={openProfileModal}>
            {matchData?.status === "accepted"
              ? otherParticipant.username
              : otherParticipant.pseudonym}
          </div>
        ) : (
          <LoadingSpinner />
        ))}
      <div className={styles.buttonMenu}>
        <div className={styles.button} onClick={toggleSideMenu}>
          <HiOutlineInformationCircle />
        </div>
      </div>
    </div>
  );
};

const SideMenu = ({
  openProfileModal,
  isSideMenuVisible,
  otherParticipant,
  matchData,
  handleReport,
  toggleSideMenu,
  isMobileView,
  onAccept,
  isAccepting,
  acceptError,
  userData,
}) => {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const renderAcceptButton = () => {
    if (matchData?.status === "accepted") return null;

    const currentUserParticipant =
      matchData?.participants?.user1?.user?._id === userData?._id
        ? matchData?.participants?.user1
        : matchData?.participants?.user2;

    const hasUserAccepted = currentUserParticipant?.accepted;

    return (
      <div className={styles.sideMenuSection}>
        <p className={styles.sectionTitle}>
          Ready to stumble into an active chat?
        </p>

        <div
          className={cn(styles.acceptStatus, {
            [styles.pending]: !otherParticipant?.accepted,
          })}
        >
          <span>
            {otherParticipant?.accepted ? (
              <>
                <HiCheckCircle />
                {otherParticipant.pseudonym} has accepted
              </>
            ) : (
              <>
                <HiOutlineRefresh />
                Pending acceptance from {otherParticipant?.pseudonym}
              </>
            )}
          </span>
        </div>

        {!hasUserAccepted && (
          <div className={styles.acceptHelperText}>
            <p>accepting will:</p>
            <ul>
              <li>add your matched tags as connections</li>
              <li>enable calling features</li>
              <li>allow mutual reviews</li>
            </ul>
          </div>
        )}

        <Button
          onClick={onAccept}
          variant={hasUserAccepted ? "default" : "success"}
          className={cn(styles.acceptButton, {
            [styles.acceptButtonDisabled]: hasUserAccepted || isAccepting,
          })}
          size="medium"
          shape="rounded"
          disabled={hasUserAccepted || isAccepting}
        >
          {isAccepting ? (
            <>
              <LoadingSpinner size="small" />
              Accepting...
            </>
          ) : hasUserAccepted ? (
            "You've accepted the match!"
          ) : (
            "Accept Match"
          )}
        </Button>
        {acceptError && <div className={styles.error}>{acceptError}</div>}
      </div>
    );
  };

  const renderUserInfo = () => {
    if (!otherParticipant) return null;

    const displayName =
      matchData?.status === "accepted"
        ? otherParticipant.username
        : otherParticipant.pseudonym;

    return (
      <div className={styles.sideMenuSection}>
        {isMobileView && (
          <div className={styles.mobileBackButton} onClick={toggleSideMenu}>
            <HiOutlineArrowLeft />
            <span>Back to chat</span>
          </div>
        )}
        <div className={styles.flexColCenter}>
          <p className={styles.sectionTitle}>You are chatting with:</p>
          <Button className={styles.userButton} onClick={openProfileModal}>
            {displayName}
          </Button>
          <div className={styles.userMetadata}>
            <div className={styles.metadataItem}>
              <span>{otherParticipant.ageRange}</span>
            </div>
            <div className={styles.metadataItem}>
              <span>
                {otherParticipant.gender
                  ? formatGender(otherParticipant.gender)
                  : "Not set"}
              </span>
            </div>
            <div className={styles.metadataItem}>
              <span
                className={cn(styles.badge, {
                  [styles.valid]: otherParticipant.isEmailVerified,
                  [styles.invalid]: !otherParticipant.isEmailVerified,
                })}
              >
                {otherParticipant.isEmailVerified ? (
                  <HiCheckCircle />
                ) : (
                  <HiXCircle />
                )}
                {otherParticipant.isEmailVerified
                  ? "Email Verified"
                  : "Email Un-verified"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(styles.sideMenu, {
        [styles.sideMenuVisible]: isSideMenuVisible,
        [styles.sideMenuMobile]: isMobileView,
      })}
    >
      {renderUserInfo()}

      {matchData?.sharedTags?.length > 0 && (
        <div className={styles.sideMenuSection}>
          <p className={styles.sectionTitle}>Shared Tags</p>
          <div className={styles.resourceCard}>
            <div className={styles.tags}>
              {matchData.sharedTags.map((tag) => (
                <span key={tag._id} className={styles.tagName}>
                  {tag.name}
                </span>
              ))}
            </div>
            <div className={styles.createdAt}>
              matched on {new Date(matchData.createdAt).toLocaleDateString()}
            </div>
          </div>
        </div>
      )}

      {renderAcceptButton()}

      <div className={cn(styles.sideMenuSection, styles.reportContainer)}>
        <Button
          onClick={() => setIsReportModalOpen(true)}
          variant="danger"
          className={styles.reportButton}
          size="small"
          shape="rounded"
        >
          Report Match
        </Button>
      </div>

      {isReportModalOpen && (
        <ReportModal
          targetType="Match"
          targetId={matchData._id}
          targetData={matchData}
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleReport}
        />
      )}
    </div>
  );
};

const MatchBar = ({ matchData, onAccept, isAccepting, acceptError }) => {
  const navigate = useNavigate();

  const handleGoToChat = () => {
    if (matchData?.status === "accepted" && matchData?.chatId) {
      navigate(`/chats?type=chats&id=${matchData.chatId}`);
    }
  };

  const renderSpecialStatus = () => {
    const statusClasses = cn(styles.statusMessage, {
      [styles.accepted]: matchData?.status === "accepted",
      [styles.expired]: matchData?.status === "expired",
      [styles.rejected]: matchData?.status === "rejected",
      [styles.canceled]: matchData?.status === "canceled",
    });

    switch (matchData?.status) {
      case "accepted":
        return (
          <div className={statusClasses}>
            <span>This match has been accepted.</span>{" "}
            <span className={styles.link} onClick={handleGoToChat}>
              Go to chat
            </span>
          </div>
        );
      case "expired":
        return (
          <div className={statusClasses}>
            This match has expired. You can no longer accept it.
          </div>
        );
      case "rejected":
        return (
          <div className={statusClasses}>This match has been rejected.</div>
        );
      case "canceled":
        return (
          <div className={statusClasses}>This match has been canceled.</div>
        );
      default:
        return null;
    }
  };

  return <div className={styles.matchBar}>{renderSpecialStatus()}</div>;
};

const Match = ({ onBack }) => {
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToastContext();

  const [otherParticipant, setOtherParticipant] = useState(null);
  const [lastMessageRef, setLastMessageRef] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [loadingStates, setLoadingStates] = useState({
    isLoadingMessages: true,
    isSendingMessage: false,
    isInitializingRoom: false,
    isLoadingOtherParticipant: true,
    isAcceptingMatch: false,
  });
  const isMobileView = useMediaQuery("sm");

  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
  const { refs, floatingStyles } = useFloating({
    open: isScrollButtonVisible,
    onOpenChange: setIsScrollButtonVisible,
    middleware: [offset(10)],
    placement: "top",
    whileElementsMounted: autoUpdate,
  });

  const matchData = useSelector((state) => selectMatchById(state, matchId));
  const messages = useSelector((state) =>
    selectMessagesByMatchId(state, matchId)
  );
  const { userData } = useUserContext();
  const { sendMessage } = useSocketContext();
  const [retry, setRetry] = useState(false); // For retry logic

  const [errors, setErrors] = useState({
    initError: null,
    messageError: null,
  });

  const [isAccepting, setIsAccepting] = useState(false);
  const [acceptError, setAcceptError] = useState(null);

  const handleAcceptMatch = async () => {
    if (!matchData?._id || isAccepting) return;

    setIsAccepting(true);
    setAcceptError(null);

    try {
      const { updatedMatch, newChat } = await dispatch(
        acceptMatch(matchData._id)
      );

      if (newChat) {
        navigate(`/chats?type=chats&id=${newChat._id}`);
      }
    } catch (error) {
      addToast(
        "Failed to accept match. Please try again later.",
        "error",
        5000
      );
      setAcceptError(error.message);
    } finally {
      setIsAccepting(false);
    }
  };

  // Initialize match room
  useEffect(() => {
    const initializeRoom = async () => {
      if (!matchId) return;

      setLoadingStates((prev) => ({
        ...prev,
        isInitializingRoom: true,
      }));

      setErrors((prev) => ({ ...prev, initError: null }));

      try {
        await dispatch(initRoom(matchId, "match"));
        await dispatch(initMessages(matchId, "match"));
      } catch (error) {
        console.error("Error initializing match:", error);
        setErrors((prev) => ({
          ...prev,
          initError: "Failed to load match. Please try again later.",
        }));
      } finally {
        setLoadingStates((prev) => ({
          ...prev,
          isInitializingRoom: false,
          isLoadingMessages: false,
        }));
      }
    };

    initializeRoom();
  }, [matchId, retry, dispatch]);

  // --- Handle Retry ---
  const handleRetry = () => {
    setRetry((prev) => !prev); // Toggle retry to re-trigger API calls
  };

  // Fetch other participant's profile
  useEffect(() => {
    const fetchOtherParticipant = async () => {
      if (matchData && userData) {
        const currentUserId = userData._id;

        const otherParticipant =
          matchData.participants.user1.user._id === currentUserId
            ? matchData.participants.user2
            : matchData.participants.user1;

        try {
          const profile = await USERS_API.fetchPublicProfile(
            otherParticipant.user.id,
            { matchId: matchData._id }
          );
          setOtherParticipant({ ...otherParticipant, ...profile });
        } catch (error) {
          console.error("Error fetching other participant:", error);
        } finally {
          setLoadingStates((prev) => ({
            ...prev,
            isLoadingOtherParticipant: false,
          }));
        }
      }
    };

    fetchOtherParticipant();
  }, [matchData, userData]);

  const renderMessages = () => {
    return messages?.map((message, index) => (
      <ChatMessage
        key={`${matchId}:${message._id}`}
        message={message}
        otherParticipant={otherParticipant}
        ref={(el) => {
          if (index === messages.length - 1) {
            setLastMessageRef(el);
          }
        }}
      />
    ));
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setLoadingStates((prev) => ({
      ...prev,
      isSendingMessage: true,
    }));

    try {
      // Send the message using the socket context
      sendMessage(matchId, newMessage.trim(), "match");
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoadingStates((prev) => ({
        ...prev,
        isSendingMessage: false,
      }));
    }
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const toggleSideMenu = () => setIsSideMenuVisible((prev) => !prev);
  const openProfileModal = () => setIsProfileModalVisible(true);
  const closeProfileModal = () => setIsProfileModalVisible(false);

  const renderSharedTags = () => {
    if (!matchData?.sharedTags?.length) return null;

    return (
      <div className={styles.resourceContainer}>
        <div className={styles.content}>
          <span className={styles.label}>Shared Tags:</span>
          <div className={styles.tags}>
            {matchData.sharedTags.map((tag) => (
              <span key={tag._id} className={styles.tag}>
                {tag.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleReport = () => {};

  // Add scroll button visibility effect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsScrollButtonVisible(!entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (lastMessageRef) {
      observer.observe(lastMessageRef);
    }

    return () => {
      if (lastMessageRef) {
        observer.unobserve(lastMessageRef);
      }
    };
  }, [messages, lastMessageRef]);

  // Add scroll to bottom function
  const scrollToBottom = () => {
    lastMessageRef?.scrollIntoView({ behavior: "smooth" });
  };

  // Update loadingStates when isAccepting changes
  useEffect(() => {
    setLoadingStates((prev) => ({
      ...prev,
      isAcceptingMatch: isAccepting,
    }));
  }, [isAccepting]);

  return (
    <>
      <div className={styles.chat}>
        <div
          className={cn(styles.chatContent, {
            [styles.chatContentContracted]: isSideMenuVisible,
          })}
        >
          <Toolbar
            toggleSideMenu={toggleSideMenu}
            otherParticipant={otherParticipant}
            openProfileModal={openProfileModal}
            isMobileView={isMobileView}
            isSideMenuVisible={isSideMenuVisible}
            onBack={onBack}
            matchData={matchData}
          />

          <div className={styles.messageContainer}>
            {loadingStates.isInitializingRoom ? (
              <div className={styles.status}>
                <LoadingMessage message="Initializing room..." />
              </div>
            ) : errors?.initError ? (
              <div className={styles.errorContainer}>
                <h2>{errors.initError}</h2>
                <button onClick={handleRetry}>Retry</button>
                <button onClick={() => navigate("/chats?type=matches")}>
                  Go Back to Matches
                </button>
              </div>
            ) : loadingStates.isLoadingMessages ||
              loadingStates.isLoadingOtherParticipant ? (
              <div className={styles.status}>
                <LoadingMessage message="Loading messages..." />
              </div>
            ) : (
              <>
                {renderMessages()}

                <AnimatePresence>
                  {isScrollButtonVisible && (
                    <FloatingPortal>
                      <motion.div
                        key="scroll-btn"
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, zIndex: "2" }}
                        className={styles.scrollToBottom}
                        onClick={scrollToBottom}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.1 }}
                      >
                        <FaArrowDown />
                      </motion.div>
                    </FloatingPortal>
                  )}
                </AnimatePresence>
              </>
            )}
          </div>
          <div ref={refs.setReference} style={{ width: "100%" }}></div>
          <MatchBar
            matchData={matchData}
            onAccept={handleAcceptMatch}
            isAccepting={isAccepting}
            acceptError={acceptError}
          />
          {matchData?.status !== "accepted" && (
            <ChatInput
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              onEnterPress={onEnterPress}
              handleSendMessage={handleSendMessage}
              isSendingMessage={loadingStates.isSendingMessage}
            />
          )}
        </div>
        <SideMenu
          openProfileModal={openProfileModal}
          isSideMenuVisible={isSideMenuVisible}
          otherParticipant={otherParticipant}
          matchData={matchData}
          handleReport={handleReport}
          toggleSideMenu={toggleSideMenu}
          isMobileView={isMobileView}
          onAccept={handleAcceptMatch}
          isAccepting={isAccepting}
          acceptError={acceptError}
          userData={userData}
        />
      </div>

      <UserProfileModal
        isOpen={isProfileModalVisible}
        onClose={closeProfileModal}
        className={styles.userModalStyles}
        otherParticipant={otherParticipant}
      />
    </>
  );
};

export default Match;
