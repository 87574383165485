import api from "api";
import handleAxiosError from "./handleAxiosError";
import * as typedefs from "typedefs";

/**
 * @typedef {typedefs.ChatRequestData} ChatRequestData
 * @typedef {typedefs.ChatData} ChatData
 * @typedef {typedefs.ErrorResponse} ErrorResponse
 * @typedef {typedefs.MessageData} MessageData
 */

/**
 * Sends a chat request.
 * @param {Object} chatRequest - The data for the chat request.
 * @param {mongoose.Types.ObjectId} chatRequest.recipient - The ID of the recipient user.
 * @param {Object} [chatRequest.sourceDocument] - Optional source document related to the chat request.
 * @param {string} requestData.sourceDocument.type - The type of the source document ('LifeExperience' or 'Story').
 * @param {mongoose.Types.ObjectId} requestData.sourceDocument._id - The ID of the source document.
 * @param {string} [chatRequest.message] - Optional message text for the chat request.
 * @returns {Promise<{ message: string, case: string, chatRequest?: ChatRequestData, chat?: ChatData }>}
 * 201 - An object containing a message, case type, and either chatRequest or chat based on the result type.
 * @returns {ErrorResponse} 400 - An error response object if an error occurs, structured as: { message: "Error message" }.
 * @returns {ErrorResponse} 500 - An error response object if an unexpected result type occurs, structured as: { message: "Unexpected result type" }.
 *
 * Possible case types:
 * - existingChatRequest: An existing chat request was found.
 * - existingChat: An existing chat was found.
 * - newApprovedChat: A new chat request was approved.
 * - newChatRequest: A new chat request was created.
 */
export const sendChatRequest = async (requestData) => {
  try {
    const response = await api.post("/chatrequests/", requestData);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Fetches chat requests for the authenticated user.
 * @param {Object} [options={}] - Optional parameters for fetching chat requests.
 * @returns {Promise<Array<ChatRequestData>>} A promise that resolves to an array of chat request objects.
 */
export const fetchChatRequests = async (options = {}) => {
  const status = options.status || "pending"; // Default status to 'pending' if not provided
  try {
    const response = await api.get("chatrequests", {
      params: { status }, // Pass status as a query parameter
    });
    return response.data; // The chat requests data
  } catch (error) {
    console.error("Error fetching chat requests:", error); // Log the error
    handleAxiosError(error);
  }
};

/**
 * Fetches a specific chat request by its requestId.
 * @param {string} requestId - The ID of the chat request to fetch.
 * @returns {Promise<ChatRequestData>} A promise that resolves to the chat request object.
 * @throws {Error} Throws an error if the chat request cannot be fetched.
 */
export const fetchChatRequestById = async (requestId) => {
  try {
    const response = await api.get(`chatrequests/${requestId}`);
    return response.data; // The chat request data
  } catch (error) {
    handleAxiosError(error); // Use handleError instead of console.error
    // Do not throw the error
  }
};

/**
 * Fetches messages for a specific chat request.
 *
 * @param {string} requestId - The unique identifier for the chat request.
 * @param {Object} [options={}] - Optional parameters for fetching messages.
 * @param {string} [options.before] - Fetch messages sent before this timestamp.
 * @param {number} [options.limit=20] - The maximum number of messages to fetch.
 * @returns {Promise<Array<MessageData>>} A promise that resolves to an array of ChatMessage objects for the chat request.
 * @throws {Error} Throws an error if the fetch operation fails.
 */
export const fetchMessagesForChatRequest = async (requestId, options = {}) => {
  const { before, limit = 20 } = options;

  try {
    const res = await api.get(`chatrequests/${requestId}/messages`, {
      params: { before, limit },
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err); // Use handleError instead of console.error
    // Do not throw the error
  }
};

/**
 * Approves a chat request.
 * @param {string} chatRequestId - The ID of the chat request to approve.
 * @returns {Promise<{ updatedChatRequest: ChatRequestData, newChat: ChatData }>} The updated chat request and the new chat.
 * @throws {Error} Throws an error if the approval fails.
 */
export const approveChatRequest = async (chatRequestId) => {
  try {
    const response = await api.post(`chatrequests/${chatRequestId}/approve`);
    return response.data; // Return the updated chat request and new chat
  } catch (error) {
    handleAxiosError(error);
  }
};
