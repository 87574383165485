import { useEffect, useState } from "react";
import styles from "./UserManagement.module.css";
import { useAdmin } from "hooks/useAdmin";
import { IoMdSettings } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { useToastContext } from "contexts/ToastContext";

const getAdminActionTypes = (userStatus) => {
  const baseActions = [
    { type: "Approve" },
    { type: "Ban" },
    { type: "Suspend" },
    { type: "Delete" },
  ];

  if (userStatus !== "active") {
    baseActions.push({ type: "Restore" });
  }

  return baseActions;
};

const REGISTRATION_STATUS_MAP = {
  pending: "pending",
  registered: "registered",
  emailVerified: "email verified",
  adminApproved: "admin approved",
};

const STATUS_INFO = {
  active: "User can access all permitted features",
  suspended: "Temporarily restricted from using the platform",
  banned: "Permanently restricted from using the platform",
  deleted: "Account has been removed",
};

const REGISTRATION_STATUS_INFO = {
  pending: "User has started registration but not completed",
  registered: "User has completed initial registration",
  emailVerified: "User has verified their email address",
  adminApproved: "Admin has approved the user account",
};

const ROLE_INFO = {
  user: "Regular user with standard permissions",
  admin: "Administrator with full platform access",
  moderator: "Can moderate content and users",
};

const UserManagement = () => {
  const { addToast } = useToastContext();

  const {
    users,
    fetchAllUsers,
    approveUserAccount,
    deleteUserAccount,
    banUserAccount,
    suspendUserAccount,
    restoreUserAccount,
  } = useAdmin();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isAdminActionModalOpen, setIsAdminActionModalOpen] = useState(false);
  const [adminActionType, setAdminActionType] = useState(null);
  const [actionReason, setActionReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpenAdminActions = (userId) => {
    setSelectedUserId(selectedUserId === userId ? null : userId);
  };

  const handleAdminActionModal = (type) => {
    setIsAdminActionModalOpen(true);
    setAdminActionType(type);
  };

  const handleCloseModal = () => {
    setSelectedUserId(null);
    setIsAdminActionModalOpen(false);
    setAdminActionType(null);
    setActionReason("");
  };

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      let res;
      switch (adminActionType) {
        case "Approve":
          res = await approveUserAccount(data);
          break;
        case "Ban":
          res = await banUserAccount(data);
          break;
        case "Suspend":
          res = await suspendUserAccount(data);
          break;
        case "Delete":
          res = await deleteUserAccount(data);
          break;
        case "Restore":
          res = await restoreUserAccount(data);
          break;
        default:
          throw new Error("Invalid action type");
      }
      addToast(res.message, "success");
      fetchAllUsers({ page: 1, limit: 10 });
    } catch (err) {
      addToast(
        `Failed to ${adminActionType.toLowerCase()} user. Please try again later.`,
        "error"
      );
    } finally {
      setIsSubmitting(false);
      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchAllUsers({ page: 1, limit: 10 });
  }, []);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.closest("button")) return;
      setSelectedUserId(null);
      setIsAdminActionModalOpen(false);
      setAdminActionType(null);
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const AdminActionButton = ({ key, user, action }) => (
    (user.isAdminApproved && action.type === "Approve") ||
      (user.banned && action.type === "Ban") ||
      (user.suspended && action.type === "Suspend") ||
      ((user.deleted || user.banned || user.suspened) && action.type === "Restore") ? null :
      <button key={key} onClick={() => handleAdminActionModal(action.type)}>
        {action.type}
      </button>
  )

  return (
    <div className={styles.userManagementContainer}>
      <Modal
        isOpen={isAdminActionModalOpen}
        onClose={handleCloseModal}
        showCloseButton={false}
      >
        {selectedUserId && (
          <div>
            {adminActionType} user <br />
            <span>
              Email: {users.find((user) => user._id === selectedUserId).email}
            </span>
            <br />
            <span>Id: {selectedUserId}</span>
            <div className={styles.actionReason}>
              <input
                type="text"
                placeholder="Input reason to proceed"
                value={actionReason}
                onChange={(e) => setActionReason(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <Button
                onClick={handleCloseModal}
                color="secondary"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                onClick={() =>
                  handleSubmit({ userId: selectedUserId, reason: actionReason })
                }
                disabled={!actionReason || isSubmitting}
              >
                {isSubmitting ? "Submitting..." : adminActionType}
              </Button>
            </div>
          </div>
        )}
      </Modal>
      {users.length === 0 && (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      )}
      {users.length > 0 && (
        <div className={styles.usersTable}>
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Username</th>
                <th className={styles.tooltipContainer}>
                  Role
                  <div className={styles.tooltip}>
                    {Object.entries(ROLE_INFO).map(([role, desc]) => (
                      <div key={role}>
                        <strong>{role}:</strong> {desc}
                      </div>
                    ))}
                  </div>
                </th>
                <th className={styles.tooltipContainer}>
                  Status
                  <div className={styles.tooltip}>
                    {Object.entries(STATUS_INFO).map(([status, desc]) => (
                      <div key={status}>
                        <strong>{status}:</strong> {desc}
                      </div>
                    ))}
                  </div>
                </th>
                <th className={styles.tooltipContainer}>
                  Registration Status
                  <div className={styles.tooltip}>
                    {Object.entries(REGISTRATION_STATUS_INFO).map(
                      ([status, desc]) => (
                        <div key={status}>
                          <strong>{REGISTRATION_STATUS_MAP[status]}:</strong>{" "}
                          {desc}
                        </div>
                      )
                    )}
                  </div>
                </th>
                <th>Created At</th>
                <th>
                  <IoMdSettings />
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.email}</td>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td>{user.status}</td>
                  <td>
                    {REGISTRATION_STATUS_MAP[user.registrationStatus] ||
                      user.registrationStatus}
                  </td>
                  <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                  <td>
                    <div key={user._id}>
                      <button onClick={() => handleOpenAdminActions(user._id)}>
                        <BsThreeDotsVertical />
                      </button>
                      {selectedUserId === user._id && (
                        <div className={styles.adminActions}>
                          {getAdminActionTypes(user.status).map(
                            (action, index) => (
                              <AdminActionButton key={index} user={user} action={action} />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
