// packages/client/src/components/LifeExperience/StoryEditor.module.css
import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react";
import cn from "classnames";

import styles from "./TagEditor.module.css";

import Portal from "components/Portal/Portal";

import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import _ from "lodash";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";
import { FaClock } from "react-icons/fa";
import useTagsSearch from "hooks/useTagsSearch";

/**
 * TagOption component represents an individual tag option in the dropdown list.
 *
 * @component
 * @param {Object} props - The props for the TagOption component.
 * @param {Tag} props.tag - The tag data to display.
 * @param {number} props.i - The index of the tag in the list.
 * @param {Function} props.selectTag - Function to handle tag selection.
 * @param {Function} props.setIsOpen - Function to set the open state of the dropdown.
 * @param {Function} props.setHighlightedIndex - Function to set the index of the highlighted tag.
 * @param {Function} props.isTagSelected - Function to check if the tag is selected.
 * @param {number} props.highlightedIndex - The index of the currently highlighted tag.
 * @param {React.Ref} ref - Forwarded ref to access the component's reference.
 * @returns {JSX.Element} The rendered TagOption component.
 */
const TagOption = forwardRef(
  (
    {
      tag,
      i,
      selectTag,
      setIsOpen,
      setHighlightedIndex,
      isTagSelected,
      highlightedIndex,
    },
    ref
  ) => {
    const popularityIndex =
      (tag.matchesCount || 0) +
      (tag.storiesCount || 0) +
      (tag.lifeExpCount || 0);

    const getPopularityText = (count) => {
      if (count === 0) return null;
      if (count === 1) return "used once";
      if (count < 5) return `used ${count} times`;
      if (count < 10) return "used a few times";
      if (count < 50) return "used frequently";
      return "popular tag";
    };

    return (
      <li
        ref={ref}
        key={tag._id}
        onClick={(e) => {
          e.stopPropagation();
          selectTag(tag);
          setIsOpen(false);
        }}
        onMouseEnter={() => setHighlightedIndex(i)}
        onMouseDown={(e) => e.preventDefault()}
        className={cn(styles.option, {
          [styles.selected]: isTagSelected(tag),
          [styles.highlighted]: i === highlightedIndex,
        })}
      >
        <div className={styles["option-header"]}>
          <div className={styles["option-name"]}>{tag.name}</div>
          {popularityIndex > 0 && (
            <div className={styles["option-hits"]}>
              {getPopularityText(popularityIndex)}
            </div>
          )}
        </div>
        {tag.description && (
          <div className={styles["option-description"]}>{tag.description}</div>
        )}
      </li>
    );
  }
);

const SelectedBadge = ({ tag, onSelect }) => (
  <button
    key={tag._id}
    onClick={(e) => {
      e.stopPropagation();
      onSelect(tag);
    }}
    className={styles["option-badge"]}
  >
    {tag.name}
    {tag.status && (
      <Tooltip>
        <TooltipTrigger>
          <div className={styles["pending-icon"]}>
            <FaClock />
          </div>
        </TooltipTrigger>
        <TooltipContent>Still subject to admin approval.</TooltipContent>
      </Tooltip>
    )}
    <span className={styles["remove-btn"]}>&times;</span>
  </button>
);

const TagEditor = forwardRef(
  ({ selected, onSelect, className, maxTags = 3 }, ref) => {
    const [query, setQuery] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [open, setOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    const { tags, loading, error, hasMore } = useTagsSearch(query, pageNumber);

    const { refs, floatingStyles } = useFloating({
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(3),
        flip(),
        shift(),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    useImperativeHandle(ref, () => refs.reference.current);

    const handleInputChange = (e) => {
      setQuery(e.target.value);
    };

    const mirrorRef = useRef(null);
    const inputRef = useRef(null);

    const adjustWidth = useCallback(() => {
      const mirrorText = mirrorRef.current;
      const input = inputRef.current;
      const container = inputRef.current?.parentNode;

      if (mirrorText && input && container) {
        mirrorText.textContent = query || input.placeholder || " ";
        let calculatedWidth = mirrorText.offsetWidth + 10;
        calculatedWidth = Math.min(calculatedWidth, container.offsetWidth);
        input.style.width = `${calculatedWidth}px`;
      }
    }, [query]);

    useEffect(() => {
      const handleResize = () => {
        adjustWidth();
      };

      window.addEventListener("resize", handleResize);

      adjustWidth();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [adjustWidth, query, selected]);

    useEffect(() => {
      if (isInputFocused) {
        setOpen(true);
      }
    }, [isInputFocused]);

    useEffect(() => {}, [open, tags]);

    function clearOptions() {
      onSelect([]);
      setQuery("");
    }

    function isTagSelected(tag) {
      return selected?.some((t) => t._id === tag._id);
    }

    function selectTag(tag) {
      const isAlreadySelected = selected?.some((t) => t._id === tag._id);

      if (isAlreadySelected) {
        onSelect(selected.filter((t) => t._id !== tag._id));
      } else if (selected?.length < maxTags) {
        onSelect([...(selected || []), tag]);
      }
      setQuery("");
    }

    const tagOptionProps = {
      selectTag,
      setIsOpen: setOpen,
      setHighlightedIndex,
      isTagSelected,
      highlightedIndex,
    };

    const observer = useRef();
    const lastElementRef = useCallback(
      (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((prev) => prev + 1);
          }
        });
        if (node) observer.current.observe(node);
      },
      [loading, hasMore]
    );

    useEffect(() => {
      setPageNumber(1);
    }, [query]);

    return (
      <div
        ref={refs.setReference}
        data-tour="tag-editor"
        className={cn(styles.container, className, {
          [styles.containerFocused]: isInputFocused,
        })}
        tabIndex={0}
        onFocus={() => inputRef.current.focus()}
        onClick={() => inputRef.current.focus()}
        onBlur={() => setOpen(false)}
      >
        <span className={styles.selectedList}>
          {selected?.map((tag) => (
            <SelectedBadge key={tag._id} tag={tag} onSelect={selectTag} />
          ))}
          {selected?.length < maxTags && (
            <input
              ref={inputRef}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onChange={handleInputChange}
              className={styles.input}
              value={query}
              placeholder={
                selected?.length ? "Add another tag" : "Select up to 3 tags"
              }
            />
          )}
          <span ref={mirrorRef} className={styles["mirror-text"]}></span>
        </span>
        <button
          className={styles["clear-btn"]}
          onClick={(e) => {
            e.stopPropagation();
            clearOptions();
          }}
        >
          &times;
        </button>

        {open && (
          <Portal>
            <ul
              ref={refs.setFloating}
              className={styles.list}
              style={floatingStyles}
            >
              {tags.map((tag, i) => (
                <TagOption
                  ref={tags.length === i + 1 ? lastElementRef : null}
                  key={tag._id}
                  tag={tag}
                  i={i}
                  {...tagOptionProps}
                />
              ))}
              {loading && (
                <li className={styles.loading}>
                  <LoadingMessage message="Loading more tags..." />
                </li>
              )}
            </ul>
          </Portal>
        )}
      </div>
    );
  }
);

export default TagEditor;
