// packages/client/src/pages/ProtectedRoutes/ProtectedRoutes.jsx
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import PageWrapper from "components/PageWrapper/PageWrapper";
import AuthOverlay from "./AuthOverlay/AuthOverlay";
import Button from "components/Button/Button";
import VerifyEmailRequired from "pages/EmailVerification/VerifyEmailRequired";
import AccountStatus from "pages/AccountStatus/AccountStatus";
import { Login } from "pages";

/**
 * A wrapper component that protects routes based on user authentication and status
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.redirectTo="/"] - Path to redirect to when access is denied
 * @param {boolean} [props.loggedInOnly=false] - Whether the route requires authentication
 * @param {boolean} [props.showOverlay=false] - Whether to show auth overlay instead of redirecting
 * @param {boolean} [props.registeredOnly=false] - Whether the route requires user registration
 * @param {boolean} [props.emailVerifiedOnly=false] - Whether the route requires email verification
 * @param {boolean} [props.activeOnly=false] - Whether the route requires an active account status
 * @param {boolean} [props.adminApprovedOnly=false] - Whether the route requires admin approval
 *
 * @example
 * // Basic authentication protection
 * <Route element={<ProtectedRoutes loggedInOnly />}>
 *   <Route path="/protected" element={<ProtectedComponent />} />
 * </Route>
 *
 * @example
 * // Require email verification
 * <Route element={<ProtectedRoutes loggedInOnly emailVerifiedOnly />}>
 *   <Route path="/verified-only" element={<VerifiedComponent />} />
 * </Route>
 *
 * @example
 * // Show overlay instead of redirecting
 * <Route element={<ProtectedRoutes loggedInOnly showOverlay />}>
 *   <Route path="/with-overlay" element={<OverlayComponent />} />
 * </Route>
 *
 * @returns {JSX.Element} The protected route content or a redirect
 */
const ProtectedRoutes = (props) => {
  const {
    redirectTo = "/",
    loggedInOnly = false,
    showOverlay = false,
    registeredOnly = false,
    emailVerifiedOnly = false,
    activeOnly = false,
    adminApprovedOnly = false,
    isAdmin,
  } = props;

  const { userData, loading } = useUserContext();
  const isAdminRole = userData && userData.role === "admin";

  if (loading) {
    return (
      <PageWrapper>
        <LoadingContainer>
          <LoadingMessage message="Authenticating user..." />
        </LoadingContainer>
      </PageWrapper>
    );
  }

  // Check authentication
  const isAuthenticated = !!userData;
  if (loggedInOnly && !isAuthenticated) {
    return showOverlay ? (
      <AuthOverlay overlayContent={<Login />}>
        <Outlet />
      </AuthOverlay>
    ) : (
      <Navigate to={redirectTo} />
    );
  }

  // Early return if not authenticated
  if (!isAuthenticated) {
    return <Outlet />;
  }

  // Check registration
  if (registeredOnly && !userData.isRegistered) {
    return (
      <AuthOverlay overlayContent={<AccountStatus />}>
        <Outlet />
      </AuthOverlay>
    );
  }

  // Check email verification
  if (emailVerifiedOnly && !userData.isEmailVerified) {
    return showOverlay ? (
      <AuthOverlay overlayContent={<AccountStatus />}>
        <Outlet />
      </AuthOverlay>
    ) : (
      <Navigate to="/verify-email" />
    );
  }

  // Check admin approval
  if (adminApprovedOnly && !userData.isAdminApproved) {
    return showOverlay ? (
      <AuthOverlay overlayContent={<AccountStatus />}>
        <Outlet />
      </AuthOverlay>
    ) : (
      <Navigate to="/account-status" />
    );
  }

  // Check account status
  if (
    activeOnly &&
    (userData.isBanned || userData.isSuspended || userData.isDeleted)
  ) {
    return showOverlay ? (
      <AuthOverlay overlayContent={<AccountStatus />}>
        <Outlet />
      </AuthOverlay>
    ) : (
      <Navigate to="/account-status" />
    );
  }

  if (isAdmin && !isAdminRole) {
    // User is not an admin but needs to be
    return <Navigate to={"/"} />;
  }

  // All checks passed
  return <Outlet />;
};

export default ProtectedRoutes;
