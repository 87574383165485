const { createContext, useState, useEffect, useContext } = require("react");
const callingSoundRaw = require("../assets/sounds/internalRing.mp3");
const messageNotifRaw = require("../assets/sounds/messageNotif.mp3");
const receivingCallSoundRaw = require("../assets/sounds/normalSoftCall.mp3");

const SoundContext = createContext();

const SoundContextProvider = ({ children }) => {
  const callingSound = new Audio(callingSoundRaw);
  callingSound.loop = true;
  const messageNotifSound = new Audio(messageNotifRaw);
  const receivingCallSound = new Audio(receivingCallSoundRaw);
  receivingCallSound.loop = true;

  return (
    <SoundContext.Provider
      value={{ callingSound, messageNotifSound, receivingCallSound }}
    >
      {children}
    </SoundContext.Provider>
  );
};

/**
 * Custom hook to use the SoundContext.
 * @returns {Object} The context value containing callingSound, messageNotifSound, and receivingCallSound.
 */
const useSoundContext = () => useContext(SoundContext);

export { SoundContext, SoundContextProvider, useSoundContext };
