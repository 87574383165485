import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { motion, AnimatePresence } from "framer-motion"; // Import motion and AnimatePresence
import styles from "./Stories.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import SearchInput from "components/SearchInput/SearchInput";
import { FaSortAmountDown, FaPen } from "react-icons/fa"; // Import FaPen for the write button icon
import * as _ from "lodash";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import cn from "classnames";
import { UserContext } from "contexts/UserContext";
import Button from "components/Button/Button";
import StoryCard from "components/StoryCard/StoryCard";
import * as STORIES_API from "api/stories";
import useSearch from "hooks/useSearch";
import FilterPopover from "components/FilterPopover/FilterPopover";

// const Sort = ({ sort, setSort, options, onChange }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const { refs, floatingStyles, context } = useFloating({
//     open: isOpen,
//     onOpenChange: setIsOpen,
//     middleware: [offset(3), flip(), shift()],
//     whileElementsMounted: autoUpdate,
//   });

//   const dismiss = useDismiss(context);

//   const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

//   const handleItemClick = (option) => {
//     setSort(option);
//     setIsOpen(false);
//     onChange(option); // Call the onChange function passed as a prop
//   };

//   return (
//     <>
//       <span
//         ref={refs.setReference}
//         className={styles.sortBtn}
//         onClick={() => setIsOpen(true)}
//         {...getReferenceProps()}
//       >
//         {sort.label} <FaSortAmountDown />
//       </span>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.ul
//             ref={refs.setFloating}
//             style={floatingStyles}
//             className={cn(styles.list)}
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             transition={{ duration: 0.1 }}
//             {...getFloatingProps()}
//           >
//             <div className={styles.dropdownHeader}>Sort by:</div>
//             {options.map((option) => (
//               <li key={option.value} onClick={() => handleItemClick(option)}>
//                 {option.label}
//               </li>
//             ))}
//           </motion.ul>
//         )}
//       </AnimatePresence>
//     </>
//   );
// };

const sortOptions = [
  {
    value: "relevance",
    label: "Relevance",
  },
  {
    value: "createdAt",
    label: "Newest",
  },
  {
    value: "hugs",
    label: "Most Hugs",
  },
];

function Stories() {
  const { userData } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [input, setInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    sort: {
      value: "createdAt",
      label: "Newest",
    },
    tags: [],
    anonymized: null,
    reachedOut: null,
    connected: null,
  });

  const {
    results: stories,
    loading,
    search,
    hasMore,
  } = useSearch({
    searchAPI: STORIES_API.searchStories,
    initialQuery: "",
    initialPage: 1,
    limit: 5,
  });

  useEffect(() => {
    const filterObj = {
      sort: filters.sort ? filters.sort.value : null,
      tags: filters.tags.map((tag) => tag._id),
      isAnonymized: filters.anonymized ? filters.anonymized.value : null,
      isReachedOut: filters.reachedOut ? filters.reachedOut.value : null,
      isConnected: filters.connected ? filters.connected.value : null,
    };

    search({ query: searchQuery, page: 1, ...filterObj });
  }, []); // Empty dependency array to run once on initial render

  const handleSearchChange = (e) => {
    setInput(e.target.value);
  };

  const handleSearchEnter = (value) => {
    setSearchQuery(value);
    setCurrentPage(1);

    const filterObj = {
      sort: filters.sort ? filters.sort.value : null,
      tags: filters.tags.map((tag) => tag._id),
      isAnonymized: filters.anonymized ? filters.anonymized.value : null,
      isReachedOut: filters.reachedOut ? filters.reachedOut.value : null,
      isConnected: filters.connected ? filters.connected.value : null,
    };

    search({ query: value, page: 1, ...filterObj });
  };

  const handleSearchClear = () => {
    setInput(""); // Clear the search input
  };

  const observer = useRef();

  const loadMoreResults = useCallback(() => {
    if (hasMore && !loading) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);

      const filterObj = {
        sort: filters.sort ? filters.sort.value : null,
        tags: filters.tags.map((tag) => tag._id),
        isAnonymized: filters.anonymized ? filters.anonymized.value : null,
        isReachedOut: filters.reachedOut ? filters.reachedOut.value : null,
        isConnected: filters.connected ? filters.connected.value : null,
      };

      search({ query: searchQuery, page: nextPage, ...filterObj });
    }
  }, [hasMore, loading, currentPage, searchQuery, filters, search]);

  const lastStoryRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreResults();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, loadMoreResults]
  );

  const [sort, setSort] = useState({ label: "Relevance", value: "relevance" });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div>
          <div className={styles.searchbar}>
            <SearchInput
              value={input}
              onChange={handleSearchChange}
              onEnter={handleSearchEnter}
              onClear={handleSearchClear}
              className={styles.searchInput}
              placeholder="Search stories..."
            />
          </div>
          <div className={styles.filters}>
            <FilterPopover
              onChange={handleFilterChange}
              filters={filters}
              sortOptions={sortOptions}
              handleFilter={() => handleSearchEnter("")}
            />
          </div>
          <Button
            shape="rounded"
            onClick={() => window.open(`/stories/new`, "_blank")}
            color="primary"
            className={styles.writeButton} // Add class for styling
          >
            <FaPen /> Write
          </Button>
        </div>
      </div>
      <div className={styles.storyList}>
        {stories.length > 0 &&
          stories.map((story, i) => (
            <StoryCard
              ref={i === stories.length - 1 ? lastStoryRef : null}
              key={story._id}
              story={story}
              userData={userData}
            />
          ))}
        {loading && (
          <div className={styles.status}>
            <LoadingMessage message="Loading stories..." />
          </div>
        )}

        {!loading && !hasMore && stories.length > 0 && (
          <div className={styles.status}>You've reached the end!</div>
        )}
        {!loading && stories.length === 0 && (
          <div className={styles.status}>No stories found.</div>
        )}
      </div>
    </div>
  );
}

export default Stories;
