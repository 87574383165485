// packages/client/src/pages/Register/StepLifeExperiences.jsx
import React, { useContext, useEffect, useRef, useState } from "react";
import common from "../Register.module.css";
import self from "./StepLifeExperiences.module.css";
import { motion } from "framer-motion";
import Button from "components/Button/Button";

import { ToastContext } from "contexts/ToastContext";
import LifeExperienceModal from "components/LifeExperienceModal/LifeExperienceModal";
import { ReactTyped } from "react-typed";

import { useLifeExperiences } from "hooks/useLifeExperience";
import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";

import { formatMonthYear } from "utils/dates";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { GiBilledCap } from "react-icons/gi";
import { IoMdSearch } from "react-icons/io";

const steps = [
  {
    target: '[data-tour="editor"]',
    title: "What is a Life Experience?",
    content: (
      <div>
        <p>Share significant life challenges that have shaped who you are:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>🤍 Loss of loved ones</li>
          <li>🏥 Health challenges</li>
          <li>💔 Relationship struggles</li>
          <li>📚 Academic/career pressures</li>
          <li>💰 Financial difficulties</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: '[data-tour="tag-editor"]',
    title: "Adding Tags",
    content: (
      <div>
        <p>Tags help organize and connect experiences:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>🏷️ Choose relevant categories</li>
          <li>🤝 Find others with similar experiences</li>
          <li>🔍 Make your story discoverable</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: '[data-tour="story-editor"]',
    title: "Sharing Your Story",
    content: (
      <div>
        <p>Your story helps others understand your journey:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>📝 Share what happened</li>
          <li>💭 Describe how it affected you</li>
          <li>💪 Include how you're coping</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: '[data-tour="editor"]',
    title: "Privacy & Matching",
    content: (
      <div>
        <p>Your experiences are protected:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>🔒 Share only what you're comfortable with</li>
          <li>👥 Control who sees your stories</li>
          <li>✨ Get matched with understanding peers</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
];

const AddLifeExpModal = ({ isOpen, onSave, onCancel, isFirstOpen }) => {
  // note: I've placed the data-tour attributes inside the LifeExperience, TagEditor, and StoryEditor components themselves

  const [startTour, setStartTour] = useState(false);

  // Will run after commit/mount of Modal and LifeExperience
  // This assumes the Modal and LifeExperience are ready to be interacted with.
  useEffect(() => {
    if (isOpen) {
      setStartTour(true); //startTour renders JoyrideWrapper
    }
  }, []); // Depend on isOpen to re-evaluate when the modal opens

  useEffect(() => {
    if (startTour) {
      isFirstOpen.current = false;
    }
  }, [startTour]);

  if (!isOpen) return null;

  return (
    <>
      <LifeExperienceModal
        isOpen={isOpen}
        onCreate={onSave}
        onClose={onCancel}
        mode="new"
      />
      {startTour && (
        <JoyrideWrapper steps={steps} runOnInit={isFirstOpen.current} />
      )}
    </>
  );
};

// Base Card Component
const BaseLifeExperienceCard = ({ experience, actionMenuOptions }) => {
  return (
    <div key={experience._id} className={self.experienceCard}>
      <div className={self.inner}>
        <div className={self.header}>
          <div className={self.tags}>
            {experience.tags.map((tag) => (
              <div key={tag._id} className={self.tag}>
                {tag.name}
              </div>
            ))}
          </div>
        </div>
        <div className={self.story}>{experience.story}</div>
        <div className={self.footer}>
          <div className={self.date}>
            {formatMonthYear(experience.date.month, experience.date.year)}
          </div>
          <div className={self.status}>
            <div
              className={`${self.statusIcon} ${
                experience.anonymized ? self.statusTrue : self.statusFalse
              }`}
            >
              <GiBilledCap />
            </div>
            <div
              className={`${self.statusText} ${
                experience.anonymized ? self.statusTrue : self.statusFalse
              }`}
            >
              {experience.anonymized ? "Anonymized" : "Not Anonymized"}
            </div>
            <div
              className={`${self.statusIcon} ${
                experience.searchable ? self.statusTrue : self.statusFalse
              }`}
            >
              <IoMdSearch />
            </div>
            <div
              className={`${self.statusText} ${
                experience.searchable ? self.statusTrue : self.statusFalse
              }`}
            >
              {experience.searchable ? "Searchable" : "Not Searchable"}
            </div>
          </div>
        </div>
        <div className={self.actionMenu}>
          <ReusableActionMenu
            options={actionMenuOptions}
            icon={IoEllipsisHorizontal}
          />
        </div>
      </div>
    </div>
  );
};

const LifeExperienceItem = ({ lifeExperience, onSave, onDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleUpdate = async (formData) => {
    try {
      onSave(formData);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating life experience:", error);
    }
  };

  const handleDeleteClick = () => {
    onDelete();
  };

  const actionMenuOptions = [
    { label: "Edit", onClick: handleEdit },
    { label: "Delete", onClick: handleDeleteClick },
  ];

  return (
    <>
      <BaseLifeExperienceCard
        experience={lifeExperience}
        actionMenuOptions={actionMenuOptions}
      />
      {isModalOpen && (
        <LifeExperienceModal
          lifeExperience={lifeExperience}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode="edit"
          onUpdate={handleUpdate}
          onDelete={handleDeleteClick}
        />
      )}
    </>
  );
};

const LifeExperienceList = ({
  lifeExperiences,
  onSave,
  onCancel,
  onDelete,
}) => {
  return (
    <>
      {lifeExperiences.length > 0 &&
        lifeExperiences.map((lifeExp, index) => (
          <LifeExperienceItem
            key={index}
            index={index}
            lifeExperience={lifeExp}
            onSave={(data) => onSave(index, data)}
            onCancel={() => onCancel(index)}
            onDelete={() => onDelete(index)}
          />
        ))}
    </>
  );
};

const StepLifeExperiences = ({ data, onSave, handleStepValidity }) => {
  const { toastList, addToast, removeToast } = useContext(ToastContext);

  const [isEditing, setIsEditing] = useState(false); // New state to track edit mode
  const [isAdding, setIsAdding] = useState(false);

  const {
    lifeExperiences,
    addLifeExperience,
    editLifeExperience,
    setLifeExperiences,
  } = useLifeExperiences(data);

  // Update parent form data
  useEffect(() => {
    onSave({ lifeExperiences });
  }, [lifeExperiences, onSave]);

  const handleSave = (index, lifeExp) => {
    // Adding a new experience
    if (index === null) {
      addLifeExperience(lifeExp);
    } else {
      editLifeExperience(index, lifeExp);
    }

    setIsAdding(false);
    addToast("Life experiences successfully updated", "success");
  };

  const isBlankLifeExp = (lifeExp) => {
    return !lifeExp.story && !lifeExp.tag._id && !lifeExp.tag.name;
  };

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleEndEdit = () => {
    setIsEditing(false);
  };

  const handleCancel = (index) => {
    // Revert to the original tag story
    const original = data.lifeExperiences[index];

    if (isBlankLifeExp(original)) {
      handleDelete(index);
      return;
    } else {
      const updatedLifeExperiences = [...lifeExperiences];
      updatedLifeExperiences[index] = original;
      setLifeExperiences(updatedLifeExperiences);
    }
  };

  const handleDelete = (index) => {
    if (lifeExperiences.length > 1) {
      const updatedLifeExperiences = lifeExperiences.filter(
        (_, i) => i !== index
      );
      setLifeExperiences(updatedLifeExperiences);
    } else {
      // Handle the case where this is the only tag story
      // For example, reset to default or show a message
      setLifeExperiences([]);
    }
  };

  // Count the non-blank tag stories
  const nonBlank = lifeExperiences.filter(
    (lifeExp) => !isBlankLifeExp(lifeExp)
  ).length;

  // Function to check if the tag stories meet the validity criteria
  const checkValidity = () => {
    // Valid if there's at least one non-blank tag story and not more than three
    return nonBlank >= 1 && !isEditing;
  };

  // Call this function whenever tagStories change or edit mode changes
  useEffect(() => {
    const isValid = checkValidity();
    handleStepValidity(isValid);
  }, [lifeExperiences, isEditing]);

  const canAddNew = !isEditing;

  // What has life been like for you, {username}?
  // typewritten

  const [animationStep, setAnimationStep] = useState(1);

  const variants = {
    hidden: { opacity: 0, pointerEvents: "none" },
    visible: { opacity: 1, pointerEvents: "auto" },
  };

  const isFirstOpen = useRef(true);

  const handleAddClick = () => {
    setIsAdding(true);
  };

  // Use this `handleAddClick` for the button's onClick event handler

  return (
    <>
      <div className={common.header}>
        <ReactTyped
          strings={[`What has life been like for you,^500 ${data?.username}?`]}
          typeSpeed={50}
          backSpeed={35}
          showCursor
          onComplete={(el) => {
            setTimeout(() => {
              el.cursor.hidden = true;
            }, 1500);
            setAnimationStep((prev) => prev + 1);
          }}
        />
      </div>

      <motion.div
        className={self.container}
        variants={variants}
        transition={{ ease: "easeIn", duration: 1 }}
        initial="hidden"
        animate={animationStep >= 1 ? "visible" : "hidden"}
        onAnimationComplete={() => {
          if (animationStep === 1) setAnimationStep(2);
        }}
      >
        <LifeExperienceList
          lifeExperiences={lifeExperiences}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={handleDelete}
          onStartEdit={handleStartEdit} // Pass callback to start edit
          onEndEdit={handleEndEdit} // Pass callback to end edit
        />
        <div className={self.addLifeExp}>
          {canAddNew && (
            <Button onClick={handleAddClick}>Write about an experience</Button>
          )}
        </div>
      </motion.div>
      {isAdding && (
        <AddLifeExpModal
          isOpen={isAdding}
          onSave={(lifeExp) => handleSave(null, lifeExp)}
          onCancel={() => setIsAdding(false)}
          isFirstOpen={isFirstOpen}
        />
      )}
    </>
  );
};

export default StepLifeExperiences;
