// packages/client/src/components/Modal/Modal.jsx
import ReactDOM from "react-dom";
import React, { Children, useEffect, useRef, useState } from "react";
import styles from "./Modal.module.css";
import Button from "components/Button/Button";
import cn from "classnames";
import ModalButtons from "./ModalButtons";

const Modal = ({
  isOpen: isOpenProp,
  onClose,
  showCloseButton = true,
  children,
  portalNodeSelector = "#root", // Default to '#root' if no selector is provided
  className,
}) => {
  const [isOpen, setIsOpen] = useState(null);
  const [portalNode, setPortalNode] = useState(null);
  // Use a ref for the modal root element
  const modalRootRef = useRef();

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  useEffect(() => {
    const portalNodeProp = document.querySelector(portalNodeSelector);
    const fatherElement = portalNodeProp || document.querySelector("#root");
    const modalElement = document.createElement("div");
    fatherElement.appendChild(modalElement);
    setPortalNode(modalElement);

    return () => {
      fatherElement.removeChild(modalElement);
    };
  }, [portalNodeSelector]);

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsOpen(isOpenProp || false);
      onClose();
    }
  };

  return isOpen && portalNode
    ? ReactDOM.createPortal(
      <div className={styles.modal} onClick={handleBackdropClick}>
        <div
          className={cn(styles.box, className)}
          onClick={(e) => e.stopPropagation()}
        >
          {showCloseButton && (
            <div className={styles["close-btn"]}>
              <button onClick={onClose}>&times;</button>
            </div>
          )}
          {children}
        </div>
      </div>,
      portalNode
    )
    : null;
};

export default Modal;
