// useLifeExperiences.js
import { useState } from "react";

export const useLifeExperiences = (initialData) => {
  const [lifeExperiences, setLifeExperiences] = useState(() => {
    // Initialize lifeExperiences from initialData
    return initialData &&
      initialData.lifeExperiences &&
      initialData.lifeExperiences.length > 0
      ? initialData.lifeExperiences
      : [];
  });

  /**
   * Adds a new life experience to the current list of life experiences.
   * This function updates the state to include the newly provided life experience
   * by appending it to the end of the existing life experiences array.
   *
   * @param {Object} lifeExperience - The new life experience to be added
   * @param {Array<Object>} lifeExperience.tags - Array of tags associated with the life experience
   * @param {string} lifeExperience.tags[].._id - The unique identifier of each tag
   * @param {string} lifeExperience.tags[].name - The name of each tag
   * @param {string} lifeExperience.story - The narrative details of the life experience
   * @param {Object} lifeExperience.date - The date of the life experience
   * @param {number} lifeExperience.date.month - The month (1-12)
   * @param {number} lifeExperience.date.year - The year
   *
   * @example
   * addLifeExperience({
   *   tags: [
   *     { _id: "65436a9256985446898af17a", name: "grief" },
   *     { _id: "65436a9256985446898af17b", name: "loss" }
   *   ],
   *   story: "It was a challenging time, but I learned a lot about myself.",
   *   date: {
   *     month: 6,
   *     year: 2023
   *   }
   * });
   */
  const addLifeExperience = (lifeExp) => {
    setLifeExperiences((prev) => [...prev, lifeExp]);
  };

  const editLifeExperience = (index, updatedLifeExp) => {
    setLifeExperiences((prev) =>
      prev.map((exp, i) => (i === index ? updatedLifeExp : exp))
    );
  };

  const deleteLifeExperience = (index) => {
    setLifeExperiences((prev) => prev.filter((_, i) => i !== index));
  };

  return {
    lifeExperiences,
    addLifeExperience,
    editLifeExperience,
    deleteLifeExperience,
    setLifeExperiences,
  };
};
