import React, { useState } from "react";
import styles from "./LifeExperienceCard.module.css";
import { formatMonthYear } from "utils/dates";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import { IoEllipsisHorizontal } from "react-icons/io5";
import LifeExperienceModal from "components/LifeExperienceModal/LifeExperienceModal";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";
import useLifeExperience from "./useLifeExperience";
import ReachOutDialog from "components/ReachOutDialog/ReachOutDialog"; // Updated import
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";
import { GiBilledCap } from "react-icons/gi";
import { PiPlugsConnectedFill, PiHandHeart } from "react-icons/pi";
import { MdVerified } from "react-icons/md";
import { GiCampfire } from "react-icons/gi";
import ScoreMetadataDisplay from "components/ScoreMetadataDisplay/ScoreMetadataDisplay";
import ReportModal from "components/ReportModal/ReportModal";
import { reportLifeExperience } from "api/reports";

// Function to render score icons
const renderScoreIcons = (IconComponent, score, color) => {
  return Array.from({ length: 5 }, (_, index) => (
    <IconComponent
      key={index}
      style={{ color: index < score ? color : "lightgray" }}
    />
  ));
};

// Base Card Component
const BaseLifeExperienceCard = ({ experience, actionMenuOptions }) => {
  return (
    <div key={experience._id} className={styles.experienceCard}>
      <div className={styles.inner}>
        <div className={styles.header}>
          <div className={styles.tags}>
            {experience.tags.map((tag) => (
              <div key={tag._id} className={styles.tag}>
                {tag.name}
              </div>
            ))}
          </div>
          {experience.score && (
            <div className={styles.score}>
              {Math.ceil(experience.score * 100)}% match
            </div>
          )}
        </div>
        <div className={styles.story}>
          {experience.story}
          {experience.isReachedOut && (
            <Tooltip>
              <TooltipTrigger>
                <div>
                  <PiHandHeart className={styles.reachedOutIcon} />
                </div>
              </TooltipTrigger>
              <TooltipContent>You've reached out on this story</TooltipContent>
            </Tooltip>
          )}
          {experience.isConnected && (
            <Tooltip>
              <TooltipTrigger>
                <div>
                  <PiPlugsConnectedFill className={styles.connectedIcon} />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                You're already connected on this story
              </TooltipContent>
            </Tooltip>
          )}
        </div>
        <div className={styles.footer}>
          <span className={styles.author}>
            {experience.author.username === "anonymous"
              ? experience.authorPseudonym
              : experience.author.username}
            {experience.author.username === "anonymous" && (
              <Tooltip>
                <TooltipTrigger>
                  <div>
                    <GiBilledCap className={styles.anonymousIcon} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  This life experience was posted anonymously
                </TooltipContent>
              </Tooltip>
            )}
          </span>
          {/* Add scores display */}
          {experience.author.scores && (
            <ScoreMetadataDisplay scores={experience.author.scores} />
          )}
          <div className={styles.date}>
            {formatMonthYear(experience.date.month, experience.date.year)}
          </div>
        </div>
        <div className={styles.actionMenu}>
          <ReusableActionMenu
            options={actionMenuOptions}
            icon={IoEllipsisHorizontal}
          />
        </div>
      </div>
    </div>
  );
};

// Owner Card Component
const OwnerLifeExperienceCard = ({ experience, onUpdate, onDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const {
    lifeExperience,
    error,
    status,
    statusMessage,
    updateLifeExperience,
    deleteLifeExperience,
  } = useLifeExperience(experience);

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleUpdate = async (formData) => {
    try {
      const updatedLifeExp = await updateLifeExperience(
        experience._id,
        formData
      );
      if (updatedLifeExp) {
        onUpdate(updatedLifeExp);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating life experience:", error);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteLifeExperience(experience._id);
      onDelete(experience._id);
      setShowDeleteConfirmation(false);
    } catch (err) {
      console.error("Error deleting life experience:", err);
    }
  };

  const actionMenuOptions = [
    { label: "Edit", onClick: handleEdit },
    { label: "Delete", onClick: handleDeleteClick },
  ];

  return (
    <>
      <BaseLifeExperienceCard
        experience={experience}
        actionMenuOptions={actionMenuOptions}
      />
      {isModalOpen && (
        <LifeExperienceModal
          lifeExperience={lifeExperience}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode="edit"
          onUpdate={handleUpdate}
          onDelete={handleDeleteClick}
          error={error}
          status={status}
          statusMessage={statusMessage}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteConfirm}
          itemName="life experience"
          status={status}
          statusMessage={statusMessage}
        />
      )}
    </>
  );
};

// Non-Owner Card Component
const NonOwnerLifeExperienceCard = ({ experience }) => {
  const [isReachOutDialogOpen, setIsReachOutDialogOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleReachOut = () => {
    setIsReachOutDialogOpen((prev) => !prev);
  };

  const handleReport = () => {
    setIsReportModalOpen(true);
  };

  const handleSubmitReport = async (reportData) => {
    return reportLifeExperience({
      targetId: experience._id,
      ...reportData,
    });
  };

  const actionMenuOptions = [
    { label: "Reach Out", onClick: handleReachOut },
    { label: "Report", onClick: handleReport },
  ];

  return (
    <>
      <BaseLifeExperienceCard
        experience={experience}
        actionMenuOptions={actionMenuOptions}
      />
      <ReachOutDialog
        open={isReachOutDialogOpen}
        onOpenChange={setIsReachOutDialogOpen}
        type="LifeExperience"
        resource={experience}
      />
      {isReportModalOpen && (
        <ReportModal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleSubmitReport}
          targetType="LifeExperience"
          targetData={experience}
          targetId={experience._id}
        />
      )}
    </>
  );
};

// Main LifeExperienceCard Component
const LifeExperienceCard = ({
  lifeExpData,
  userData,
  onUpdate,
  onDelete,
  onReachOut,
}) => {
  // To create reusability across the site (to be used for simple viewing)
  if (!userData || !onUpdate || !onDelete)
    return (
      <NonOwnerLifeExperienceCard
        experience={lifeExpData}
        onReachOut={onReachOut}
      />
    );

  const isOwner = lifeExpData.author._id === userData._id;

  return isOwner ? (
    <OwnerLifeExperienceCard
      experience={lifeExpData}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  ) : (
    <NonOwnerLifeExperienceCard experience={lifeExpData} />
  );
};

export default LifeExperienceCard;
