import React from "react";
import LoadingMessage from "../LoadingSpinner/LoadingMessage";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import LoadingContainer from "components/LoadingContainer/LoadingContainer";

const AsyncResourceRenderer = ({ status, data, error, children }) => {
  if (status === "loading") {
    return (
      <LoadingContainer>
        <LoadingMessage message="Loading..." />
      </LoadingContainer>
    );
  }

  if (status === "error") {
    return (
      <LoadingContainer>
        <ErrorMessage message={error?.message || "An error occured"} />
      </LoadingContainer>
    );
  }

  if (status === "success") {
    return children(data);
  }

  return null;
};

export default AsyncResourceRenderer;
