import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./About.module.css";
import cn from "classnames";

// Import components
import PseudonymityIcon from "components/PseudonymityIcon";
import StumbleSprite from "components/StumbleSprite";
import NeuralNetworkIcon from "components/NeuralNetworkIcon";
import ScoringIcon from "components/ScoringIcon";

const fadeInUp = {
  initial: { opacity: 0, y: 40 },
  whileInView: { opacity: 1, y: 0 },
  viewport: { once: true, margin: "-100px" },
  transition: { duration: 0.8 },
};

const staggerContainer = {
  initial: {},
  whileInView: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  viewport: { once: true, margin: "-100px" },
};

const Section = ({ children, className, ...props }) => (
  <motion.section
    className={cn(styles.section, className)}
    {...fadeInUp}
    {...props}
  >
    {children}
  </motion.section>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    className={styles.featureCard}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    {Icon && (
      <div className={styles.iconContainer}>
        <Icon className={styles.featureIcon} fill="var(--chair-orange)" />
      </div>
    )}
    <h3>{title}</h3>
    <p>{description}</p>
  </motion.div>
);

const Testimonial = ({ quote, author, experience }) => (
  <div className={styles.testimonialCard}>
    <p className={styles.quote}>"{quote}"</p>
    <div className={styles.testimonialFooter}>
      <p className={styles.author}>- {author}</p>
      <p className={styles.experience}>{experience}</p>
    </div>
  </div>
);

function About() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={styles.container}>
      <section className={styles.hero}>
        <motion.div
          className={styles.content}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className={styles.title}>Why Stumble Exists</h1>
          <p className={styles.subtitle}>
            A platform built to help people connect through shared experiences,
            because sometimes the most healing thing is knowing you're not
            alone.
          </p>
        </motion.div>

        <div className={styles.illustration}>
          <StumbleSprite
            fill="var(--chair-orange)"
            className={cn(styles.figure, styles.leftFigure, {
              [styles.visible]: isVisible,
            })}
          />
          <div
            className={cn(styles.connectingLine, {
              [styles.visible]: isVisible,
            })}
          />
          <StumbleSprite
            fill="var(--chair-orange)"
            className={cn(styles.figure, styles.rightFigure, {
              [styles.visible]: isVisible,
            })}
          />
        </div>
      </section>

      <motion.section
        className={styles.story}
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, margin: "-50px" }}
        transition={{ duration: 1 }}
      >
        <div className={styles.sectionHeader}>
          <h2 className={styles.sectionTitle}>Why I Built Stumble</h2>
          <p className={styles.sectionSubtitle}>
            A personal note from the founder
          </p>
        </div>
        <div className={styles.storyContent}>
          <p>
            I lost my Mom to cancer a few years ago. She was my best friend for
            everything. There is a law in physics that says energy is neither
            created nor destroyed, but rather it changes from one form to
            another. I feel the same applies to love. And I want to recreate the
            love that my mom created with me, but at scale.
          </p>
        </div>
      </motion.section>

      <section className={styles.features}>
        <div className={styles.featureCards}>
          <FeatureCard
            icon={NeuralNetworkIcon}
            title="AI-powered matching"
            description="Use natural language processing to intuitively find others who share your experiences."
          />
          <FeatureCard
            icon={PseudonymityIcon}
            title="Pseudonymity for safe sharing"
            description="Share openly with pseudonyms, revealing your identity only when you're ready."
          />
          <FeatureCard
            icon={ScoringIcon}
            title="Trust and Warmth scores"
            description="Foster meaningful and positive connections with our unique accountability and trust-building system."
          />
        </div>
      </section>

      <motion.section className={styles.testimonials}>
        <div className={styles.sectionHeader}>
          <h2 className={styles.sectionTitle}>What Our Community Says</h2>
          <p className={styles.sectionSubtitle}>
            Real stories from real people
          </p>
        </div>
        <motion.div
          className={styles.testimonialGrid}
          variants={staggerContainer}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true, margin: "-100px" }}
        >
          <motion.div variants={fadeInUp}>
            <Testimonial
              quote="Finding someone who understood my grief journey changed everything."
              author="Anonymous"
              experience="Loss of a Parent"
            />
          </motion.div>
          <motion.div variants={fadeInUp}>
            <Testimonial
              quote="The anonymity helped me open up about things I've never shared before."
              author="Anonymous"
              experience="Mental Health Journey"
            />
          </motion.div>
          <motion.div variants={fadeInUp}>
            <Testimonial
              quote="Being able to help others through similar experiences has been healing."
              author="Anonymous"
              experience="Cancer Survivor"
            />
          </motion.div>
        </motion.div>
      </motion.section>

      <motion.section
        className={styles.betaCallout}
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <h2>Ready to Connect?</h2>
        <p>
          Find someone who understands, or be that someone for another person.
        </p>
        <div className={styles.ctaButtons}>
          <motion.button
            className={styles.primaryButton}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => (window.location.href = "/find")}
          >
            Find Someone to Talk To
          </motion.button>
          <motion.button
            className={styles.secondaryButton}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => (window.location.href = "/write")}
          >
            Share Your Story
          </motion.button>
        </div>
      </motion.section>
    </div>
  );
}

export default About;
