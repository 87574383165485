// packages/client/src/components/ReviewModal/ReviewModal.jsx
import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./ReviewModal.module.css";

import ModalBody from "components/Modal/ModalBody";
import ModalHeader from "components/Modal/ModalHeader";
import Modal from "components/Modal/Modal";

import Button from "components/Button/Button";
import StarRating from "./StarRating";
import cn from "classnames";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import { isReviewThrottled } from "utils/dates";
import * as REVIEWS_API from "api/reviews";
import { setReviews } from "store/actions";
import { useDispatch } from "react-redux";
import { UserContext } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";

const ReviewListModal = ({ reviews, isOpen, onClose }) => {
  const { userData } = useContext(UserContext);
  const hasReviews = reviews && reviews.length > 0;
  const navigate = useNavigate();

  // const testData = Array(4).fill(reviews[0]);

  const reviewsUrl = `/user/${userData.username}/reviews`;
  const handleRedirect = () => {
    navigate(reviewsUrl);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Previous Reviews</ModalHeader>
      <div>
        {hasReviews ? (
          <ul className={styles.reviewList}>
            {/* Map only the first 3 items */}
            {reviews.slice(0, 3).map((review) => (
              <li key={review._id} className={styles.reviewItem}>
                <div className={styles.reviewScores}>
                  <div className={styles.score}>
                    <label className={styles.scoreLabel}>Trust Score:</label>
                    <StarRating
                      rating={review.trustScore}
                      totalStars={5}
                      disabled
                      view="chat"
                    />
                  </div>
                  <div className={styles.score}>
                    <label className={styles.scoreLabel}>Warmth Score:</label>
                    <StarRating
                      rating={review.warmthScore}
                      totalStars={5}
                      disabled
                      view="chat"
                    />
                  </div>
                </div>
                <div className={styles.reviewText}>{review.comments}</div>
                <div className={styles.reviewMeta}>
                  <span className={styles.reviewDate}>
                    Submitted on:{" "}
                    {new Date(review.createdAt).toLocaleDateString()}
                  </span>
                  <span className={styles.reviewStatus}>
                    Status: {review.status}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.noReviews}>No reviews yet.</p>
        )}
        {hasReviews && reviews.length > 3 && (
          <div className={styles.modalButton}>
            <Button onClick={handleRedirect} size="medium" shape="rounded">
              See more reviews
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

const ReviewModal = ({ chat, subject, reviews, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [trustScore, setTrustScore] = useState();
  const [warmthScore, setWarmthScore] = useState();
  const [trustDesc, setTrustDesc] = useState("");
  const [warmthDesc, setWarmthDesc] = useState("");
  const [comments, setComments] = useState();
  const [errors, setErrors] = useState({});
  const [fetchState, setFetchState] = useState("initial");
  const [reviewThrottle, setReviewThrottle] = useState(null);

  const validate = () => {
    const newErrors = {};

    if (!trustScore) {
      newErrors.trustScore = "Trust score is required.";
    }

    if (!warmthScore) {
      newErrors.warmthScore = "Warmth score is required.";
    }

    if (!comments || comments.trim() === "") {
      newErrors.comments = "Comments are required.";
    }

    setErrors(newErrors);

    setTimeout(() => {
      setErrors({});
    }, 3000);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setFetchState("loading");

    try {
      const review = await REVIEWS_API.submitReview({
        chatId: chat._id,
        subjectId: subject._id,
        trustScore,
        warmthScore,
        comments,
      });

      dispatch(setReviews([review]));
      setFetchState("success");
    } catch (err) {
      console.error("Failed to submit review:", err);
      setFetchState("error");
      setTimeout(() => {
        setFetchState("initial");
      }, 2000);
    }
  };

  useEffect(() => {
    const getDescription = (score, type) => {
      switch (score) {
        case 1:
          return type === "trust"
            ? "Very low trust. Felt unreliable and untrustworthy."
            : "Very cold. Felt distant and unapproachable.";
        case 2:
          return type === "trust"
            ? "Low trust. Somewhat unreliable."
            : "Cold. Somewhat distant.";
        case 3:
          return type === "trust"
            ? "Neutral trust. Neither trustworthy nor untrustworthy."
            : "Neutral warmth. Neither warm nor cold.";
        case 4:
          return type === "trust"
            ? "High trust. Generally reliable and trustworthy."
            : "Warm. Generally approachable and friendly.";
        case 5:
          return type === "trust"
            ? "Very high trust. Extremely reliable and trustworthy."
            : "Very warm. Extremely approachable and friendly.";
        default:
          return "";
      }
    };

    setTrustDesc(getDescription(trustScore, "trust"));
    setWarmthDesc(getDescription(warmthScore, "warmth"));
  }, [trustScore, warmthScore]);

  useEffect(() => {
    const updateReviewAvailability = () => {
      if (reviews && reviews.length > 0) {
        const message = isReviewThrottled(reviews[0].createdAt, 7);

        if (message) {
          setReviewThrottle(`You can submit your next review in ${message}.`);
        } else {
          setReviewThrottle(false);
        }
      } else {
        setReviewThrottle(false);
      }
    };

    updateReviewAvailability();

    const intervalId = setInterval(updateReviewAvailability, 60000);

    return () => clearInterval(intervalId);
  }, [reviews]);

  const ratingLabelRef = useRef();
  const textAreaRef = useRef();

  const [showPreviousReviews, setShowPreviousReviews] = useState(false);

  const handleShowReviews = () => {
    setShowPreviousReviews(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>How is your chat with {subject.username} going?</ModalHeader>
      <ModalBody className={styles.body}>
        <p>
          This review will remain completely confidential and will help us
          improve our matching algorithm.
        </p>
        <div
          className={cn(styles.formContainer, {
            [styles.disabled]: fetchState === "success",
          })}
          onSubmit={handleSubmit}
        >
          <div className={styles.reviewScores}>
            <div className={cn(styles.formField, styles.rating)}>
              <label className={styles.formFieldLabel}>Trust Score:</label>
              <StarRating
                totalStars={5}
                rating={trustScore}
                setRating={setTrustScore}
                disabled={fetchState === "success"}
                view="chat"
              />
              <div className={styles.ratingDesc}>
                <p>{trustDesc}</p>
              </div>
            </div>

            <div className={cn(styles.formField, styles.rating)}>
              <label className={styles.formFieldLabel}>Warmth Score:</label>
              <StarRating
                totalStars={5}
                rating={warmthScore}
                setRating={setWarmthScore}
                disabled={fetchState === "success"}
                view="chat"
              />
              <div className={styles.ratingDesc}>
                <p>{warmthDesc}</p>
              </div>
            </div>
          </div>

          {errors.trustScore && (
            <FloatingTooltip
              anchorRef={ratingLabelRef}
              show={errors.trustScore}
            >
              <p style={{ color: "white" }}>{errors.trustScore}</p>
            </FloatingTooltip>
          )}

          {errors.warmthScore && (
            <FloatingTooltip
              anchorRef={ratingLabelRef}
              show={errors.warmthScore}
            >
              <p style={{ color: "white" }}>{errors.warmthScore}</p>
            </FloatingTooltip>
          )}

          <div className={styles.formField}>
            <label className={styles.formFieldLabel}>Comments:</label>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              ref={textAreaRef}
              disabled={fetchState === "success"}
            />
          </div>

          {errors.comments && (
            <FloatingTooltip anchorRef={textAreaRef} show={errors.comments}>
              <p style={{ color: "white" }}>{errors.comments}</p>
            </FloatingTooltip>
          )}

          <Button
            onClick={handleSubmit}
            disabled={fetchState === "success" || reviewThrottle}
            fetchState={fetchState}
            size="small"
            shape="rounded"
          >
            {fetchState === "loading"
              ? "Submitting..."
              : fetchState === "success"
                ? "Review submitted"
                : "Submit Review"}
          </Button>
          {reviewThrottle && (
            <p className={styles.throttleHint}>{reviewThrottle}</p>
          )}
          <Button onClick={handleShowReviews} size="small" shape="rounded">
            Show Previous Reviews ({reviews ? reviews.length : 0})
          </Button>

          <ReviewListModal
            chat={chat}
            reviews={reviews}
            isOpen={showPreviousReviews}
            onClose={() => setShowPreviousReviews(false)}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReviewModal;
