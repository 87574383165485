import handleAxiosError from "./handleAxiosError";
import api from "./index";

export const submitReview = async ({
  chatId,
  subjectId,
  trustScore,
  warmthScore,
  comments,
}) => {
  try {
    const res = await api.post("/reviews", {
      chatId,
      subjectId,
      trustScore,
      warmthScore,
      comments,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchChatReviews = async (chatId) => {
  try {
    const response = await api.get(`chats/${chatId}/reviews`);
    return response.data; // Assuming the response data contains the reviews
  } catch (error) {
    console.error("Error fetching chat reviews:", error);
    throw error; // Re-throw the error so the calling code can handle it
  }
};

export const fetchAllReviews = async () => {
  try {
    const response = await api.get("/reviews");
    return response.data; // Assuming the response data contains all reviews
  } catch (error) {
    console.error("Error fetching all reviews:", error);
    throw error; // Re-throw the error so the calling code can handle it
  }
};

export const fetchReviewsByChatId = async (chatId) => {
  try {
    const response = await api.get(`/reviews?chatId=${chatId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching reviews by chat ID:", error);
    throw error;
  }
};
