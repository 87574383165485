import { fetchTagByHyphenatedName } from "api/tags";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./TagDetail.module.css";
import { IoArrowBackOutline } from "react-icons/io5";

import { searchStories } from "api/stories"; // Updated import

import cn from "classnames";
import SearchInput from "components/SearchInput/SearchInput";
import Button from "components/Button/Button";

import StoryCard from "components/StoryCard/StoryCard";
import { UserContext } from "contexts/UserContext";
import ErrorDisplay from "components/ErrorDisplay/ErrorDisplay";
import { FaPen } from "react-icons/fa";

function TagDetail() {
  const { userData } = useContext(UserContext);
  let { tagName } = useParams();

  const [tagDetails, setTagDetails] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const fetchTagDetails = async () => {
      setIsLoadingDetails(true);
      setError(null); // Reset error state before fetching
      try {
        const fetchedTag = await fetchTagByHyphenatedName(tagName);

        if (!fetchedTag) {
          throw new Error("Tag not found");
        }
        setTagDetails(fetchedTag);
      } catch (error) {
        console.error("Failed to fetch tag details:", error);
        setError(error.message); // Set error state
      } finally {
        setIsLoadingDetails(false);
      }
    };

    fetchTagDetails();
  }, [tagName]);

  const [stories, setStories] = useState([]);
  const [currentStoriesPage, setCurrentStoriesPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalStoriesPages, setTotalStoriesPages] = useState(0);

  useEffect(() => {
    const loadStories = async () => {
      setLoading(true);
      if (tagDetails) {
        try {
          const { results: stories, totalPages } = await searchStories({
            tags: [tagDetails._id],
            page: currentStoriesPage,
            limit: 10,
          });
          setStories(stories);
          setTotalStoriesPages(totalPages);
        } catch (error) {
          console.error("Failed to fetch stories:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadStories();
  }, [tagDetails, currentStoriesPage]);

  const handleStoriesPageChange = (newPage) => {
    setCurrentStoriesPage(newPage);
  };

  const [quotes, setQuotes] = useState([]);
  // Conditionally render content based on loading state and tag data
  const navigate = useNavigate();

  if (isLoadingDetails) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <LoadingMessage message="Loading tag details..." />
        </div>
      </div>
    );
  }

  if (error) {
    return <ErrorDisplay message={error} onRetry={() => navigate("/tags")} />;
  }

  if (!tagDetails) {
    return null;
  }

  // Format date
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbarContainer}>
        <Button
          className={styles.backToTags}
          onClick={() => navigate("/tags")}
          color="primary"
        >
          <IoArrowBackOutline /> Back to tags
        </Button>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.card}>
          <h3 className={styles.name}>{tagDetails.name}</h3>
          {tagDetails.description && (
            <p className={styles.description}>{tagDetails.description}</p>
          )}
          <div className={styles.metadata}>
            {tagDetails.createdAt && (
              <p>Created on: {formatDate(tagDetails.createdAt)}</p>
            )}
            {typeof tagDetails.matches === "number" && (
              <p>Matches: {tagDetails.matches}</p>
            )}
            {typeof tagDetails.stories === "number" && (
              <p>Stories: {tagDetails.stories}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>Stories</h2>
        <Button
          shape="rounded"
          onClick={() => window.open(`/stories/new?tag=${tagName}`, "_blank")}
          className={styles.tellYourStoryButton}
          color="primary"
        >
          <FaPen /> Write
        </Button>
      </div>
      <div className={styles.storiesContainer}>
        <SearchInput placeholder="Search stories..." />
        <div className={styles.storyList}>
          {loading && (
            <div className={styles.status}>
              <LoadingMessage message="Loading stories..." />
            </div>
          )}
          {stories.length > 0 &&
            stories.map((story) => (
              <StoryCard
                key={story._id}
                story={story}
                userData={userData} // Ensure userData is fetched or passed down from context if needed
              />
            ))}
          {!loading && stories.length === 0 && (
            <div className={styles.status}>No stories found.</div>
          )}
        </div>
        <div className={styles.pagination}>
          {Array.from({ length: totalStoriesPages }, (_, i) => (
            <button
              key={i}
              className={`${styles.pageButton} ${
                currentStoriesPage === i + 1 ? styles.active : ""
              }`}
              onClick={() => handleStoriesPageChange(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.quotesContainer}>
        <h2 className={styles.title}>Quotes</h2>
        <div className={styles.body}></div>
      </div>
    </div>
  );
}

export default TagDetail;
