import React from "react";
import styles from "./CallDialog.module.css";
import { useCallContext } from "contexts/CallContext";
import Button from "components/Button/Button";
import { FloatingPortal, FloatingOverlay } from "@floating-ui/react";

import { useNavigate } from "react-router-dom";

const CallDialog = () => {
  const { callStatus, receivedRTCOffer, acceptCall, rejectCall } =
    useCallContext();
  const navigate = useNavigate(); // Initialize navigate

  // Only render if there is a received RTC offer and call status is receivingCall
  if (callStatus !== "receivingCall" || !receivedRTCOffer.offer) {
    return null;
  }

  const handleAccept = () => {
    acceptCall(receivedRTCOffer.chatId);
    navigate(`/chats?type=chats&id=${receivedRTCOffer.chatId}`); // Use navigate to open chat in the same tab
  };

  const handleReject = () => {
    rejectCall(receivedRTCOffer.chatId);
  };

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={styles.overlay}>
        <div className={styles.dialog}>
          <p>{receivedRTCOffer?.from?.username || "test"} is calling...</p>
          <div className={styles.buttonGroup}>
            <Button onClick={handleAccept}>Accept</Button>
            <Button color="error" onClick={handleReject}>
              Reject
            </Button>
          </div>
        </div>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default CallDialog;
