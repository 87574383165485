import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./CustomCombobox.module.css";
import cn from "classnames";

import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";
import Portal from "components/Portal/Portal";

/**
 * Option component that represents a single option in the dropdown list.
 *
 * @param {Object} props - The props for the Option component.
 * @param {number} props.i - The index of the option in the list.
 * @param {Object} props.option - The option data.
 * @param {Function} props.selectOption - Function to select the option.
 * @param {Function} props.setIsOpen - Function to set the open state of the dropdown.
 * @param {Function} props.isOptionSelected - Function to check if the option is selected.
 * @param {number} props.highlightedIndex - The index of the currently highlighted option.
 * @param {React.Ref} ref - The ref for the option element.
 * @returns {JSX.Element} The rendered Option component.
 */
const Option = forwardRef(
  (
    { i, option, selectOption, setIsOpen, isOptionSelected, highlightedIndex },
    ref
  ) => {
    return (
      <li
        ref={ref}
        key={option.value}
        onClick={(e) => {
          e.stopPropagation();
          selectOption(option);
          setIsOpen(false);
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={cn(styles.option, {
          [styles.selected]: isOptionSelected(option),
          [styles.highlighted]: i === highlightedIndex,
        })}
      >
        <div className={styles["option-header"]}>
          <div className={styles["option-name"]}>{option.label}</div>
        </div>
        {option.description && (
          <div className={styles["option-description"]}>
            {option.description}
          </div>
        )}
      </li>
    );
  }
);

/**
 * SelectedBadge component that represents a selected option in the input.
 *
 * @param {Object} props - The props for the SelectedBadge component.
 * @param {Object} props.option - The selected option data.
 * @param {Function} props.selectOption - Function to select the option.
 * @param {boolean} props.isMulti - Indicates if multiple options can be selected.
 * @returns {JSX.Element} The rendered SelectedBadge component.
 */
const SelectedBadge = ({ option, selectOption, isMulti }) => {
  return (
    <button
      key={option.value}
      onClick={(e) => {
        e.stopPropagation();
        selectOption(option);
      }}
      className={styles["option-badge"]}
    >
      {option.label}
      {isMulti && <span className={styles["remove-btn"]}>&times;</span>}
    </button>
  );
};

/**
 * CustomCombobox component that allows users to select options from a dropdown list.
 * Supports single and multi-selection modes.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isMulti - Indicates if multiple options can be selected.
 * @param {Array} props.options - The list of options to display in the combobox.
 * @param {Array|Object} props.selected - The currently selected option(s).
 * @param {Function} props.onSelect - Callback function to handle option selection.
 * @param {string} [props.placeholder] - Placeholder text for the input field.
 * @param {string} [props.morePlaceholder] - Placeholder text when multiple options are selected.
 * @param {string} [props.className] - Additional class names for the container.
 * @param {string} [props.focusedClassName] - Class name to apply when the input is focused.
 * @param {string} [props.listClassName] - Additional class names for the dropdown list.
 * @param {Object} rest - Additional props to pass to the container.
 * @returns {JSX.Element} The rendered CustomCombobox component.
 */
const CustomCombobox = forwardRef(
  (
    {
      isMulti,
      options,
      selected,
      onSelect,
      placeholder,
      morePlaceholder,
      className,
      focusedClassName,
      listClassName,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [highlightedIndex, setHighlightedIndex] = useState(null);

    const { refs, floatingStyles } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      middleware: [
        offset(3),
        flip(),
        shift(),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    useImperativeHandle(ref, () => refs.reference.current);

    const inputRef = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);

    function handleChange(e) {
      setQuery(e.target.value);
    }

    function clearOptions() {
      if (isMulti) {
        onSelect([]);
      } else {
        onSelect(null);
      }
      setQuery("");
    }

    function selectOption(option) {
      if (isMulti) {
        if (isOptionSelected(option)) {
          onSelect(selected.filter((s) => s.value !== option.value));
        } else {
          onSelect([...selected, option]);
        }
      } else {
        if (isOptionSelected(option)) {
          onSelect(null);
        } else {
          onSelect(option);
        }
      }
      setQuery("");
    }

    function isOptionSelected(option) {
      if (isMulti) {
        return selected.some((s) => s.value === option.value);
      }
      return selected?.value === option.value;
    }

    useEffect(() => {
      setHighlightedIndex(0);
    }, [isOpen]);

    useEffect(() => {
      if (isInputFocused) {
        setIsOpen(true);
      }
    }, [isInputFocused]);

    useEffect(() => {
      if (query?.length > 0) {
        setIsOpen(true);
      }
    }, [query]);

    const filteredOptions = options
      .filter((option) =>
        option.label.toLowerCase().startsWith(query.toLowerCase())
      )
      .sort((a, b) => {
        // Move selected options to the top
        const isASelected = isOptionSelected(a);
        const isBSelected = isOptionSelected(b);

        if (isASelected && !isBSelected) return -1; // a is selected, b is not
        if (!isASelected && isBSelected) return 1; // b is selected, a is not
        return 0; // maintain original order if both or neither are selected
      });

    const optionProps = {
      selectOption,
      setIsOpen,
      setHighlightedIndex,
      isOptionSelected,
      highlightedIndex,
    };

    const mirrorRef = useRef(null);

    const adjustWidth = () => {
      const mirrorText = mirrorRef.current;
      const input = inputRef.current;
      const container = inputRef.current.parentNode;

      if (mirrorText && input && container) {
        mirrorText.textContent = query || input.placeholder || " ";
        let calculatedWidth = mirrorText.offsetWidth + 10;
        calculatedWidth = Math.min(calculatedWidth, container.offsetWidth);
        input.style.width = `${calculatedWidth}px`;
      }
    };

    useEffect(() => {
      const handleResize = () => {
        adjustWidth();
      };

      window.addEventListener("resize", handleResize);

      adjustWidth();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [query, selected]);

    function handleKeyDown(e) {
      if (e.key === "Backspace" && query === "") {
        if (isMulti) {
          if (selected.length > 0) {
            const newSelected = selected.slice(0, -1);
            onSelect(newSelected);
          }
        } else {
          onSelect(null);
        }
      }
    }

    return (
      <div
        {...rest}
        ref={refs.setReference}
        onFocus={() => {
          inputRef.current.focus();
        }}
        onClick={() => {
          inputRef.current.focus();
        }}
        onBlur={() => {
          setIsOpen(false);
        }}
        className={cn(styles.container, className, {
          [styles.containerFocused]: isInputFocused,
          [focusedClassName]: isInputFocused, // Apply focusedClassName when input is focused
        })}
      >
        <span className={styles.selectedList}>
          {isMulti
            ? selected.map((option) => (
                <SelectedBadge
                  key={option.value}
                  option={option}
                  selectOption={selectOption}
                  isMulti={isMulti}
                />
              ))
            : selected && (
                <SelectedBadge
                  key={selected.value}
                  option={selected}
                  selectOption={selectOption}
                  isMulti={isMulti}
                />
              )}
          <input
            ref={inputRef}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            className={styles.input}
            value={query}
            onChange={handleChange}
            placeholder={
              isMulti
                ? selected.length > 0
                  ? morePlaceholder || "Select another option (optional)"
                  : placeholder || "Select an option"
                : selected
                ? ""
                : placeholder || "Select an option"
            }
            onKeyDown={handleKeyDown}
          />
          <span ref={mirrorRef} className={styles["mirror-text"]}></span>
        </span>
        <button
          className={styles["clear-btn"]}
          onClick={(e) => {
            e.stopPropagation();
            clearOptions();
          }}
        >
          &times;
        </button>

        {isOpen && (
          <Portal>
            <ul
              ref={refs.setFloating}
              style={floatingStyles}
              className={cn(styles.list, listClassName)} // Merge ulClassName with existing styles
            >
              {filteredOptions.map((option, i) => {
                return (
                  <Option
                    key={option.value}
                    option={option}
                    i={i}
                    {...optionProps}
                  />
                );
              })}
            </ul>
          </Portal>
        )}
      </div>
    );
  }
);

export default CustomCombobox;
