import { useState } from "react";
import {
  fetchUsers,
  approveUser,
  deleteUser,
  banUser,
  suspendUser,
  fetchReports,
  restoreUser,
} from "api/admin";

export const useAdmin = () => {
  const [users, setUsers] = useState([]);
  const [reports, setReports] = useState([]);

  const fetchAllUsers = async (filter) => {
    const { page, limit } = filter;

    try {
      const res = await fetchUsers({ page: page, limit: limit });

      setUsers(res.users);
    } catch (err) {
      console.error(err);
    }
  };

  const approveUserAccount = async (data) => {
    try {
      const res = await approveUser(data);

      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const banUserAccount = async (data) => {
    try {
      const res = await banUser(data);

      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const suspendUserAccount = async (data) => {
    try {
      const res = await suspendUser(data);

      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const deleteUserAccount = async (data) => {
    try {
      const res = await deleteUser(data);

      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAllReports = async () => {
    try {
      const res = await fetchReports();

      setReports(res);
    } catch (err) {
      console.error(err);
    }
  };

  const restoreUserAccount = async (data) => {
    try {
      const res = await restoreUser(data);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  return {
    users,
    fetchAllUsers,
    approveUserAccount,
    deleteUserAccount,
    banUserAccount,
    suspendUserAccount,
    reports,
    fetchAllReports,
    restoreUserAccount,
  };
};
