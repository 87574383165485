import { fetchUserData } from "api/auth";
import { BASE_SERVER_URL } from "config";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadInitialData } from "../store/actions";

/**
 * @typedef {Object} UserLocation
 * @property {string} name - The name of the location (e.g. "Philippines")
 * @property {string} formattedName - The formatted name of the location (e.g. "the Philippines")
 * @property {string} code - The country code (e.g. "PH")
 */

/**
 * @typedef {Object} UserNotificationSettings
 * @property {boolean} inApp - Whether in-app notifications are enabled
 * @property {boolean} email - Whether email notifications are enabled
 */

/**
 * @typedef {Object} UserSettings
 * @property {UserNotificationSettings} notifications - User notification preferences
 */

/**
 * @typedef {Object} UserScores
 * @property {number} totalReviews - Total number of reviews received
 * @property {number} trustScore - User's trust score (1-5)
 * @property {number} warmthScore - User's warmth score (1-5)
 */

/**
 * @typedef {Object} User
 * @property {UserLocation} location - User's location information
 * @property {string} registeredAt - When the user completed registration
 * @property {string} _id - MongoDB ObjectId
 * @property {string} email - User's email address
 * @property {boolean} isRegistered - Whether user has completed registration
 * @property {boolean} isEmailVerified - Whether email is verified
 * @property {string} registrationStatus - Current registration status
 * @property {string} role - User role (e.g. "admin", "user")
 * @property {string} createdAt - Account creation timestamp
 * @property {string} updatedAt - Last update timestamp
 * @property {number} __v - MongoDB version key
 * @property {string} birthdate - User's birthdate
 * @property {string} gender - User's gender
 * @property {string} username - User's username
 * @property {UserSettings} settings - User preferences
 * @property {string|null} deletedAt - Account deletion timestamp
 * @property {boolean} isActive - Whether account is active
 * @property {boolean} isDeleted - Whether account is deleted
 * @property {boolean} isSuspended - Whether account is suspended
 * @property {string} status - Account status
 * @property {string} lastUsernameChange - Last username change timestamp
 * @property {UserScores} scores - User's reputation scores
 * @property {number} age - User's current age
 * @property {string} ageRange - User's age range category
 * @property {string} id - Alias for _id
 */

// Create a context for user-related data and functions
export const UserContext = createContext();

// Custom hook to easily access the UserContext
export const useUserContext = () => useContext(UserContext);

// Define and export the Google login URL
export const GOOGLE_LOGIN_URL = `${BASE_SERVER_URL}/auth/google`;

/**
 * UserContextProvider component
 *
 * This component provides user-related data and functions to its children
 * using React Context and manual caching.
 */
export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("");

  const dispatch = useDispatch();

  // Fetch user data manually
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchUserData();
        if (data) {
          setUserData(data);
          setIsError(false);
          dispatch(loadInitialData()); // Dispatch only if user data is fetched
        } else {
          setUserData(null); // Set userData to null if data is falsy
        }
      } catch (err) {
        setIsError(true);
        setError(err);
      } finally {
        setLoading(false);
        setLoadingMessage("");
      }
    };

    fetchData();
  }, [dispatch]);

  // Function to handle Google login
  const handleGoogleLogin = () => {
    setLoading(true);
    setLoadingMessage("Logging you in...");
    window.open(GOOGLE_LOGIN_URL, "_self");
  };

  // Function to handle user logout
  const handleLogout = async () => {
    // Clear all cookies
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name] = cookie.split("=");
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    setLoading(true);
    setLoadingMessage("Logging you out...");
    try {
      const response = await fetch(`${BASE_SERVER_URL}/auth/logout`, {
        method: "POST",
        credentials: "include",
      });
      const data = await response.json();
      if (data) {
        setUserData(null);
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setLoading(false);
      setLoadingMessage("");
    }
  };

  // Function to update user data in the cache
  const updateUser = (updatedUserData) => {
    setUserData(updatedUserData);
  };

  // Provide the user context to children components
  return (
    <UserContext.Provider
      value={{
        userData,
        loading,
        isError,
        error,
        loadingMessage,
        handleGoogleLogin,
        handleLogout,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
