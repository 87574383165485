import api from "api";
import handleAxiosError from "./handleAxiosError";

/**
 * Accepts a match by its ID.
 * @param {string} matchId - The ID of the match to accept.
 * @returns {Promise<Object>} A promise that resolves to the updated match and chat objects if both users have accepted.
 * @throws {Error} Throws an error if the acceptance fails.
 */
export const acceptMatch = async (matchId) => {
  try {
    const response = await api.patch(`/matches/${matchId}/accept`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
