import handleAxiosError from "./handleAxiosError";
import api from "./index"; // Ensure this path is correct
import * as typedefs from "../typedefs";

export const submitStory = async (storyData) => {
  try {
    const response = await api.post("/stories", storyData);
    return response.data;
  } catch (error) {
    console.error("Error submitting story:", error);
    handleAxiosError(error);
  }
};

/**
 * Fetches a story by its ID.
 * @param {string} storyId - The ID of the story to fetch.
 * @returns {Promise<typedefs.StoryData>} A promise that resolves to the story data.
 * @throws Will throw an error if the request fails.
 */
export const fetchStoryById = async (storyId) => {
  try {
    const response = await api.get(`/stories/${storyId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching story by id:", error);
    handleAxiosError(error);
  }
};

/**
 * Toggles a hug for a story by the current user.
 * @param {string} storyId - The ID of the story to hug.
 * @returns {Promise<Object>} A promise that resolves to an object containing the hug state and count.
 * @throws Will throw an error if the request fails.
 */
export const hugStory = async (storyId) => {
  try {
    const response = await api.post(`/stories/${storyId}/hug`);
    return response.data;
  } catch (error) {
    console.error("Error hugging story:", error);
    handleAxiosError(error);
  }
};

/**
 * Searches for stories based on various criteria.
 * @param {Object} params - The search parameters.
 * @param {string} params.query - The search query.
 * @param {number} [params.limit=10] - The maximum number of results to return.
 * @param {string} [params.sort="relevance"] - The sort order of the results.
 * @param {number} [params.page=1] - The page number of the results to return.
 * @param {string} [params.userId] - The ID of the user to filter stories by author.
 * @param {Array<string>} [params.tags] - The array of tag IDs to filter stories by tags.
 * @param {string} [params.isAnonymized] - The isAnonymized filter parameter.
 * @param {string} [params.isReachedOut] - The isReachedOut filter parameter.
 * @param {string} [params.isConnected] - The isConnected filter parameter.
 * @returns {Promise<Object>} A promise that resolves to an object containing the {stories, totalCount, totalPages, undiscoveredCount, page}.
 * @throws Will throw an error if the request fails.
 */
export async function searchStories({
  query = "",
  limit = 10,
  sort = "relevance",
  page = 1,
  userId,
  tags = [],
  isAnonymized = "any", // New parameter
  isReachedOut = "any", // New parameter
  isConnected = "any", // New parameter
}) {
  try {
    const res = await api.get("/stories/search", {
      params: {
        query,
        sort,
        limit,
        page,
        userId,
        tags: tags.join(","),
        isAnonymized, // Pass the new filter parameter
        isReachedOut, // Pass the new filter parameter
        isConnected, // Pass the new filter parameter
      },
    });

    // Extract data from the response
    const {
      results, // Always present
      totalCount, // Always present
      totalPages, // Always present
      undiscoveredCount, // Optional if userId is passed
      page: currentPage, // Extract the current page from the response
    } = res.data;

    // Build the base return object
    const result = { results, totalCount, totalPages, page: currentPage }; // Include current page in the result

    // If undiscoveredCount is present (because userId was passed), include it
    if (undiscoveredCount !== undefined) {
      result.undiscoveredCount = undiscoveredCount;
    }

    return result; // Return the constructed result
  } catch (error) {
    handleAxiosError(error);
  }
}

/**
 * Updates a story by its ID.
 * @param {string} storyId - The ID of the story to update.
 * @param {Object} storyData - The updated story data.
 * @returns {Promise<typedefs.StoryData>} A promise that resolves to the updated story data.
 * @throws Will throw an error if the request fails.
 */
export const updateStory = async (storyId, storyData) => {
  try {
    const response = await api.patch(`/stories/${storyId}`, storyData);
    return response.data;
  } catch (error) {
    console.error("Error updating story:", error);
    handleAxiosError(error);
  }
};

/**
 * Deletes a story by its ID.
 * @param {string} storyId - The ID of the story to delete.
 * @returns {Promise<Object>} A promise that resolves to an object containing the success message.
 * @throws Will throw an error if the request fails.
 */
export const deleteStory = async (storyId) => {
  try {
    const response = await api.delete(`/stories/${storyId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting story:", error);
    handleAxiosError(error);
  }
};

export const fetchStories = async () => {
  try {
    const response = await api.get("/stories");
    return response.data;
  } catch (error) {
    console.error("Error fetching stories:", error);
    handleAxiosError(error);
  }
};
