import React, { useState, useCallback, useRef, forwardRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import SearchInput from "components/SearchInput/SearchInput";
import styles from "./LifeExperiences.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import useSearch from "../../hooks/useSearch";

import FilterPopover from "components/FilterPopover/FilterPopover";
import * as LIFEEXP_API from "api/lifeExperiences";

import * as typedefs from "typedefs";

import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";

/**
 * @typedef {typedefs.LifeExperienceData} LifeExperience
 */

/**
 * LifeExperienceCard component that displays a life experience.
 * @param {Object} props - Component props.
 * @param {LifeExperience} props.experience - The life experience data.
 * @param {React.Ref} ref - Forwarded ref.
 */
const ResultCardWrapper = forwardRef(({ experience }, ref) => {
  return (
    <div ref={ref} className={styles.resultItem}>
      <LifeExperienceCard lifeExpData={experience} />
    </div>
  );
});

function LifeExperiences() {
  const [focused, setFocused] = useState(false);
  const [firstAnimationComplete, setFirstAnimationComplete] = useState(false);

  const [input, setInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    sort: null,
    tags: [],
    anonymized: null,
    reachedOut: null,
    connected: null,
  });

  const { results, loading, search, hasMore } = useSearch({
    searchAPI: LIFEEXP_API.searchLifeExperiences,
    initialQuery: "",
    initialPage: 1,
    limit: 5,
  });

  const handleSearchChange = (event) => {
    setInput(event.target.value);
  };

  const handleSearchClear = () => {
    setInput(""); // Clear the search input
  };

  const handleSearchEnter = (value) => {
    setSearchQuery(value);
    setCurrentPage(1);

    const filterObj = {
      sort: filters.sort ? filters.sort.value : null,
      tags: filters.tags.map((tag) => tag._id),
      isAnonymized: filters.anonymized ? filters.anonymized.value : null,
      isReachedOut: filters.reachedOut ? filters.reachedOut.value : null,
      isConnected: filters.connected ? filters.connected.value : null,
    };

    search({ query: value, page: 1, ...filterObj });
    setFocused(true);
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const observer = useRef();

  const loadMoreResults = useCallback(() => {
    if (hasMore && !loading) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);

      const filterObj = {
        sort: filters.sort ? filters.sort.value : null,
        tags: filters.tags.map((tag) => tag._id),
        isAnonymized: filters.anonymized ? filters.anonymized.value : null,
        isReachedOut: filters.reachedOut ? filters.reachedOut.value : null,
        isConnected: filters.connected ? filters.connected.value : null,
      };

      search({ query: searchQuery, page: nextPage, ...filterObj });
    }
  }, [hasMore, loading, currentPage, searchQuery, filters, search]);

  const lastExperienceRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreResults();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, loadMoreResults]
  );

  return (
    <div className={styles.container}>
      <motion.div
        initial={{ y: "40vh" }}
        animate={focused ? { y: "0" } : { y: "40vh" }}
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
        className={`${styles.searchBar} ${
          focused ? styles.searchBarFocused : ""
        }`}
        onAnimationComplete={(def) => {
          if (def.y === "0") {
            setFirstAnimationComplete(true);
          }
        }}
      >
        <div>
          <SearchInput
            value={input}
            onChange={handleSearchChange}
            onClear={handleSearchClear}
            onEnter={handleSearchEnter}
            placeholder={["Find someone like you.", "I lost my Mom to cancer."]}
            className={styles.searchInput}
            queryRequired
          />
          <FilterPopover
            filters={filters}
            onChange={handleFiltersChange}
            handleFilter={() => handleSearchEnter(input)}
          />
        </div>{" "}
      </motion.div>

      <AnimatePresence>
        {focused && firstAnimationComplete && (
          <>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5 }}
              className={styles.results}
            >
              {results.length > 0 &&
                !loading &&
                results.map((result, index) => (
                  <ResultCardWrapper
                    key={result._id}
                    experience={result}
                    ref={
                      index === results.length - 1 ? lastExperienceRef : null
                    }
                  />
                ))}
              {loading && (
                <div className={styles.status}>
                  <LoadingMessage message="Loading experiences..." />
                </div>
              )}
              {!loading && !hasMore && results.length > 0 && (
                <div className={styles.status}>You've reached the end!</div>
              )}
              {!loading && results.length === 0 && searchQuery === "" && (
                <div className={styles.status}>Submit a search query...</div>
              )}
              {!loading && results.length === 0 && searchQuery !== "" && (
                <div className={styles.status}>
                  No experiences match your search.
                </div>
              )}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}

export default LifeExperiences;
