import * as typedefs from "typedefs";

/**
 * @typedef {typedefs.ErrorResponseData} ErrorResponseData
 */
/**
 * Custom error class for handling Axios errors.
 * @extends Error
 */
class AxiosError extends Error {
  /**
   * Create an AxiosError.
   *
   * @param {ErrorResponseData} errorData - The error data.
   */
  constructor(errorData) {
    const {
      message,
      statusCode,
      redirect = null,
      errors = [],
      _error,
    } = errorData;
    super(message);
    this.statusCode = statusCode;
    this.redirect = redirect;
    this.errors = errors;
    this._error = _error;

    // Set the name property to the class name for better error identification
    this.name = this.constructor.name;

    // Capture the stack trace if available (only available in V8 environments)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  /**
   * Converts the AxiosError instance to a plain object.
   * @returns {ErrorResponseData} The plain object representation of the error.
   */
  toObject() {
    return {
      message: this.message,
      statusCode: this.statusCode,
      redirect: this.redirect,
      errors: this.errors,
    };
  }

  /**
   * Converts the AxiosError instance to a JSON string.
   * @returns {string} The JSON string representation of the error.
   */
  toJSON() {
    return JSON.stringify(this.toObject());
  }
}

export default AxiosError;

// Usage example:
// const axiosError = new AxiosError({
//   message: "Not Found",
//   statusCode: 404,
//   redirect: "/home",
//   errors: [{ message: "Specific error detail" }],
// });
// );
// );
