import { useState, useEffect } from "react";
import { BREAKPOINTS } from "../constants/breakpoints";

/**
 * Custom hook to track media query matches.
 *
 * @param {string} breakpoint - The breakpoint key from BREAKPOINTS.
 *   Valid breakpoints are:
 *   - xs: "(max-width: 575px)"
 *   - sm: "(max-width: 768px)"
 *   - md: "(min-width: 768px)"
 * @returns {boolean} - Whether the media query matches.
 */
const useMediaQuery = (breakpoint) => {
  const query = BREAKPOINTS[breakpoint];
  if (!query) {
    throw new Error(`Invalid breakpoint: ${breakpoint}`);
  }

  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    const handleMediaQueryChange = (event) => {
      setMatches(event.matches);
    };

    mediaQueryList.addEventListener("change", handleMediaQueryChange);
    setMatches(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener("change", handleMediaQueryChange);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
