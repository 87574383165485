import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./VerifyEmailRequired.module.css";
import Button from "components/Button/Button";
import * as AUTH_API from "api/auth";
import { useToastContext } from "contexts/ToastContext";
import { IoMail } from "react-icons/io5";

const VerifyEmailRequired = () => {
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const [resendState, setResendState] = useState("initial"); // initial, loading, success

  const handleResendEmail = async () => {
    try {
      setResendState("loading");
      await AUTH_API.resendVerificationEmail();
      setResendState("success");
      addToast("Verification email sent successfully!", "success");
    } catch (error) {
      setResendState("initial");
      addToast(error.message || "Failed to send verification email", "error");
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Email Verification Required</h1>
        </div>

        <div className={styles.contentSection}>
          <div className={styles.iconHeader}>
            <IoMail className={styles.icon} />
          </div>

          <div className={styles.message}>
            <p>
              Please verify your email address to access all features. Check
              your inbox for the verification link.
            </p>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            color="primary"
            onClick={handleResendEmail}
            fetchState={resendState}
            disabled={resendState === "loading"}
          >
            Resend Verification Email
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailRequired;
