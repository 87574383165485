import React from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/ReusableTooltip/ReusableTooltip";
import styles from "./InfoTooltip.module.css";

const InfoTooltip = ({ content }) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <div className={styles.icon}>
          <IoInformationCircleOutline />
        </div>
      </TooltipTrigger>
      <TooltipContent>{content}</TooltipContent>
    </Tooltip>
  );
};

export default InfoTooltip;
