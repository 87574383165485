// packages/client/src/components/Story/Story.jsx
import React, { useRef, forwardRef } from "react";
import styles from "./Story.module.css";
import { FaXmark } from "react-icons/fa6";

const Story = forwardRef(
  ({ maxWords: maxWordsInit, story, onChange, placeholder }, ref) => {
    const textAreaRef = useRef(null);
    const maxWords = maxWordsInit || 100;
    const MAX_HEIGHT = 150;

    const handleInput = (event) => {
      const textArea = event.target;
      textArea.style.height = "auto"; // Reset the height
      textArea.style.height = `${Math.min(
        textArea.scrollHeight,
        MAX_HEIGHT
      )}px`; // Set to scrollHeight or MAX_HEIGHT
    };

    const handleStoryChange = (event) => {
      const text = event.target.value;
      const words = text.split(/\s+/);
      if (words.length > maxWords) {
        return;
      }
      onChange(text);
    };

    const clearInput = () => {
      onChange("");
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto"; // Reset the height
      }
    };

    const wordCount = story?.trim() ? story?.split(/\s+/).length : 0;
    const hasReachedLimit = wordCount >= maxWords;

    return (
      <div ref={ref} className={styles.story} data-tour="story-input">
        <div className={styles.textAreaContainer}>
          <textarea
            ref={textAreaRef}
            className={`${styles.essayTextArea} ${
              hasReachedLimit ? styles.textAreaError : ""
            }`}
            value={story}
            onChange={handleStoryChange}
            onInput={handleInput}
            placeholder={placeholder || "Write your story here..."}
            rows={3}
            maxLength={1000}
          />

          {story && (
            <FaXmark className={styles.clearButton} onClick={clearInput} />
          )}
        </div>
      </div>
    );
  }
);

export default Story;
