import handleAxiosError from "./handleAxiosError";
import api from "./index";

/**
 * Submit a report for a user
 */
export const reportUser = async ({ targetId, reason, details }) => {
  try {
    const res = await api.post(`/reports/users/${targetId}`, {
      reason,
      details,
    });
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

/**
 * Submit a report for a story
 */
export const reportStory = async ({ targetId, reason, details }) => {
  try {
    const res = await api.post(`/reports/stories/${targetId}`, {
      reason,
      details,
    });
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

/**
 * Submit a report for a life experience
 */
export const reportLifeExperience = async ({ targetId, reason, details }) => {
  try {
    const res = await api.post(`/reports/lifeexperiences/${targetId}`, {
      reason,
      details,
    });
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

/**
 * Submit a report for a reachout
 */
export const reportReachout = async ({ targetId, reason, details }) => {
  try {
    const res = await api.post(`/reports/reachouts/${targetId}`, {
      reason,
      details,
    });
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

/**
 * Fetch all reports submitted by the current user
 */
export const fetchUserReports = async () => {
  try {
    const response = await api.get("/reports");
    return response.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

/**
 * Fetch a specific report by ID
 */
export const fetchReport = async (reportId) => {
  try {
    const response = await api.get(`/reports/${reportId}`);
    return response.data;
  } catch (err) {
    handleAxiosError(err);
  }
};
