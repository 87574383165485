// packages/client/src/pages/Lobby/FloatingTooltip/FloatingTooltip.jsx
import React, { useEffect, useRef, useState } from "react";
import { useFloating, offset, arrow, FloatingArrow } from "@floating-ui/react";
import { AnimatePresence, motion } from "framer-motion"; // Import AnimatePresence and motion from framer-motion

import styles from "./FloatingTooltip.module.css";

const FloatingTooltip = ({
  anchorRef,
  children,
  show,
  placement = "bottom",
}) => {
  const arrowRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const { refs, floatingStyles, update, context } = useFloating({
    placement,
    middleware: [offset(8), arrow({ element: arrowRef, padding: 5 })],
    elements: {
      reference: referenceElement,
    },
  });

  useEffect(() => {
    setReferenceElement(anchorRef.current);
  }, [anchorRef]);

  useEffect(() => {
    if (show && referenceElement) {
      update();
    }
  }, [show, referenceElement, update]);

  if (!show) return null;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          ref={refs.setFloating}
          role="tooltip"
          className={styles.tooltip}
          style={floatingStyles}
          initial={{ opacity: 0 }} // Initial state for fade-in
          animate={{ opacity: 1 }} // Animate to this state
          exit={{ opacity: 0 }} // Exit state for fade-out
          transition={{ duration: 0.1 }}
        >
          {children}
          <FloatingArrow
            ref={arrowRef}
            context={context}
            fill="#ed7d31"
            tipRadius="3"
            staticOffset={"15%"}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FloatingTooltip;
