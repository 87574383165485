// packages/client/src/api/chats.js
import api from "api";
import "../typedefs";
import handleAxiosError from "./handleAxiosError";

/**
 * Fetches chats for the authenticated user.
 * @returns {Promise<Array<ChatData>>} A promise that resolves to an array of chat objects.
 */
export const fetchChats = async () => {
  try {
    const response = await api.get("chats");

    return response.data; // The chats data
  } catch (error) {
    console.error("Error fetching my chats:", error);
    // Handle the error appropriately
  }
};

/**
 * Fetches a specific chat by its chatId.
 * @param {string} chatId - The ID of the chat to fetch.
 * @returns {Promise<ChatData>} A promise that resolves to the chat object.
 * @throws {Error} Throws an error if the chat cannot be fetched.
 */
export const fetchChatById = async (chatId) => {
  try {
    const response = await api.get(`chats/${chatId}`);
    return response.data; // The chat data
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Fetches messages for a specific chat.
 *
 * @param {string} chatId - The unique identifier for the chat.
 * @param {Object} [options={}] - Optional parameters for fetching messages.
 * @param {string} [options.before] - Fetch messages sent before this timestamp.
 * @param {number} [options.limit=20] - The maximum number of messages to fetch.
 * @returns {Promise<Array<MessageData>>} A promise that resolves to an array of ChatMessage objects for the chat.
 * @throws {Error} Throws an error if the fetch operation fails.
 */
export const fetchMessagesForChat = async (chatId, options = {}) => {
  const { before, limit = 20 } = options;

  try {
    const res = await api.get(`chats/${chatId}/messages`, {
      params: { before, limit },
    });

    return res.data;
  } catch (err) {
    console.error("Error fetching chat messages:", err);
    throw err;
  }
};

export const fetchReviewsForChat = async (chatId) => {
  try {
    const response = await api.get(`chats/${chatId}/reviews`);
    return response.data; // Assuming the response data contains the reviews
  } catch (error) {
    console.error("Error fetching chat reviews:", error);
    throw error; // Re-throw the error so the calling code can handle it
  }
};
