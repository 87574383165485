import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { useUserContext } from "contexts/UserContext";
import { useToastContext } from "contexts/ToastContext";

import { produce } from "immer";
import * as CHATS_API from "api/chats";

import * as typedefs from "typedefs";
import { useChat } from "./useChat";
import { useChatRequest } from "./useChatRequest";
import { useMessages } from "./useMessages";
import useEffectDebugger from "utils/useEffectDebugger";

import { useUserProfiles } from "./useUserProfiles";
import { useWebRTC } from "./useWebRTC";

/**
 * Represents a chat in the application.
 *
 * @typedef {typedefs.Chat} Chat
 * @typedef {typedefs.ChatData} ChatData
 * @typedef {typedefs.ChatRequest} ChatRequest
 * @typedef {typedefs.ChatRequestData} ChatRequestData
 * @typedef {typedefs.ChatMessage} ChatMessage
 * @typedef {typedefs.MessageData} MessageData
 */

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

const ChatContextProvider = ({ children }) => {
  const { userData } = useUserContext();
  const { addToast } = useToastContext();

  const navigate = useNavigate();

  /**
   * Represents a collection of chats.
   *
   * @typedef {Object<string, Chat>} chats - An object mapping chat IDs to their corresponding chat data, allowing for quick access to chat information by ID.
   */
  const [chats, setChats] = useState({}); // chats is of type Chats

  const { loadChats, fetchAndLoadChats, isLoadingChats, initializeChat } =
    useChat({
      userData,
      setChats,
      addToast,
      navigate,
    });

  /**
   * Represents a collection of chat requests.
   *
   * @typedef {Object<string, ChatRequest>} chatRequests - An object mapping chat request IDs to their corresponding chat request data, allowing for quick access to chat request information by ID.
   */
  const [chatRequests, setChatRequests] = useState({});

  const {
    loadChatRequests,
    fetchAndLoadChatRequests,
    isLoadingChatRequests,
    initializeChatRequest,
    approveChatRequest,
  } = useChatRequest({ userData, setChatRequests, addToast, navigate });

  useEffectDebugger(
    () => { },
    [userData?._id, fetchAndLoadChats, fetchAndLoadChatRequests],
    ["userData?._id", "fetchAndLoadChats", "fetchAndLoadChatRequests"]
  );

  const { fetchMoreMessages, sendMessage } = useMessages({
    chats,
    chatRequests,
    setChats,
    setChatRequests,
    initializeChat,
    initializeChatRequest,
  });

  const ongoingReviewFetches = useRef({});

  const fetchChatReviews = useCallback(async (chatId) => {
    try {
      if (ongoingReviewFetches.current[chatId]) {
        return ongoingReviewFetches.current[chatId];
      }

      const fetchPromise = (async () => {
        try {
          const reviews = await CHATS_API.fetchReviewsForChat(chatId);

          setChats((prevChats) =>
            produce(prevChats, (draft) => {
              if (draft[chatId]) {
                draft[chatId].reviews = reviews;
              } else {
                console.warn(`Draft chat not found for chatId: ${chatId}`);
              }
            })
          );
          return reviews;
        } catch (error) {
          console.error("Failed to fetch reviews", error);
          throw error;
        } finally {
          delete ongoingReviewFetches.current[chatId];
        }
      })();

      ongoingReviewFetches.current[chatId] = fetchPromise;
      return fetchPromise;
    } catch (error) {
      console.error("Error in fetchChatReviews:", error);
    }
  }, []);

  const { fetchAndCacheUserProfile, refreshUserProfile, userProfiles } =
    useUserProfiles();

  const {
    isSearching,
    startSearch,
    stopSearch,
    matchedUser,
    matchAccepted,
    matchedUserResponse,
    acceptMatch,
    rejectMatch,
    callStatus,
    callDuration,
    cleanupChat,
    remoteStream,
    initiateCall,
    acceptCall,
    callChatId,
    endCall,
    error,
  } = useWebRTC();

  return (
    <ChatContext.Provider
      value={{
        chats,
        chatRequests,
        fetchChatReviews,
        fetchAndCacheUserProfile,
        refreshUserProfile,
        userProfiles,
        initializeChat,
        initializeChatRequest,
        approveChatRequest,
        loadChats,
        fetchAndLoadChats, // Expose the new function
        loadChatRequests,
        fetchAndLoadChatRequests, // Expose the new function
        isLoadingChats,
        isLoadingChatRequests,
        sendMessage,
        remoteStream,
        initiateCall,
        callStatus,
        callDuration,
        callChatId,
        endCall,
        acceptCall,
        cleanupChat,
        matchedUser,
        matchAccepted,
        acceptMatch,
        rejectMatch,
        error,
        matchedUserResponse,
        isSearching,
        startSearch,
        stopSearch,
        fetchMoreMessages,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatContext, ChatContextProvider };
