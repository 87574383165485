import { useCallback, useEffect, useRef } from "react";
import * as CHATS_API from "api/chats";
import * as CHATREQUESTS_API from "api/chatRequests";
import { useSocketContext } from "contexts/SocketContext";

/**
 * Custom hook for managing messages in chats and chat requests.
 * This hook sets up a listener for new messages and provides functions
 * to fetch more messages for a specified entity (chat or chat request)
 * and to send messages.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.chats - The current state of chats.
 * @param {Object} params.chatRequests - The current state of chat requests.
 * @param {Function} params.setChats - Function to update the chats state.
 * @param {Function} params.setChatRequests - Function to update the chat requests state.
 * @param {Function} params.initializeChat - Function to initialize a chat.
 * @param {Function} params.initializeChatRequest - Function to initialize a chat request.
 * @returns {Object} An object containing the fetchMoreMessages and sendMessage functions.
 */
export const useMessages = ({
  chats,
  chatRequests,
  setChats,
  setChatRequests,
  initializeChat,
  initializeChatRequest,
}) => {
  const { socket } = useSocketContext();
  const ongoingMessageFetches = useRef({});

  /**
   * Fetches more messages for a specified entity (chat or chat request).
   *
   * This function initiates a fetch request for more messages associated with
   * the given entity ID and type. It checks if a fetch is already in progress
   * to avoid duplicate requests. If new messages are received, it updates the
   * corresponding entity's state with the new messages.
   *
   * @param {string} entityId - The ID of the entity (chat or chat request) for which to fetch messages.
   * @param {string} entityType - The type of the entity, either "chat" or "chatRequest".
   * @param {string} before - A timestamp or identifier to fetch messages before this point.
   * @returns {Promise<boolean>} A promise that resolves to true if new messages were fetched, false otherwise.
   */
  const fetchMoreMessages = useCallback(
    async (entityId, entityType, before) => {
      const fetchKey = `${entityId}-${before}`;

      if (ongoingMessageFetches.current[fetchKey]) {
        return ongoingMessageFetches.current[fetchKey];
      }

      const fetchPromise = (async () => {
        try {
          const newMessages = await (entityType === "chat"
            ? CHATS_API.fetchMessagesForChat(entityId, { before })
            : CHATREQUESTS_API.fetchMessagesForChatRequest(entityId, {
                before,
              }));

          if (newMessages.length > 0) {
            const updateFunction =
              entityType === "chat" ? setChats : setChatRequests;
            updateFunction((prevEntities) => {
              if (prevEntities[entityId]) {
                const updatedEntity = { ...prevEntities[entityId] };
                updatedEntity.update({ messages: newMessages });

                return { ...prevEntities, [entityId]: updatedEntity };
              } else {
                console.warn(`${entityType} not found for ID: ${entityId}`);
                return prevEntities;
              }
            });
          } else {
          }
          return newMessages.length > 0;
        } catch (error) {
          console.error(
            `Failed to fetch more messages for ${entityType}`,
            error
          );
          return false;
        } finally {
          delete ongoingMessageFetches.current[fetchKey];
        }
      })();

      ongoingMessageFetches.current[fetchKey] = fetchPromise;
      return fetchPromise;
    },
    [setChats, setChatRequests]
  );

  const handleNewMessage = useCallback(
    async (data) => {
      const { chatId, chatRequestId, ...messageData } = data;

      if (chatId) {
        const chatExists = chats[chatId];
        if (!chatExists) {
          await initializeChat(chatId);
        } else {
          setChats((prevChats) => {
            const existingChat = prevChats[chatId];
            existingChat.addMessage(messageData);

            return { ...prevChats, [chatId]: existingChat };
          });
        }
      } else if (chatRequestId) {
        const chatRequestExists = chatRequests[chatRequestId];
        if (!chatRequestExists) {
          await initializeChatRequest(chatRequestId);
        } else {
          setChatRequests((prevRequests) => {
            const existingRequest = prevRequests[chatRequestId];
            if (existingRequest) {
              existingRequest.addMessage(messageData);

              return { ...prevRequests, [chatRequestId]: existingRequest };
            } else {
              console.warn(
                `Chat request not found for chatRequestId: ${chatRequestId}.`
              );
              return prevRequests;
            }
          });
        }
      } else {
        console.warn(
          `Neither chatId nor chatRequestId provided for message:`,
          messageData
        );
      }
    },
    [
      chats,
      chatRequests,
      initializeChat,
      initializeChatRequest,
      setChats,
      setChatRequests,
    ]
  );

  // useEffect(() => {
  //   if (socket) {
  //     socket.on("newMessage", handleNewMessage);

  //     return () => {
  //       socket.off("newMessage", handleNewMessage);
  //     };
  //   } else {
  //     console.warn("Socket is not available.");
  //   }
  // }, [socket, handleNewMessage]);

  /**
   * Sends a message to the backend, which then emits it to the chat.
   * The message is processed by the listener above by both clients.
   *
   * @param {string} id - The ID of the chat or chat request.
   * @param {string} message - The message to be sent.
   * @param {string} [type="chat"] - The type of the message, either "chat" or "chatRequest".
   * @returns {void}
   */
  const sendMessage = useCallback(
    (id, message, type = "chat") => {
      socket.emit("sendMessage", { id, message, type });
    },
    [socket]
  );

  return { fetchMoreMessages, sendMessage };
};
