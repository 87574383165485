import { useState, useCallback, useEffect } from "react";
import { hugStory } from "api/stories";
import { useToastContext } from "contexts/ToastContext";

/**
 * Custom hook to manage story hug functionality
 * @param {Object} story - The story object
 * @param {Object} userData - The current user's data
 * @returns {Object} Hook methods and state
 */
const useStoryHug = (story, userData) => {
  // Initialize states with story.isHugged if available, otherwise check hugs array
  const [isHugged, setIsHugged] = useState(() => {
    if (story?.isHugged !== undefined) {
      return story.isHugged;
    }
    return story?.hugs && userData?._id
      ? story.hugs.includes(userData._id)
      : false;
  });

  // Initialize hugs length from hugs array
  const [hugsLength, setHugsLength] = useState(() => story?.hugs?.length || 0);

  // Update states when story or userData changes
  useEffect(() => {
    if (story) {
      // Prefer the isHugged field if available
      if (story.isHugged !== undefined) {
        setIsHugged(story.isHugged);
      } else if (story.hugs && userData?._id) {
        setIsHugged(story.hugs.includes(userData._id));
      }
      setHugsLength(story.hugs?.length || 0);
    }
  }, [story, userData]);

  const { addToast } = useToastContext();

  const handleHugClick = useCallback(async () => {
    if (!userData) {
      addToast("Please log in to hug stories", "info");
      return;
    }

    if (!story?._id) {
      addToast("Cannot hug story at this time", "error");
      return;
    }

    const previousIsHugged = isHugged;
    const previousHugsLength = hugsLength;

    // Optimistic update
    setIsHugged(!previousIsHugged);
    setHugsLength(previousHugsLength + (previousIsHugged ? -1 : 1));

    try {
      const response = await hugStory(story._id);
      setIsHugged(response.isHugged);
      setHugsLength(response.hugCount);

      addToast(
        response.isHugged ? "Story hugged!" : "Story unhugged!",
        "success"
      );
    } catch (error) {
      console.error("Failed to toggle hug:", error);
      // Revert states
      setIsHugged(previousIsHugged);
      setHugsLength(previousHugsLength);
      addToast("Failed to hug story. Please try again.", "error");
    }
  }, [userData, story?._id, isHugged, hugsLength, addToast]);

  return {
    isHugged,
    hugsLength,
    handleHugClick,
  };
};

export default useStoryHug;
