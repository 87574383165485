import React from "react";
import PropTypes from "prop-types";
import styles from "./ErrorDisplay.module.css";
import Button from "components/Button/Button"; // Import your Button component
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import PageWrapper from "components/PageWrapper/PageWrapper";

const ErrorDisplay = ({ message, onRetry }) => {
  const handleReload = () => {
    if (onRetry) {
      onRetry();
    } else {
      window.location.reload();
    }
  };

  const handleGoHome = () => {
    window.location.href = "/";
  };

  return (
    <PageWrapper>
      <div className={styles.container}>
        <ErrorMessage message={message || "Something went wrong."} />{" "}
        {/* Display error message */}
        <div className={styles.actions}>
          <Button onClick={handleReload}>Reload Page</Button>
          <Button onClick={handleGoHome}>Go to Home</Button>
        </div>
      </div>
    </PageWrapper>
  );
};

ErrorDisplay.propTypes = {
  message: PropTypes.string.isRequired,
  onRetry: PropTypes.func, // Optional retry function
};

export default ErrorDisplay;
