// packages/client/src/components/Button/Button.jsx
import React from "react";
import cn from "classnames";
import styles from "./Button.module.css";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

/**
 * Button component to render a styled button with various themes and sizes.
 * It can also display a loading spinner when the `fetchState` prop is 'loading'.
 *
 * @param {string} className - Additional custom class for styling.
 * @param {string} color - Theme color of the button. Options: 'default', 'primary', 'secondary', 'error', 'success', 'gray', 'orange'. Default is 'default'.
 * @param {string} size - Size of the button. Options: 'small', 'medium', 'large'. Default is 'medium'.
 * @param {string} shape - Shape of the button. Options: 'rounded', 'square'. Default is 'rounded'.
 * @param {ReactNode} children - Content to be displayed inside the button.
 * @param {string} href - URL to navigate to on click.
 * @param {Function} onClick - Callback function that fires when the button is clicked.
 * @param {string} fetchState - The state of the button. Options: 'initial', 'loading', 'success'. Default is 'initial'.
 * @param {string} cursor - The type of cursor to display on hover. Options: 'pointer', 'default'. Default is 'pointer'.
 * @param {string} buttonType - Type of the button ('button', 'submit', 'reset').
 * @param {string} ariaLabel - Accessible label for the button.
 * @param {boolean} disabled - If true, the button is disabled.
 */
const Button = ({
  className,
  color = "default", // default, primary, secondary, error, success, gray, orange
  size = "medium", // small, medium, large
  shape = "rounded", // rounded, square
  children,
  href,
  onClick,
  fetchState = "initial", // initial, loading, success
  cursor = "pointer",
  buttonType = "button", // button, submit, reset
  ariaLabel,
  disabled,
  ...rest
}) => {
  let navigate = useNavigate();

  const handleClick = (event) => {
    if (fetchState === "loading") return; // Prevent action if loading

    if (onClick) {
      onClick(event);
    }

    if (href) {
      navigate(href);
    }
  };

  return (
    <button
      type={buttonType}
      className={cn(
        styles.button,
        styles[color],
        styles[size],
        styles[shape],
        className,
        {
          // [styles.loading]: fetchState === "loading",
          [styles.disabled]: disabled,
          [styles["default-cursor"]]: cursor === "default",
          [styles.success]: fetchState === "success",
        }
      )}
      onClick={handleClick}
      aria-label={ariaLabel || children}
      disabled={disabled || fetchState === "loading"}
      {...rest}
    >
      <span className={styles.buttonContent}>
        {children}
        {fetchState === "loading" && (
          <LoadingSpinner className={styles.spinner} />
        )}
      </span>
    </button>
  );
};

export default Button;
