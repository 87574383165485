// actionTypes.js
export const SET_CHATS = "SET_CHATS";
export const SET_CHAT_REQUESTS = "SET_CHAT_REQUESTS";
export const SET_REACHOUTS = "SET_REACHOUTS";
export const SET_MESSAGES = "SET_MESSAGES";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

// Loading and error states
export const FETCH_CHATS_START = "FETCH_CHATS_START";
export const FETCH_CHATS_ERROR = "FETCH_CHATS_ERROR";
export const FETCH_CHAT_REQUESTS_START = "FETCH_CHAT_REQUESTS_START";
export const FETCH_CHAT_REQUESTS_ERROR = "FETCH_CHAT_REQUESTS_ERROR";
export const FETCH_REACHOUTS_START = "FETCH_REACHOUTS_START";
export const FETCH_REACHOUTS_ERROR = "FETCH_REACHOUTS_ERROR";
export const FETCH_MESSAGES_START = "FETCH_MESSAGES_START";
export const FETCH_MESSAGES_ERROR = "FETCH_MESSAGES_ERROR";

export const FETCH_MORE_MESSAGES_START = "FETCH_MORE_MESSAGES_START";
export const FETCH_MORE_MESSAGES_ERROR = "FETCH_MORE_MESSAGES_ERROR";

// New action type for reviews
export const SET_REVIEWS = "SET_REVIEWS";
export const FETCH_REVIEWS_START = "FETCH_REVIEWS_START";
export const FETCH_REVIEWS_ERROR = "FETCH_REVIEWS_ERROR";

// Match-related actions
export const SET_MATCHES = "SET_MATCHES";
export const RECEIVE_MATCH = "RECEIVE_MATCH";
export const FETCH_MATCHES_START = "FETCH_MATCHES_START";
export const FETCH_MATCHES_ERROR = "FETCH_MATCHES_ERROR";

// Add these new action types
export const ACCEPT_MATCH_START = "ACCEPT_MATCH_START";
export const ACCEPT_MATCH_SUCCESS = "ACCEPT_MATCH_SUCCESS";
export const ACCEPT_MATCH_ERROR = "ACCEPT_MATCH_ERROR";
