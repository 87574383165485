// packages/client/src/pages/Lobby/FilterRoom/FilterRoom.jsx
import React, { useState, useEffect, useContext, useRef } from "react";
import { LobbyContext } from "contexts/LobbyContext";
import cn from "classnames";
import common from "../Lobby.module.css";

import { UserContext } from "contexts/UserContext";
import Story from "../Story/Story";
import Button from "components/Button/Button";
import TagSelect from "../TagSelect/TagSelect";

import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import RecommendedTags from "../RecommendedTags/RecommendedTags";
import { ReactTyped } from "react-typed";
import { motion } from "framer-motion";

import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper"; // Import JoyrideWrapper
import useRecommendedTags from "hooks/useRecommendedTags";
import CustomCombobox from "components/CustomCombobox/CustomCombobox";
import Modal from "components/Modal/Modal";
import ModalHeader from "components/Modal/ModalHeader";
import ModalBody from "components/Modal/ModalBody";
import { useLocation } from "react-router-dom"; // Add this import

const ageRanges = [
  {
    min: 0,
    max: 12,
    label: "0-12",
    description:
      "Encompasses early childhood through pre-teen years, focusing on primary school experiences and the onset of adolescence.",
  },
  {
    min: 13,
    max: 17,
    label: "13-17",
    description:
      "Typically encompasses middle to high school years, focusing on adolescence and early teenage experiences.",
  },
  {
    min: 18,
    max: 22,
    label: "18-22",
    description:
      "Often associated with college years or the start of adulthood, including early career steps and higher education.",
  },
  {
    min: 23,
    max: 25,
    label: "23-25",
    description:
      "Often associated with college years or the start of adulthood, including early career steps and higher education.",
  },
  {
    min: 26,
    max: 30,
    label: "26-30",
    description:
      "Late 20s, a period of further establishing one's career, possibly starting a family, and making long-term life decisions.",
  },
  {
    min: 31,
    max: 35,
    label: "31-35",
    description:
      " Early 30s, a time for advancing in one's career, experiencing significant life milestones, and possibly parenting young children.",
  },
  {
    min: 36,
    max: 40,
    label: "36-40",
    description:
      "Late 30s, focusing on career consolidation, family life, and preparing for the next stages of adulthood.",
  },
  {
    min: 41,
    max: 45,
    label: "41-45",
    description:
      "Early middle age, often marked by mid-life career achievements, parenting teenagers or young adults, and reflecting on personal goals.",
  },
  {
    min: 46,
    max: 55,
    label: "46-55",
    description:
      "Mid to late middle age, a period for reflecting on career progression, preparing for retirement, and experiencing significant life transitions.",
  },
  {
    min: 56,
    max: 65,
    label: "56-65",
    description:
      "Approaching retirement, focusing on retirement planning, lifestyle changes, and possibly becoming grandparents.",
  },
  {
    min: 66,
    max: 120,
    label: "66+",
    description:
      "Retirement and elder years, concentrating on leisure, health management, legacy considerations, and enjoying later life stages.",
  }, // Assuming a max age of 120 for simplicity
];

const genders = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

const steps = [
  {
    title: "Welcome to the Lobby!",
    target: `[data-tour='filter-room']`,
    placement: "center",
    content:
      "This is the heart of our community. Here, you can discover new friends and interesting conversations. Let's get started!",
    disableBeacon: true,
  },
  {
    target: `[data-tour='story-input']`,
    title: "Share Your Story",
    content:
      "What's on your mind today? Share a bit about what you're thinking or how you're feeling. Your story helps others connect with you.",
    disableBeacon: true,
  },
  {
    target: `[data-tour='tag-select']`,
    title: "Choose Your Tags",
    content:
      "Select tags that reflect the topics you wish to discuss. This helps us connect you with people going through the same struggles as you.",
    disableBeacon: true,
  },
  {
    target: `[data-tour='age-select']`,
    title: "Choose Your Age Range",
    content:
      "Select an age range that you would like to chat with. This helps us match you with people of similar ages.",
    disableBeacon: true,
  },
  {
    target: `[data-tour='gender-select']`,
    title: "Choose Your Gender",
    content:
      "Select a gender that you would like to chat with. This helps us match you with people of similar genders.",
    disableBeacon: true,
  },
];

const TagHelp = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <p onClick={openModal} className={common.helper}>
        How do I find the right tag?
      </p>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalHeader>Finding the Right Tag</ModalHeader>
        <ModalBody>
          <p>You can find the right tag by:</p>
          <ul>
            <li>
              Visiting{" "}
              <a href="/stories" target="_blank">
                /stories
              </a>{" "}
              to search for stories and see the tags used.
            </li>
            <li>
              Searching through the collection of tags at{" "}
              <a href="/tags" target="_blank">
                /tags
              </a>
              .
            </li>
          </ul>
        </ModalBody>
      </Modal>
    </>
  );
};

const FilterRoom = ({ step, setStep }) => {
  const active = step === 1;
  const { userData } = useContext(UserContext);
  const location = useLocation(); // Add this

  const {
    selectedTags,
    selectTag,
    setSelectedTags,
    clearSelectedTags,
    isTagSelected,
    story,
    setStory,
    selectedAgeRanges,
    setSelectedAgeRanges,
    selectedGenders,
    setSelectedGenders,
  } = useContext(LobbyContext);

  const {
    fetchRecommendedTags,
    tags: recommendedTags,
    loading: loadingRecommendedTags,
    error: errorLoadingRecommendedTags,
  } = useRecommendedTags();

  useEffect(() => {
    if (location.state?.story) {
      setStory(location.state.story);
    }
  }, [location.state, setStory]);

  const handleNext = () => {
    setStep(2);
  };

  const tagSelectRef = useRef(null);

  const [showTagSelectNotification, setShowTagSelectNotification] =
    useState(false);
  const [showStoryNotification, setShowStoryNotification] = useState(false);
  const storyRef = useRef(null);

  const handleNextPreCheck = () => {
    if (!story?.trim()) {
      // Show story notification
      setShowStoryNotification(true);
      setTimeout(() => setShowStoryNotification(false), 3000);
      return;
    }

    if (selectedTags.length === 0) {
      // Show tag notification
      setShowTagSelectNotification(true);
      setTimeout(() => setShowTagSelectNotification(false), 3000);
      return;
    }

    handleNext(); // Proceed only if both validations pass
  };

  const handleStoryChange = async (story) => {
    setStory(story);
    fetchRecommendedTags(story);
  };

  const [animationStep, setAnimationStep] = useState(0);

  const greeting = `Hi, ${
    userData?.username ? userData.username : "stranger"
  }. ^500  What do you want to talk about?`;

  return (
    <>
      {active && <JoyrideWrapper steps={steps} disableScrolling />}
      <div
        className={cn(common.room, common.filterRoom, {
          [common.active]: active,
          [common.hidden]: !active,
        })}
        data-tour="filter-room"
      >
        <div className={common.header} data-tour="header">
          <h1>
            <ReactTyped
              strings={[greeting]}
              typeSpeed={50}
              showCursor
              startWhenVisible
              onStart={() => {
                setTimeout(() => {
                  setAnimationStep(1);
                }, 500);
              }}
            />
          </h1>
        </div>
        <div data-tour="input-wrapper" className={common.inputWrapper}>
          <motion.div
            className={common.storyInput}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <Story
              ref={storyRef}
              maxWords={25}
              story={story}
              onChange={handleStoryChange}
              placeholder="What's happening?"
              required
            />
            <FloatingTooltip anchorRef={storyRef} show={showStoryNotification}>
              <p>Please share what's on your mind</p>
            </FloatingTooltip>
            <div style={{ color: "white" }}>
              <RecommendedTags
                recommendedTags={recommendedTags}
                isLoading={loadingRecommendedTags}
                selected={selectedTags}
                onSelect={(tag) => selectTag(tag)}
                isTagSelected={isTagSelected}
              />
            </div>
          </motion.div>

          <motion.div
            className={common.tagsInput}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          >
            <TagSelect
              ref={tagSelectRef}
              onSelect={setSelectedTags}
              selected={selectedTags}
              clearSelectedTags={clearSelectedTags}
              className={common.comboboxStyles}
              focusedClassName={common.comboboxStylesFocused}
              listClassName={common.comboboxStylesList}
            />
            <FloatingTooltip
              anchorRef={tagSelectRef}
              show={showTagSelectNotification}
            >
              <p>Please pick at least one tag</p>
            </FloatingTooltip>
            <TagHelp />
          </motion.div>
          <motion.div
            className={common.ageInput}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.5 }}
          >
            <CustomCombobox
              isMulti
              options={ageRanges.map((range) => {
                return { ...range, value: range.label };
              })}
              selected={selectedAgeRanges}
              onSelect={(selected) => setSelectedAgeRanges(selected)}
              placeholder="Select an age range "
              morePlaceholder="Select more age ranges (optional)"
              data-tour="age-select"
              className={common.comboboxStyles}
              focusedClassName={common.comboboxStylesFocused}
              listClassName={common.comboboxStylesList}
            />
          </motion.div>
          <motion.div
            className={common.genderInput}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.75 }}
          >
            <CustomCombobox
              data-tour="gender-select"
              isMulti
              options={genders}
              selected={selectedGenders}
              onSelect={(selected) => setSelectedGenders(selected)}
              placeholder="Select a gender"
              morePlaceholder="Select more genders (optional)"
              className={common.comboboxStyles}
              focusedClassName={common.comboboxStylesFocused}
              listClassName={common.comboboxStylesList}
            />
          </motion.div>
        </div>

        <motion.div
          className={common.btn}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2 }}
        >
          <Button onClick={handleNextPreCheck}>
            <p>Next</p>
          </Button>
        </motion.div>
      </div>
    </>
  );
};

export default FilterRoom;
