// packages/client/src/components/JoyrideWrapper/JoyrideWrapper.jsx
import React, { useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import JoyrideFab from "components/JoyrideFab/JoyrideFab";

/**
 * Wraps the React Joyride component to provide a guided tour through the application.
 * This component abstracts Joyride's setup and state management, offering a simple interface
 * to start and stop the tour, along with customizable steps and initial run behavior.
 * It includes a floating action button (FAB) that triggers the start of the tour.
 *
 * The tour's appearance and behavior can be customized through the `styles` and `locale` props.
 * The component listens to Joyride's callback to automatically stop the tour on completion or when skipped.
 *
 * Props:
 * - steps {Array<Object>}: An array of step objects that define the tour. Each step object must at least have
 *   a `target` (CSS selector or an HTMLElement directly) and `content` (string or JSX to display).
 *   See Joyride documentation for more step object options.
 * - runOnInit {boolean}: Indicates whether the tour should start automatically upon component mount. Default is `false`.
 * - includeHelpButton {boolean}: Indicates whether to include a help button reminder step. Default is `true`.
 * - onTourComplete {function}: Callback function to be called when the tour is completed.
 *
 * @param {Object} props The props for configuring the JoyrideWrapper.
 * @param {Array<Object>} props.steps Required. The steps of the tour.
 * @param {boolean} [props.runOnInit=false] Optional. Whether the tour should start automatically.
 * @param {boolean} [props.includeHelpButton=true] Optional. Whether to include a help button reminder.
 * @param {function} [props.onTourComplete] Optional. Callback function to be called when the tour is completed.
 * @returns {React.Component} A React component that renders the Joyride tour and a FAB for starting it.
 */
const JoyrideWrapper = ({
  steps,
  runOnInit = false,
  includeHelpButton = true,
  onTourComplete,
  ...rest
}) => {
  const [run, setRun] = useState(runOnInit);
  const [stepIndex, setStepIndex] = useState(0);
  const [hasCompletedTour, setHasCompletedTour] = useState(false);

  // Add the help button reminder step if enabled
  const allSteps = React.useMemo(() => {
    if (!includeHelpButton) return steps;

    return [
      ...steps,
      {
        target: '[data-tour="help-fab"]',
        title: "Need help later?",
        content:
          "Remember, you can always restart the tour by clicking this help button. It'll stay right here whenever you need it!",
        placement: "top-end",
        disableBeacon: true,
        styles: {
          options: {
            zIndex: 10000, // Ensure it's above the FAB
          },
        },
      },
    ];
  }, [steps, includeHelpButton]);

  const startTour = () => {
    setRun(true);
    setStepIndex(stepIndex < allSteps.length ? stepIndex : 0);
  };

  const stopTour = () => {
    setRun(false);
  };

  const handleJoyrideCallback = (data) => {
    const { status, action, index, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      stopTour();
      setHasCompletedTour(true);
      if (onTourComplete) {
        onTourComplete();
      }
    } else if (action === ACTIONS.CLOSE) {
      stopTour();
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Move to next step only if not closing
      if (action !== ACTIONS.CLOSE) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        setStepIndex(nextStepIndex);
      }
    }
  };

  return (
    <>
      <JoyrideFab
        onClick={startTour}
        hasCompletedTour={hasCompletedTour}
        data-tour="help-fab"
      />

      <Joyride
        continuous
        run={run}
        steps={allSteps}
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: "white",
            backgroundColor: "white",
            overlayColor: "rgba(0, 0, 0, 0.6)",
            primaryColor: "var(--chair-orange)",
            textColor: "var(--dark-graphite)",
            zIndex: 1000,
          },
          spotlight: {
            borderRadius: "8px",
          },
          tooltip: {
            borderRadius: "8px",
            padding: "20px",
          },
          buttonNext: {
            backgroundColor: "var(--chair-orange)",
          },
          buttonBack: {
            color: "var(--chair-orange)",
            marginRight: "8px",
          },
        }}
        locale={{
          last: "Got it!",
          skip: "Skip tour",
          next: "Next",
          back: "Back",
        }}
        {...rest}
      />
    </>
  );
};

export default JoyrideWrapper;
