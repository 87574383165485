import handleAxiosError from "api/handleAxiosError";
import api from "../api";
import {
  SET_CHATS,
  SET_CHAT_REQUESTS,
  SET_REACHOUTS,
  SET_MESSAGES,
  RECEIVE_MESSAGE,
  FETCH_CHATS_START,
  FETCH_CHATS_ERROR,
  FETCH_CHAT_REQUESTS_START,
  FETCH_CHAT_REQUESTS_ERROR,
  FETCH_REACHOUTS_START,
  FETCH_REACHOUTS_ERROR,
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_ERROR,
  FETCH_MORE_MESSAGES_START,
  FETCH_MORE_MESSAGES_ERROR,
  SET_REVIEWS,
  FETCH_REVIEWS_START,
  FETCH_REVIEWS_ERROR,
  SET_MATCHES,
  RECEIVE_MATCH,
  FETCH_MATCHES_START,
  FETCH_MATCHES_ERROR,
  ACCEPT_MATCH_START,
  ACCEPT_MATCH_SUCCESS,
  ACCEPT_MATCH_ERROR,
} from "./actionTypes";
import * as REACHOUT_API from "api/reachouts";
import * as REVIEWS_API from "../api/reviews";
import * as MATCHES_API from "api/matches";

// Action Creators for setting state
export const setChats = (chats) => {
  const byId = {};
  const allIds = [];

  chats.forEach((chat) => {
    byId[chat._id] = chat;
    allIds.push(chat._id);
  });

  return { type: SET_CHATS, payload: { byId, allIds } };
};

export const setChatRequests = (chatRequests) => {
  const byId = {};
  const allIds = [];

  chatRequests.forEach((request) => {
    byId[request._id] = request;
    allIds.push(request._id);
  });

  return { type: SET_CHAT_REQUESTS, payload: { byId, allIds } };
};

export const setReachouts = (reachouts) => {
  const byId = {};
  const allIds = [];

  reachouts.forEach((reachout) => {
    byId[reachout._id] = reachout;
    allIds.push(reachout._id);
  });

  return { type: SET_REACHOUTS, payload: { byId, allIds } };
};

export const setMessages = (messages) => {
  const byId = {};
  const byChatId = {};
  const byChatRequestId = {};
  const byReachoutId = {};
  const byMatchId = {};
  const allIds = [];

  messages.forEach((message) => {
    const messageId = message._id;
    byId[messageId] = message;
    allIds.push(messageId);

    // Group messages by chatId
    if (message.chatId) {
      if (!byChatId[message.chatId]) {
        byChatId[message.chatId] = [];
      }
      byChatId[message.chatId].push(messageId);
    }

    // Group messages by matchId
    if (message.matchId) {
      if (!byMatchId[message.matchId]) {
        byMatchId[message.matchId] = [];
      }
      byMatchId[message.matchId].push(messageId);
    }

    // Group messages by chatRequestId
    if (message.chatRequestId) {
      if (!byChatRequestId[message.chatRequestId]) {
        byChatRequestId[message.chatRequestId] = [];
      }
      byChatRequestId[message.chatRequestId].push(messageId);
    }

    // Group messages by reachoutId
    if (message.reachoutId) {
      if (!byReachoutId[message.reachoutId]) {
        byReachoutId[message.reachoutId] = [];
      }
      byReachoutId[message.reachoutId].push(messageId);
    }
  });

  return {
    type: SET_MESSAGES,
    payload: {
      byId,
      byChatId,
      byChatRequestId,
      byReachoutId,
      byMatchId,
      allIds,
    },
  };
};

export const receiveMessage = (message) => ({
  type: RECEIVE_MESSAGE,
  payload: message,
});

// Load initial data with error handling and loading state management
export const loadInitialData = () => async (dispatch) => {
  try {
    // Dispatch all fetch operations in parallel
    await Promise.all([
      dispatch(fetchChats()),
      dispatch(fetchChatRequests()),
      dispatch(fetchReachouts()),
      dispatch(fetchMatches()),
      dispatch(initMessages()),
      dispatch(initReviews()),
    ]);
  } catch (error) {
    console.error("Error loading initial data:", error);
    // You could also dispatch a general error action here if necessary
  }
};

// Fetching chats
export const fetchChats = () => async (dispatch) => {
  dispatch({ type: FETCH_CHATS_START });

  try {
    const response = await api.get("/chats");
    dispatch(setChats(response.data)); // Use action creator to format the payload
  } catch (error) {
    dispatch({ type: FETCH_CHATS_ERROR });
    console.error("Error fetching chats:", error);
  }
};

// Fetching chat requests
export const fetchChatRequests = () => async (dispatch) => {
  dispatch({ type: FETCH_CHAT_REQUESTS_START });

  try {
    const response = await api.get("/chatrequests");
    dispatch(setChatRequests(response.data)); // Use action creator
  } catch (error) {
    dispatch({ type: FETCH_CHAT_REQUESTS_ERROR });
    console.error("Error fetching chat requests:", error);
  }
};

// Fetching reachouts
export const fetchReachouts = () => async (dispatch) => {
  dispatch({ type: FETCH_REACHOUTS_START });

  try {
    const response = await api.get("/reachouts");
    dispatch(setReachouts(response.data)); // Use action creator
  } catch (error) {
    dispatch({ type: FETCH_REACHOUTS_ERROR });
    console.error("Error fetching reachouts:", error);
  }
};

// Generic initialize action for chats, chat requests, and reachouts
export const initRoom = (entityId, entityType) => async (dispatch) => {
  let response;

  const entityEndpoints = {
    chat: "/chats",
    chatRequest: "/chatrequests",
    reachout: "/reachouts",
    match: "/matches",
  };

  const errorActions = {
    chat: FETCH_CHATS_ERROR,
    chatRequest: FETCH_CHAT_REQUESTS_ERROR,
    reachout: FETCH_REACHOUTS_ERROR,
    match: FETCH_MATCHES_ERROR,
  };

  const setters = {
    chat: setChats,
    chatRequest: setChatRequests,
    reachout: setReachouts,
    match: setMatches,
  };

  try {
    // Validate entity type
    if (!entityEndpoints[entityType]) {
      throw new Error(`Invalid entity type: ${entityType}`);
    }

    // Fetch entity data
    response = await api.get(`${entityEndpoints[entityType]}/${entityId}`);

    // Dispatch the appropriate setter action
    dispatch(setters[entityType]([response.data]));

    // Return success or fetched data (useful for component-level decision making)
    return response.data;
  } catch (error) {
    console.error(`Error initializing ${entityType}:`, error.message || error);

    // Dispatch error action
    dispatch({ type: errorActions[entityType] });

    handleAxiosError(error);
  }
};

/**
 * Fetches messages for a specific entity (chat, chat request, reachout, or match) or all messages if no entity is specified.
 *
 * This action will dispatch the appropriate action to start loading messages and handle errors.
 * If an `entityId` and `entityType` are provided, it will fetch messages for the corresponding entity type.
 * Otherwise, it will fetch all messages.
 *
 * @param {string|null} [entityId=null] - The ID of the entity (chat, chat request, reachout, or match) for which to fetch messages.
 * @param {string|null} [entityType=null] - The type of the entity. Can be "chat", "chatRequest", "reachout", or "match". If null, fetches all messages.
 * @returns {function} A thunk that dispatches actions to fetch messages and update the Redux store.
 */
export const initMessages =
  (entityId = null, entityType = null) =>
  async (dispatch) => {
    dispatch({ type: FETCH_MESSAGES_START });

    try {
      const endpoints = {
        chat: `/chats/${entityId}/messages`,
        chatRequest: `/chatrequests/${entityId}/messages`,
        reachout: `/reachouts/${entityId}/messages`,
        match: `/matches/${entityId}/messages`,
      };

      // Fetch messages based on entity type and ID
      const response = await (entityId && entityType
        ? endpoints[entityType]
          ? api.get(endpoints[entityType])
          : Promise.reject(new Error(`Invalid entity type: ${entityType}`))
        : api.get("/messages"));

      if (!response || !response.data) {
        throw new Error("No response data received");
      }

      // Use setMessages to update the Redux store with the fetched messages
      dispatch(setMessages(response.data));
    } catch (error) {
      dispatch({ type: FETCH_MESSAGES_ERROR });
      console.error("Error fetching messages:", error);
    }
  };

export const fetchMoreMessages =
  (entityId, entityType, before) => async (dispatch) => {
    dispatch({ type: FETCH_MORE_MESSAGES_START });

    try {
      let response;
      const endpoints = {
        chat: `/chats/${entityId}/messages`,
        chatRequest: `/chatrequests/${entityId}/messages`,
        reachout: `/reachouts/${entityId}/messages`,
        match: `/matches/${entityId}/messages`,
      };

      if (!endpoints[entityType]) {
        throw new Error(`Invalid entity type: ${entityType}`);
      }

      response = await api.get(endpoints[entityType], {
        params: { before },
      });

      dispatch(setMessages(response.data));
      return response.data.length > 0; // Return whether there are more messages to fetch
    } catch (error) {
      dispatch({ type: FETCH_MORE_MESSAGES_ERROR });
      console.error("Error fetching more messages:", error);
      return false;
    }
  };

/**
 * Accept a reachout and convert it into a chat.
 * This action will update both the `chatRequests` and `chats` states.
 *
 * @param {string} reachoutId - The ID of the reachout to accept.
 * @returns {function} A thunk that dispatches actions to update the Redux store.
 */
export const acceptReachout = (reachoutId) => async (dispatch) => {
  try {
    // Call the API to accept the reachout
    const { reachout, chat } = await REACHOUT_API.acceptReachout(reachoutId);

    // Return the newly created chat for further navigation
    return chat;
  } catch (error) {
    console.error("Error accepting reachout:", error);

    // Dispatch error action for reachouts
    dispatch({
      type: FETCH_REACHOUTS_ERROR,
      error: error.message || "Failed to accept reachout",
    });

    throw error; // Rethrow error so the component can handle it
  }
};

// Action Creator for setting reviews
export const setReviews = (reviews) => {
  const byId = {};
  const byChatId = {};
  const allIds = [];

  reviews.forEach((review) => {
    const reviewId = review._id;
    byId[reviewId] = review;
    allIds.push(reviewId);

    // Group reviews by chatId
    if (review.chat) {
      if (!byChatId[review.chat]) {
        byChatId[review.chat] = [];
      }
      byChatId[review.chat].push(reviewId);
    }
  });

  return { type: SET_REVIEWS, payload: { byId, byChatId, allIds } };
};

// Thunk to initialize all reviews
export const initReviews = () => async (dispatch) => {
  dispatch({ type: FETCH_REVIEWS_START });

  try {
    const reviews = await REVIEWS_API.fetchAllReviews();
    dispatch(setReviews(reviews)); // Use action creator to format the payload
  } catch (error) {
    dispatch({ type: FETCH_REVIEWS_ERROR });
    console.error("Error initializing reviews:", error);
  }
};

// Thunk to fetch reviews by chatId
export const fetchChatReviews = (chatId) => async (dispatch) => {
  dispatch({ type: FETCH_REVIEWS_START });

  try {
    const reviews = await REVIEWS_API.fetchReviewsByChatId(chatId);
    dispatch(setReviews(reviews)); // Use action creator to format the payload
  } catch (error) {
    dispatch({ type: FETCH_REVIEWS_ERROR });
    console.error("Error fetching chat reviews:", error);
  }
};

// Match action creators
export const setMatches = (matches) => {
  const byId = {};
  const allIds = [];

  matches.forEach((match) => {
    byId[match._id] = match;
    allIds.push(match._id);
  });

  return { type: SET_MATCHES, payload: { byId, allIds } };
};

export const receiveMatch = (match) => ({
  type: RECEIVE_MATCH,
  payload: match,
});

// Fetch matches
export const fetchMatches = () => async (dispatch) => {
  dispatch({ type: FETCH_MATCHES_START });

  try {
    const response = await api.get("/matches");
    dispatch(setMatches(response.data));
  } catch (error) {
    dispatch({ type: FETCH_MATCHES_ERROR });
    console.error("Error fetching matches:", error);
  }
};

/**
 * Accept a match and handle the response which may include a new chat
 * @param {string} matchId - The ID of the match to accept
 * @returns {function} Thunk that handles the match acceptance process
 */
export const acceptMatch = (matchId) => async (dispatch) => {
  dispatch({ type: ACCEPT_MATCH_START });

  try {
    // Use the API handler to accept the match
    const { updatedMatch, newChat } = await MATCHES_API.acceptMatch(matchId);

    // Always update the match
    dispatch({
      type: ACCEPT_MATCH_SUCCESS,
      payload: updatedMatch,
    });

    // Only if a new chat was created (both users accepted), add it to chats
    if (newChat) {
      dispatch({
        type: SET_CHATS,
        payload: {
          byId: { [newChat._id]: newChat },
          allIds: [newChat._id],
        },
      });
    }

    return { updatedMatch, newChat };
  } catch (error) {
    dispatch({
      type: ACCEPT_MATCH_ERROR,
      error: error.message || "Failed to accept match",
    });
    throw error;
  }
};
