import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as AUTH_API from "api/auth";
import Button from "components/Button/Button";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { useToastContext } from "contexts/ToastContext";
import {
  IoWarning,
  IoTime,
  IoRefresh,
  IoArrowBack,
  IoTrash,
  IoInformation,
} from "react-icons/io5";
import styles from "./Reactivate.module.css";
import { useUserContext } from "contexts/UserContext";

const Reactivate = () => {
  const [loading, setLoading] = useState(true);
  const [accountInfo, setAccountInfo] = useState(null);
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const { updateUser } = useUserContext();

  useEffect(() => {
    const checkRecoveryStatus = async () => {
      try {
        const status = await AUTH_API.getAccountStatus();
        if (status.status !== "deleted") {
          navigate("/");
          return;
        }
        setAccountInfo(status);
      } catch (error) {
        addToast("Error checking account status", "error");
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    checkRecoveryStatus();
  }, [navigate, addToast]);

  const handleReactivate = async () => {
    setLoading(true);
    try {
      const { message, user } = await AUTH_API.reactivateAccount();
      updateUser(user);
      addToast(message || "Account reactivated successfully!", "success");
      navigate("/");
    } catch (error) {
      addToast("Failed to reactivate account", "error");
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getRemainingDays = (deadline) => {
    const now = new Date();
    const deadlineDate = new Date(deadline);
    const days = Math.ceil((deadlineDate - now) / (1000 * 60 * 60 * 24));
    return days;
  };

  if (loading) {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.loadingContainer}>
          <LoadingMessage message="Checking account status..." />
        </div>
      </div>
    );
  }

  const remainingDays = getRemainingDays(accountInfo.recoveryDeadline);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Reactivate Your Account</h1>
        </div>

        <div className={styles.content}>
          <div className={styles.warningSection}>
            <IoWarning className={styles.warningIcon} />
            <div>
              <p className={styles.warningTitle}>Account Recovery Available</p>
              <p className={styles.warningText}>
                Your account was recently deleted but can still be recovered
                within {remainingDays} days.
              </p>
            </div>
          </div>

          <div className={styles.infoSection}>
            <div className={styles.infoItem}>
              <IoTrash className={styles.infoIcon} />
              <div>
                <h3>Deletion Details</h3>
                <p className={styles.infoDate}>
                  Deleted on: {formatDate(accountInfo.deletedAt)}
                </p>
                <p className={styles.reason}>{accountInfo.reason}</p>
                <p className={styles.type}>
                  <IoInformation className={styles.typeIcon} />
                  {accountInfo.deletionType}
                </p>
              </div>
            </div>

            <div className={styles.infoItem}>
              <IoTime className={styles.infoIcon} />
              <div>
                <h3>Recovery Window</h3>
                <p className={styles.deadline}>
                  Available until: {formatDate(accountInfo.recoveryDeadline)}
                </p>
                <p className={styles.note}>
                  After this deadline, your account will be permanently deleted
                  and cannot be recovered.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.actions}>
            <Button
              onClick={handleReactivate}
              variant="primary"
              className={styles.reactivateButton}
            >
              <IoRefresh /> Reactivate Account
            </Button>
            <Button
              onClick={() => navigate("/")}
              variant="secondary"
              className={styles.cancelButton}
            >
              <IoArrowBack /> Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reactivate;
