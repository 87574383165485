import React, { createContext, useState, useEffect, useContext } from "react";
import { SocketContext } from "./SocketContext";
import * as NOTIFS_API from "api/notifications";
import * as typedefs from "typedefs";

/**
 *
 * @typedef {typedefs.NotificationData} NotificationData
 */

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { socket } = useContext(SocketContext);
  const [notificationMap, setNotificationMap] = useState(new Map());
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const limit = 5;

  // Derive notifications from notificationMap
  const notifications = Array.from(notificationMap.values()).sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  useEffect(() => {
    const handleNewNotification = (notification) => {
      setNotificationMap((prevMap) => {
        if (!prevMap.has(notification._id)) {
          const newMap = new Map(prevMap);
          newMap.set(notification._id, notification);
          return newMap;
        }
        return prevMap;
      });
    };

    const handleLatestNotifications = (notifications) => {
      setNotificationMap((prevMap) => {
        const newMap = new Map(prevMap);
        notifications.forEach((notif) => {
          newMap.set(notif._id, notif);
        });
        return newMap;
      });
      setSkip((prevSkip) => prevSkip + notifications.length);
      setHasMore(notifications.length === limit);
    };

    if (socket.connected) {
      socket.on("newNotification", handleNewNotification);
      socket.on("latestNotifications", handleLatestNotifications);
    } else {
      socket.connect();
      socket.on("connect", () => {
        socket.on("newNotification", handleNewNotification);
        socket.on("latestNotifications", handleLatestNotifications);
      });
    }

    return () => {
      socket.off("newNotification", handleNewNotification);
      socket.off("latestNotifications", handleLatestNotifications);
    };
  }, [socket]);

  const fetchMoreNotifications = async () => {
    if (!hasMore) {
      return;
    }

    try {
      const data = await NOTIFS_API.fetchNotifications(limit, skip);
      setNotificationMap((prevMap) => {
        const newMap = new Map(prevMap);
        data.forEach((notif) => {
          if (!newMap.has(notif._id)) {
            newMap.set(notif._id, notif);
          }
        });
        return newMap;
      });
      setSkip((prev) => prev + data.length);
      setHasMore(data.length === limit);
    } catch (error) {
      console.error("Error fetching more notifications:", error);
    }
  };

  const markAsRead = async (id) => {
    try {
      await NOTIFS_API.markNotificationAsRead(id);
      setNotificationMap((prevMap) => {
        const newMap = new Map(prevMap);
        if (newMap.has(id)) {
          const notif = newMap.get(id);
          newMap.set(id, { ...notif, read: true });
        }
        return newMap;
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, markAsRead, fetchMoreNotifications, hasMore }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
