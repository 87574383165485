import { useState, useEffect } from "react";
import { useReviews } from "hooks/useReviews";
import Modal from "components/Modal/Modal";
import StarRating from "components/ReviewModal/StarRating";
import { useUserContext } from "contexts/UserContext";

import styles from "./Profile.module.css";

// const textObj = {
//   _id: "671f6eb8824be7c6418a18d9",
//   author: {
//     _id: "66df05098a820f10aa0dde98",
//     username: "jamespsb",
//     status: "active",
//     id: "66df05098a820f10aa0dde98"
//   },
//   subject: {
//     _id: "6677c0eba3d2bc3f64d91df5",
//     username: "baltie",
//     status: "active",
//     id: "6677c0eba3d2bc3f64d91df5"
//   },
//   chat: "66e30f3d410d7844fbd0af20",
//   trustScore: 5,
//   warmthScore: 5,
//   comments: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec laoreet, mi nec aliquam porta, tortor purus interdum arcu, eu interdum sem nibh at leo. Morbi iaculis arcu eu justo viverra, id aliquam nisl placerat. Curabitur tempus non ligula a commodo. Nunc cursus ante lorem, eu posuere sem faucibus sit amet. Cras rhoncus neque sit amet felis faucibus tincidunt. Etiam consectetur in tortor in commodo. Nullam sed porttitor massa, eu eleifend felis. Praesent vulputate, neque in placerat rutrum, elit tortor pharetra arcu, laoreet tempus tortor ex vel turpis. Ut sit amet quam semper, fringilla purus eu, pretium nisl.",
//   status: "approved",
//   approvedAt: null,
//   createdAt: "2024-10-28T11:00:08.607Z",
//   updatedAt: "2024-10-28T11:00:08.607Z",
//   id: "671f6eb8824be7c6418a18d9"
// }

// const testData = Array(12).fill(textObj);

const Reviews = ({ username }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const { reviewsByAuthor, fetchReviews, reviewsBySubject, fetchReviewsBySubject } = useReviews();
  const { userData } = useUserContext();

  const isAuthor = userData && userData.username === username;

  const handleOpenModal = (data) => {
    setIsModalOpen(true);
    setSelectedReview(data);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReview(null);
  }

  useEffect(() => {
    fetchReviews();
  }, [])

  useEffect(() => {
    fetchReviewsBySubject(username);
  }, [username])

  const ReviewCardModal = ({ review }) => {
    return (
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        showCloseButton={false}
      >
        {review && (
          <div className={styles.reviewModal}>
            <div className={styles.reviewModalHeader}>
              <p>{review.subject.username}</p>
              <p>{new Date(review.createdAt).toLocaleDateString('en-US')}</p>
            </div>
            <p>{review.comments}</p>
            <p>Status:&nbsp;<span>{review.status}</span></p>
            <div className={styles.scoreRating}>
              <label className={styles.scoreLabel}>Trust Score:</label>
              <StarRating rating={review.trustScore} view="profile" />
            </div>
            <div className={styles.scoreRating}>
              <label className={styles.scoreLabel}>Warmth Score:</label>
              <StarRating rating={review.warmthScore} view="profile" />
            </div>
          </div>
        )}
      </Modal>
    );
  }

  const ReviewCard = ({ review, handleOpenModal }) => (
    <div key={review._id} className={styles.reviewCard} onClick={handleOpenModal}>
      <div>
        <div>
          <p>{review.subject.username}</p>
          <p>{new Date(review.createdAt).toLocaleDateString('en-US')}</p>
        </div>
        <p>{review.comments}</p>
        <p>Status:&nbsp;<span>{review.status}</span></p>
      </div>
      <div className={styles.scoreRating}>
        <label className={styles.scoreLabel}>Trust Score:</label>
        <StarRating rating={review.trustScore} view="profile" />
      </div>
      <div className={styles.scoreRating}>
        <label className={styles.scoreLabel}>Warmth Score:</label>
        <StarRating rating={review.warmthScore} view="profile" />
      </div>
    </div>
  );

  return (
    <div className={styles.reviewsParentContainer}>
      <div className={styles.reviewsHeader}>
        <h1>Reviews</h1>
        {userData && userData.username === username
          ? <h5>All your reviews of other users are listed here</h5>
          : <h5>All your reviews for {username} are listed here</h5>
        }
      </div>
      <div className={styles.reviewItemsContainer}>
        {isAuthor && reviewsByAuthor.map((review) => (
          <ReviewCard review={review} handleOpenModal={() => handleOpenModal(review)} />
        ))}
        {!isAuthor && reviewsBySubject.map((review) => (
          <ReviewCard review={review} handleOpenModal={() => handleOpenModal(review)} />
        ))}
      </div>
      <ReviewCardModal review={selectedReview} />
    </div>
  );
}

export default Reviews;
