import React, { useState } from "react";
import styles from "./Profile.module.css";
import { useUserContext } from "contexts/UserContext";

import Button from "components/Button/Button";
import SearchInput from "components/SearchInput/SearchInput";
import FilterPopover from "components/FilterPopover/FilterPopover"; // Import FilterPopover
import LoadingMessage from "components/LoadingSpinner/LoadingMessage"; // Import LoadingMessage

import { FaPen } from "react-icons/fa";
import useMediaQuery from "hooks/useMediaQuery";

import LifeExperienceModal from "components/LifeExperienceModal/LifeExperienceModal";

import { searchLifeExperiences } from "api/lifeExperiences";
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import useLifeExperience from "components/LifeExperienceCard/useLifeExperience";

const comboboxOptions = [
  { value: "relevance", label: "Most Relevant" },
  { value: "new", label: "Newest" },
  { value: "old", label: "Oldest" },
];

const NewLifeExperienceModal = ({ isOpen, onClose, onCreate }) => {
  const { lifeExperience, error, status, statusMessage, createLifeExperience } =
    useLifeExperience();

  const handleCreate = async (formData) => {
    try {
      const createdLifeExp = await createLifeExperience(formData);
      onCreate(createdLifeExp);
      onClose();
    } catch (error) {
      console.error("Error creating life experience:", error);
    }
  };

  return (
    <LifeExperienceModal
      lifeExperience={lifeExperience}
      isOpen={isOpen}
      onClose={onClose}
      mode="new"
      onCreate={handleCreate}
      error={error}
      status={status}
      statusMessage={statusMessage}
    />
  );
};

const LifeExperienceContent = ({
  experiences,
  undiscoveredCount,
  onExperiencesChange,
  profileData,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({ sort: null, tags: [] }); // State for filters
  const [loading, setLoading] = useState(false); // State for loading
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery("sm");
  const { userData } = useUserContext();

  const handleSearch = async () => {
    setLoading(true); // Set loading to true before API call
    try {
      const searchParams = {
        query: searchQuery,
        user: profileData._id,
        sort: filters.sort ? filters.sort.value : null,
        tags: filters.tags.map((tag) => tag._id),
        // Add other search parameters as needed (e.g., page, limit)
      };
      const { results, undiscoveredCount } = await searchLifeExperiences(
        searchParams
      );
      onExperiencesChange(results, undiscoveredCount);
    } catch (error) {
      console.error("Error searching life experiences:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchInputClear = () => {
    setSearchQuery("");
    // Optionally, reset the experiences to the original list here
  };

  const handleSearchInputEnter = () => {
    handleSearch();
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleNewExperienceClick = () => {
    setIsModalOpen(true);
  };

  const handleExperienceCreate = (newExperience) => {
    onExperiencesChange([newExperience, ...experiences]);
  };

  const handleExperienceUpdate = (updatedExperience) => {
    const updatedExperiences = experiences.map((exp) =>
      exp._id === updatedExperience._id ? updatedExperience : exp
    );
    onExperiencesChange(updatedExperiences);
  };

  const handleExperienceDelete = (deletedExperienceId) => {
    const updatedExperiences = experiences.filter(
      (exp) => exp._id !== deletedExperienceId
    );
    onExperiencesChange(updatedExperiences);
  };

  return (
    <div className={styles.lifeExperiences}>
      <div className={styles.toolbar}>
        <SearchInput
          value={searchQuery}
          onChange={handleSearchInputChange}
          onClear={handleSearchInputClear}
          onEnter={handleSearchInputEnter}
          placeholder="Search life experiences..."
          className={styles.searchInput}
        />
        <FilterPopover
          filters={filters}
          onChange={handleFiltersChange}
          comboboxOptions={comboboxOptions}
          comboboxPlaceholder="Select sort option"
        />
        {profileData._id === userData._id && (
          <Button
            className={styles.newButton}
            color="primary"
            onClick={handleNewExperienceClick}
          >
            {isSmallScreen ? <FaPen /> : "New Life Experience"}
          </Button>
        )}
      </div>
      {/* {loading ? (
        <LoadingMessage message="Loading life experiences..." />
      ) : (
        userData &&
        experiences &&
        experiences.map((experience) =>
          experience?.author?._id === userData?._id ? (
            <OwnerLifeExperienceCard
              key={experience._id}
              experience={experience}
              onUpdate={handleExperienceUpdate}
              onDelete={handleExperienceDelete}
            />
          ) : (
            <NonOwnerLifeExperienceCard
              key={experience._id}
              experience={experience}
            />
          )
        )
      )} */}
      {loading ? (
        <LoadingMessage message="Loading life experiences..." />
      ) : (
        userData &&
        experiences &&
        experiences.map((experience) => (
          <LifeExperienceCard
            key={experience._id}
            lifeExpData={experience}
            userData={userData}
            onUpdate={handleExperienceUpdate}
            onDelete={handleExperienceDelete}
          />
        ))
      )}
      {undiscoveredCount > 0 && (
        <div className={styles.undiscoveredMessage}>
          <strong>{profileData.username}</strong> has {undiscoveredCount}
          {undiscoveredCount === 1
            ? " life experience"
            : " more life experiences"}{" "}
          you haven’t yet
          <a href="/faq/connections"> connected on.</a> Keep chatting with them
          and maybe if they're comfortable enough, they will reveal more about
          themselves to you.
        </div>
      )}
      {isModalOpen && (
        <NewLifeExperienceModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onCreate={handleExperienceCreate}
        />
      )}
    </div>
  );
};

export default LifeExperienceContent;
