import { UserContext } from "contexts/UserContext";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ChatWindow.module.css";
import cn from "classnames";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { formatRelativeTime } from "utils/dates";
import { useSelector } from "react-redux";
import { selectLastMessageByChatId } from "store/selectors";
import LastMessageDisplay from "./LastMessageDisplay";

/**
 * @typedef {import("classes/Chat").default} Chat
 */
/**
 * @typedef {Object} props
 * @property {Chat} data - The chat data.
 * @property {function} onClick - Optional click handler.
 * @property {boolean} isSelected - Indicates if the chat is selected.
 */
/**
 * @type {React.ForwardRefExoticComponent<props & React.RefAttributes<any>>}
 */
const ChatCard = React.forwardRef(({ data, onClick, isSelected }, ref) => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const chatId = data._id;
  const lastMessage = useSelector((state) =>
    selectLastMessageByChatId(state, chatId)
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`/chats?type=chats&id=${data._id}`);
    }
  };

  // Identify the other participant
  const otherParticipant = data.participants.find(
    (p) => p._id !== userData._id
  );
  const otherParticipantName = otherParticipant
    ? otherParticipant.username
    : "Unknown";

  return (
    <li
      className={cn(styles.chatCard, { [styles.selected]: isSelected })}
      onClick={handleClick}
    >
      <div className={styles.cardHeader}>
        <span className={styles.participantName}>{otherParticipantName}</span>
        {lastMessage && (
          <span className={styles.relativeTime}>
            {formatRelativeTime(lastMessage.createdAt)}
          </span>
        )}
      </div>
      {loading ? (
        <LoadingMessage message="loading messages..." />
      ) : (
        <LastMessageDisplay
          lastMessage={lastMessage}
          currentUserId={userData._id}
          otherParticipantName={otherParticipantName}
        />
      )}
      {/* <div className={styles.sharedTags}>
        {data.sharedTags.map((tag, i) => (
          <div key={i} className={styles.tag}>
            {tag.tag.name}
          </div>
        ))}
      </div> */}
    </li>
  );
});

export default ChatCard;
