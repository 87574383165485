import React from "react";
import styles from "./Footer.module.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";

function Footer() {
  const socialLinks = [
    {
      icon: <FacebookIcon />,
      url: "https://www.facebook.com",
      label: "Facebook",
    },
    { icon: <YouTubeIcon />, url: "https://www.youtube.com", label: "YouTube" },
    {
      icon: <InstagramIcon />,
      url: "https://www.instagram.com",
      label: "Instagram",
    },
    { icon: <XIcon />, url: "https://www.x.com", label: "X" },
  ];

  const footerLinks = [
    { label: "Privacy Policy", path: "/privacy" },
    { label: "Terms of Service", path: "/terms" },
    { label: "Rules", path: "/rules" }, // Added Rules link
    { label: "Contact Us", path: "/contact" },
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.social}>
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noreferrer"
              aria-label={link.label}
              className={styles.socialLink}
            >
              {link.icon}
            </a>
          ))}
        </div>

        <nav className={styles.links}>
          <ul>
            {footerLinks.map((link, index) => (
              <li key={index}>
                <a href={link.path} target="_blank" rel="noopener noreferrer">
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </nav>

        <div className={styles.copyright}>
          <p>&copy; {new Date().getFullYear()} Stumble. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
