// packages/client/src/pages/Views.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import { About, Login, Register, Lobby } from "pages";
import Landing from "pages/Landing/Landing";

import PageWrapper from "components/PageWrapper/PageWrapper";
import Profile from "./Profile/Profile";
import Chats from "./Chats/Chats";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./TermsOfService/TermsOfService";
import Tags from "./Tags/Tags";
import TagDetail from "./Tags/TagDetail/TagDetail";
import NewStory from "./NewStory/NewStory";
import EditStory from "./EditStory/EditStory"; // Import EditStory component

import StoryDetail from "./StoryDetail/StoryDetail";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import Stories from "./Stories/Stories";
import VerifyEmailSuccess from "./EmailVerification/VerifyEmailSuccess";
import VerifyEmailFailure from "./EmailVerification/VerifyEmailFailure";
import LifeExperiences from "./LifeExperiences/LifeExperiences";

import AdminDashboard from "./AdminDashboard/AdminDashboard";

import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import AccountStatus from "./AccountStatus/AccountStatus";
import VerifyEmailRequired from "./EmailVerification/VerifyEmailRequired";

// Comment out the Feedback import
// import Feedback from "./Feedback/Feedback";
import Rules from "./Rules/Rules";
import Reactivate from "./Reactivate/Reactivate";
import FindOthers from "./FindOthers/FindOthers";
import Write from "./Write/Write";

const Views = () => {
  return (
    <Routes>
      <Route path="/verify-email/success" element={<VerifyEmailSuccess />} />
      <Route path="/verify-email/failure" element={<VerifyEmailFailure />} />
      <Route
        path="/about"
        element={
          <PageWrapper>
            <About />
          </PageWrapper>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/rules" element={<Rules />} />
      {/* <Route path="/feedback" element={<Feedback />} /> */}

      <Route
        path="/"
        element={
          <PageWrapper ticker footer>
            <Landing />
          </PageWrapper>
        }
      />

      <Route element={<ProtectedRoutes loggedInOnly isAdmin redirectTo="/" />}>
        <Route path="/dashboard" element={<AdminDashboard />} />
      </Route>

      <Route element={<ProtectedRoutes loggedInOnly redirectTo="/" />}>
        <Route path="/register" element={<Register />} />
      </Route>

      <Route element={<ProtectedRoutes loggedInOnly registeredOnly />}>
        <Route path="/verify-email" element={<VerifyEmailRequired />} />
      </Route>

      <Route
        element={
          <ProtectedRoutes loggedInOnly registeredOnly emailVerifiedOnly />
        }
      >
        <Route
          path="/reactivate"
          element={
            <PageWrapper>
              <ErrorBoundary>
                <Reactivate />
              </ErrorBoundary>
            </PageWrapper>
          }
        />
        <Route
          path="/account-status"
          element={
            <PageWrapper>
              <ErrorBoundary>
                <AccountStatus />
              </ErrorBoundary>
            </PageWrapper>
          }
        />
      </Route>

      <Route
        element={
          <ProtectedRoutes loggedInOnly registeredOnly activeOnly showOverlay />
        }
      >
        <Route path="/lobby" element={<Lobby />} />
        <Route
          path="/find"
          element={
            <PageWrapper>
              <FindOthers />
            </PageWrapper>
          }
        />
        {/* <Route
          path="/experiences"
          element={
            <PageWrapper>
              <LifeExperiences />
            </PageWrapper>
          }
        /> */}
        <Route
          path="/user/:username"
          element={
            <ErrorBoundary>
              <Profile />{" "}
            </ErrorBoundary>
          }
        >
          <Route path="experiences" element={<Profile />} />
          <Route path="stories" element={<Profile />} />
          <Route path="quotes" element={<Profile />} />
          <Route path="reviews" element={<Profile />} />
          <Route path="settings" element={<Profile />} />
        </Route>

        <Route
          path="/chats"
          element={
            <ErrorBoundary>
              <PageWrapper>
                <Chats />
              </PageWrapper>
            </ErrorBoundary>
          }
        />
        {/* <Route
          path="/stories"
          element={
            <PageWrapper>
              <Stories />
            </PageWrapper>
          }
        /> */}
        <Route path="/stories/new" element={<NewStory />} />
        <Route
          path="/stories/:storyId"
          element={
            <ErrorBoundary>
              <PageWrapper>
                <StoryDetail />
              </PageWrapper>
            </ErrorBoundary>
          }
        />
        <Route
          path="/stories/:storyId/edit"
          element={
            <ErrorBoundary>
              <PageWrapper>
                <EditStory />
              </PageWrapper>
            </ErrorBoundary>
          }
        />
        <Route
          path="/tags"
          element={
            <PageWrapper ticker>
              <Tags />
            </PageWrapper>
          }
        />
        <Route
          path="/tags/:tagName"
          element={
            <PageWrapper ticker>
              <ErrorBoundary>
                <TagDetail />
              </ErrorBoundary>
            </PageWrapper>
          }
        />
        <Route
          path="/write"
          element={
            <PageWrapper>
              <Write />
            </PageWrapper>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          <PageWrapper>
            <NotFoundPage />
          </PageWrapper>
        }
      />
    </Routes>
  );
};

export default Views;
