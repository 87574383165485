import ChatMessage from "./ChatMessage";
import * as typedefs from "typedefs";
/**
 * @typedef {import("classes/ChatMessage").default} ChatMessage
 * @typedef {typedefs.ChatRequestData} ChatRequestData
 * @typedef {typedefs.MessageData} MessageData
 */

/**
 * @implements {typedefs.ChatRequest}
 * @method update(newData) - Updates the ChatRequest instance with new data.
 * @method addMessage(messageData) - Adds a new message to the chat.
 * @method getOtherParticipant() - Retrieves the other participant in the chat by comparing the current user ID to the recipient and requester IDs.
 * @method getLastMessage() - Retrieves the most recent message in the chat.
 */
class ChatRequest {
  /**
   * @param {ChatRequestData} chatRequestData - The data used to initialize the ChatRequest instance.
   * @param {string} currentUserId - The ID of the current user.
   */
  constructor(chatRequestData, currentUserId) {
    /**
     * A map to store unique messages by their ID.
     * This will be used by processors to efficiently access messages.
     * @type {Map<string, ChatMessage>}
     * @private
     */
    this._messageMap = new Map();

    /**
     * The ID of the current user.
     * This will be used to identify the sender of messages.
     * @type {string}
     * @private
     */
    this._currentUserId = currentUserId;

    // Loop through all properties
    for (const [key, value] of Object.entries(chatRequestData)) {
      if (key === "messages") {
        this.messages = this._initializeMessages(value);
      } else {
        this[key] = value;
      }
    }
  }

  /**
   * Initializes an array of unique ChatMessage instances from the provided message data.
   *
   * This method processes the input array of message data, creating ChatMessage instances
   * for each unique message. It maintains a map to ensure that duplicate messages are not added.
   * The resulting array of unique messages is then sorted by their timestamp.
   *
   * @param {Array<ChatMessage>} messages - An array of message data to be initialized.
   * @returns {Array<ChatMessage>} An array of unique ChatMessage instances sorted by timestamp.
   */
  _initializeMessages(messages = []) {
    const uniqueMessages = [];
    messages.forEach((messageData) => {
      const message = new ChatMessage(messageData, this._currentUserId);
      if (!this._messageMap.has(message._id)) {
        this._messageMap.set(message._id, message);
        uniqueMessages.push(message);
      }
    });
    const sortedMessages = this._sortMessages(uniqueMessages);
    return sortedMessages;
  }

  /**
   * Updates the Chat instance with new data.
   *
   * This method merges the provided newData into the current Chat instance,
   * updating its properties. If new messages are included in the newData,
   * they will be processed and added to the existing messages in the chat.
   *
   * @param {ChatRequestData} newData - The data used to update the ChatRequest instance.
   */
  update(newData) {
    for (const key in newData) {
      if (newData[key] !== undefined && newData[key] !== null) {
        if (key === "messages") {
          this._updateMessages(newData[key]);
        } else {
          this[key] = newData[key];
        }
      }
    }
  }

  /**
   * Updates the messages in the chat request with new messages.
   * @param {Array<MessageData>} newMessages - An array of new message data to be added to the chat request.
   */
  _updateMessages(newMessages) {
    const updatedMessages = [...this.messages];
    newMessages.forEach((messageData) => {
      const message = new ChatMessage(messageData, this._currentUserId);
      if (!this._messageMap.has(message._id)) {
        this._messageMap.set(message._id, message);
        updatedMessages.push(message);
      }
    });
    this.messages = this._sortMessages(updatedMessages);
  }

  /**
   * Adds a new message to the chat.
   * @param {MessageData} messageData - The data for the message to be added.
   * @returns {ChatMessage} The added message instance.
   */
  addMessage(messageData) {
    const message = new ChatMessage(messageData, this._currentUserId);

    if (!this._messageMap.has(message._id)) {
      this._messageMap.set(message._id, message);
      const updatedMessages = [...this.messages, message];
      this.messages = this._sortMessages(updatedMessages);
    }
    return message;
  }

  /**
   * Sorts an array of ChatMessage instances by their timestamp.
   * @param {Array<ChatMessage>} messages - The array of messages to be sorted.
   * @returns {Array<ChatMessage>} The sorted array of ChatMessage instances.
   */
  _sortMessages(messages) {
    const sortedMessages = messages.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
    return sortedMessages;
  }

  /**
   * Retrieves the other participant in the chat by comparing the current user ID
   * to the recipient and requester IDs.
   * @returns {{_id: string, username: string}|null} The other participant object containing _id and username, or null if not found.
   */
  getOtherParticipant() {
    if (this.recipient._id === this._currentUserId) {
      return this.requester;
    } else if (this.requester._id === this._currentUserId) {
      return this.recipient;
    }
    return null;
  }

  /**
   * Retrieves the most recent message in the chat.
   * @returns {ChatMessage|null} The most recent ChatMessage instance or null if no messages exist.
   */
  getLastMessage() {
    return this.messages.length > 0
      ? this.messages[this.messages.length - 1]
      : null;
  }
}

export default ChatRequest;
