/**
 * ToastContext provides a context for managing toast notifications in the application.
 * It allows components to add and remove toasts, which are displayed to the user.
/**
 * ToastContext provides a context for managing toast notifications in the application.
 * It allows components to add and remove toasts, which are displayed to the user.
 *
 * @component
 * @example
 * Start of Selection
 * This is the usage in App (initialization, where it's placed/anchored)
 *
 * <ToastNotifs toastList={toastList} removeToast={removeToast} />
 *
 * @example
 * import { useToastContext } from 'path/to/ToastContext';
 *
 * const MyComponent = () => {
 *   const { addToast } = useToastContext();
 *
 *   const handleClick = () => {
 *     addToast("This is a toast message!", "success");
 *   };
 *
 *   return <button onClick={handleClick}>Show Toast</button>;
 * };
 */

const { createContext, useState, useCallback, useContext } = require("react");

const ToastContext = createContext();

/**
 * Custom hook to use the ToastContext.
 * @returns {Object} The context value containing toastList, addToast, and removeToast.
 */
const useToastContext = () => {
  return useContext(ToastContext);
};

/**
 * ToastContextProvider component that wraps its children with the ToastContext provider.
 * It manages the state of toast notifications and provides functions to add and remove toasts.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children components to be wrapped.
 * @returns {JSX.Element} The provider component.
 */
const ToastContextProvider = ({ children }) => {
  const [toastList, setToastList] = useState([]);

  /**
   *
   * @param {string} props.message - The message to display in the toast.
   * @param {'info' | 'success' | 'orange' | 'error'} props.type - The type of toast, which determines its styling.
   */
  const addToast = useCallback((message, type = "info") => {
    if (typeof message !== "string") {
      console.warn("Warning: message must be a string.");
      return;
    }

    const id = `toast-${Date.now()}-${Math.random().toString(36).slice(2)}`;

    // Using Date.now() and Math.random() for a unique ID
    setToastList((prev) => {
      const newToastList = [...prev, { id, message, type }];
      return newToastList;
    });
  }, []);

  const removeToast = useCallback((id) => {
    setToastList((prev) => {
      const newToastList = prev.filter((toast) => toast.id !== id);
      return newToastList;
    });
  }, []); // Similarly, dependencies array is empty

  return (
    <ToastContext.Provider value={{ toastList, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContextProvider, ToastContext, useToastContext };
