// hooks/useChatRequest.js
import { useCallback, useEffect, useRef, useState } from "react";
import * as CHATREQUESTS_API from "api/chatRequests";
import ChatRequest from "classes/ChatRequest";
import Chat from "classes/Chat";
import { useSocketContext } from "contexts/SocketContext";

/**
 * Custom hook for managing chat requests.
 * This hook provides functionality to load, initialize, approve chat requests,
 * and handle updates when a chat request is approved.
 * It also sets up a listener for 'chatRequestApproved' events using the socket.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.userData - The user data object containing user information.
 * @param {Function} params.setChats - Function to update the chats state.
 * @param {Function} params.setChatRequests - Function to update the chat requests state.
 * @param {Function} params.addToast - Function to display toast notifications.
 * @param {Function} params.navigate - Function to navigate to different routes.
 *
 * @returns {Object} An object containing the loadChatRequests, fetchAndLoadChatRequests, initializeChatRequest, and approveChatRequest functions.
 *
 * @throws {ErrorResponse} Throws an error if the chat request fetching fails during initialization.
 */
export const useChatRequest = ({
  userData,
  setChats,
  setChatRequests,
  addToast,
  navigate,
}) => {
  const { socket } = useSocketContext();

  const [isLoadingChatRequests, setIsLoadingChatRequests] = useState(false);
  /**
   * A reference to track if chat requests are currently being loaded.
   * This ref holds a boolean value that indicates whether the loading process
   * for the user's chat requests is currently in progress. It prevents multiple simultaneous
   * loading requests for the same user.
   */
  const isLoadingChatRequestsRef = useRef(false);

  /**
   * Loads chat requests from the server and updates the state with new instances of ChatRequest.
   * This function ensures that any old messages are overwritten to prevent missing updates
   * between the current frontend state and the latest data from the server.
   * Previous messages can be fetched separately using the fetch more functionality.
   *
   * @param {Array<Object>} userChatRequests - An array of chat request objects received from the server.
   * @returns {void}
   */
  const loadChatRequests = useCallback(
    (userChatRequests) => {
      try {
        setIsLoadingChatRequests(true); // Set loading state to true
        if (userChatRequests.length > 0) {
          setChatRequests((prevChatRequests) => {
            const updatedChatRequests = { ...prevChatRequests };
            userChatRequests.forEach((request) => {
              // Overwrite any existing request with the new instance
              updatedChatRequests[request._id] = new ChatRequest(
                request,
                userData?._id
              );
            });
            return updatedChatRequests;
          });
        } else {
          setChatRequests({});
        }
      } catch (error) {
        console.error("Error loading chat requests:", error);
        setChatRequests({});
      } finally {
        setIsLoadingChatRequests(false); // Reset loading state
      }
    },
    [userData?._id, setChatRequests]
  );

  const fetchAndLoadChatRequests = useCallback(async () => {
    if (isLoadingChatRequestsRef.current) {
      return;
    }
    isLoadingChatRequestsRef.current = true;
    setIsLoadingChatRequests(true); // Set loading state to true
    try {
      const userChatRequests = await CHATREQUESTS_API.fetchChatRequests();
      loadChatRequests(userChatRequests);
    } catch (error) {
      console.error("Error fetching chat requests from server:", error);
      setChatRequests({});
    } finally {
      isLoadingChatRequestsRef.current = false;
      setIsLoadingChatRequests(false); // Reset loading state
    }
  }, [loadChatRequests, setChatRequests]);

  /**
   * A reference to track the initialization state of chat requests.
   * This Set holds chat request IDs that are currently being initialized to prevent
   * multiple simultaneous initialization requests for the same chat request.
   * It ensures that if an initialization request for a chat request is already in progress,
   * subsequent requests for that chat request will not initiate another fetch until the
   * first one completes.
   */
  const isInitializingChatRequestRef = useRef(new Set());

  /**
   * Initializes a specific chat request by its requestId.
   * This function fetches the chat request from the server and updates the local state.
   *
   * @param {string} requestId - The ID of the chat request to initialize.
   * @returns {Promise<boolean>} Returns a promise that resolves to true if the chat request is initialized successfully, false otherwise.
   *
   * @throws {ErrorResponse} Throws an error if the chat request fetching fails.
   */
  const initializeChatRequest = useCallback(
    async (requestId) => {
      try {
        if (isInitializingChatRequestRef.current.has(requestId)) {
          return false;
        }

        isInitializingChatRequestRef.current.add(requestId);

        const chatRequestData = await CHATREQUESTS_API.fetchChatRequestById(
          requestId
        );

        setChatRequests((prevChatRequests) => {
          if (prevChatRequests[requestId]) {
            prevChatRequests[requestId].update(chatRequestData);
            return { ...prevChatRequests };
          } else {
            const newChatRequestInstance = new ChatRequest(
              chatRequestData,
              userData._id
            );
            return {
              ...prevChatRequests,
              [requestId]: newChatRequestInstance,
            };
          }
        });

        return true;
      } catch (error) {
        console.error("Failed to fetch chat request", error);
        addToast(error.message, "error");
        if (error.redirect) {
          navigate(error.redirect);
        }
      } finally {
        isInitializingChatRequestRef.current.delete(requestId);
      }
    },
    [userData?._id, setChatRequests, addToast, navigate]
  );

  /**
   * Approves a chat request by calling the API and updating the state.
   * @param {string} chatRequestId - The ID of the chat request to approve.
   * @param {Object} options - Options for the approval process.
   * @param {boolean} options.redirect - If true, navigate to the new chat after approval.
   * @returns {Promise<Object>} The newly created chat object.
   * @throws {Error} Throws an error if the approval fails.
   */
  const approveChatRequest = useCallback(
    async (chatRequestId, options = {}) => {
      try {
        // Call the API to approve the chat request
        const { updatedChatRequest, newChat } =
          await CHATREQUESTS_API.approveChatRequest(chatRequestId);

        // Assuming the socket event will handle state updates
        // You can optionally update the state here if needed

        if (options.redirect) {
          navigate(`/chats?type=activeChats&id=${newChat._id}`); // Navigate to the new chat with query params if redirect is true
        }

        return newChat; // Return the newly created chat
      } catch (error) {
        console.error("Error approving chat request:", error.message);
        throw error; // Rethrow the error for handling in the component if needed
      }
    },
    [navigate]
  );

  /**
   * Handles the approval of a chat request.
   * This function updates the chat requests and chats state when a chat request is approved.
   *
   * @param {Object} params - The parameters for the function.
   * @param {ChatRequestData} params.approvedChatRequest - The approved chat request object.
   * @param {ChatData} params.newChat - The newly created chat object resulting from the approval.
   *
   * @returns {void}
   */
  const handleChatRequestApproved = useCallback(
    ({ approvedChatRequest, newChat }) => {
      // Create a new Chat instance
      const chatInstance = new Chat(newChat, userData._id);

      setChatRequests((prevRequests) => {
        const updatedRequests = { ...prevRequests };
        updatedRequests[approvedChatRequest._id].update({
          status: "approved",
          approvedChatId: newChat._id,
        });
        return updatedRequests;
      });

      setChats((prevChats) => {
        const updatedChats = { ...prevChats };
        updatedChats[chatInstance._id] = chatInstance; // Use the new Chat instance
        return updatedChats;
      });
    },
    [userData?._id, setChatRequests, setChats]
  );

  /**
   * Sets up a listener for chat requests.
   * This effect runs when the user ID is available and listens for 'chatRequestApproved' events from the socket.
   * It also cleans up the listener when the component unmounts or when the user ID changes.
   */
  useEffect(() => {
    if (userData?._id) {
      socket.on("chatRequestApproved", handleChatRequestApproved);

      return () => {
        socket.off("chatRequestApproved", handleChatRequestApproved);
      };
    } else {
    }
  }, [socket, userData?._id, handleChatRequestApproved]);

  useEffect(() => {
    try {
      if (socket) {
        socket.on("latestChatRequestData", (chatRequests) => {
          loadChatRequests(chatRequests); // Call loadChatRequests with the received data
        });

        return () => {
          socket.off("latestChatRequestData");
        };
      }
    } catch (error) {
      console.error(
        "Error setting up listener for latest chat request data:",
        error
      );
    }
  }, [socket, loadChatRequests]);

  return {
    loadChatRequests,
    fetchAndLoadChatRequests,
    initializeChatRequest,
    isLoadingChatRequests,
    approveChatRequest,
  };
};
