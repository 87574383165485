import React, { useEffect, useState } from "react";
import { PiHandHeart } from "react-icons/pi";
import styles from "./ReachOutButton.module.css"; // Import your CSS module
import classNames from "classnames"; // Import classnames

/**
 * ReachOutButton component allows users to indicate if they have reached out or not.
 *
 * @param {Object} props - The component props.
 * @param {boolean} [props.state=false] - The initial state of the button (reached out or not).
 * @param {function} [props.onClick] - Callback function to be called when the button is clicked.
 * @param {string} [props.className] - Additional class names to be applied to the button.
 *
 * @returns {JSX.Element} The rendered button component.
 */
const ReachOutButton = ({ state = false, onClick, className }) => {
  const [isReachedOut, setIsReachedOut] = useState(state);

  useEffect(() => {
    setIsReachedOut(state);
  }, [state]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={classNames(styles.reachOutButton, className, {
        [styles.reachedOut]: isReachedOut,
      })}
      onClick={handleClick}
    >
      <span className={styles.icon}>
        <PiHandHeart className={styles.reachOutIcon} />
      </span>
      {isReachedOut ? "Reached Out" : "Reach Out"}
    </button>
  );
};

export default ReachOutButton;
