import React, { useState } from "react";
import styles from "./ReviewModal.module.css";
import cn from "classnames";

// view is either "chat" or "profile"
const StarRating = ({ rating, setRating, totalStars = 5, disabled, view }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const handleClick = (value) => {
    setRating(value);
  };

  const handleMouseOver = (value) => {
    setHoverRating(value);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  // Determine the class based on the view prop
  const starRatingClass = view === "chat" ? styles.chatStarRating : styles.profileStarRating;

  if (!setRating || disabled) {
    return (
      <div
        className={cn(starRatingClass, styles.readOnly)}
      >
        {[...Array(totalStars)].map((_, index) => {
          const ratingValue = index + 1;

          return (
            <span
              key={index}
              className={cn(styles.star, {
                [styles.filled]: ratingValue <= (hoverRating || rating),
                [styles.hover]: ratingValue <= hoverRating,
              })}
            >
              &#9733;
            </span>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className={starRatingClass}>
        {[...Array(totalStars)].map((_, index) => {
          const ratingValue = index + 1;
          return (
            <span
              key={index}
              className={cn(styles.star, {
                [styles.filled]: ratingValue <= (hoverRating || rating),
                [styles.hover]: ratingValue <= hoverRating,
              })}
              onClick={() => handleClick(ratingValue)}
              onMouseOver={() => handleMouseOver(ratingValue)}
              onMouseLeave={handleMouseLeave}
            >
              &#9733;
            </span>
          );
        })}
      </div>
    );
  }
};

export default StarRating;
