// packages/client/src/components/Navbar/Navbar.jsx
import React, { useState, useRef, useCallback } from "react";
import { useNotificationContext } from "contexts/NotificationContext";
import { FaBell } from "react-icons/fa";
import {
  useFloating,
  offset,
  autoUpdate,
  useDismiss,
  useInteractions,
  flip,
  shift,
  size,
  autoPlacement,
} from "@floating-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { FloatingPortal } from "@floating-ui/react";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import NotificationItem from "components/Notifications/NotificationItem";
import styles from "./NotificationsDropdown.module.css";
import { IoClose } from "react-icons/io5";
import useMediaQuery from "hooks/useMediaQuery";

const NotificationItemWrapper = React.forwardRef(
  ({ notif, closeDropdown }, ref) => (
    <div ref={ref} className={styles.notificationItemWrapper}>
      <NotificationItem notif={notif} onClick={closeDropdown} />
    </div>
  )
);

NotificationItemWrapper.displayName = "NotificationItemWrapper";

function NotificationsDropdown() {
  const { notifications, fetchMoreNotifications, hasMore } =
    useNotificationContext();
  const [isOpen, setIsOpen] = useState(false);
  const observer = useRef();
  const isMobile = useMediaQuery("xs");

  // Match the successful NotificationsIcon floating configuration
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-end",
    middleware: [
      autoPlacement({
        padding: 10,
      }),
      shift(),
      offset(10),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  // Calculate unread notifications
  const unreadCount = notifications.filter((notif) => !notif.read).length;

  // Infinite scroll setup
  const lastNotificationRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreNotifications();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, fetchMoreNotifications]
  );

  return (
    <div className={styles.notificationContainer}>
      <button
        ref={refs.setReference}
        className={styles.notificationButton}
        onClick={() => setIsOpen(!isOpen)}
        {...getReferenceProps()}
      >
        <FaBell className={styles.bellIcon} />
        {unreadCount > 0 && <span className={styles.badge}>{unreadCount}</span>}
      </button>

      <AnimatePresence>
        {isOpen && (
          <FloatingPortal>
            <motion.div
              ref={refs.setFloating}
              style={!isMobile ? floatingStyles : undefined}
              className={styles.dropdown}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              {...getFloatingProps()}
            >
              <div className={styles.dropdownHeader}>
                <h3>Notifications</h3>
                {isMobile && (
                  <button
                    className={styles.closeButton}
                    onClick={() => setIsOpen(false)}
                    aria-label="Close notifications"
                  >
                    <IoClose size={24} />
                  </button>
                )}
                {!isMobile && unreadCount > 0 && (
                  <span className={styles.unreadCount}>{unreadCount} new</span>
                )}
              </div>

              <div className={styles.scrollableContent}>
                {notifications.length === 0 ? (
                  <div className={styles.emptyState}>
                    <p>No notifications yet</p>
                  </div>
                ) : (
                  notifications.map((notif, index) => (
                    <ErrorBoundary key={notif._id}>
                      <NotificationItemWrapper
                        notif={notif}
                        closeDropdown={() => setIsOpen(false)}
                        ref={
                          index === notifications.length - 1
                            ? lastNotificationRef
                            : null
                        }
                      />
                    </ErrorBoundary>
                  ))
                )}
              </div>

              <div className={styles.dropdownFooter}>
                <a href="/notifications" className={styles.viewAll}>
                  View All Notifications
                </a>
              </div>
            </motion.div>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </div>
  );
}

export default NotificationsDropdown;
