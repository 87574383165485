// packages/client/src/components/LifeExperience/StoryEditor.module.css
import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";

import styles from "./StoryEditor.module.css";

import cn from "classnames";

const StoryEditor = forwardRef(
  ({ maxWords: maxWordsInit, story, setStory, className }, ref) => {
    const textAreaRef = useRef(null);
    const [wordStatus, setWordStatus] = useState("valid"); // 'tooShort' | 'tooLong' | 'valid'
    const [wordCount, setWordCount] = useState(0);
    const maxWords = maxWordsInit || 25;
    const minWords = 15;
    const MAX_HEIGHT = 150;

    // Expose specific properties and methods to parent component
    useImperativeHandle(ref, () => textAreaRef.current);

    const handleInput = (event) => {
      const textArea = event.target;
      textArea.style.height = "auto"; // Reset the height
      textArea.style.height = `${Math.min(
        textArea.scrollHeight,
        MAX_HEIGHT
      )}px`; // Set to scrollHeight or MAX_HEIGHT
    };

    const handleEssayChange = (event) => {
      const text = event.target.value;
      const words = text.trim().split(/\s+/);
      const currentWordCount = text.trim() ? words.length : 0;

      setWordCount(currentWordCount);
      setStory(text);

      if (currentWordCount > maxWords) {
        setWordStatus("tooLong");
      } else if (currentWordCount > 0 && currentWordCount < minWords) {
        setWordStatus("tooShort");
      } else {
        setWordStatus("valid");
      }
    };

    const clearInput = () => {
      setStory("");
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto"; // Reset the height
      }
    };

    return (
      <div className={cn(styles.story, className)}>
        <div className={styles.textAreaContainer}>
          <textarea
            ref={textAreaRef}
            data-tour="story-editor"
            className={`${styles.essayTextArea} ${
              wordStatus === "tooLong" ? styles.textAreaError : ""
            }`}
            value={story}
            onChange={handleEssayChange}
            onInput={handleInput}
            placeholder="Share a specific life experience in 15-25 words. Be descriptive but concise..."
            rows={3}
            maxLength={1000}
          />
          {story && (
            <button
              className={styles["clear-btn"]}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                clearInput();
              }}
            >
              &times;
            </button>
          )}
        </div>

        <div className={styles.infoBar}>
          {wordStatus === "tooLong" && (
            <p className={cn(styles.helper, styles.error)}>
              Please keep your story under 25 words while maintaining key
              details
            </p>
          )}
          {wordStatus === "tooShort" && (
            <p className={cn(styles.helper, styles.warning)}>
              Add more details to make your story unique and searchable (minimum
              15 words)
            </p>
          )}
          <p
            className={`${styles.wordCount} ${
              wordStatus === "tooLong" ? styles.error : ""
            }`}
          >
            Word count: {wordCount} / {maxWords}
          </p>
        </div>
      </div>
    );
  }
);

export default StoryEditor;
