import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as API from "api/stories"; // Assume you have this API function
import useStoryCRUD from "hooks/useStoryCRUD";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import styles from "./StoryDetail.module.css";
import { UserContext } from "contexts/UserContext";
import { FaEllipsisH } from "react-icons/fa";
import { formatRelativeTime } from "utils/dates";
import HugButton from "components/Button/HugButton/HugButton"; // Import HugButton
import ReachOutButton from "components/Button/ReachOutButton/ReachOutButton"; // Import ReachOutButton
import ReachOutDialog from "components/ReachOutDialog/ReachOutDialog"; // Import ReachOutDialog
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu"; // Import ReusableActionMenu
import * as typedefs from "../../typedefs";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/ReusableTooltip/ReusableTooltip"; // Import Tooltip components
import { GiBilledCap } from "react-icons/gi"; // Import the anonymous icon
import ErrorDisplay from "components/ErrorDisplay/ErrorDisplay"; // Import ErrorDisplay
import { MdVerified } from "react-icons/md";
import { GiCampfire } from "react-icons/gi";
import ScoreMetadataDisplay from "components/ScoreMetadataDisplay/ScoreMetadataDisplay";
import ReportModal from "components/ReportModal/ReportModal";
import * as REPORTS_API from "api/reports";
import useStoryHug from "hooks/useStoryHug";

/**
 *
 * @typedef {typedefs.StoryData} StoryData
 */

function StoryDetail() {
  const { userData } = useContext(UserContext);
  const { storyId } = useParams();
  const navigate = useNavigate();
  const [story, setStory] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isReachedOut, setIsReachedOut] = useState(false);
  const [isReachOutDialogOpen, setIsReachOutDialogOpen] = useState(false);
  const { deleteStory, status, statusMessage } = useStoryCRUD();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const { isHugged, hugsLength, handleHugClick } = useStoryHug(story, userData);

  useEffect(() => {
    const loadStory = async () => {
      setIsLoading(true);
      try {
        const fetchedStory = await API.fetchStoryById(storyId);
        setStory(fetchedStory);
        setError("");
      } catch (error) {
        console.error("Failed to load story:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadStory();
  }, [storyId]);

  const isAuthor = userData?._id === story?.author?._id;

  const displayedName = useMemo(() => {
    if (!story) return ""; // Return an empty string or a placeholder if story is not loaded
    if (isAuthor) return story.author?.username;
    if (story.isConnected) return story.author?.username;
    if (story.author?.username === "anonymous") return story.authorPseudonym;
    return story.author?.username;
  }, [story, isAuthor]);

  const isHuggedByUser = useCallback(
    (story) => {
      if (!userData || !story.hugs) {
        return false;
      }
      return story.hugs.includes(userData._id);
    },
    [userData]
  );

  useEffect(() => {
    if (story && userData) {
      const isHugged = isHuggedByUser(story);
      setIsReachedOut(story.isReachedOut);
    }
  }, [story, userData, isHuggedByUser]);

  const handleReachOutClick = () => {
    setIsReachOutDialogOpen(true);
  };

  const handleReport = async (reportData) => {
    return REPORTS_API.reportStory({
      targetId: story._id,
      ...reportData,
    });
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <LoadingMessage message="Loading story details..." />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <ErrorDisplay message={error} onRetry={() => window.location.reload()} />
    );
  }

  if (!story) {
    return (
      <ErrorDisplay message="Story not found." onRetry={() => navigate("/")} />
    );
  }

  const handleDeleteStory = async () => {
    try {
      await deleteStory(story._id);
    } catch (error) {
      console.error("Error deleting story:", error);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    await handleDeleteStory();
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const authorOptions = [
    { label: "Edit", onClick: () => navigate(`/stories/${story._id}/edit`) },
    { label: "Hide", onClick: () => {} },
    { label: "Delete", onClick: handleDeleteClick },
  ];

  const nonAuthorOptions = [
    { label: "Save", onClick: () => {} },
    {
      label: "Report",
      onClick: () => setIsReportModalOpen(true),
      className: styles.reportOption,
    },
  ];

  const dropdownOptions = isAuthor ? authorOptions : nonAuthorOptions;

  // Function to render score icons
  const renderScoreIcons = (IconComponent, score, color) => {
    return Array.from({ length: 5 }, (_, index) => (
      <IconComponent
        key={index}
        style={{ color: index < score ? color : "lightgray" }}
      />
    ));
  };

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <ReusableActionMenu options={dropdownOptions} icon={FaEllipsisH} />
      </div>
      <h1 className={styles.title}>{story.title}</h1>
      <div className={styles.metadata}>
        <div className={styles.author}>
          {displayedName}
          {story.author?.username === "anonymous" && (
            <Tooltip>
              <TooltipTrigger>
                <div className={styles.anonymousIcon}>
                  <GiBilledCap />
                </div>
              </TooltipTrigger>
              <TooltipContent>This story was posted anonymously</TooltipContent>
            </Tooltip>
          )}
        </div>

        {story.author.scores && (
          <ScoreMetadataDisplay scores={story.author.scores} />
        )}

        <div className={styles.date}>{formatRelativeTime(story.createdAt)}</div>
        <div className={styles.tags}>
          {story.tags.map((tag) => (
            <span key={tag._id} className={styles.tagLabel}>
              {tag.name}
            </span>
          ))}
        </div>
      </div>
      <div className={styles.actions}>
        <HugButton
          state={isHugged}
          onClick={handleHugClick}
          hugCount={hugsLength}
        />
        {userData?._id !== story?.author?._id && (
          <>
            <ReachOutButton
              state={isReachedOut}
              onClick={handleReachOutClick}
            />
            <ReachOutDialog
              open={isReachOutDialogOpen}
              onOpenChange={setIsReachOutDialogOpen}
              type="Story"
              resource={story}
            />
          </>
        )}
      </div>
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: story.body }}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        itemName="story"
        status={status === "deleting" ? "loading" : status}
        statusMessage={statusMessage}
      />
      <ReportModal
        targetType="Story"
        targetId={story?._id}
        targetData={story}
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        onSubmit={handleReport}
      />
    </div>
  );
}

export default StoryDetail;
