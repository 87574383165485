import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./VerifyEmail.module.css";
import Cookies from "js-cookie";

const VerifyEmailSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectSource = Cookies.get("redirectSource");
    const verificationStatus = Cookies.get("verificationStatus");

    if (redirectSource !== "server" || verificationStatus !== "success") {
      navigate("/lobby");
    }

    // Cleanup function to clear cookies on unmount
    return () => {
      Cookies.remove("redirectSource");
      Cookies.remove("verificationStatus");
    };
  }, [navigate]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Email Verification Successful</h1>
      <p className={styles.message}>
        Your email has been successfully verified. You can now log in.
      </p>
    </div>
  );
};

export default VerifyEmailSuccess;
