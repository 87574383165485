const countryList = require("country-list");

const countriesWithThe = [
  "BS", // Bahamas
  "GM", // Gambia
  "NL", // Netherlands
  "PH", // Philippines
  "GB", // United Kingdom
  "US", // United States
];

const getFormattedCountryName = (countryCode) => {
  const countryName = countryList.getName(countryCode);
  if (!countryName) {
    return null;
  }
  if (countriesWithThe.includes(countryCode)) {
    return `the ${countryName}`;
  }
  return countryName;
};

module.exports = {
  getFormattedCountryName,
};
