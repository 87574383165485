import React from "react";
import { motion } from "framer-motion";
import styles from "./HugButton.module.css"; // Import your CSS module
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/ReusableTooltip/ReusableTooltip";
import { LuHeartHandshake } from "react-icons/lu";

/**
 * HugButton component that allows users to toggle a "hug" state.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.state=false] - The initial state of the hug button.
 * @param {function} [props.onClick] - Callback function to be called when the button is clicked.
 * @param {number} [props.hugCount=0] - The number of hugs.
 *
 * @returns {JSX.Element} The rendered HugButton component.
 */

const HugButton = ({ state = false, onClick, hugCount = 0 }) => {
  const handleClick = (e) => {
    e.stopPropagation(); // Handle stopPropagation at the UI level
    if (onClick) onClick(e);
  };

  return (
    <Tooltip>
      <TooltipTrigger>
        <motion.button
          className={`${styles.hugButton} ${state ? styles.hugged : ""}`}
          onClick={handleClick}
          whileTap={{ scale: 0.9 }}
        >
          <span className={styles.icon}>
            <LuHeartHandshake className={styles.hugIcon} />
          </span>
          {hugCount}
        </motion.button>
      </TooltipTrigger>
      <TooltipContent>{state ? "Unhug" : "Hug"}</TooltipContent>
    </Tooltip>
  );
};

export default HugButton;
