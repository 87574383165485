// packages/client/src/pages/Register/StepPersonalInfo.jsx
import React, { useState, useEffect, useRef } from "react";
import countryList from "country-list";

import { motion } from "framer-motion";
import cn from "classnames";
import common from "../Register.module.css";
import self from "./StepPersonalInfo.module.css";

import Combobox from "components/Combobox/Combobox";

import DatePickerInput from "components/DatePickerInput/DatePickerInput";
import JoyrideWrapper from "components/JoyrideWrapper/JoyrideWrapper";
import debounce from "lodash/debounce";
import { fetchAgeRanges } from "api/config";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { getFormattedCountryName } from "utils/location";

const steps = [
  {
    target: '[data-tour="birthdate-input"]',
    title: "Why Share Your Age?",
    content: (
      <div>
        <p>Age helps provide context to advice and experiences:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>✨ Get advice from people in similar life stages</li>
          <li>🔒 Only age range is shown (e.g., "20s", "30s")</li>
          <li>📅 Exact birthdate stays private</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: '[data-tour="gender-input"]',
    title: "Why Share Your Gender?",
    content: (
      <div>
        <p>Gender adds valuable perspective to conversations:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>👥 Connect with others who share similar experiences</li>
          <li>🤝 Get diverse viewpoints on your questions</li>
          <li>💡 Better understand advice context</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
  {
    target: '[data-tour="country-input"]',
    title: "Why Share Your Country?",
    content: (
      <div>
        <p>Cultural context matters in advice:</p>
        <ul
          style={{ marginTop: "8px", paddingLeft: "20px", textAlign: "start" }}
        >
          <li>🌍 Get culturally relevant perspectives</li>
          <li>🤔 Understand advice through cultural lens</li>
          <li>🎯 Receive more applicable suggestions</li>
        </ul>
      </div>
    ),
    disableBeacon: true,
    placement: "bottom",
  },
];

export const StepPersonalInfo = ({ onSave, data, handleStepValidity }) => {
  // Local state for inputs
  const [birthdate, setBirthdate] = useState(data.birthdate || null);
  const [gender, setGender] = useState(data.gender || "");
  const [location, setLocation] = useState(
    data.location || { name: "", code: "" }
  );

  // Update the parent state and perform validation
  const handleLocationChange = (selectedCountryName) => {
    if (selectedCountryName === null) {
      setLocation({ name: "", formattedName: "", code: "" });
    } else {
      const code = countryList.getCode(selectedCountryName);
      const formattedName = getFormattedCountryName(code);
      setLocation({ name: selectedCountryName, formattedName, code });
    }
  };

  const [age, setAge] = useState("");
  // State for validation errors
  const [errors, setErrors] = useState({
    birthdate: null,
    gender: null,
    location: null,
  });

  // Validate date of birth and age (must be 18 or older)
  const validateBirthdate = (dob) => {
    if (!dob) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        birthdate: "Date of birth is required.",
      }));
      return false;
    }

    // Calculate age
    const birthday = new Date(dob);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

    // If date of birth is valid and age is 18 or older
    setErrors((prevErrors) => ({ ...prevErrors, birthdate: null }));
    return true;
  };

  // Validate gender
  const validateGender = (gender) => {
    if (!gender) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        gender: "Gender is required.",
      }));
      return false;
    }
    setErrors((prevErrors) => ({ ...prevErrors, gender: null }));
    return true;
  };

  // Validate location
  const validateLocation = (location) => {
    if (!location.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        location: "Location is required.",
      }));
      return false;
    }
    setErrors((prevErrors) => ({ ...prevErrors, location: null }));
    return true;
  };

  // Update the parent state and perform validation
  const handleDateOfBirthChange = (date) => {
    setBirthdate(date);
  };

  // Create a debounced version of the function that checks form validity
  const checkFormValidity = debounce(() => {
    const isFormValid =
      validateBirthdate(birthdate) &&
      validateGender(gender) &&
      validateLocation(location);

    handleStepValidity(isFormValid);
  }, 500); // Adjust the debounce delay as needed

  useEffect(() => {
    // Call the debounced function in the effect
    checkFormValidity();

    // Cleanup function to cancel the debounced calls if the component unmounts
    return () => {
      checkFormValidity.cancel();
    };
  }, [birthdate, gender, location]);

  useEffect(() => {
    return () => {
      handleStepValidity(false, "reset");
    };
  }, []);

  // Effect to calculate age when date of birth changes
  useEffect(() => {
    if (birthdate) {
      const birthday = new Date(birthdate);
      const ageDifMs = Date.now() - birthday.getTime();
      const ageDate = new Date(ageDifMs);
      setAge(Math.abs(ageDate.getUTCFullYear() - 1970));
      // Update the parent state
      onSave({ birthdate: birthdate });
    }
  }, [birthdate, onSave]);

  // Update the parent state when gender or country changes
  useEffect(() => {
    onSave({ gender, location });
  }, [gender, location, onSave]);

  // Options for the gender select
  const genderOptions = ["Female", "Male", "Other"];

  // Get the list of countries from country-list
  const countryOptions = countryList.getData().map((country) => country.name); // Returns an array of { code: 'XX', name: 'CountryName' }

  const [animationStep, setAnimationStep] = useState(0);

  const variants = {
    hidden: { opacity: 0, pointerEvents: "none" },
    visible: { opacity: 1, pointerEvents: "auto" },
  };

  const [startTour, setStartTour] = useState(false);

  useEffect(() => {
    if (animationStep === 3) setStartTour(true);
  }, [animationStep]);

  const isFirstOpen = useRef(true); // Add this line to track the first open

  useEffect(() => {
    if (startTour) {
      isFirstOpen.current = false;
    }
  }, [startTour]);

  const [ageRanges, setAgeRanges] = useState(null);

  useEffect(() => {
    const loadAgeRanges = async () => {
      try {
        const ageRangesData = await fetchAgeRanges();
        setAgeRanges(ageRangesData);
      } catch (error) {
        // Handle the error appropriately
        console.error("Failed to load age ranges:", error);
      }
    };

    loadAgeRanges();
  }, []);

  const [ageRangeLabel, setAgeRangeLabel] = useState("");

  const [loading, setLoading] = useState(false);

  // Debounced function to calculate and set the age range label
  const calculateAgeRange = debounce(async (birthdate) => {
    setLoading(true); // Show loading message
    if (birthdate && ageRanges) {
      const birthday = new Date(birthdate);
      const ageDifMs = Date.now() - birthday.getTime();
      const ageDate = new Date(ageDifMs);
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);

      const range = ageRanges.find((r) => age >= r.min && age <= r.max);
      const label = range ? range.label : "Unknown";
      setAgeRangeLabel(label);
    }
    setLoading(false); // Hide loading message
  }, 500);

  useEffect(() => {
    calculateAgeRange(birthdate);
    // Cleanup function to cancel the debounced call if the component unmounts
    return () => {
      calculateAgeRange.cancel();
    };
  }, [birthdate, ageRanges]);

  return (
    <>
      {startTour && (
        <JoyrideWrapper steps={steps} runOnInit={isFirstOpen.current} />
      )}
      <div style={{ marginBottom: "10px" }}>
        <motion.div
          className={common.header}
          variants={variants}
          transition={{ ease: "easeIn", duration: 0.5 }}
          initial="hidden"
          animate={animationStep >= 0 ? "visible" : "hidden"}
          onAnimationComplete={() => {
            if (animationStep === 0)
              setTimeout(() => {
                setAnimationStep(1);
              }, 500);
          }}
        >
          <h1>Hi, {data.username}.</h1>
        </motion.div>
        <motion.div
          variants={variants}
          transition={{ ease: "easeIn", duration: 0.5 }}
          initial="hidden"
          animate={animationStep >= 1 ? "visible" : "hidden"}
          onAnimationComplete={() => {
            if (animationStep === 1) setAnimationStep(2);
          }}
        >
          <div className={cn(common["with-tooltip"])}>
            <span style={{ fontSize: "20px", textAlign: "center" }}>
              Help us find others like you.
            </span>
          </div>
        </motion.div>
      </div>
      <motion.div
        className={cn(common["overflow-wrapper"], self.container)}
        data-tour="form-body"
        variants={variants}
        transition={{ ease: "easeIn", duration: 0.5 }}
        initial="hidden"
        animate={animationStep >= 2 ? "visible" : "hidden"}
        onAnimationComplete={() => {
          if (animationStep === 2) setAnimationStep(3);
        }}
      >
        <div className={common["input-group"]} data-tour="birthdate-input">
          <label>Date of Birth</label>
          <DatePickerInput
            value={birthdate}
            onChange={handleDateOfBirthChange}
            className={common.input}
          />
          {birthdate && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className={self.ageRangeDisplay}
            >
              {loading ? (
                <LoadingMessage message="Calculating age range..." />
              ) : (
                `Your Age Range: ${ageRangeLabel}`
              )}
            </motion.div>
          )}
        </div>
        <div className={common["input-group"]} data-tour="gender-input">
          <label>Gender</label>
          <Combobox
            options={genderOptions}
            value={gender}
            onSelect={setGender}
            placeholder="Select Gender"
          />
        </div>
        <div
          className={cn(common["input-group"], self.countryInput)}
          data-tour="country-input"
        >
          <label>Country</label>
          <Combobox
            options={countryOptions}
            value={location.name}
            onSelect={handleLocationChange}
            placeholder="Select Country"
          />
        </div>
      </motion.div>
    </>
  );
};
