import CustomCombobox from "components/CustomCombobox/CustomCombobox";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import Portal from "components/Portal/Portal";
import React from "react";
import styles from "./DateSelector.module.css";

const months = [
  { value: "jan", label: "January", label_short: "Jan" },
  { value: "feb", label: "February", label_short: "Feb" },
  { value: "mar", label: "March", label_short: "Mar" },
  { value: "apr", label: "April", label_short: "Apr" },
  { value: "may", label: "May", label_short: "May" },
  { value: "jun", label: "June", label_short: "Jun" },
  { value: "jul", label: "July", label_short: "Jul" },
  { value: "aug", label: "August", label_short: "Aug" },
  { value: "sep", label: "September", label_short: "Sep" },
  { value: "oct", label: "October", label_short: "Oct" },
  { value: "nov", label: "November", label_short: "Nov" },
  { value: "dec", label: "December", label_short: "Dec" },
];

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 100 }, (_, i) => currentYear - i).map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));
};

const DateSelector = ({
  selectedMonth,
  setSelectedMonth,
  selectedYear,
  setSelectedYear,
  yearError,
  yearEditorRef,
}) => {
  const monthOptions = months.map((month, index) => ({
    value: index + 1,
    label: month.label,
  }));

  const yearOptions = generateYearOptions();

  const handleMonthSelect = (option) => {
    setSelectedMonth(option ? option.value : null);
  };

  const handleYearSelect = (option) => {
    setSelectedYear(option ? Number(option.value) : null);
  };

  const selectedMonthOption = monthOptions.find(
    (option) => option.value === selectedMonth
  );
  const selectedYearOption = yearOptions.find(
    (option) => Number(option.value) === selectedYear
  );

  return (
    <>
      <div className={styles.date}>
        <CustomCombobox
          isMulti={false}
          options={monthOptions}
          selected={selectedMonthOption}
          onSelect={handleMonthSelect}
          placeholder="Month"
        />
        <CustomCombobox
          isMulti={false}
          options={yearOptions}
          selected={selectedYearOption}
          onSelect={handleYearSelect}
          placeholder="Year"
          ref={yearEditorRef}
        />
      </div>
      <Portal>
        <FloatingTooltip
          anchorRef={yearEditorRef}
          show={!!yearError}
          placement="bottom-start"
        >
          {yearError}
        </FloatingTooltip>
      </Portal>
    </>
  );
};

export default DateSelector;
