import { UserContext } from "contexts/UserContext";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ChatWindow.module.css";
import cn from "classnames";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { formatRelativeTime } from "utils/dates";
import { selectLastMessageByReachoutId } from "store/selectors";
import { useSelector } from "react-redux";
import LastMessageDisplay from "./LastMessageDisplay";

/**
 * @typedef {Object} props
 * @property {Reachout} data - The reachout data.
 * @property {function} onClick - Optional click handler.
 * @property {boolean} isSelected - Indicates if the chat is selected.
 */
/**
 * @type {React.ForwardRefExoticComponent<props & React.RefAttributes<any>>}
 */
const ReachoutCard = React.forwardRef(({ data, onClick, isSelected }, ref) => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const lastMessage = useSelector((state) =>
    selectLastMessageByReachoutId(state, data._id)
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`/chats?type=reachouts&id=${data._id}`);
    }
  };

  // Identify the other participant
  const otherParticipant = data.isSender ? data.recipient : data.sender;

  // Determine the display name for the other participant
  const otherParticipantName =
    otherParticipant.username === "anonymous"
      ? data.isSender
        ? data.recipientPseudonym
        : data.senderPseudonym
      : otherParticipant.username;

  return (
    <li
      className={cn(styles.chatCard, { [styles.selected]: isSelected })}
      onClick={handleClick}
    >
      <div className={styles.cardHeader}>
        <span className={styles.participantName}>{otherParticipantName}</span>
        <span className={styles.relativeTime}>
          {formatRelativeTime(lastMessage?.createdAt || data.createdAt)}
        </span>
      </div>
      {loading ? (
        <LoadingMessage message="loading messages..." />
      ) : (
        <LastMessageDisplay
          lastMessage={lastMessage}
          currentUserId={userData._id}
          otherParticipantName={otherParticipantName}
        />
      )}
      <div className={styles.sharedTags}>
        {data.resourceData.label && (
          <div className={styles.tag}>{data.resourceData.label}</div>
        )}
      </div>
    </li>
  );
});

export default ReachoutCard;
