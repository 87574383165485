// packages/client/src/pages/Lobby/Lobby.jsx
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FloatingPortal, FloatingOverlay } from "@floating-ui/react";
import styles from "./MatchModal.module.css";
import Button from "components/Button/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { FaStar, FaHeart, FaUserFriends } from "react-icons/fa";

const MatchModal = ({
  matchedUser,
  handleAccept,
  handleReject,
  matchAccepted,
  matchedUserResponse,
  matchComplete,
  error,
  onClose,
  cleanupMatchState,
}) => {
  const navigate = useNavigate();

  const handleNavigateToChat = () => {
    cleanupMatchState();
    navigate(`/chats?type=matches&id=${matchComplete._id}`);
  };

  const renderScores = () => (
    <div className={styles.scores}>
      <div className={styles.scoreItem}>
        <FaStar className={styles.scoreIcon} />
        <div className={styles.scoreDetails}>
          <span className={styles.scoreLabel}>Trust Score</span>
          <div className={styles.scoreValue}>
            {matchedUser?.scores?.trustScore.toFixed(1)} / 5
          </div>
          <span className={styles.reviewCount}>
            {matchedUser?.scores?.totalReviews} reviews
          </span>
        </div>
      </div>
      <div className={styles.scoreItem}>
        <FaHeart className={styles.scoreIcon} />
        <div className={styles.scoreDetails}>
          <span className={styles.scoreLabel}>Warmth Score</span>
          <div className={styles.scoreValue}>
            {matchedUser?.scores?.warmthScore.toFixed(1)} / 5
          </div>
        </div>
      </div>
    </div>
  );

  const renderMatchInfo = () => (
    <div className={styles.matchInfo}>
      <div className={styles.userInfo}>
        <div className={styles.demographic}>
          <FaUserFriends className={styles.demographicIcon} />
          <span>
            {matchedUser?.ageRange} • {matchedUser?.gender}
          </span>
        </div>
        {renderScores()}
      </div>
      <div className={styles.story}>
        {matchedUser?.story ? (
          <p>{matchedUser.story}</p>
        ) : (
          <p className={styles.noStory}>No story provided</p>
        )}
      </div>
      <div className={styles.tags}>
        {matchedUser?.sharedTags?.length > 0 ? (
          matchedUser.sharedTags.map((tag, i) => (
            <motion.div
              key={i}
              className={styles.tag}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3 + i * 0.1 }}
            >
              {tag.name}
            </motion.div>
          ))
        ) : (
          <p className={styles.noTags}>No shared tags</p>
        )}
      </div>
    </div>
  );

  const renderContent = () => {
    if (matchComplete) {
      return (
        <motion.div className={styles.dialog} {...fadeAnimation}>
          <motion.p className={styles.header}>Match Complete!</motion.p>
          <motion.p>You can now start chatting with your match.</motion.p>
          <motion.div className={styles.buttonGroup}>
            <Button onClick={handleNavigateToChat}>Go to Chat</Button>
          </motion.div>
        </motion.div>
      );
    }

    // Handle error states
    if (error === "disconnected") {
      return (
        <motion.div className={styles.dialog} {...fadeAnimation}>
          <motion.p className={styles.header}>Match Disconnected</motion.p>
          <motion.p>Your match has disconnected. Please try again.</motion.p>
          <Button onClick={onClose}>Close</Button>
        </motion.div>
      );
    }

    if (error === "rejected") {
      return (
        <motion.div className={styles.dialog} {...fadeAnimation}>
          <motion.p className={styles.header}>Match Rejected</motion.p>
          <motion.p>
            Your match has rejected the connection. Please try again.
          </motion.p>
          <Button onClick={onClose}>Close</Button>
        </motion.div>
      );
    }

    // Handle waiting states
    if (matchAccepted) {
      return (
        <motion.div className={styles.dialog} {...fadeAnimation}>
          <motion.p className={styles.header}>Waiting for match...</motion.p>
          <LoadingSpinner />
          {matchedUser && (
            <motion.div className={styles.matchInfo}>
              {renderMatchInfo()}
            </motion.div>
          )}
        </motion.div>
      );
    }

    if (matchedUserResponse === "accepted") {
      return (
        <motion.div className={styles.dialog} {...fadeAnimation}>
          <motion.p className={styles.header}>Match Accepted!</motion.p>
          <motion.p>
            Your match has accepted. Waiting for you to accept...
          </motion.p>
          <motion.div className={styles.matchInfo}>
            {renderMatchInfo()}
          </motion.div>
          <motion.div className={styles.buttonGroup}>
            <Button onClick={handleAccept}>Accept</Button>
            <Button color="error" onClick={handleReject}>
              Reject
            </Button>
          </motion.div>
        </motion.div>
      );
    }

    // Default state - initial match found
    return (
      <motion.div className={styles.dialog} {...fadeAnimation}>
        <motion.p className={styles.header}>You've found a match!</motion.p>
        <motion.div className={styles.matchInfo}>
          {renderMatchInfo()}
        </motion.div>
        <motion.div className={styles.buttonGroup}>
          <Button onClick={handleAccept}>Accept</Button>
          <Button color="error" onClick={handleReject}>
            Reject
          </Button>
        </motion.div>
      </motion.div>
    );
  };

  const fadeAnimation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.2 },
  };

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={styles.overlay}>
        <AnimatePresence mode="wait">{renderContent()}</AnimatePresence>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default MatchModal;
