import api from "api";
import handleAxiosError from "./handleAxiosError";

/**
 * Creates a new reachout.
 * @param {Object} reachoutData - The data for the new reachout.
 * @param {string} reachoutData.resourceId - The ID of the resource associated with the reachout.
 * @param {string} reachoutData.resourceType - The type of the resource (either "LifeExperience" or "Story").
 * @param {string} reachoutData.message - The message to be sent with the reachout.
 * @returns {Promise<Object>} A promise that resolves to the created reachout object.
 * @throws {Error} Throws an error if the creation fails.
 */
export const createReachout = async (reachoutData) => {
  // Validate required parameters
  if (
    !reachoutData.resourceId ||
    !reachoutData.resourceType ||
    !reachoutData.message
  ) {
    throw new Error(
      "Missing required reachout data: resourceId, resourceType, message are required."
    );
  }

  try {
    const response = await api.post("/reachouts/", reachoutData);
    return response.data; // Return the created reachout data
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Accepts a reachout by its ID.
 * @param {string} reachoutId - The ID of the reachout to accept.
 * @returns {Promise<Object>} A promise that resolves to the updated reachout and chat objects.
 * @throws {Error} Throws an error if the acceptance fails.
 */
export const acceptReachout = async (reachoutId) => {
  try {
    const response = await api.patch(`/reachouts/${reachoutId}/accept`);
    return response.data; // Return the updated reachout and chat data
  } catch (error) {
    handleAxiosError(error);
  }
};
