import React, { useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./ToastNotif.module.css"; // Import your CSS module here
import { FaXmark } from "react-icons/fa6";

/**
 * ToastNotif component displays a notification toast.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The unique identifier for the toast.
 * @param {string} props.message - The message to display in the toast.
 * @param {'info' | 'success' | 'orange' | 'error'} props.type - The type of toast, which determines its styling.
 * @param {function} props.onClose - Callback function to call when the toast is closed.
 * @param {number} [props.duration=3000] - Duration in milliseconds before the toast automatically closes.
 */
const ToastNotif = ({ id, message, type, onClose, duration = 3000 }) => {
  // Automatically close the toast after a specified duration
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [id, duration, onClose]);

  // Animation variants
  const variants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  return (
    <motion.div
      key={id}
      className={`${styles.toast} ${styles[type]}`}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <span>{message}</span>
      <button
        onClick={() => {
          onClose();
        }}
        className={styles.closeButton}
      >
        <FaXmark />
      </button>
    </motion.div>
  );
};

export default ToastNotif;
