import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./LifeExperienceForm.module.css";
import TagEditor from "./TagEditor";
import Portal from "components/Portal/Portal";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import RecommendedTags from "pages/Lobby/RecommendedTags/RecommendedTags";
import DateSelector from "./DateSelector";
import StoryEditor from "./StoryEditor";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";
import { FaInfoCircle } from "react-icons/fa";
import ToggleButton from "components/Button/ToggleButton/ToggleButton";
import Button from "components/Button/Button";
import cn from "classnames";
import * as TAGS_API from "api/tags";
import { useTagsContext } from "contexts/TagsContext";
import _ from "lodash";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";

const LifeExperienceForm = ({
  lifeExperience,
  initialData,
  mode,
  onSave,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(initialData);
  }, [lifeExperience, initialData]);

  const [errors, setErrors] = useState({
    tagError: "",
    storyError: "",
    yearError: "",
  });

  const tagEditorRef = useRef(null);
  const storyEditorRef = useRef(null);
  const yearEditorRef = useRef(null);

  const handleTagChange = (tags) => {
    setFormData((prevData) => ({ ...prevData, tags }));
  };

  const handleStoryChange = (story) => {
    setFormData((prevData) => ({ ...prevData, story }));
  };

  const handleDateChange = (month, year) => {
    setFormData((prevData) => ({
      ...prevData,
      date: { month, year },
    }));
  };

  const handleToggleSearchable = () => {
    setFormData((prevData) => ({
      ...prevData,
      searchable: !prevData?.searchable,
    }));
  };

  const [recommendedTags, setRecommendedTags] = useState([]);
  const [isLoadingRecommendedTags, setIsLoadingRecommendedTags] =
    useState(false);

  const prevStoryRef = useRef(lifeExperience?.story);

  useEffect(() => {
    const fetchRecommendedTags = async (storyContent) => {
      if (!storyContent.trim()) {
        return;
      }

      setIsLoadingRecommendedTags(true);
      prevStoryRef.current = storyContent;
      try {
        const data = await TAGS_API.suggestTags({
          text: storyContent,
          limit: 3,
        });
        setRecommendedTags(data.tags);
      } catch (err) {
        setRecommendedTags([]);
      } finally {
        setIsLoadingRecommendedTags(false);
      }
    };

    const debouncedFetchRecommendedTags = _.debounce(fetchRecommendedTags, 500);

    if (formData?.story !== prevStoryRef.current) {
      debouncedFetchRecommendedTags(formData?.story);
    }

    return () => {
      debouncedFetchRecommendedTags.cancel(); // Cancel the debounce on cleanup
    };
  }, [formData]);

  const validateForm = () => {
    let hasError = false;
    const newErrors = {
      tagError: "",
      storyError: "",
      yearError: "",
    };

    if (!formData?.tags?.length) {
      newErrors.tagError = "Please select at least one tag.";
      hasError = true;
    }

    const wordCount = formData?.story?.trim()
      ? formData.story.trim().split(/\s+/).length
      : 0;
    if (!formData?.story?.trim()) {
      newErrors.storyError = "Please write a story.";
      hasError = true;
    } else if (wordCount < 15) {
      newErrors.storyError = "Story must be at least 15 words.";
      hasError = true;
    } else if (wordCount > 25) {
      newErrors.storyError = "Story must not exceed 25 words.";
      hasError = true;
    }

    if (!formData?.date?.year) {
      newErrors.yearError = "Please select a year.";
      hasError = true;
    }

    setErrors(newErrors);

    setTimeout(() => {
      setErrors({
        tagError: "",
        storyError: "",
        yearError: "",
      });
    }, 3000);

    return !hasError;
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave(formData);
    }
  };

  const handleCreate = () => {
    if (validateForm()) {
      onCreate(formData);
    }
  };

  const handleUpdate = () => {
    if (validateForm()) {
      onUpdate(formData);
    }
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    onDelete();
    setShowDeleteConfirmation(false);
  };

  // Handler to check if a tag is selected
  const isTagSelected = useCallback(
    (tag) => {
      return formData?.tags?.some((selectedTag) => selectedTag._id === tag._id);
    },
    [formData?.tags]
  );

  // Handler for single tag selection (used by RecommendedTags)
  const handleSingleTagSelect = useCallback(
    (tag) => {
      if (!isTagSelected(tag)) {
        setFormData((prevData) => ({
          ...prevData,
          tags: [...(prevData.tags || []), tag],
        }));
      }
    },
    [isTagSelected]
  );

  // Handler for tag array updates (used by TagEditor)
  const handleTagsChange = useCallback((tags) => {
    setFormData((prevData) => ({
      ...prevData,
      tags,
    }));
  }, []);

  return (
    <div className={cn(styles.container)} data-tour="editor">
      <p className={styles.heading}>Write your story...</p>
      <StoryEditor
        className={styles["story-editor"]}
        ref={storyEditorRef}
        error={errors.storyError}
        story={formData?.story}
        setStory={handleStoryChange}
      />
      <p className={styles.heading}>Choose a tag:</p>
      <TagEditor
        error={errors.tagError}
        ref={tagEditorRef}
        selected={formData?.tags}
        onSelect={handleTagsChange}
        className={styles["tag-editor"]}
        useTagsContext={useTagsContext}
      />
      <p className={styles.helper}>
        <a href="/tags" target="_blank">
          {" "}
          How do I find the right tag?{" "}
        </a>
      </p>
      {isLoadingRecommendedTags ? (
        <LoadingMessage
          message="Loading recommended tags..."
          className={styles.loading}
        />
      ) : (
        recommendedTags.length > 0 && (
          <RecommendedTags
            recommendedTags={recommendedTags}
            isLoading={isLoadingRecommendedTags}
            onSelect={handleSingleTagSelect}
            isTagSelected={isTagSelected}
            selected={formData?.tags}
          />
        )
      )}

      <p className={styles.heading}>When did you experience this?</p>
      <DateSelector
        selectedMonth={formData?.date?.month}
        setSelectedMonth={(month) =>
          handleDateChange(month, formData?.date?.year)
        }
        selectedYear={formData?.date?.year}
        setSelectedYear={(year) =>
          handleDateChange(formData?.date?.month, year)
        }
        yearError={errors.yearError}
        yearEditorRef={yearEditorRef}
      />

      <div className={styles.toggleButtonsContainer}>
        <div className={styles.toggleContainer}>
          <div className={styles.heading}>
            <div className={styles.labelWithIcon}>
              <span>Make this experience searchable?</span>
              <Tooltip>
                <TooltipTrigger>
                  <span>
                    <FaInfoCircle />
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  Searchable experiences can be found by other users when they
                  search using similar text strings via the /experiences page.
                </TooltipContent>
              </Tooltip>
            </div>
          </div>

          <ToggleButton
            isOn={formData?.searchable}
            handleToggle={handleToggleSearchable}
            styles={{ marginLeft: "auto" }}
          />
        </div>
      </div>
      {errors && (
        <Portal>
          <FloatingTooltip
            anchorRef={tagEditorRef}
            show={!!errors.tagError}
            placement="bottom-start"
          >
            {errors.tagError}
          </FloatingTooltip>
          <FloatingTooltip
            anchorRef={yearEditorRef}
            show={!!errors.yearError}
            placement="bottom-start"
          >
            {errors.yearError}
          </FloatingTooltip>
          <FloatingTooltip
            anchorRef={storyEditorRef}
            show={!!errors.storyError}
            placement="bottom-start"
          >
            {errors.storyError}
          </FloatingTooltip>
        </Portal>
      )}
      <div className={styles.actionMenu}>
        {mode === "register" && <Button onClick={handleSave}>Save</Button>}
        {mode === "new" && <Button onClick={handleCreate}>Create</Button>}
        {mode === "edit" && (
          <>
            <Button onClick={handleDeleteClick} color="error">
              Delete
            </Button>
            <Button onClick={handleUpdate}>Update</Button>
          </>
        )}
      </div>
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteConfirm}
          itemName="life experience"
        />
      )}
    </div>
  );
};

export default LifeExperienceForm;
