import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Chat from "pages/Chat/Chat";
import Reachout from "pages/Reachout/Reachout";
import Match from "pages/Match/Match";

import ChatCard from "./ChatCard";
import ReachoutCard from "./ReachoutCard";
import MatchCard from "./MatchCard";

import styles from "./ChatWindow.module.css";
import cn from "classnames";
import { useSelector } from "react-redux";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import {
  selectSortedChats,
  selectSortedMatches,
  selectSortedReachouts,
} from "store/selectors";

const ChatWindow = ({ initialTab, initialId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(initialTab || "chats");
  const [selectedChatId, setSelectedChatId] = useState(
    initialTab === "chats" ? initialId : null
  );
  const [selectedReachoutId, setSelectedReachoutId] = useState(
    initialTab === "reachouts" ? initialId : null
  );
  const [selectedMatchId, setSelectedMatchId] = useState(
    initialTab === "matches" ? initialId : null
  );
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [isInstanceOpen, setIsInstanceOpen] = useState(!isMobileView); // Always open if not mobile

  const sortedChats = useSelector(selectSortedChats);
  const sortedReachouts = useSelector(selectSortedReachouts);
  const sortedMatches = useSelector(selectSortedMatches);

  const isLoadingChats = useSelector((state) => state.chats.isLoading);
  const isLoadingReachouts = useSelector((state) => state.reachouts.isLoading);
  const isLoadingMatches = useSelector((state) => state.matches.isLoading);
  const isLoadingMessages = useSelector((state) => state.messages.isLoading);
  const loading =
    isLoadingChats ||
    isLoadingReachouts ||
    isLoadingMatches ||
    isLoadingMessages;

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobileView(isMobile);

      // Only auto-close/open if no chat is selected
      if (!selectedChatId && !selectedReachoutId && !selectedMatchId) {
        setIsInstanceOpen(!isMobile);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedChatId, selectedReachoutId, selectedMatchId]);

  useEffect(() => {
    const type = searchParams.get("type");
    const id = searchParams.get("id");

    if (type === "chats" && id) {
      setActiveTab("chats");
      setSelectedChatId(id);
      setSelectedReachoutId(null);
      setSelectedMatchId(null);
      setIsInstanceOpen(true);
    } else if (type === "reachouts" && id) {
      setActiveTab("reachouts");
      setSelectedChatId(null);
      setSelectedReachoutId(id);
      setSelectedMatchId(null);
      setIsInstanceOpen(true);
    } else if (type === "matches" && id) {
      setActiveTab("matches");
      setSelectedChatId(null);
      setSelectedReachoutId(null);
      setSelectedMatchId(id);
      setIsInstanceOpen(true);
    } else {
      setSelectedChatId(null);
      setSelectedReachoutId(null);
      setSelectedMatchId(null);
      setIsInstanceOpen(false);
    }
  }, [searchParams]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedChatId(null);
    setSelectedReachoutId(null);
    setSelectedMatchId(null);
    setIsInstanceOpen(false);
    setSearchParams({ type: tab });
  };

  const handleSelect = (type, id) => {
    if (type === "chats") {
      setSelectedChatId(id);
      setSelectedReachoutId(null);
      setSelectedMatchId(null);
    } else if (type === "reachouts") {
      setSelectedReachoutId(id);
      setSelectedChatId(null);
      setSelectedMatchId(null);
    } else if (type === "matches") {
      setSelectedMatchId(id);
      setSelectedChatId(null);
      setSelectedReachoutId(null);
    }
    setIsInstanceOpen(true);
    setSearchParams({ type, id });
  };

  const handleBackButtonClick = () => {
    setIsInstanceOpen(false);
    setSearchParams({});
  };

  return (
    <div className={styles.chatWindow}>
      {(!isMobileView || !isInstanceOpen) && (
        <>
          <div className={styles.tabs}>
            <div
              className={cn(styles.tab, {
                [styles.active]: activeTab === "chats",
              })}
              onClick={() => handleTabChange("chats")}
            >
              Active
            </div>
            <div
              className={cn(styles.tab, {
                [styles.active]: activeTab === "reachouts",
              })}
              onClick={() => handleTabChange("reachouts")}
            >
              Reachouts
            </div>
            <div
              className={cn(styles.tab, {
                [styles.active]: activeTab === "matches",
              })}
              onClick={() => handleTabChange("matches")}
            >
              Matches
            </div>
          </div>
          <div className={styles.list}>
            {loading && (
              <div className={styles.status}>
                <LoadingMessage message="Loading chats..." />
              </div>
            )}
            {activeTab === "chats" &&
              sortedChats.map((chat) => (
                <ChatCard
                  key={chat._id}
                  data={chat}
                  isSelected={chat._id === selectedChatId}
                  className={cn(styles.listItem, {
                    [styles.selected]: chat._id === selectedChatId,
                  })}
                  onClick={() => handleSelect("chats", chat._id)}
                />
              ))}
            {activeTab === "reachouts" &&
              sortedReachouts.map((reachout) => (
                <ReachoutCard
                  key={reachout._id}
                  data={reachout}
                  isSelected={reachout._id === selectedReachoutId}
                  className={cn(styles.listItem, {
                    [styles.selected]: reachout._id === selectedReachoutId,
                  })}
                  onClick={() => handleSelect("reachouts", reachout._id)}
                />
              ))}
            {activeTab === "matches" &&
              sortedMatches.map((match) => (
                <MatchCard
                  key={match._id}
                  data={match}
                  isSelected={match._id === selectedMatchId}
                  className={cn(styles.listItem, {
                    [styles.selected]: match._id === selectedMatchId,
                  })}
                  onClick={() => handleSelect("matches", match._id)}
                />
              ))}
          </div>
        </>
      )}
      {isInstanceOpen && (
        <div className={styles.chatInstance}>
          {activeTab === "chats" && selectedChatId && (
            <Chat onBack={handleBackButtonClick} />
          )}
          {activeTab === "reachouts" && selectedReachoutId && (
            <Reachout onBack={handleBackButtonClick} />
          )}
          {activeTab === "matches" && selectedMatchId && (
            <Match onBack={handleBackButtonClick} />
          )}
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
