import React, { useState } from "react";
import styles from "./Profile.module.css";
import { useUserContext } from "contexts/UserContext";

import Button from "components/Button/Button";
import SearchInput from "components/SearchInput/SearchInput";
import FilterPopover from "components/FilterPopover/FilterPopover"; // Import FilterPopover
import LoadingMessage from "components/LoadingSpinner/LoadingMessage"; // Import LoadingMessage

import StoryCard from "components/StoryCard/StoryCard";
import { FaPen } from "react-icons/fa";
import useMediaQuery from "hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { searchStories } from "api/stories"; // Import searchStories API

const comboboxOptions = [
  { value: "relevance", label: "Relevance" },
  { value: "new", label: "Newest" },
  { value: "hugs", label: "Most Hugs" },
];

const StoriesContent = ({
  stories,
  undiscoveredCount,
  profileData,
  onStoriesChange,
}) => {
  const { userData } = useUserContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({ sort: null, tags: [] });
  const [loading, setLoading] = useState(false); // State for loading
  const isSmallScreen = useMediaQuery("sm");
  const navigate = useNavigate();

  const handleSearch = async () => {
    setLoading(true); // Set loading to true before API call
    try {
      const searchParams = {
        query: searchQuery,
        userId: profileData._id,
        sort: filters.sort ? filters.sort.value : null,
        tags: filters.tags.map((tag) => tag._id),
      };
      const data = await searchStories(searchParams);

      onStoriesChange(data.stories, data.undiscoveredCount);
    } catch (error) {
      console.error("Error searching stories:", error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchInputClear = () => {
    setSearchQuery("");
    // Optionally, reset the stories to the original list here
  };

  const handleSearchInputEnter = () => {
    handleSearch();
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div className={styles.lifeExperiences}>
      <div className={styles.toolbar}>
        <SearchInput
          value={searchQuery}
          onChange={handleSearchInputChange}
          onClear={handleSearchInputClear}
          onEnter={handleSearchInputEnter}
          placeholder="Search stories..."
          className={styles.searchInput}
        />
        <FilterPopover
          filters={filters}
          onChange={handleFiltersChange}
          comboboxOptions={comboboxOptions}
          comboboxPlaceholder="Select sort option"
        />
        {profileData?._id === userData?._id && (
          <Button
            className={styles.newButton}
            color="primary"
            onClick={() => navigate("/stories/new")}
          >
            {isSmallScreen ? <FaPen /> : "New Story"}
          </Button>
        )}
      </div>
      {loading ? (
        <LoadingMessage message="Loading stories..." />
      ) : (
        <>
          {stories && stories.length > 0 ? (
            stories.map((story) => (
              <StoryCard key={story._id} story={story} userData={userData} />
            ))
          ) : (
            <div className={styles.noStories}>No stories available.</div>
          )}
        </>
      )}
      {undiscoveredCount > 0 && (
        <div className={styles.undiscoveredMessage}>
          <strong>{profileData.username}</strong> has {undiscoveredCount}
          {undiscoveredCount === 1 ? " story" : " more stories"} you haven’t yet
          <a href="/faq/connections"> connected on.</a> Keep chatting with them
          and maybe if they're comfortable enough, they will reveal more about
          themselves to you.
        </div>
      )}
    </div>
  );
};

export default StoriesContent;
