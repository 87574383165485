import api from "api";
import handleAxiosError from "./handleAxiosError";

/**
 * Searches for public life experiences based on a query.
 * @param {Object} params - The search parameters.
 * @param {string} params.query - The search query.
 * @param {number} [params.page=1] - The page number for pagination.
 * @param {number} [params.limit=10] - The number of results per page.
 * @param {string} [params.sort="relevance"] - The sorting method for results:
 *   - "relevance": Most relevant to search query (default)
 *   - "newest": Most recently created
 *   - "oldest": Oldest content first
 *   - "popular": Most overall engagement
 *   - "trending": Recently trending content
 * @param {string} [params.type] - The type of content to search for ('experiences', 'stories', or undefined for both).
 * @param {string} [params.tags] - Comma-separated list of tags to filter results.
 * @returns {Promise<Object>} A promise that resolves to the search results.
 */
export const unifiedContentSearch = async ({
  query,
  page = 1,
  limit = 10,
  sort = "relevance", // Default sort
  type,
  tags,
}) => {
  // Validate sort parameter against allowed values
  const validSorts = ["relevance", "newest", "oldest", "popular", "trending"];
  if (!validSorts.includes(sort)) {
    throw new Error(
      `Invalid sort value. Allowed values are: ${validSorts.join(", ")}`
    );
  }

  try {
    const response = await api.get("search", {
      params: { q: query, page, limit, sort, type, tags },
    });

    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
