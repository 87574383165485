import * as React from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingArrow,
  arrow,
  autoPlacement,
  size,
} from "@floating-ui/react";
import styles from "./ReusableTooltip.module.css";

import { AnimatePresence, motion } from "framer-motion"; // Import motion from framer-motion

/**
 * @param {Object} options - Options for the tooltip
 * @param {boolean} [options.initialOpen=false] - Whether the tooltip is initially open
 * @param {string} [options.placement="top"] - The placement of the tooltip
 * @param {boolean} [options.open] - Controlled open state of the tooltip
 * @param {function} [options.onOpenChange] - Function to handle open state change
 * @param {number} [options.delay=0] - Delay in seconds for tooltip interactions
 */
export function useTooltip({
  initialOpen = false,
  placement = "bottom",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  delay = 0,
} = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const arrowRef = React.useRef(null); // Create a ref for the arrow element

  const data = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      offset(15),
      flip({ fallbackPlacements: ["top", "left", "right"] }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            // minWidth: "200px",
            maxWidth: `${availableWidth}px`,
            // maxWidth: `50vw`,
            maxHeight: `50vh`,
          });
        },
      }),
      arrow({ element: arrowRef }), // Add arrow middleware

      // autoPlacement(),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
    delay: {
      open: delay * 1000,
      close: 0, // Customize close delay if needed
    },
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      delay, // Include delay in the return value
      arrowRef, // Include arrowRef in the return value
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data, arrowRef, delay]
  );
}

const TooltipContext = React.createContext(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

export function Tooltip({ children, ...options }) {
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}

export const TooltipTrigger = React.forwardRef(function TooltipTrigger(
  { children, asChild = false, ...props },
  propRef
) {
  const context = useTooltipContext();
  const childrenRef = children.ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      })
    );
  }
  return React.cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      "data-state": context.open ? "open" : "closed",
    })
  );
});

export const TooltipContent = React.forwardRef(function TooltipContent(
  { style, ...props },
  propRef
) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  // if (!context.open) return null;

  return (
    <AnimatePresence>
      {context.open && (
        <FloatingPortal>
          <motion.div
            key="tooltip"
            ref={ref}
            className={styles.tooltip}
            style={{
              ...context.floatingStyles,
              ...style,
              zIndex: "1000",
              // maxWidth: "60vw",
            }}
            initial={{ opacity: 0 }} // Initial state for fade-in
            animate={{ opacity: 1 }} // Animate to this state
            exit={{ opacity: 0 }} // Exit state for fade-out
            transition={{ duration: 0.1 }}
            {...context.getFloatingProps(props)}
          >
            {props.children}
            <FloatingArrow
              ref={context.arrowRef}
              context={context}
              fill="white"
              tipRadius="3"
            />
          </motion.div>
        </FloatingPortal>
      )}
    </AnimatePresence>
  );
});
