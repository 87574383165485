// packages/client/src/components/JoyrideFab/JoyrideFab.jsx
import React from "react";
import { IoHelpCircleOutline } from "react-icons/io5";
import styles from "./JoyrideFab.module.css";

const JoyrideFab = ({
  onClick,
  hasCompletedTour = false,
  "data-tour": dataTour,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick?.(e);
  };

  return (
    <button
      className={`${styles.fab} ${hasCompletedTour ? styles.completed : ""}`}
      onClick={handleClick}
      aria-label="Help tour"
      data-tour={dataTour}
      data-joyride="true"
    >
      <IoHelpCircleOutline className={styles.icon} />
    </button>
  );
};

export default JoyrideFab;
