// packages/client/src/pages/Login/Login.jsx
import React, { useContext } from "react";
import { motion } from "framer-motion";
import { GOOGLE_LOGIN_URL, UserContext } from "contexts/UserContext";
import styles from "./Login.module.css";
import googleIcon from "../../assets/google_icon.png";
import logo from "../../assets/logo_chair.png";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

const Login = () => {
  const { userData, loading, loadingMessage, handleGoogleLogin, handleLogout } =
    useContext(UserContext);

  return (
    <motion.div
      className={styles.overlay}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <div className={styles.loginContainer}>
        <div className={styles.header}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <h1 className={styles.headerText}>
            {userData ? "Log out" : "Log in to Stumble"}
          </h1>
        </div>

        {userData ? (
          <button
            onClick={handleLogout}
            className={styles.logoutButton}
            disabled={loading}
          >
            {loading ? (
              <>
                <LoadingSpinner />
                {loadingMessage}
              </>
            ) : (
              "Logout"
            )}
          </button>
        ) : (
          <>
            <button
              className={styles.googleBtn}
              onClick={handleGoogleLogin}
              disabled={loading}
            >
              {loading ? (
                <>
                  <LoadingSpinner />
                  {loadingMessage}
                </>
              ) : (
                <>
                  <img src={googleIcon} alt="Google Icon" />
                  Log in with Google
                </>
              )}
            </button>
            <hr className={styles.divider} />
            <div className={styles.signupContainer}>
              Don't have an account?{" "}
              <a className={styles.signupLink} href={GOOGLE_LOGIN_URL}>
                Sign up with Google
              </a>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default Login;
