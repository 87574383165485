import React, { useEffect, useState } from "react";
import styles from "./Landing.module.css";
import { motion } from "framer-motion";
import classNames from "classnames/bind";
import { ReactTyped } from "react-typed";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/ReusableTooltip/ReusableTooltip";

import PseudonymityIcon from "../../components/PseudonymityIcon";
import StumbleSprite from "components/StumbleSprite";
import NeuralNetworkIcon from "components/NeuralNetworkIcon";
import ScoringIcon from "components/ScoringIcon";
import { getPublicStats } from "api/public";

import PublicSearchSection from "./PublicSearchSection/PublicSearchSection";

const cn = classNames.bind(styles);

const FeatureCard = ({ title, description, icon: Icon }) => (
  <motion.div
    className={styles.featureCard}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    {Icon && (
      <div className={styles.iconContainer}>
        <Icon className={styles.featureIcon} fill="var(--chair-orange)" />
      </div>
    )}
    <h3>{title}</h3>
    <p>{description}</p>
  </motion.div>
);

const Landing = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeSection, setActiveSection] = useState("hero");
  const [stats, setStats] = useState({
    users_registered: 0,
    experiences_shared: 0,
    stories_written: 0,
    reachouts_sent: 0,
    matches_made: 0,
    connections_made: 0,
    great_conversations: 0,
  });

  useEffect(() => {
    setIsVisible(true);

    // Fetch stats when component mounts
    const loadStats = async () => {
      try {
        const { stats: statsData } = await getPublicStats();
        if (statsData) {
          setStats(statsData);
        }
      } catch (error) {
        console.error("Failed to load stats:", error);
      }
    };

    loadStats();
  }, []);

  const handleLearnMore = () => {
    setActiveSection("features");
    document.getElementById("features").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.container}>
      <section className={styles.hero}>
        <div className={styles.content}>
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className={styles.title}
          >
            You aren't alone.
          </motion.h1>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className={styles.subtitleContainer}
          >
            <p className={styles.subtitle}>Find someone who has</p>
            <div className={styles.dynamicText}>
              <ReactTyped
                strings={[
                  "lost a mom",
                  "struggled with cancer",
                  "cared for a sick loved one",
                  "been a victim of abuse",
                  "felt alone",
                  "battled depression",
                  "needed someone to talk to",
                ]}
                typeSpeed={40}
                backSpeed={30}
                backDelay={2000}
                loop
                className={styles.typedText}
              />
            </div>
          </motion.div>
        </div>

        <div className={styles.illustration}>
          <StumbleSprite
            fill="white"
            stroke="#FED030" // optional outline color
            className={cn("figure", "leftFigure", { visible: isVisible })}
          />
          <div className={cn("connectingLine", { visible: isVisible })} />
          <StumbleSprite
            fill="white"
            stroke="#FED030" // optional outline color
            className={cn("figure", "rightFigure", { visible: isVisible })}
          />
        </div>

        <PublicSearchSection />
        <motion.div
          className={styles.cta}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1.2 }}
        >
          <button
            className={styles.primaryButton}
            onClick={() => (window.location.href = "/login")}
          >
            Join Stumble
          </button>
          <button className={styles.secondaryButton} onClick={handleLearnMore}>
            Learn how it works
          </button>
        </motion.div>
      </section>

      <section id="features" className={styles.features}>
        <div className={styles.featureCards}>
          <FeatureCard
            icon={NeuralNetworkIcon}
            title="AI-powered matching"
            description="Use natural language processing to intuitively find others who share your experiences."
          />
          <FeatureCard
            icon={PseudonymityIcon}
            title="Pseudonymity for safe sharing"
            description="Open up and share your stories safely, with full control over when you reveal your identity on the site."
          />
          <FeatureCard
            icon={ScoringIcon}
            title="Trust and Warmth scores"
            description="Foster meaningful and positive connections with our unique accountability and trust-building system."
          />
        </div>
      </section>

      <motion.section
        className={styles.story}
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.8 }}
      >
        <div className={styles.sectionHeader}>
          <motion.h2
            className={styles.sectionTitle}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            Why I Built Stumble
          </motion.h2>
          <motion.p
            className={styles.sectionSubtitle}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            A personal note from the founder
          </motion.p>
        </div>
        <motion.div
          className={styles.storyContent}
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <p>
            I lost my Mom to cancer a few years ago. She was my best friend for
            everything. There is a law in physics that says energy is neither
            created nor destroyed, but rather it changes from one form to
            another. I feel the same applies to love. And I want to recreate the
            love that my mom created with me, but at scale.
          </p>
        </motion.div>
      </motion.section>

      <section className={styles.faq}>
        <div className={styles.sectionHeader}>
          <h2 className={styles.sectionTitle}>Frequently Asked Questions</h2>
          <p className={styles.sectionSubtitle}>
            Got questions? We've got answers.
          </p>
        </div>

        <div className={styles.faqContainer}>
          {[
            {
              category: "Privacy & Security",
              questions: [
                {
                  question: "How do you protect my privacy?",
                  answer:
                    "Your privacy comes first! We hash your email (convert it to unreadable code), use pseudonyms by default, and never reveal your identity unless you choose to. We recommend using a nickname that doesn't hint at who you are.",
                },
                {
                  question: "Can I delete my data?",
                  answer:
                    "Absolutely! Delete your account anytime to permanently remove all your data from our systems. Message deletion features are coming soon.",
                },
              ],
            },
            {
              category: "Matching & Connections",
              questions: [
                {
                  question: "How does matching work?",
                  answer:
                    "Connect in two ways: Join the lobby for immediate matching, or browse and respond to reachouts at your own pace. When matched, you'll start in an 'airlock' chat where you're both anonymous and can see each other's trust scores before deciding to connect.",
                },
                {
                  question: "How do you find similar experiences?",
                  answer:
                    "Our AI understands the context of your experiences, not just keywords. This means we can connect you with people who've been through similar situations, even if they describe them differently.",
                },
                {
                  question: "What if I'm not ready to chat?",
                  answer:
                    "No pressure! Browse reachouts and respond when you feel ready. Take all the time you need.",
                },
              ],
            },
            {
              category: "Community & Safety",
              questions: [
                {
                  question: "What happens if someone breaks the rules?",
                  answer:
                    "We act fast. Their account gets suspended immediately while we review what happened. Depending on the situation, they might get a warning or a permanent ban. Safety first!",
                },
                {
                  question: "What are Trust and Warmth scores?",
                  answer:
                    "Think of them as community reputation points. Trust scores show how reliable someone is, while Warmth scores reflect how supportive and empathetic they are in conversations.",
                },
                {
                  question: "Can I both give and receive support?",
                  answer:
                    "Of course! Many of our users do both. It's a great way to build meaningful connections and can be healing for everyone involved.",
                },
              ],
            },
            {
              category: "Getting Started",
              questions: [
                {
                  question: "Is Stumble free?",
                  answer:
                    "Yes! All core features are free. We believe everyone deserves access to understanding and support.",
                },
                {
                  question: "What kind of support will I find?",
                  answer:
                    "We're a peer support community, not professional counseling. While our members are caring and empathetic, we'll help you find professional help if you need it.",
                },
                {
                  question: "How do I get started?",
                  answer:
                    "Getting started is easy! Just sign up, create your profile, and begin exploring reachouts or the lobby. You're only a few clicks away from finding someone who truly understands.",
                },
              ],
            },
          ].map((category, categoryIndex) => (
            <div key={categoryIndex} className={styles.faqCategory}>
              <h3 className={styles.faqCategoryTitle}>{category.category}</h3>
              {category.questions.map((item, index) => (
                <div
                  key={index}
                  className={styles.faqItem}
                  onClick={() => {
                    const el = document.getElementById(
                      `faq-${categoryIndex}-${index}`
                    );
                    el.classList.toggle(styles.active);
                  }}
                >
                  <div className={styles.faqQuestion}>
                    <h4>{item.question}</h4>
                    <span className={styles.faqIcon}>+</span>
                  </div>
                  <div
                    id={`faq-${categoryIndex}-${index}`}
                    className={styles.faqAnswer}
                  >
                    <p>{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </section>

      <section className={styles.stats}>
        <div className={styles.statsContainer}>
          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statItem}>
                <span className={styles.statNumber}>
                  {stats.users_registered}
                </span>
                <span className={styles.statLabel}>Users Registered</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Total number of users who have created an account on Stumble
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statItem}>
                <span className={styles.statNumber}>
                  {stats.experiences_shared}
                </span>
                <span className={styles.statLabel}>Experiences Shared</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Total experiences shared in profiles and during matching
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statItem}>
                <span className={styles.statNumber}>
                  {stats.stories_written}
                </span>
                <span className={styles.statLabel}>Stories Written</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Personal stories shared by users in their profiles
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statItem}>
                <span className={styles.statNumber}>
                  {stats.reachouts_sent}
                </span>
                <span className={styles.statLabel}>Reachouts Sent</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Number of times users have reached out to connect with others
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statItem}>
                <span className={styles.statNumber}>{stats.matches_made}</span>
                <span className={styles.statLabel}>Matches Made</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Number of successful matches between users with similar
              experiences
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statItem}>
                <span className={styles.statNumber}>
                  {stats.connections_made}
                </span>
                <span className={styles.statLabel}>Connections Made</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Users who moved beyond the initial match to form a connection
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statItem}>
                <span className={styles.statNumber}>
                  {stats.great_conversations}
                </span>
                <span className={styles.statLabel}>Great Conversations</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Conversations rated 5 stars for both Trust and Warmth
            </TooltipContent>
          </Tooltip>

          <p className={styles.betaNote}>
            We're in beta, and these numbers reflect our journey so far. Join us
            in shaping the future of Stumble!
          </p>
        </div>
      </section>

      <section className={styles.betaCallout}>
        <h2>Help Shape Stumble's Future</h2>
        <p>
          As part of our beta, your voice matters. Share your experience, and
          help us build Stumble into a platform the world needs.
        </p>

        <div className={styles.feedbackEmail}>
          <p>Have suggestions or feedback?</p>
          <a href="mailto:feedback@stumble.chat" className={styles.emailLink}>
            feedback@stumble.chat
          </a>
        </div>

        <div className={styles.roadmap}>
          <h3>What we're building right now... 🏗️</h3>
          <ul>
            <li>Enhanced matching algorithms</li>
            <li>Improved lobby matching</li>
            <li>Improved messaging features</li>
          </ul>
        </div>
        <button className={styles.primaryButton}>Join the Beta</button>
      </section>
    </div>
  );
};

export default Landing;
