import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import styles from "./Reachout.module.css";
import { ChatContext, useChatContext } from "contexts/ChatContext";
import Button from "components/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";

import useMediaQuery from "hooks/useMediaQuery";

import cn from "classnames";

import {
  HiOutlineInformationCircle,
  HiOutlineRefresh,
  HiOutlineArrowLeft,
  HiCheckCircle,
  HiXCircle,
} from "react-icons/hi";
import ChatMessage from "components/ChatMessage/ChatMessage";
import Modal from "components/Modal/Modal";

import { useUserContext } from "contexts/UserContext";
import { ToastContext } from "contexts/ToastContext";

import { motion, AnimatePresence } from "framer-motion";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import formatGender from "utils/formatGender";
import { offset, useFloating, autoUpdate } from "@floating-ui/react";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import Portal from "components/Portal/Portal";

import * as typedefs from "../../typedefs";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptReachout,
  fetchMoreMessages,
  initMessages,
  initRoom,
} from "store/actions";
import {
  selectMessagesByReachoutId,
  selectReachoutById,
} from "store/selectors";
import * as USERS_API from "api/users";
import { useSocketContext } from "contexts/SocketContext";
import { FaArrowDown } from "react-icons/fa";
import ScoreMetadataDisplay from "components/ScoreMetadataDisplay/ScoreMetadataDisplay";
import { useCallContext } from "contexts/CallContext";
import ReportModal from "components/ReportModal/ReportModal";
import ChatInput from "components/ChatInput/ChatInput";

/**
 * @typedef {import("classes/ChatMessage").default} ChatMessage
 * @typedef {import("classes/ChatRequest").default} ChatRequest
 */

// Function to render score icons
const renderScoreIcons = (IconComponent, score, color) => {
  return Array.from({ length: 5 }, (_, index) => (
    <IconComponent
      key={index}
      style={{ color: index < score ? color : "lightgray" }}
    />
  ));
};

// UserProfile Component
const UserProfile = ({ otherParticipant }) => {
  const [loading, setLoading] = useState(false);

  const renderProfile = () => {
    return (
      <>
        <div className={styles.usernameContainer}>
          <h2 className={styles.username}>{otherParticipant?.username}</h2>
        </div>
        <div className={styles.profileDetails}>
          <span>{otherParticipant?.ageRange}</span> <span>·</span>
          <span>
            {otherParticipant?.gender
              ? formatGender(otherParticipant?.gender)
              : "Not set"}
          </span>
          <span>·</span>
          <span
            className={cn(styles.badge, {
              [styles.valid]: otherParticipant?.isEmailVerified,
              [styles.invalid]: !otherParticipant?.isEmailVerified,
            })}
          >
            {otherParticipant?.isEmailVerified ? (
              <HiCheckCircle />
            ) : (
              <HiXCircle />
            )}
            {otherParticipant?.isEmailVerified
              ? "Email Verified"
              : "Email Un-verified"}
          </span>
        </div>
        {otherParticipant?.scores && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ScoreMetadataDisplay scores={otherParticipant.scores} />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.profileContainer}>
      {loading ? (
        <div style={{ padding: "10px 0" }}>
          <LoadingSpinner />
        </div>
      ) : (
        renderProfile()
      )}
    </div>
  );
};

const SideMenu = ({
  openProfileModal,
  isSideMenuVisible,
  otherParticipant,
  reachoutData,
  handleReport,
  toggleSideMenu,
  isMobileView,
}) => {
  const { resourceType, resourceData } = reachoutData || {};
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const onReportClick = () => {
    setIsReportModalOpen(true);
  };

  const renderUserInfo = () => {
    if (!otherParticipant) return null;

    return (
      <div className={styles.sideMenuSection}>
        {isMobileView && (
          <div className={styles.mobileBackButton} onClick={toggleSideMenu}>
            <HiOutlineArrowLeft />
            <span>Back to chat</span>
          </div>
        )}
        <div className={styles.flexColCenter}>
          <p className={styles.sectionTitle}>You are chatting with:</p>
          <Button className={styles.userButton} onClick={openProfileModal}>
            {otherParticipant.username}
          </Button>
          <div className={styles.userMetadata}>
            <div className={styles.metadataItem}>
              <span>{otherParticipant.ageRange}</span>
            </div>
            <div className={styles.metadataItem}>
              <span>
                {otherParticipant.gender
                  ? formatGender(otherParticipant.gender)
                  : "Not set"}
              </span>
            </div>
            <div className={styles.metadataItem}>
              <span
                className={cn(styles.badge, {
                  [styles.valid]: otherParticipant.isEmailVerified,
                  [styles.invalid]: !otherParticipant.isEmailVerified,
                })}
              >
                {otherParticipant.isEmailVerified ? (
                  <HiCheckCircle />
                ) : (
                  <HiXCircle />
                )}
                {otherParticipant.isEmailVerified
                  ? "Email Verified"
                  : "Email Un-verified"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderResource = () => {
    if (!reachoutData || !resourceType) return null;

    let resourceLabel =
      resourceType === "LifeExperience" ? "Life Experience" : "Story";
    let resourceContent = resourceData?.label;

    const resourceCardContent =
      resourceType === "Story" ? (
        <a
          href={`/stories/${resourceData.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.resourceLink}
        >
          {resourceContent}
        </a>
      ) : (
        <div className={styles.data}>{resourceContent}</div>
      );

    return (
      <div className={styles.sideMenuSection}>
        <p className={styles.sectionTitle}>
          {resourceLabel} {reachoutData.isSender ? "you" : "they"} resonated
          with:
        </p>
        <div className={styles.resourceCard}>
          {resourceCardContent}
          <div className={styles.createdAt}>
            reached out on{" "}
            {new Date(reachoutData.createdAt).toLocaleDateString()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(styles.sideMenu, {
        [styles.sideMenuVisible]: isSideMenuVisible,
        [styles.sideMenuMobile]: isMobileView,
      })}
    >
      {renderUserInfo()}
      {renderResource()}

      <div className={cn(styles.sideMenuSection, styles.reportContainer)}>
        <Button
          onClick={onReportClick}
          variant="danger"
          className={styles.reportButton}
          size="small"
          shape="rounded"
        >
          Report Reachout
        </Button>
      </div>

      {isReportModalOpen && (
        <ReportModal
          targetType="Reachout"
          targetId={reachoutData._id}
          targetData={reachoutData}
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleReport}
        />
      )}
    </div>
  );
};

const Toolbar = ({
  toggleSideMenu,
  otherParticipant,
  openProfileModal,
  isMobileView,
  isSideMenuVisible,
  onBack,
}) => {
  return (
    <div className={styles.toolbar}>
      {isMobileView && (
        <div className={styles.backButton} onClick={onBack}>
          <HiOutlineArrowLeft />
        </div>
      )}
      {!(isMobileView && isSideMenuVisible) &&
        (otherParticipant?.username ? (
          <div className={styles.username} onClick={openProfileModal}>
            {otherParticipant.username}
          </div>
        ) : (
          <LoadingSpinner />
        ))}
      <div className={styles.buttonMenu}>
        <div className={styles.button} onClick={toggleSideMenu}>
          <HiOutlineInformationCircle />
        </div>
      </div>
    </div>
  );
};

const ReachoutBar = React.forwardRef((props, ref) => {
  const { userData, reachoutData } = props;
  const navigate = useNavigate();

  const handleAccept = () => {
    props.handleAccept();
  };

  const handleGoToChat = () => {
    if (reachoutData.status === "accepted") {
      navigate(`/chats?type=chats&id=${reachoutData.chatId}`);
    }
  };

  const renderSpecialStatus = () => {
    const statusClasses = cn(styles.statusMessage, {
      [styles.accepted]: reachoutData.status === "accepted",
      [styles.expired]: reachoutData.status === "expired",
      [styles.rejected]: reachoutData.status === "rejected",
      [styles.canceled]: reachoutData.status === "canceled",
    });

    switch (reachoutData.status) {
      case "accepted":
        return (
          <div className={statusClasses}>
            <span>This reachout has been accepted.</span>{" "}
            <span className={styles.link} onClick={handleGoToChat}>
              Go to chat
            </span>
          </div>
        );
      case "expired":
        return (
          <div className={statusClasses}>
            This reachout has expired. You can no longer accept it.
          </div>
        );
      case "rejected":
        return (
          <div className={statusClasses}>This reachout has been rejected.</div>
        );
      case "canceled":
        return (
          <div className={statusClasses}>This reachout has been canceled.</div>
        );
      default:
        return (
          <div className={statusClasses}>Status: {reachoutData.status}</div>
        );
    }
  };

  const recipientMessage = reachoutData?.isRecipient
    ? "Until you accept, neither participant can call, and notifications will be silenced."
    : `Until ${reachoutData?.recipientPseudonym} accepts, neither participant can call, and notifications will be silenced.`;

  return (
    <div className={styles.chatRequestBar} ref={ref}>
      {props.isAccepting ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {reachoutData.status === "pending" ? (
            <>
              <div className={styles.statusMessage}>{recipientMessage}</div>
              {reachoutData?.recipient?._id === userData._id && (
                <div className={styles.chatRequestActions}>
                  <Button
                    color="success"
                    onClick={handleAccept}
                    disabled={props.isAccepting}
                  >
                    Accept
                  </Button>
                  <Button color="error" onClick={props.handleReport}>
                    Report
                  </Button>
                  <Button color="gray" onClick={props.handleDelete}>
                    Delete
                  </Button>
                </div>
              )}
            </>
          ) : (
            renderSpecialStatus()
          )}
        </>
      )}
    </div>
  );
});

const Reachout = ({ onBack }) => {
  const { sendMessage } = useSocketContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const { userData } = useUserContext();
  const { cleanupChat } = useCallContext();
  const [searchParams] = useSearchParams();

  // --- State Hooks ---
  const [reachoutId, setReachoutId] = useState();

  const [newMessage, setNewMessage] = useState("");
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState();
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [retry, setRetry] = useState(false); // For retry logic

  // Error states
  const [errors, setErrors] = useState({
    initError: null,
    messageError: null,
    acceptError: null,
  });

  // Loading states
  const [loadingStates, setLoadingStates] = useState({
    isInitializingRoom: false,
    isLoadingMessages: false,
    isLoadingMoreMessages: false,
    isAcceptingReachout: false,
    isSendingMessage: false,
  });

  // Function to update specific loading states
  const setLoadingState = (key, value) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Function to update specific error states
  const setError = (key, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // --- Refs ---
  const [lastMessageRef, setLastMessageRef] = useState(null); // Making it state so its reactive
  const sentinelRef = useRef(null);
  const audioRef = useRef();

  const { refs, floatingStyles } = useFloating({
    open: isScrollButtonVisible,
    onOpenChange: setIsScrollButtonVisible,
    middleware: [offset(10)],
    placement: "top",
    whileElementsMounted: autoUpdate,
  });

  // --- Redux State Hooks ---
  const messages = useSelector((state) =>
    selectMessagesByReachoutId(state, reachoutId)
  );

  const reachoutData = useSelector((state) =>
    selectReachoutById(state, reachoutId)
  );

  // --- Fetch and Set Reachout Data ---
  useEffect(() => {
    const id = searchParams.get("id");
    const type = searchParams.get("type") || "reachouts";
    setReachoutId(id);
  }, [searchParams]);

  // --- Initialize Reachout ---
  useEffect(() => {
    const initializeReachout = async () => {
      if (reachoutId) {
        setLoadingState("isInitializingRoom", true);
        setError("initError", null);
        try {
          await dispatch(initRoom(reachoutId, "reachout"));
          setLoadingState("isLoadingMessages", true);
          await dispatch(initMessages(reachoutId, "reachout"));
          setError("initError", null); // Clear error on success
        } catch (err) {
          console.error("Error initializing reachout room:", err);

          // Display the error message as a toast notification
          addToast(err.message, "error");

          // Check if there's a redirect URL and navigate to it
          if (err.redirect) {
            navigate(err.redirect);
          } else {
            setError(
              "initError",
              "Failed to load reachout. Please try again later."
            );
          }
        } finally {
          setLoadingState("isInitializingRoom", false);
          setLoadingState("isLoadingMessages", false);
        }
      }
    };

    initializeReachout();
  }, [dispatch, reachoutId, retry, addToast, navigate]);

  // --- Handle Retry ---
  const handleRetry = () => {
    setRetry((prev) => !prev); // Toggle retry to re-trigger API calls
  };

  // Redirect if chatRequestData.status is approved
  useEffect(() => {
    if (reachoutData?.status === "approved") {
      navigate(`/chats?type=chats&id=${reachoutData.approvedChatId}`);
    }
  }, [reachoutData, navigate]);

  const isMobileView = useMediaQuery("sm");

  /**
   * @type {[ChatMessage, any]}
   */

  // --- Fetch Other Participant Data ---

  const [otherParticipant, setOtherParticipant] = useState();

  const isFetchingRef = useRef(false); // Track if the API call is ongoing

  useEffect(() => {
    const fetchOtherParticipant = async () => {
      if (isFetchingRef.current) return;

      if (reachoutData && Object.keys(reachoutData).length > 0 && userData) {
        isFetchingRef.current = true;

        const currentUserId = userData._id;
        const otherParticipant =
          reachoutData.recipient._id === currentUserId
            ? reachoutData.sender
            : reachoutData.recipient;

        try {
          const profile = await USERS_API.fetchPublicProfile(
            otherParticipant._id,
            { reachoutId: reachoutData._id }
          );

          // Determine the pseudonym based on whether the current user is the sender
          const pseudonym = reachoutData.isSender
            ? reachoutData.recipientPseudonym
            : reachoutData.senderPseudonym;

          // Merge the fetched profile with the pseudonym
          const profileWithPseudonym = {
            ...profile,
            username:
              profile.username === "anonymous" ? pseudonym : profile.username,
          };

          setOtherParticipant(profileWithPseudonym);
        } catch (error) {
          console.error("Error fetching profile:", error);
        } finally {
          isFetchingRef.current = false;
        }
      }
    };

    fetchOtherParticipant();
  }, [reachoutData, userData]);

  // --- Cleanup Chat on Unmount ---
  useEffect(() => {
    return () => cleanupChat();
  }, [cleanupChat]);

  // --- Load More Messages ---
  const isDebounced = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (!entry.isIntersecting) return;

        if (loadingStates.isLoadingMoreMessages) {
          return;
        }

        if (!hasMore) {
          setError(
            "messageError",
            "You've reached the end of the conversation."
          );
          return;
        }

        if (!isDebounced.current) {
          isDebounced.current = true;

          setLoadingState("isLoadingMoreMessages", true);
          setError("messageError", null);

          try {
            const firstMessage = messages[0];
            const firstMessageId = firstMessage?._id;

            if (firstMessageId) {
              const hasMore = await dispatch(
                fetchMoreMessages(reachoutId, "reachout", firstMessageId)
              );
              // Handle newMessages and update hasMore accordingly
              setHasMore(hasMore); // Update hasMore based on new messages
            }
          } catch (err) {
            console.error("Error loading more messages:", err);
            setError(
              "messageError",
              "Failed to load more messages. Please try again."
            );
          } finally {
            setLoadingState("isLoadingMoreMessages", false);
            isDebounced.current = false;
          }
        }
      },
      { threshold: 1.0 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [
    messages,
    hasMore,
    reachoutId,
    loadingStates.isLoadingMoreMessages,
    dispatch,
  ]);

  // --- Scroll Button Visibility ---
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        setIsScrollButtonVisible(!entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (lastMessageRef) {
      observer.observe(lastMessageRef);
    }

    return () => {
      if (lastMessageRef) {
        observer.unobserve(lastMessageRef);
      }
    };
  }, [messages, lastMessageRef]);

  // --- Message Input Handlers ---
  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (newMessage.trim()) {
        sendMessage(reachoutId, newMessage, "reachout");
        setNewMessage("");
      }
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      setLoadingState("isSendingMessage", true);
      setError("messageError", null);
      try {
        await sendMessage(reachoutId, newMessage, "reachout");
        setNewMessage("");
        setError("messageError", null); // Clear error on success
      } catch (error) {
        console.error("Error sending message:", error);
        setError("messageError", "Failed to send message. Please try again.");
      } finally {
        setLoadingState("isSendingMessage", false);
      }
    }
  };

  // --- Utility Functions ---
  const scrollToBottom = () => {
    if (lastMessageRef) {
      lastMessageRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleSideMenu = () => setIsSideMenuVisible((prev) => !prev);
  const handleLeaveChat = () => navigate("/lobby");

  const openProfileModal = () => setIsProfileModalVisible(true);
  const closeProfileModal = () => setIsProfileModalVisible(false);

  // --- Render Messages ---
  const renderMessages = () => {
    if (!messages?.length) return null;

    return messages.map((message, index) => (
      <ChatMessage
        key={`${reachoutId}:${message._id}`}
        message={message}
        otherParticipant={otherParticipant}
        ref={(el) => {
          if (index === messages.length - 1) {
            setLastMessageRef(el);
          }
        }}
      />
    ));
  };

  // --- Handle Reachout Acceptance ---
  const handleAccept = async () => {
    setLoadingState("isAcceptingReachout", true);
    setError("acceptError", null);
    try {
      const newChat = await dispatch(acceptReachout(reachoutId));

      setError("acceptError", null); // Clear error on success
      navigate(`/chats?type=chats&id=${newChat._id}`);
    } catch (error) {
      console.error("Error accepting Reachout:", error.message);
      setError("acceptError", "Failed to approve Reachout. Please try again.");
      addToast("Failed to approve Reachout", "error");
    } finally {
      setLoadingState("isAcceptingReachout", false);
    }
  };

  const handleReport = () => {
    // Implement report logic
  };

  const handleDelete = () => {
    // Implement delete logic
  };

  return (
    <>
      <div className={cn(styles.chat)}>
        <div
          className={cn(styles.chatContent, {
            [styles.chatContentContracted]: isSideMenuVisible,
          })}
        >
          <Toolbar
            toggleSideMenu={toggleSideMenu}
            otherParticipant={otherParticipant}
            openProfileModal={openProfileModal}
            isMobileView={isMobileView}
            isSideMenuVisible={isSideMenuVisible}
            onBack={onBack}
          />

          <div className={styles.messageContainer}>
            {loadingStates.isInitializingRoom ? (
              <div className={styles.status}>
                <LoadingMessage message="Initializing room..." />
              </div>
            ) : errors?.initError ? (
              <div className={styles.errorContainer}>
                <h2>{errors.initError}</h2>
                <button onClick={handleRetry}>Retry</button>
                <button onClick={() => navigate("/chats?type=reachouts")}>
                  Go Back to Reachouts
                </button>
              </div>
            ) : loadingStates.isLoadingMessages ||
              loadingStates.isLoadingOtherParticipant ? (
              <div className={styles.status}>
                <LoadingMessage message="Loading messages..." />
              </div>
            ) : (
              <>
                {renderMessages()}

                <AnimatePresence>
                  {isScrollButtonVisible && (
                    <Portal>
                      <motion.div
                        key="scroll-btn"
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, zIndex: "2" }}
                        className={styles.scrollToBottom}
                        onClick={scrollToBottom}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.1 }}
                      >
                        <FaArrowDown />
                      </motion.div>
                    </Portal>
                  )}
                </AnimatePresence>
              </>
            )}
          </div>
          <div ref={refs.setReference} style={{ width: "100%" }}></div>
          <ReachoutBar
            handleAccept={handleAccept}
            handleReport={handleReport}
            handleDelete={handleDelete}
            userData={userData}
            reachoutData={reachoutData}
            isAccepting={loadingStates.isAcceptingReachout}
            acceptError={errors.acceptError}
          />
          {reachoutData.status === "pending" && (
            <ChatInput
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              onEnterPress={onEnterPress}
              handleSendMessage={handleSendMessage}
              isSendingMessage={loadingStates.isSendingMessage}
            />
          )}
          <AnimatePresence>
            {isScrollButtonVisible && (
              <Portal>
                <motion.div
                  key="scroll-btn"
                  ref={refs.setFloating}
                  style={{ ...floatingStyles, zIndex: "1000" }}
                  className={styles.scrollToBottom}
                  onClick={scrollToBottom}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                >
                  <FaArrowDown />
                </motion.div>
              </Portal>
            )}
          </AnimatePresence>
        </div>
        <SideMenu
          isSideMenuVisible={isSideMenuVisible}
          handleLeaveChat={handleLeaveChat}
          otherParticipant={otherParticipant}
          reachoutData={reachoutData}
          openProfileModal={openProfileModal}
          handleReport={handleReport}
          toggleSideMenu={toggleSideMenu}
          isMobileView={isMobileView}
        />
      </div>

      <Modal
        isOpen={isProfileModalVisible}
        onClose={closeProfileModal}
        className={styles.userModalStyles}
      >
        <UserProfile otherParticipant={otherParticipant} />
      </Modal>
    </>
  );
};

export default Reachout;
