import DOMPurify from "dompurify";
import React, { forwardRef, useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./StoryCard.module.css";
import { formatRelativeTime } from "utils/dates";
import FloatingTooltip from "components/FloatingTooltip/FloatingTooltip";
import { hugStory } from "api/stories";
import HugButton from "components/Button/HugButton/HugButton";
import { convert } from "html-to-text";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { GiBilledCap } from "react-icons/gi";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";

import { PiPlugsConnectedFill, PiHandHeart } from "react-icons/pi";

import ScoreMetadataDisplay from "components/ScoreMetadataDisplay/ScoreMetadataDisplay";
import ReachOutDialog from "components/ReachOutDialog/ReachOutDialog";
import ReportModal from "components/ReportModal/ReportModal";
import { reportStory } from "api/reports";
import useStoryHug from "hooks/useStoryHug";

/**
 * StoryCard component displays a single story with its details.
 * It allows users to hug the story and view highlighted text.
 *
 * @typedef {Object} Props - The component props.
 * @property {Object} story - The story object containing details to display.
 * @property {Object} userData - The user data of the currently logged-in user.
 * @property {React.Ref} ref - The ref object forwarded to the component.
 * @returns {JSX.Element} The rendered StoryCard component.
 */
/**
 * @type {React.ForwardRefExoticComponent<Props & React.RefAttributes<unknown>>}
 * @description This component is responsible for rendering individual chat messages, including their content, sender information, and any associated metadata such as timestamps and shared tags.
 */
const StoryCard = forwardRef(({ story, userData }, ref) => {
  const { isHugged, hugsLength, handleHugClick } = useStoryHug(story, userData);

  const navigate = useNavigate();

  const isAuthor = story.author?._id === userData?._id; // Calculate isAuthor

  const [isReachOutDialogOpen, setIsReachOutDialogOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleReachOut = (e) => {
    setIsReachOutDialogOpen(true);
  };

  const handleReport = (e) => {
    setIsReportModalOpen(true);
  };

  const handleSubmitReport = async (reportData) => {
    return reportStory({
      targetId: story._id,
      ...reportData,
    });
  };

  const actionMenuOptions = isAuthor
    ? [
        {
          label: "Edit",
          onClick: () => {
            /* Implement edit functionality */
          },
        },
        {
          label: "Delete",
          onClick: () => {
            /* Implement delete functionality */
          },
        },
      ]
    : [
        {
          label: "Reach Out",
          onClick: handleReachOut,
        },
        {
          label: "Report",
          onClick: handleReport,
        },
      ];

  function stripHtml(html) {
    return convert(html, {
      wordwrap: false,
      selectors: [
        { selector: "a", format: "inline" },
        { selector: "img", format: "skip" },
      ],
    });
  }

  function constructHighlightedText(highlights) {
    const textSegments = [];

    highlights.forEach((highlight) => {
      highlight.texts.forEach((text) => {
        let stripped = stripHtml(text.value);
        if (text.type === "hit") {
          textSegments.push(`<mark>${stripped}</mark>`); // Add highlighted text to the array
        } else {
          textSegments.push(stripped); // Add non-highlighted text to the array
        }
      });
    });

    return textSegments.join(" "); // Join the array elements with a space delimiter
  }

  // Extract and strip HTML from text
  const textContent = story.highlights
    ? constructHighlightedText(story.highlights)
    : stripHtml(story.body);

  const safeHTML = DOMPurify.sanitize(textContent); // Sanitize the HTML

  // Handle card click to redirect to the story detail page
  const handleCardClick = () => {
    navigate(`/stories/${story._id}`);
  };

  const tooltipTimeout = useRef(null);

  const handleMouseEnter = () => {
    tooltipTimeout.current = setTimeout(() => {
      setShowTooltip(true);
    }, 500); // Delay in milliseconds
  };

  const handleMouseLeave = () => {
    clearTimeout(tooltipTimeout.current); // Clear the timeout if the mouse leaves
    setShowTooltip(false);
  };

  const relativeTimeRef = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);

  // Function to render score icons
  const renderScoreIcons = (IconComponent, score, color) => {
    return Array.from({ length: 5 }, (_, index) => (
      <IconComponent
        key={index}
        style={{ color: index < score ? color : "lightgray" }}
      />
    ));
  };

  return (
    <>
      <div ref={ref} className={styles.storyCard}>
        <div className={styles.storyCardInner} onClick={handleCardClick}>
          <h2 className={styles.title}>
            {story.title}
            {story.isReachedOut && (
              <Tooltip>
                <TooltipTrigger>
                  <div>
                    <PiHandHeart className={styles.reachedOutIcon} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  You've already reached out on this story
                </TooltipContent>
              </Tooltip>
            )}
            {story.isConnected && (
              <Tooltip>
                <TooltipTrigger>
                  <div>
                    <PiPlugsConnectedFill className={styles.connectedIcon} />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  You're already connected on this story
                </TooltipContent>
              </Tooltip>
            )}
          </h2>
          <div className={styles.actionMenu}>
            <ReusableActionMenu
              options={actionMenuOptions}
              icon={IoEllipsisHorizontal}
            />
          </div>
          <div className={styles.meta}>
            <span className={styles.author}>
              {story.author.username === "anonymous"
                ? story.authorPseudonym
                : story.author.username}
              {story.author.username === "anonymous" && (
                <Tooltip>
                  <TooltipTrigger>
                    <div>
                      <GiBilledCap className={styles.anonymousIcon} />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    This story was posted anonymously
                  </TooltipContent>
                </Tooltip>
              )}
            </span>
            {/* Add scores display */}
            {story.author.scores && (
              <ScoreMetadataDisplay scores={story.author.scores} />
            )}

            <span
              ref={relativeTimeRef}
              className={styles.relativeTime}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {formatRelativeTime(story.createdAt)}
              <FloatingTooltip
                anchorRef={relativeTimeRef}
                show={showTooltip}
                placement="top"
              >
                {new Date(story.createdAt).toLocaleString()}
              </FloatingTooltip>
            </span>
          </div>

          <div className={styles.previewText}>
            <p dangerouslySetInnerHTML={{ __html: safeHTML }} />
          </div>
          <div className={styles.tags}>
            {story.tags &&
              story.tags.map((tag) => (
                <span key={tag._id} className={styles.tagLabel}>
                  {tag.name}
                </span>
              ))}
          </div>
          <div className={styles.actionBar}>
            <HugButton
              state={isHugged}
              onClick={handleHugClick}
              hugCount={hugsLength}
            />
          </div>
          <div className={styles.hugsBar}></div>
        </div>
      </div>

      <ReachOutDialog
        open={isReachOutDialogOpen}
        onOpenChange={setIsReachOutDialogOpen}
        type="Story"
        resource={story}
      />

      {isReportModalOpen && (
        <ReportModal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleSubmitReport}
          targetType="Story"
          targetData={story}
          targetId={story._id}
        />
      )}
    </>
  );
});
export default StoryCard;
