import { publicApi } from "api";
import "../typedefs";
import handleAxiosError from "./handleAxiosError";

/**
 * Fetches public stats about the platform
 * @returns {Promise<StatsData>} A promise that resolves to stats data
 * @throws {Error} Throws an error if the stats cannot be fetched
 */
export const getPublicStats = async () => {
  try {
    const response = await publicApi.get("/stats");
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Searches public life experiences
 * @param {Object} params - Search parameters
 * @returns {Promise<Array<LifeExperienceData>>} A promise that resolves to an array of life experiences
 * @throws {Error} Throws an error if the search fails
 */
export const searchPublicLifeExperiences = async (params) => {
  try {
    const response = await publicApi.get("/life-experiences", { params });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
