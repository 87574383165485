// packages/client/src/pages/Register/StepReview.jsx
import React, { useState } from "react";
import common from "../Register.module.css";
import self from "./StepReview.module.css";
import ScrollWrapper from "components/ScrollWrapper/ScrollWrapper";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { BsCheckCircleFill } from "react-icons/bs";

import { motion } from "framer-motion";
import getAge from "utils/getAge";
import { formatMonthYear } from "utils/dates";

const fadeInVariants = {
  hidden: {
    opacity: 0,
    y: 10,
    pointerEvents: "none",
  },
  visible: {
    opacity: 1,
    y: 0,
    pointerEvents: "auto",
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    y: -10,
    pointerEvents: "none",
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.2,
    },
  },
};

const StepReview = ({ data, submissionStatus }) => {
  function getGender(val) {
    let gender = val.toLowerCase();
    if (gender === "male") {
      return "(M)";
    } else if (gender === "female") {
      return "(F)";
    } else {
      return "person";
    }
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
    >
      <div className={common.header}>
        <motion.h1 variants={fadeInVariants}>Review your information</motion.h1>
      </div>

      <div
        className={`${self.container} ${
          submissionStatus !== "idle" ? self.overlay : ""
        }`}
      >
        <motion.div className={self.section} variants={fadeInVariants}>
          <p style={{ textAlign: "center" }}>
            Your username is <span className={self.data}>{data.username}</span>
          </p>
        </motion.div>

        <motion.div className={self.section} variants={fadeInVariants}>
          <p>
            You're a <span className={self.data}>{getAge(data.birthdate)}</span>{" "}
            year old <span className={self.data}>{getGender(data.gender)}</span>{" "}
            from{" "}
            <span className={self.data}>{data.location.formattedName}</span>
          </p>
        </motion.div>

        <motion.div className={self.section} variants={fadeInVariants}>
          {data.lifeExperiences.length > 0 ? (
            <motion.div variants={containerVariants}>
              <p className={self.bold} style={{ marginBottom: "10px" }}>
                ...who has experienced
              </p>
              <div className={self.tagStoriesWrapper}>
                {data.lifeExperiences.map(({ tags, story, date }, i) => (
                  <motion.div
                    key={i}
                    className={self.experience}
                    variants={fadeInVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div className={self.experienceHeader}>
                      <div className={self.tags}>
                        {tags.map((tag) => (
                          <span key={tag._id} className={self.tag}>
                            {tag.name}
                          </span>
                        ))}
                      </div>
                      <span className={self.date}>
                        {formatMonthYear(date.month, date.year)}
                      </span>
                    </div>
                    <p className={self.story}>{story}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          ) : (
            <p style={{ marginBottom: "10px" }}>
              You haven't shared any stories yet...
            </p>
          )}
        </motion.div>

        {submissionStatus !== "idle" && (
          <motion.div
            className={self.statusOverlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {submissionStatus === "loading" && <LoadingSpinner size={40} />}
            {submissionStatus === "success" && (
              <BsCheckCircleFill size={40} className={self.successIcon} />
            )}
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default StepReview;
