import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  useFloating,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";
import styles from "./ReusableActionMenu.module.css";

const ReusableActionMenu = ({
  options,
  icon: Icon,
  theme = "light",
  buttonClassName = "",
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isDropdownOpen,
    onOpenChange: setIsDropdownOpen,
    placement: "bottom-end",
    middleware: [offset(1), flip(), shift()],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleDropdownToggle = (e) => {
    e.preventDefault(); // In case you place this inside a form, to prevent submitting it
    e.stopPropagation(); // Stop event propagation
    setIsDropdownOpen((prev) => !prev);
  };

  const handleMenuItemClick = (e, onClick) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div className={styles.dropdownContainer}>
      <button
        ref={refs.setReference}
        {...getReferenceProps()} // Order is important
        className={`${styles.dropdownButton} ${buttonClassName}`}
        onClick={handleDropdownToggle} // This will override getReferenceProps effect on onClick and ensure stopPropagation is adhered to
      >
        <Icon />
      </button>
      <AnimatePresence>
        {isDropdownOpen && (
          <motion.div
            ref={refs.setFloating}
            className={`${styles.dropdownMenu} ${styles[theme]}`}
            style={floatingStyles}
            {...getFloatingProps()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <div className={styles.dropdownHeader}>Actions</div>
            {options.map((option, index) => (
              <div
                key={index}
                className={styles.menuItem}
                onClick={(e) => handleMenuItemClick(e, option.onClick)}
              >
                {option.label}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ReusableActionMenu;
