import * as typedefs from "typedefs";
/**
 * @typedef {typedefs.ReachOut} ReachOut
 * @typedef {typedefs.ChatMessage} ChatMessage
 * @typedef {typedefs.MessageData} MessageData
 */

/**
 * @implements {typedefs.ChatMessage}
 * @description This represents MessageData that was processed by the ChatMessage class into a structure consumed by the frontend.
 */
class ChatMessage {
  /**
   * @param {MessageData} data - The message data used to initialize the ChatMessage instance.
   * @param {string} currentUserId - The ID of the current user as a string.
   */
  constructor(data, currentUserId) {
    // Initialize default values upfront
    this._id = data._id;
    this.text = "Message content unavailable"; // Default message
    this.sharedTags = []; // Default to no shared tags
    this.url = null; // Default to no URL
    this.senderId = data.sender.userId; // Assign senderId from data
    this.senderType = "otherUser"; // Default type, will be updated
    this.contentType = data.content.type; // New field to handle content type
    this.timestamp = new Date(data.createdAt) || new Date(); // Assign timestamp
    this.read = data.read || []; // Assign read status
    this.reachout = null; // Default to no reachout data

    if (data.content.data) {
      this.text = data.content.data.text || this.text;
      this.sharedTags = data.content.data.sharedTags || [];
      this.url = data.content.data.url || null;
      this.reachout = data.content.data.reachout || null;
    }

    // Determine the sender type based on senderId and currentUserId
    this.senderType = this.determineSenderType(
      data.sender.userId,
      currentUserId,
      data.sender.type
    );
  }

  determineSenderType(senderId, currentUserId, senderType) {
    if (senderType === "system") {
      return "system";
    } else if (senderId === currentUserId) {
      return "currentUser";
    }
    return "otherUser";
  }
}

export default ChatMessage;
