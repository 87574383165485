import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import * as TAGS_API from "../api/tags";
import _ from "lodash";
import { fetchTagSuggestions } from "api/users";
import { useUserContext } from "./UserContext";

const TagsContext = createContext();

export const useTagsContext = () => useContext(TagsContext);

export const TagsContextProvider = ({ children, isProfileContext = false }) => {
  const { userData } = useUserContext();
  const [tags, setTags] = useState([]);
  const [customTags, setCustomTags] = useState([]); // Separate state for custom tags
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState("");

  const fetchTags = useCallback(async (query = "", limit = 10, page = 1) => {
    try {
      setLoading(true);
      const data = await TAGS_API.searchTags({
        query,
        type: "autocomplete",
        limit,
        page,
      });
      setHasMore(data.tags.length > 0);
      setTags((prevTags) => {
        return [...new Set([...prevTags, ...data.tags])];
      });
      setLoading(false);
    } catch (err) {
      console.error("Error fetching tags:", err);
      setLoading(false);
    }
  }, []);

  const debouncedFetchTags = useCallback(_.debounce(fetchTags, 500), []);

  useEffect(() => {
    setTags([]);
    setPageNumber(1);
  }, [query]);

  useEffect(() => {
    debouncedFetchTags(query, 10, pageNumber);
  }, [query, pageNumber]);

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const addTag = (newTag) => {
    setCustomTags((prevCustomTags) => {
      return [...new Set([...prevCustomTags, newTag])];
    });
  };

  const createNewTag = (name) => {
    // Check if the tag already exists in either customTags or tags
    const tagExists = [...customTags, ...tags].some(
      (tag) => tag.name.toLowerCase() === name.toLowerCase()
    );
    if (tagExists) {
      console.warn(`Tag "${name}" already exists.`);
      return null;
    }

    const newTag = { name, _id: _.uniqueId("temp_"), status: "pending" };
    addTag(newTag);
    return newTag;
  };

  const loadCustomTags = useCallback(async () => {
    try {
      setLoading(true);
      if (userData?._id) {
        const tagSuggestions = await fetchTagSuggestions(userData._id);

        setCustomTags(tagSuggestions);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error loading custom tags:", error);
    }
  }, [userData?._id]);

  useEffect(() => {
    if (isProfileContext) {
      loadCustomTags();
    }
  }, [isProfileContext]);

  const [filteredCustomTags, setFilteredCustomTags] = useState([]);

  useEffect(() => {
    if (query.trim() === "") {
      setFilteredCustomTags(customTags);
    } else {
      const filtered = customTags.filter((tag) =>
        tag.name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredCustomTags(filtered);
    }
  }, [query, customTags]);

  // Merge custom tags with fetched tags
  const allTags = [...new Set([...tags, ...filteredCustomTags])];

  return (
    <TagsContext.Provider
      value={{
        tags: allTags,
        addTag,
        createNewTag,
        loading,
        setQuery,
        lastElementRef,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};
