import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "hooks/useMediaQuery";
import styles from "./FindOthers.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import { unifiedContentSearch } from "api/search"; // Updated import
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import StoryCard from "components/StoryCard/StoryCard";

function FindOthers() {
  const [input, setInput] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [trendingExperiences, setTrendingExperiences] = useState([]);
  const [trendingStories, setTrendingStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchResults, setSearchResults] = useState({
    experiences: [],
    stories: [],
    metadata: null,
  });
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const textareaRef = useRef(null);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("sm");
  const [activeFilter, setActiveFilter] = useState("all");
  const [isLifeExperienceModalOpen, setIsLifeExperienceModalOpen] =
    useState(false);

  // Fetch latest content on mount
  useEffect(() => {
    const fetchLatest = async () => {
      try {
        const results = await unifiedContentSearch({
          query: "",
          sort: "newest",
          limit: 3,
        });
        setTrendingExperiences(results.experiences.results || []);
        setTrendingStories(results.stories.results || []);
      } catch (error) {
        console.error("Failed to fetch latest content:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLatest();
  }, []);

  // Auto-resize textarea
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  const handleSearchChange = (event) => {
    setInput(event.target.value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setShowOptions(event.target.value.length > 0);
    }, 500);
  };

  const handleOptionClick = async (path) => {
    if (path === "browse") {
      if (input.trim().length === 0) {
        return;
      }
      setIsSearching(true);
      setIsLoading(true);
      setSearchError(null);
      try {
        const results = await unifiedContentSearch({
          query: input,
          sort: "relevance",
          limit: 10,
        });
        setSearchResults({
          experiences: results.experiences.results || [],
          stories: results.stories.results || [],
          metadata: results.metadata,
        });
      } catch (error) {
        console.error("Search failed:", error);
        setSearchError("Failed to fetch search results. Please try again.");
      } finally {
        setIsLoading(false);
        setShowOptions(false);
      }
    } else {
      switch (path) {
        case "match":
          navigate("/lobby", {
            state: { story: input },
          });
          break;
        case "share-experience":
          navigate("/write", {
            state: {
              openLifeExperience: true,
              initialStory: input,
            },
          });
          break;
        case "share-story":
          navigate("/stories/new", {
            state: { initialData: { body: input } },
          });
          break;
        default:
          break;
      }
    }
  };

  const handleConnect = (experienceId) => {
    navigate(`/experiences/${experienceId}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        {/* Search Section */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={styles.searchContainer}
        >
          <div className={styles.searchWrapper}>
            <textarea
              value={input}
              onChange={handleSearchChange}
              placeholder="How are you feeling? Describe it..."
              className={styles.searchInput}
              rows={1}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
              }}
            />
          </div>

          <AnimatePresence>
            {showOptions && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className={styles.optionsContainer}
              >
                <div className={styles.optionGroup}>
                  <h3>Connect Now</h3>
                  <button onClick={() => handleOptionClick("match")}>
                    Find someone to talk to right now
                  </button>
                </div>

                <div className={styles.optionGroup}>
                  <h3>Browse Stories</h3>
                  <button onClick={() => handleOptionClick("browse")}>
                    Find others who've shared similar experiences
                  </button>
                </div>

                <div className={styles.optionGroup}>
                  <h3>Share Your Story</h3>
                  <button onClick={() => handleOptionClick("share-experience")}>
                    Share a Life Experience
                  </button>
                  <button onClick={() => handleOptionClick("share-story")}>
                    Write a Story
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* Clear Search Button */}
        {isSearching && !isLoading && (
          <button
            className={styles.clearSearchButton}
            onClick={() => {
              setIsSearching(false);
              setSearchResults({
                experiences: [],
                stories: [],
                metadata: null,
              });
              setInput("");
            }}
          >
            Clear Search
          </button>
        )}

        {/* Search Results Section */}
        {isSearching && (
          <div className={styles.searchResults}>
            <h2>
              Search Results ({searchResults.metadata?.totalResults || 0})
            </h2>

            {/* Add Filter Toggle */}
            <div className={styles.filterToggle}>
              <button
                className={`${styles.filterButton} ${
                  activeFilter === "all" ? styles.active : ""
                }`}
                onClick={() => setActiveFilter("all")}
              >
                All
              </button>
              <button
                className={`${styles.filterButton} ${
                  activeFilter === "experiences" ? styles.active : ""
                }`}
                onClick={() => setActiveFilter("experiences")}
              >
                Life Experiences ({searchResults.experiences.length})
              </button>
              <button
                className={`${styles.filterButton} ${
                  activeFilter === "stories" ? styles.active : ""
                }`}
                onClick={() => setActiveFilter("stories")}
              >
                Stories ({searchResults.stories.length})
              </button>
            </div>

            {isLoading ? (
              <LoadingMessage message="Searching..." />
            ) : searchError ? (
              <p className={styles.errorMessage}>{searchError}</p>
            ) : searchResults.experiences.length > 0 ||
              searchResults.stories.length > 0 ? (
              <>
                {(activeFilter === "all" || activeFilter === "experiences") &&
                  searchResults.experiences.length > 0 && (
                    <div className={styles.resultsSection}>
                      <h3>Life Experiences</h3>
                      <div className={styles.resultsGrid}>
                        {searchResults.experiences.map((experience) => (
                          <LifeExperienceCard
                            key={experience._id}
                            lifeExpData={experience}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                {(activeFilter === "all" || activeFilter === "stories") &&
                  searchResults.stories.length > 0 && (
                    <div className={styles.resultsSection}>
                      <h3>Stories</h3>
                      <div className={styles.resultsGrid}>
                        {searchResults.stories.map((story) => (
                          <StoryCard key={story._id} story={story} />
                        ))}
                      </div>
                    </div>
                  )}
              </>
            ) : (
              <p>No results found.</p>
            )}
          </div>
        )}

        {/* Latest Content Section */}
        {!isSearching && (
          <div className={styles.latestSection}>
            <div className={styles.latestExperiences}>
              <h2>Latest Experiences</h2>
              {isLoading ? (
                <LoadingMessage message="Loading experiences..." />
              ) : trendingExperiences.length > 0 ? (
                <div className={styles.contentGrid}>
                  {trendingExperiences.map((exp) => (
                    <LifeExperienceCard key={exp._id} lifeExpData={exp} />
                  ))}
                </div>
              ) : (
                <p>No recent experiences found.</p>
              )}
            </div>

            <div className={styles.latestStories}>
              <h2>Latest Stories</h2>
              {isLoading ? (
                <LoadingMessage message="Loading stories..." />
              ) : trendingStories.length > 0 ? (
                <div className={styles.contentGrid}>
                  {trendingStories.map((story) => (
                    <StoryCard key={story._id} story={story} />
                  ))}
                </div>
              ) : (
                <p>No recent stories found.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FindOthers;
