import React from "react";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/ReusableTooltip/ReusableTooltip"; // Adjust import based on your tooltip library
import styles from "./ScoreMetadataDisplay.module.css";
import { MdVerified } from "react-icons/md";
import { GiCampfire } from "react-icons/gi";

const ScoreMetadataDisplay = ({ scores, isRenderTotalReviews = true }) => {
  if (!scores) return null;

  return (
    <div className={styles.scores}>
      <Tooltip>
        <TooltipTrigger>
          <div className={styles.scoreIcons}>
            {renderScoreIcons(MdVerified, scores.trustScore, "green")}
          </div>
        </TooltipTrigger>
        <TooltipContent>Trust Score: {scores.trustScore} / 5</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <div className={styles.scoreIcons}>
            {renderScoreIcons(GiCampfire, scores.warmthScore, "orange")}
          </div>
        </TooltipTrigger>
        <TooltipContent>Warmth Score: {scores.warmthScore} / 5</TooltipContent>
      </Tooltip>
      {isRenderTotalReviews && (
        <Tooltip>
          <TooltipTrigger>
            <span className={styles.totalReviews}>({scores.totalReviews})</span>
          </TooltipTrigger>
          <TooltipContent>Total Reviews: {scores.totalReviews}</TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};

const renderScoreIcons = (IconComponent, score, color) => {
  return Array.from({ length: 5 }, (_, index) => (
    <IconComponent
      key={index}
      style={{ color: index < score ? color : "lightgray" }}
    />
  ));
};

export default ScoreMetadataDisplay;
