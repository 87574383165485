import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  receiveMessage,
  setReachouts,
  receiveMatch,
  setMatches,
  setChats,
} from "../store/actions"; // Import the action
import { useToastContext } from "./ToastContext";
import socket from "socket";

const SocketContext = createContext();

/**
 * @typedef {Object} MatchedUserScores
 * @property {number} totalReviews - Total number of reviews received
 * @property {number} trustScore - User's trust score (0-5)
 * @property {number} warmthScore - User's warmth score (0-5)
 */

/**
 * @typedef {Object} SharedTag
 * @property {string} _id - Tag ID
 * @property {string} name - Tag name
 */

/**
 * @typedef {Object} MatchedUser
 * @property {string} userId - The matched user's ID
 * @property {string} story - The user's current story
 * @property {string} ageRange - Age range (e.g., "18-25", "26-35")
 * @property {'male' | 'female' | 'other'} gender - User's gender
 * @property {MatchedUserScores} scores - User's review scores
 * @property {SharedTag[]} sharedTags - Tags shared between matched users
 */

const SocketContextProvider = ({ children }) => {
  const [serverOnline, setServerOnline] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  /** @type {[MatchedUser | null, Function]} */
  const [matchedUser, setMatchedUser] = useState(null);
  const [matchAccepted, setMatchAccepted] = useState(false);
  const [matchedUserResponse, setMatchedUserResponse] = useState(null);
  const [error, setError] = useState(null);
  const [matchComplete, setMatchComplete] = useState(null); // { matchId, chatId }
  const dispatch = useDispatch(); // Initialize the dispatch function
  const navigate = useNavigate();
  const { addToast } = useToastContext();

  /**
   * Sends a message to the backend, which then emits it to the chat or reachout.
   * The message is processed by the listener above by both clients.
   *
   * @param {string} id - The ID of the chat or reachout.
   * @param {string} message - The message to be sent.
   * @param {string} [type="chat"] - The type of the message, either "chat" or "reachout".
   * @returns {void}
   */
  const sendMessage = useCallback((id, message, type = "chat") => {
    if (!["chat", "reachout", "match"].includes(type)) {
      console.error(
        `Invalid message type: ${type}. Must be either "chat", "reachout", or "match".`
      );
      return;
    }

    socket.emit("sendMessage", { id, message, type });
  }, []);

  /**
   * Starts a search for matches based on the provided criteria.
   *
   * @param {Object} params - The search parameters.
   * @param {Array<{_id: string, createdAt: string, description: string, matches: number, name: string, score: number, stories: number}>} params.tags - An array of tags to filter the search.
   * @param {string} params.story - The user's current story.
   * @param {Array<{min: number, max: number, label: string, description: string, value: string}>} params.ageRanges - An array of age ranges to filter the search.
   * @param {Array<{label: string, value: string}>} params.genders - An array of genders to filter the search.
   * @returns {void}
   */
  const startSearch = useCallback(
    ({ tags = [], story, ageRanges = [], genders = [] }) => {
      if (!serverOnline) {
        addToast("Cannot search while offline", "error");
        return;
      }

      socket.emit("startSearch", { tags, story, ageRanges, genders });
      setIsSearching(true);
    },
    [serverOnline, addToast]
  );

  const stopSearch = useCallback(() => {
    socket.emit("stopSearch");
    setIsSearching(false);
  }, []);

  useEffect(() => {
    const handleConnect = () => {
      setServerOnline(true);
      setLoading(false);
    };

    const handleDisconnect = () => {
      setServerOnline(false);
      setLoading(false);
      // Also stop searching if disconnected
      setIsSearching(false);
    };

    const handleConnectError = (error) => {
      setLoading(false);
      setServerOnline(false);
    };

    const handleNewMessage = (message) => {
      dispatch(receiveMessage(message)); // Dispatch the action when a new message is received
    };

    // New handler for reachoutAccepted event
    const handleReachoutAccepted = (reachout) => {
      dispatch(setReachouts([reachout])); // Use setReachouts action
    };

    // New handler for matchAccepted event
    const handleMatchAccepted = (updatedMatch) => {
      dispatch(setMatches([updatedMatch])); // Use setMatches action to update the match in store
    };

    // New handler for matchCompleted event
    const handleMatchCompleted = ({ match, chat }) => {
      dispatch(setMatches([match]));
      dispatch(setChats([chat]));
    };

    const handleAuthError = ({ message, status, redirect }) => {
      // Show error toast
      addToast(message, "error");

      // Redirect if specified
      if (redirect) {
        navigate(redirect);
      }
    };

    // Add search-related handlers
    const handleSearchError = (error) => {
      setIsSearching(false);
      addToast(error.message, "error");
    };

    const handleSearchMatchFound = (matchedUser) => {
      setMatchedUser(matchedUser);
      setIsSearching(false);
    };

    const handleSearchMatchAccepted = () => {
      setMatchedUserResponse("accepted");
    };

    const handleSearchMatchRejected = () => {
      setMatchedUser(null);
      setMatchedUserResponse(null);
      setMatchAccepted(false);
      setIsSearching(false);
      setError("rejected");
    };

    const handleSearchMatchDisconnected = () => {
      setMatchedUser(null);
      setMatchedUserResponse(null);
      setMatchAccepted(false);
      setIsSearching(false);
      setError("disconnected");
    };

    const handleSearchStopped = () => {
      setIsSearching(false);
    };

    // New handler for matchError event
    const handleSearchMatchError = ({ message }) => {
      setError(message);
      addToast(message, "error");
    };

    socket.connect();

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("connect_error", handleConnectError);
    socket.on("newMessage", handleNewMessage); // Add listener for new messages
    socket.on("reachoutAccepted", handleReachoutAccepted); // Add listener for reachoutAccepted
    socket.on("matchAccepted", handleMatchAccepted); // Add the new listener (this is for the update of an existing match, not the searching user matches below)
    socket.on("matchCompleted", handleMatchCompleted); // Add listener for matchCompleted
    socket.on("auth_error", handleAuthError);
    socket.on("searchError", handleSearchError);
    socket.on("searchMatchFound", handleSearchMatchFound);
    socket.on("searchMatchAccepted", handleSearchMatchAccepted);
    socket.on("searchMatchRejected", handleSearchMatchRejected);
    socket.on("searchMatchDisconnected", handleSearchMatchDisconnected);
    socket.on("searchStopped", handleSearchStopped);
    socket.on("searchMatchComplete", handleSearchMatchComplete);
    socket.on("searchMatchError", handleSearchMatchError); // Add listener for matchError

    // Clean up
    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("connect_error", handleConnectError);
      socket.off("newMessage", handleNewMessage); // Clean up the new message listener
      socket.off("reachoutAccepted", handleReachoutAccepted); // Clean up the reachoutAccepted listener
      socket.off("matchAccepted", handleMatchAccepted); // Clean up the new listener
      socket.off("matchCompleted", handleMatchCompleted); // Clean up the matchCompleted listener
      socket.off("auth_error", handleAuthError);
      socket.off("searchError", handleSearchError);
      socket.off("searchMatchFound", handleSearchMatchFound);
      socket.off("searchMatchAccepted", handleSearchMatchAccepted);
      socket.off("searchMatchRejected", handleSearchMatchRejected);
      socket.off("searchMatchDisconnected", handleSearchMatchDisconnected);
      socket.off("searchStopped", handleSearchStopped);
      socket.off("searchMatchComplete", handleSearchMatchComplete);
      socket.off("searchMatchError", handleSearchMatchError); // Clean up the matchError listener
      socket.disconnect();
    };
  }, [dispatch, addToast, navigate]); // Add dispatch to the dependency array

  const acceptMatch = useCallback(() => {
    setMatchAccepted(true);
    setIsSearching(false);
    socket.emit("acceptSearchMatch");
  }, [socket]);

  const rejectMatch = useCallback(() => {
    setMatchAccepted(false);
    setIsSearching(false);
    socket.emit("rejectSearchMatch");
    setMatchedUser(null);
  }, [socket]);

  // Add new cleanup function
  const cleanupMatchState = useCallback(() => {
    setMatchedUser(null);
    setMatchedUserResponse(null);
    setMatchAccepted(false);
    setError(null);
    setMatchComplete(null);
    setIsSearching(false);
  }, []);

  const handleSearchMatchComplete = useCallback(
    ({ matchData }) => {
      dispatch(receiveMatch(matchData));
      setMatchComplete(matchData);
    },
    [dispatch]
  );

  return (
    <SocketContext.Provider
      value={{
        socket,
        serverOnline,
        loading,
        sendMessage,
        isSearching,
        startSearch,
        stopSearch,
        matchedUser,
        matchAccepted,
        matchedUserResponse,
        error,
        acceptMatch,
        rejectMatch,
        cleanupMatchState,
        matchComplete,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

// Custom hook to use the SocketContext
const useSocketContext = () => {
  return useContext(SocketContext);
};

export { SocketContext, SocketContextProvider, useSocketContext };
