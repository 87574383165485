// store.js
import { configureStore } from "@reduxjs/toolkit";
import {
  SET_CHATS,
  SET_CHAT_REQUESTS,
  SET_REACHOUTS,
  SET_MESSAGES,
  RECEIVE_MESSAGE,
  FETCH_CHATS_START,
  FETCH_CHATS_ERROR,
  FETCH_CHAT_REQUESTS_START,
  FETCH_CHAT_REQUESTS_ERROR,
  FETCH_REACHOUTS_START,
  FETCH_REACHOUTS_ERROR,
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_ERROR,
  FETCH_MORE_MESSAGES_START,
  FETCH_MORE_MESSAGES_ERROR,
  SET_REVIEWS,
  FETCH_REVIEWS_START,
  FETCH_REVIEWS_ERROR,
  FETCH_MATCHES_START,
  SET_MATCHES,
  RECEIVE_MATCH,
  FETCH_MATCHES_ERROR,
} from "./actionTypes";

const initialState = {
  chats: {
    byId: {},
    allIds: [],
    isLoading: false,
    error: null,
  },
  chatRequests: {
    byId: {},
    allIds: [],
    isLoading: false,
    error: null,
  },
  reachouts: {
    byId: {},
    allIds: [],
    isLoading: false,
    error: null,
  },
  messages: {
    byId: {},
    allIds: [],
    byChatId: {},
    byChatRequestId: {},
    byReachoutId: {},
    byMatchId: {},
    isLoading: false,
    isLoadingMore: false,
    error: null,
  },
  reviews: {
    byId: {},
    byChatId: {},
    allIds: [],
    isLoading: false,
    error: null,
  },
  matches: {
    byId: {},
    allIds: [],
    isLoading: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHATS_START:
      return {
        ...state,
        chats: { ...state.chats, isLoading: true, error: null },
      };
    case SET_CHATS:
      return {
        ...state,
        chats: {
          byId: { ...state.chats.byId, ...action.payload.byId },
          allIds: [
            ...new Set([...state.chats.allIds, ...action.payload.allIds]),
          ],
          isLoading: false,
          error: null,
        },
      };
    case FETCH_CHATS_ERROR:
      return {
        ...state,
        chats: {
          ...state.chats,
          isLoading: false,
          error: "Error fetching chats",
        },
      };

    case FETCH_CHAT_REQUESTS_START:
      return {
        ...state,
        chatRequests: { ...state.chatRequests, isLoading: true, error: null },
      };
    case SET_CHAT_REQUESTS:
      return {
        ...state,
        chatRequests: {
          byId: { ...state.chatRequests.byId, ...action.payload.byId },
          allIds: [
            ...new Set([
              ...state.chatRequests.allIds,
              ...action.payload.allIds,
            ]),
          ],
          isLoading: false,
          error: null,
        },
      };
    case FETCH_CHAT_REQUESTS_ERROR:
      return {
        ...state,
        chatRequests: {
          ...state.chatRequests,
          isLoading: false,
          error: "Error fetching chat requests",
        },
      };

    case FETCH_REACHOUTS_START:
      return {
        ...state,
        reachouts: { ...state.reachouts, isLoading: true, error: null },
      };
    case SET_REACHOUTS:
      return {
        ...state,
        reachouts: {
          byId: { ...state.reachouts.byId, ...action.payload.byId },
          allIds: [
            ...new Set([...state.reachouts.allIds, ...action.payload.allIds]),
          ],
          isLoading: false,
          error: null,
        },
      };
    case FETCH_REACHOUTS_ERROR:
      return {
        ...state,
        reachouts: {
          ...state.reachouts,
          isLoading: false,
          error: "Error fetching reachouts",
        },
      };

    case FETCH_MESSAGES_START:
      return {
        ...state,
        messages: { ...state.messages, isLoading: true, error: null },
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: {
          byId: { ...state.messages.byId, ...action.payload.byId },

          // Deduplicate and merge message IDs for all messages
          allIds: [
            ...new Set([...state.messages.allIds, ...action.payload.allIds]),
          ],

          // Deduplicate and merge message IDs by chatId
          byChatId: {
            ...state.messages.byChatId,
            ...Object.keys(action.payload.byChatId || {}).reduce(
              (acc, chatId) => {
                acc[chatId] = [
                  ...new Set([
                    ...(state.messages.byChatId[chatId] || []),
                    ...action.payload.byChatId[chatId],
                  ]),
                ];
                return acc;
              },
              {}
            ),
          },

          // Deduplicate and merge message IDs by chatRequestId
          byChatRequestId: {
            ...state.messages.byChatRequestId,
            ...Object.keys(action.payload.byChatRequestId || {}).reduce(
              (acc, chatRequestId) => {
                acc[chatRequestId] = [
                  ...new Set([
                    ...(state.messages.byChatRequestId[chatRequestId] || []),
                    ...action.payload.byChatRequestId[chatRequestId],
                  ]),
                ];
                return acc;
              },
              {}
            ),
          },

          // Deduplicate and merge message IDs by reachoutId
          byReachoutId: {
            ...state.messages.byReachoutId,
            ...Object.keys(action.payload.byReachoutId || {}).reduce(
              (acc, reachoutId) => {
                acc[reachoutId] = [
                  ...new Set([
                    ...(state.messages.byReachoutId[reachoutId] || []),
                    ...action.payload.byReachoutId[reachoutId],
                  ]),
                ];
                return acc;
              },
              {}
            ),
          },

          // Deduplicate and merge message IDs by matchId
          byMatchId: {
            ...state.messages.byMatchId,
            ...Object.keys(action.payload.byMatchId || {}).reduce(
              (acc, matchId) => {
                acc[matchId] = [
                  ...new Set([
                    ...(state.messages.byMatchId[matchId] || []),
                    ...action.payload.byMatchId[matchId],
                  ]),
                ];
                return acc;
              },
              {}
            ),
          },

          isLoading: false,
          error: null,
        },
      };
    case FETCH_MESSAGES_ERROR:
      return {
        ...state,
        messages: {
          ...state.messages,
          isLoading: false,
          error: "Error fetching messages",
        },
      };
    case FETCH_MORE_MESSAGES_START:
      return {
        ...state,
        messages: { ...state.messages, isLoadingMore: true, error: null },
      };

    case FETCH_MORE_MESSAGES_ERROR:
      return {
        ...state,
        messages: {
          ...state.messages,
          isLoadingMore: false,
          error: "Error fetching more messages",
        },
      };
    case RECEIVE_MESSAGE:
      const message = action.payload;

      return {
        ...state,
        messages: {
          byId: {
            ...state.messages.byId,
            [message._id]: message,
          },
          allIds: [...new Set([...state.messages.allIds, message._id])],

          // Add message to the appropriate chatId list
          byChatId: message.chatId
            ? {
                ...state.messages.byChatId,
                [message.chatId]: [
                  ...new Set([
                    ...(state.messages.byChatId[message.chatId] || []),
                    message._id,
                  ]),
                ],
              }
            : state.messages.byChatId,

          // Add message to the appropriate chatRequestId list
          byChatRequestId: message.chatRequestId
            ? {
                ...state.messages.byChatRequestId,
                [message.chatRequestId]: [
                  ...new Set([
                    ...(state.messages.byChatRequestId[message.chatRequestId] ||
                      []),
                    message._id,
                  ]),
                ],
              }
            : state.messages.byChatRequestId,

          // Add message to the appropriate reachoutId list
          byReachoutId: message.reachoutId
            ? {
                ...state.messages.byReachoutId,
                [message.reachoutId]: [
                  ...new Set([
                    ...(state.messages.byReachoutId[message.reachoutId] || []),
                    message._id,
                  ]),
                ],
              }
            : state.messages.byReachoutId,

          // Add message to the appropriate matchId list
          byMatchId: message.matchId
            ? {
                ...state.messages.byMatchId,
                [message.matchId]: [
                  ...new Set([
                    ...(state.messages.byMatchId[message.matchId] || []),
                    message._id,
                  ]),
                ],
              }
            : state.messages.byMatchId,
        },
      };

    case FETCH_REVIEWS_START:
      return {
        ...state,
        reviews: { ...state.reviews, isLoading: true, error: null },
      };
    case SET_REVIEWS:
      return {
        ...state,
        reviews: {
          byId: { ...state.reviews.byId, ...action.payload.byId },
          byChatId: {
            ...state.reviews.byChatId,
            ...Object.keys(action.payload.byChatId || {}).reduce(
              (acc, chatId) => {
                acc[chatId] = [
                  ...new Set([
                    ...(state.reviews.byChatId[chatId] || []),
                    ...action.payload.byChatId[chatId],
                  ]),
                ];
                return acc;
              },
              {}
            ),
          },
          allIds: [
            ...new Set([...state.reviews.allIds, ...action.payload.allIds]),
          ],
          isLoading: false,
          error: null,
        },
      };
    case FETCH_REVIEWS_ERROR:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          isLoading: false,
          error: "Error fetching reviews",
        },
      };

    case FETCH_MATCHES_START:
      return {
        ...state,
        matches: { ...state.matches, isLoading: true, error: null },
      };

    case SET_MATCHES:
      return {
        ...state,
        matches: {
          byId: { ...state.matches.byId, ...action.payload.byId },
          allIds: [
            ...new Set([...state.matches.allIds, ...action.payload.allIds]),
          ],
          isLoading: false,
          error: null,
        },
      };

    case RECEIVE_MATCH:
      const match = action.payload;
      return {
        ...state,
        matches: {
          byId: {
            ...state.matches.byId,
            [match._id]: match,
          },
          allIds: [...new Set([...state.matches.allIds, match._id])],
          isLoading: false,
          error: null,
        },
      };

    case FETCH_MATCHES_ERROR:
      return {
        ...state,
        matches: {
          ...state.matches,
          isLoading: false,
          error: "Error fetching matches",
        },
      };

    default:
      return state;
  }
};

const store = configureStore({
  reducer,
});

export default store;
