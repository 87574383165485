import React from "react";
import styles from "./ChatInput.module.css";
import Button from "components/Button/Button";

const ChatInput = React.forwardRef(
  (
    {
      newMessage,
      setNewMessage,
      onEnterPress,
      handleSendMessage,
      isSendingMessage,
      disabled = false,
    },
    ref
  ) => {
    return (
      <div className={styles.input} ref={ref}>
        <textarea
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={onEnterPress}
          disabled={disabled || isSendingMessage}
          placeholder="Type a message..."
        />
        <Button
          onClick={handleSendMessage}
          disabled={disabled || isSendingMessage}
        >
          {isSendingMessage ? "Sending..." : "Send"}
        </Button>
      </div>
    );
  }
);

export default ChatInput;
