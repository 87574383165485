import { useState } from 'react';

import { TbLayoutDashboard, TbUsersGroup, TbReportSearch, TbSettings } from "react-icons/tb";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styles from './AdminDashboard.module.css'

import PageWrapper from 'components/PageWrapper/PageWrapper';

import Overview from './views/Overview';
import UserManagement from './views/UserManagement';
import ReportManagement from './views/ReportManagement';


const views = ['Overview', 'User Management', 'Reports', 'Settings']

const AdminDashboard = () => {
  const [selectedView, setSelectedView] = useState(views[0]);
  const [toggleLabel, setToggleLabel] = useState(false);

  return (
    <PageWrapper>
      <div className={styles.dashboard}>
        <div
          className={styles.sidebar}
          style={{
            transition: 'width 0.3s',
            width: toggleLabel ? '150px' : '50px',
          }}
        >
          <ul>
            <li>
              <button onClick={() => setSelectedView(views[0])}>
                <TbLayoutDashboard />{toggleLabel && 'Overview'}
              </button>
            </li>
            <li>
              <button onClick={() => setSelectedView(views[1])}>
                <TbUsersGroup />{toggleLabel && 'Users'}
              </button>
            </li>
            <li>
              <button onClick={() => setSelectedView(views[2])}>
                <TbReportSearch />{toggleLabel && 'Reports'}
              </button>
            </li>
            <li>
              <button onClick={() => setSelectedView(views[3])}>
                <TbSettings />{toggleLabel && 'Settings'}
              </button>
            </li>
          </ul>
          <div
            className={styles.toggleLabelButton}
            style={{
              position: 'absolute',
              top: '8px',
              left: toggleLabel ? '92%' : '76%',
              transition: 'left 0.3s',
            }}
          >
            <button onClick={() => setToggleLabel(!toggleLabel)}>
              {toggleLabel ? <IoIosArrowBack color="white" /> : <IoIosArrowForward color="white" />}
            </button>
          </div>
        </div>
        <div>
          {selectedView === 'Overview' && <Overview />}
          {selectedView === 'User Management' && <UserManagement />}
          {selectedView === 'Reports' && <ReportManagement />}
        </div>
      </div>
    </PageWrapper>
  )
}

export default AdminDashboard