// packages/client/src/components/TalkTicker/TalkTicker.jsx
import React, { useContext } from "react";

import styles from "./TalkTicker.module.css";

import { motion, AnimatePresence } from "framer-motion";
import { SocketContext } from "contexts/SocketContext";

const statusVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

const lightVariants = {
  online: { backgroundColor: "#00FF00", opacity: 1, scale: 1.2 },
  offline: { backgroundColor: "#FF0000", opacity: 1, scale: 1 },
  checking: { opacity: 0.5, scale: 1 }, // Dimmed and normal size when checking
};

const TopicString = ({ data }) => {
  const { topic, activeChats } = data;

  return (
    <p key={topic + activeChats} className={styles.topicString}>
      {activeChats * 2} people talking about {topic}
    </p>
  );
};

function TalkTicker() {
  const { serverOnline, loading } = useContext(SocketContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.serverStatus}>
        {!loading && (
          <motion.div
            className={styles.statusLight}
            variants={lightVariants}
            initial="checking"
            animate={loading ? "checking" : serverOnline ? "online" : "offline"}
          />
        )}
        <AnimatePresence mode="wait">
          {loading ? (
            <motion.span
              key="checking"
              variants={statusVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              checking server status...
            </motion.span>
          ) : serverOnline ? (
            <motion.span
              key="online"
              variants={statusVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              server online
            </motion.span>
          ) : (
            <motion.span
              key="offline"
              variants={statusVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              server offline
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      {/* <div className={styles.tape}>
        <p className={styles.activeUsers}>Active users: {activeUsers}</p>
      </div> */}
    </div>
  );
}

export default TalkTicker;
