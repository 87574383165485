import React, { useEffect, useState } from "react";
import * as TAGS_API from "api/tags";
import styles from "./Tags.module.css";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";

import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { formatDate } from "utils/dates";
import SearchInput from "components/SearchInput/SearchInput";

const Card = ({ data, onClick }) => {
  return (
    <div className={styles.card} onClick={(event) => onClick(data, event)}>
      <div className={styles.header}>
        <span className={styles.tag}>{data.name}</span>

        {data.score && (
          <span className={styles.score}>
            {Math.ceil(data.score * 100)}% match
          </span>
        )}
      </div>

      <div className={styles.description}>
        <p>{data.description}</p>
      </div>
      <div className={styles.metadata}>
        {data.createdAt && <p>Created on: {formatDate(data.createdAt)}</p>}
        {typeof data.matches === "number" && <p>Matches: {data.matches}</p>}
      </div>
    </div>
  );
};

function Tags() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("relevance"); //relevance || matches || new
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const debouncedFetchTags = debounce(async () => {
      setIsLoading(true);
      try {
        let data;
        if (searchTerm) {
          data = await TAGS_API.suggestTags({
            text: searchTerm,
            page: currentPage,
            limit: 20,
            sort: sortOrder,
          });
        } else {
          data = await TAGS_API.searchTags({
            query: "",
            type: "autocomplete",
            limit: 20,
            sort: sortOrder,
            page: currentPage,
          });
        }
        setSearchResults(data.tags); // Assuming the API returns an object with tags and total pages
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setIsLoading(false);
      }
    }, 500);

    debouncedFetchTags();

    // Cleanup function to cancel the debounce on component unmount or before re-running the effect
    return () => {
      debouncedFetchTags.cancel();
    };
  }, [searchTerm, sortOrder, currentPage]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCardClick = (tagData, event) => {
    // Generate the URL for the new page with the hyphenated tag name
    const hyphenatedName = tagData.name.toLowerCase().replace(/\s+/g, "-");
    const url = `/tags/${hyphenatedName}`;

    // Check if Cmd (on macOS) or Ctrl (on Windows/Linux) is pressed during the click
    if (event.metaKey || event.ctrlKey) {
      // Open the URL in a new tab if Cmd or Ctrl is pressed
      window.open(url, "_blank");
    } else {
      // Navigate in the same tab if no modifier keys are pressed
      navigate(url);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const clearInput = () => {
    setSearchTerm("");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Explore Tags</h1>
        <p>Find and discover tags intuitively</p>
      </div>

      <div className={styles.controls}>
        <SearchInput
          value={searchTerm}
          onChange={handleInputChange}
          onClear={clearInput}
          placeholder={[
            "What are you feeling?",
            "I lost my Mom to cancer",
            "I'm feeling alone",
            "I'm struggling with anxiety",
            "I need support",
            "I'm looking for advice",
          ]}
        />
        <div className={styles.sort}>
          <button
            className={`${styles.sortButton} ${
              sortOrder === "relevance" ? styles.active : ""
            }`}
            onClick={() => setSortOrder("relevance")}
          >
            Relevance
          </button>
          <button
            className={`${styles.sortButton} ${
              sortOrder === "matches" ? styles.active : ""
            }`}
            onClick={() => setSortOrder("matches")}
          >
            Matches
          </button>
          <button
            className={`${styles.sortButton} ${
              sortOrder === "new" ? styles.active : ""
            }`}
            onClick={() => setSortOrder("new")}
          >
            New
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className={styles.loading}>
          <LoadingMessage message="Loading tags..." />
        </div>
      ) : (
        <div className={styles.results}>
          {searchResults?.map((result) => (
            <Card key={result._id} data={result} onClick={handleCardClick} />
          ))}
        </div>
      )}
      <div className={styles.pagination}>
        {" "}
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            className={`${styles.btn} ${
              currentPage === i + 1 ? styles.active : ""
            }`}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Tags;
