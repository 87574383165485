import React from "react";
import {
  useFloating,
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";
import Button from "components/Button/Button";
import styles from "./DeleteConfirmationModal.module.css";

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  itemName,
  status,
  statusMessage,
}) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { outsidePressEvent: "mousedown" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);

  if (!isOpen) return null;

  return (
    <FloatingOverlay lockScroll className={styles.overlay}>
      <FloatingFocusManager context={context}>
        <div
          ref={refs.setFloating}
          className={styles.modal}
          {...getFloatingProps()}
        >
          <h2 className={styles.header}>Confirm Deletion</h2>
          <p className={styles.content}>
            Are you sure you want to delete this {itemName}?
          </p>
          {status === "error" && (
            <p className={styles.errorMessage}>{statusMessage}</p>
          )}
          <div className={styles.actions}>
            <Button
              onClick={onClose}
              className={`${styles.button} ${styles.cancelButton}`}
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              color="error"
              className={`${styles.button} ${styles.deleteButton}`}
              disabled={status === "loading"}
            >
              {status === "loading" ? "Deleting..." : "Delete"}
            </Button>
          </div>
        </div>
      </FloatingFocusManager>
    </FloatingOverlay>
  );
};

export default DeleteConfirmationModal;
