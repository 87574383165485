import { adminApi } from "api";
import handleAxiosError from "./handleAxiosError";

export const fetchUsers = async ({ page = 1, limit = 10 }) => {
  try {
    const res = await adminApi.get(`/users?page=${page}&limit=${limit}`);

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const approveUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/approve`, {
      reason: data.reason,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const banUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/ban`, {
      reason: data.reason,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const suspendUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/suspend`, {
      reason: data.reason,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const deleteUser = async (data) => {
  try {
    const res = await adminApi.delete(`/users/${data.userId}/hard-delete`, {
      data: { reason: data.reason },
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const fetchReports = async () => {
  try {
    const res = await adminApi.get("/reports");

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const restoreUser = async (data) => {
  try {
    const res = await adminApi.post(`/users/${data.userId}/restore-account`, {
      reason: data.reason,
    });

    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};
