import { configApi as api } from "api"; // Assuming 'api' is your configured Axios instance

export const fetchAgeRanges = async () => {
  try {
    const response = await api.get("/age-ranges");

    return response.data;
  } catch (error) {
    console.error("Error fetching age ranges:", error);
    throw error; // Rethrow the error for handling by the caller
  }
};
