import React, { useEffect, useState } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./VerifyEmail.module.css";
import Cookies from "js-cookie";

const VerifyEmailFailure = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const redirectSource = Cookies.get("redirectSource");
    const verificationStatus = Cookies.get("verificationStatus");
    const verificationMessage = Cookies.get("verificationMessage");

    if (redirectSource !== "server" || verificationStatus !== "failure") {
      navigate("/lobby");
    } else {
      setMessage(verificationMessage);
    }

    // Cleanup function to clear cookies on unmount
    return () => {
      Cookies.remove("redirectSource");
      Cookies.remove("verificationStatus");
      Cookies.remove("verificationMessage");
    };
  }, [navigate]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Email Verification Failed</h1>
      <p className={styles.message}>{message}</p>
    </div>
  );
};

export default VerifyEmailFailure;
