import React, { useState, useEffect } from "react";
import {
  useFloating,
  offset,
  arrow,
  FloatingArrow,
  autoUpdate,
  useDismiss,
  shift,
  useInteractions,
  flip,
} from "@floating-ui/react";
import styles from "./FilterPopover.module.css"; // Import styles as a CSS module
import Portal from "components/Portal/Portal";
import { FaFilter } from "react-icons/fa";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";
import { motion, AnimatePresence } from "framer-motion";
import CustomCombobox from "components/CustomCombobox/CustomCombobox";
import TagSelect from "pages/Lobby/TagSelect/TagSelect";
import Button from "components/Button/Button";

const defaultSortOptions = [
  { value: "newest", label: "Newest" },
  { value: "oldest", label: "Oldest" },
];

const defaultYNAOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
  { value: "any", label: "Any" },
];

/**
 * FilterPopover component for applying filters to a list of items.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {function} props.onChange - Callback function to update the selected filters.
 * @param {Object} props.filters - The current filters applied.
 * @param {Object} props.filters.sort - The current sort option, which should match the structure of combobox options.
 * @param {Array<{ _id: string, name: string }>} props.filters.tags - An array of tag objects, each containing an _id and name.
 * @param {string} [props.filters.anonymized] - The current anonymized filter option.
 * @param {string} [props.filters.reachedOut] - The current reached out filter option.
 * @param {string} [props.filters.connected] - The current connected filter option.
 * @returns {JSX.Element} The rendered component.
 */
const FilterPopover = ({
  onChange,
  filters, // Accept the entire filters object
  sortOptions: sortOptionsOverride,
  handleFilter = () => { },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState(null);
  const [sort, setSort] = useState(filters.sort);
  const [selectedTags, setSelectedTags] = useState(filters.tags);
  const [anonymized, setAnonymized] = useState(filters.anonymized);
  const [reachedOut, setReachedOut] = useState(filters.reachedOut);
  const [connected, setConnected] = useState(filters.connected);

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,

    middleware: [
      offset(10),
      flip({
        fallbackAxisSideDirection: "start",
        crossAxis: false,
      }),
      shift(),
      arrow({ element: arrowRef, padding: 5 }), // arrow should always be the last (realized after experimenting) so it can consume the positioning (I think) made by the previous middlewares
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    setSort(filters.sort);
    setSelectedTags(filters.tags);
    setAnonymized(filters.anonymized);
    setReachedOut(filters.reachedOut);
    setConnected(filters.connected);
  }, [filters]);

  const handleSortChange = (selectedOptions) => {
    setSort(selectedOptions);
    onChange({
      sort: selectedOptions,
      tags: selectedTags,
      anonymized,
      reachedOut,
      connected,
    });
  };

  const handleTagChange = (selectedTags) => {
    setSelectedTags(selectedTags);
    onChange({ sort, tags: selectedTags, anonymized, reachedOut, connected });
  };

  const handleAnonymizedChange = (selectedOption) => {
    setAnonymized(selectedOption);
    onChange({
      sort,
      tags: selectedTags,
      anonymized: selectedOption,
      reachedOut,
      connected,
    });
  };

  const handleReachedOutChange = (selectedOption) => {
    setReachedOut(selectedOption);
    onChange({
      sort,
      tags: selectedTags,
      anonymized,
      reachedOut: selectedOption,
      connected,
    });
  };

  const handleConnectedChange = (selectedOption) => {
    setConnected(selectedOption);
    onChange({
      sort,
      tags: selectedTags,
      anonymized,
      reachedOut,
      connected: selectedOption,
    });
  };

  const handleClearFilters = () => {
    setSort(null);
    setSelectedTags([]);
    setAnonymized(null);
    setReachedOut(null);
    setConnected(null);
    onChange({
      sort: null,
      tags: [],
      anonymized: null,
      reachedOut: null,
      connected: null,
    });
  };

  const hasFilters =
    (sort !== null && sort !== undefined) ||
    selectedTags.length > 0 ||
    (anonymized !== null && anonymized !== undefined) ||
    (reachedOut !== null && reachedOut !== undefined) ||
    (connected !== null && connected !== undefined);

  return (
    <>
      <Tooltip delay="1">
        <TooltipTrigger>
          <span
            ref={refs.setReference}
            className={styles.filterIcon}
            onClick={() => setIsOpen((prev) => !prev)}
            {...getReferenceProps()}
          >
            <FaFilter />
            {hasFilters && <span className={styles.filterBadge}></span>}
          </span>
        </TooltipTrigger>
        <TooltipContent>Set filter and sort options</TooltipContent>
      </Tooltip>
      <AnimatePresence>
        {isOpen && (
          <Portal>
            <motion.div
              ref={refs.setFloating}
              style={floatingStyles}
              className={styles.popover}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              {...getFloatingProps()}
            >
              <FloatingArrow
                ref={setArrowRef}
                fill="white"
                tipRadius="3"
                context={context}
              />
              <div className={styles.popoverContent}>
                <CustomCombobox
                  key="sortCombobox"
                  isMulti={false}
                  options={
                    sortOptionsOverride
                      ? sortOptionsOverride
                      : defaultSortOptions
                  }
                  selected={sort}
                  onSelect={handleSortChange}
                  placeholder="Sort by"
                  className={styles.customCombobox}
                  focusedClassName={styles.focused}
                  listClassName={styles.dropdownList}
                />
                <div className={styles.spacer}></div>
                <TagSelect
                  selected={selectedTags}
                  onSelect={handleTagChange}
                  clearSelectedTags={() => handleTagChange([])}
                  className={styles.customCombobox}
                  focusedClassName={styles.focused}
                  listClassName={styles.dropdownList}
                />
                <div className={styles.spacer}></div>
                <CustomCombobox
                  key="anonymizedCombobox"
                  isMulti={false}
                  options={defaultYNAOptions}
                  selected={anonymized}
                  onSelect={handleAnonymizedChange}
                  placeholder="Anonymized?"
                  className={styles.customCombobox}
                  focusedClassName={styles.focused}
                  listClassName={styles.dropdownList}
                />
                <div className={styles.spacer}></div>
                <CustomCombobox
                  key="reachedOutCombobox"
                  isMulti={false}
                  options={defaultYNAOptions}
                  selected={reachedOut}
                  onSelect={handleReachedOutChange}
                  placeholder="Reached Out?"
                  className={styles.customCombobox}
                  focusedClassName={styles.focused}
                  listClassName={styles.dropdownList}
                />
                <div className={styles.spacer}></div>
                <CustomCombobox
                  key="connectedCombobox"
                  isMulti={false}
                  options={defaultYNAOptions}
                  selected={connected}
                  onSelect={handleConnectedChange}
                  placeholder="Connected?"
                  className={styles.customCombobox}
                  focusedClassName={styles.focused}
                  listClassName={styles.dropdownList}
                />
              </div>
              {hasFilters && (
                <div className={styles.buttonContainer}>
                  <Button
                    color="error"
                    className={styles.filterButton}
                    onClick={handleClearFilters}
                    disabled={!hasFilters}
                  >
                    Clear Filters
                  </Button>
                  <Button
                    color="success"
                    className={styles.filterButton}
                    onClick={handleFilter}
                  >
                    Apply Filters
                  </Button>
                </div>
              )}
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
};

export default FilterPopover;
