// packages/client/src/pages/Lobby/TagSelect/TagSelect.jsx
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./TagSelect.module.css";
import cn from "classnames";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import useTagsSearch from "hooks/useTagsSearch";
import {
  autoUpdate,
  shift,
  useFloating,
  offset,
  flip,
  size,
} from "@floating-ui/react";
import Portal from "components/Portal/Portal";

/**
 * Data structure of a tag object:
 * @typedef {Object} Tag
 * @property {string} _id - The unique identifier of the tag.
 * @property {string} name - The name of the tag.
 * @property {string} description - A description of the tag.
 * @property {string} createdAt - The creation date of the tag.
 * @property {number} stories - The number of stories associated with the tag.
 * @property {number} matches - The number of matches for the tag.
 * @property {number} hits - The number of hits or uses of the tag.
 */

/**
 * TagOption component represents an individual tag option in the dropdown list.
 *
 * @component
 * @param {Object} props - The props for the TagOption component.
 * @param {Tag} props.tag - The tag data to display.
 * @param {number} props.i - The index of the tag in the list.
 * @param {Function} props.selectTag - Function to handle tag selection.
 * @param {Function} props.setIsOpen - Function to set the open state of the dropdown.
 * @param {Function} props.setHighlightedIndex - Function to set the index of the highlighted tag.
 * @param {Function} props.isTagSelected - Function to check if the tag is selected.
 * @param {number} props.highlightedIndex - The index of the currently highlighted tag.
 * @param {React.Ref} ref - Forwarded ref to access the component's reference.
 * @returns {JSX.Element} The rendered TagOption component.
 */
const TagOption = forwardRef(
  (
    {
      tag,
      i,
      selectTag,
      setIsOpen,
      setHighlightedIndex,
      isTagSelected,
      highlightedIndex,
    },
    ref
  ) => {
    return (
      <li
        ref={ref}
        key={tag._id}
        onClick={(e) => {
          e.stopPropagation();
          selectTag(tag);
          setIsOpen(false);
        }}
        onMouseEnter={(e) => {
          setHighlightedIndex(i);
        }}
        onMouseDown={(e) => e.preventDefault()}
        className={cn(styles.option, {
          [styles.selected]: isTagSelected(tag),
          [styles.highlighted]: i === highlightedIndex,
        })}
      >
        <div className={styles["option-header"]}>
          <div className={styles["option-name"]}>{tag.name}</div>
          <div className={styles["option-hits"]}>{tag.hits}</div>
        </div>
        <div className={styles["option-description"]}>{tag.description}</div>
      </li>
    );
  }
);

const SelectedBadge = ({ tag, onSelect }) => (
  <button
    key={tag._id}
    onClick={(e) => {
      e.stopPropagation();
      onSelect(tag);
    }}
    className={styles["option-badge"]}
  >
    {tag.name}
    <span className={styles["remove-btn"]}>&times;</span>
  </button>
);

/**
 * TagSelect component allows users to select tags from a searchable dropdown.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.selected - The currently selected tags.
 * @param {Function} props.onSelect - Callback function to handle tag selection.
 * @param {Function} props.clearSelectedTags - Function to clear selected tags.
 * @param {string} [props.className] - Additional class names for the container.
 * @param {string} [props.focusedClassName] - Class name to apply when the input is focused.
 * @param {string} [props.listClassName] - Additional class names for the dropdown list.
 * @param {React.Ref} ref - Forwarded ref to access the component's reference.
 *
 * @returns {JSX.Element} The rendered TagSelect component.
 */
const TagSelect = forwardRef(
  (
    {
      selected,
      onSelect,
      clearSelectedTags,
      className,
      focusedClassName,
      listClassName,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      middleware: [
        offset(3),
        flip(),
        shift(),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    useImperativeHandle(ref, () => refs.reference.current);

    const [query, setQuery] = useState("");

    useEffect(() => {
      setPageNumber(1);
    }, [query]);
    const [pageNumber, setPageNumber] = useState(1);

    const { tags, loading, error, hasMore } = useTagsSearch(query, pageNumber);

    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const inputRef = useRef(null);

    const [isInputFocused, setIsInputFocused] = useState(false);

    const mirrorRef = useRef(null);
    const adjustWidth = () => {
      const mirrorText = mirrorRef.current;
      const input = inputRef.current;
      const container = inputRef.current.parentNode; // Assuming the parent of the input is the container

      if (mirrorText && input && container) {
        mirrorText.textContent = query || input.placeholder || " "; // Fallback to placeholder or a single space
        let calculatedWidth = mirrorText.offsetWidth + 10; // +10 for a little extra space
        calculatedWidth = Math.min(calculatedWidth, container.offsetWidth); // Ensure width does not exceed container width
        input.style.width = `${calculatedWidth}px`;
      }
    };

    useEffect(() => {
      const handleResize = () => {
        adjustWidth();
      };

      // Set up the event listener
      window.addEventListener("resize", handleResize);

      // Call adjustWidth initially in case the initial size needs adjustment
      adjustWidth();

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [query, selected]);

    function handleChange(e) {
      setQuery(e.target.value);
    }

    function clearOptions() {
      clearSelectedTags();
      setQuery("");
    }

    /**
     * Selects or deselects a tag.
     * @param {Tag} tag - The tag object to be selected or deselected.
     */
    function selectTag(tag) {
      if (!tag) return; // Prevent selecting if tag is null or undefined
      if (isTagSelected(tag)) {
        // Tag is already selected, remove it
        onSelect([...selected.filter((t) => t._id !== tag._id)]);
      } else {
        // Tag is not selected, add it
        onSelect([...selected, tag]);
      }
      setQuery("");
    }

    function isTagSelected(tag) {
      return selected.some((t) => t._id === tag._id);
    }

    useEffect(() => {
      setHighlightedIndex(0);
    }, [isOpen]);

    useEffect(() => {
      if (isInputFocused) {
        setIsOpen(true);
      }
    }, [isInputFocused]);

    useEffect(() => {
      if (query?.length > 0) {
        setIsOpen(true);
      }
    }, [query]);

    const observer = useRef();

    const lastElementRef = useCallback(
      (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((prev) => prev + 1);
          }
        });
        if (node) observer.current.observe(node);
      },
      [loading, hasMore]
    );

    const tagOptionProps = {
      selectTag,
      setIsOpen,
      setHighlightedIndex,
      isTagSelected,
      highlightedIndex,
    };

    return (
      <div
        {...rest}
        data-tour="tag-select"
        ref={refs.setReference}
        tabIndex={0}
        onFocus={() => {
          inputRef.current.focus();
        }}
        onClick={() => {
          inputRef.current.focus();
        }}
        onBlur={() => {
          setIsOpen(false);
        }}
        className={cn(styles.container, className, {
          [styles.containerFocused]: isInputFocused,
          [focusedClassName]: isInputFocused, // Apply focusedClassName when input is focused
        })}
      >
        <span className={styles.selectedList}>
          {selected.map((tag) => (
            <SelectedBadge key={tag._id} tag={tag} onSelect={selectTag} />
          ))}
          <input
            ref={inputRef}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            className={styles.input}
            value={query}
            onChange={handleChange}
            placeholder={
              selected.length > 0
                ? "Select another tag (optional)"
                : "Select a tag"
            }
          />
          <span ref={mirrorRef} className={styles["mirror-text"]}></span>
        </span>
        <button
          className={styles["clear-btn"]}
          onClick={(e) => {
            e.stopPropagation();
            clearOptions();
          }}
        >
          &times;
        </button>

        {isOpen && (
          <Portal>
            <ul
              ref={refs.setFloating}
              style={floatingStyles}
              className={cn(styles.list, listClassName)} // Merge listClassName with existing styles
            >
              {tags.map((tag, i) => {
                if (tags.length === i + 1) {
                  return (
                    <TagOption
                      ref={lastElementRef}
                      key={tag._id}
                      tag={tag}
                      i={i}
                      {...tagOptionProps}
                    />
                  );
                } else {
                  return (
                    <TagOption
                      tag={tag}
                      key={tag._id}
                      i={i}
                      {...tagOptionProps}
                    />
                  );
                }
              })}
              {loading && (
                <li className={styles.loading}>
                  <LoadingMessage message="Loading more tags..." />
                </li>
              )}
            </ul>
          </Portal>
        )}
      </div>
    );
  }
);

export default TagSelect;
