import React, { useState } from "react";
import { useToastContext } from "contexts/ToastContext";
import Button from "components/Button/Button";
import { Dialog, DialogContent } from "components/Dialog/Dialog";
import styles from "./ReachOutDialog.module.css";
import * as typedefs from "../../typedefs";
import * as REACHOUTS_API from "api/reachouts";

/**
 * @typedef {typedefs.LifeExperienceData} LifeExperience
 * @typedef {typedefs.StoryData} Story
 */

/**
 * ReachOutDialog component that allows users to send messages.
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Controls the visibility of the dialog.
 * @param {function} props.onOpenChange - Function to handle dialog open/close state.
 * @param {string} props.type - The type of data being referenced (e.g., "story" or "lifeexperience").
 * @param {LifeExperience | Story} props.resource - The life experience or story data.
 */

const ReachOutDialog = ({ open, onOpenChange, type, resource }) => {
  const { addToast } = useToastContext();
  const [message, setMessage] = useState("");
  const [fetchState, setFetchState] = useState("initial");
  const [response, setResponse] = useState(null);

  // Validate type and resource
  const validTypes = ["LifeExperience", "Story"];
  if (!validTypes.includes(type)) {
    console.error(
      `Invalid type: ${type}. Expected 'LifeExperience' or 'Story'.`
    );
    return null; // Return null if type is invalid
  }

  if (
    !resource ||
    (type === "LifeExperience" && !resource.story) ||
    (type === "Story" && !resource.title)
  ) {
    console.error("Invalid resource provided.");
    return null; // Return null if resource is invalid
  }

  const handleSendMessage = async () => {
    setFetchState("loading");

    try {
      const response = await REACHOUTS_API.createReachout({
        resourceId: resource._id,
        resourceType: type, // Use the type prop to determine the resource type
        message,
      });

      setResponse(response);
      setFetchState("success");
    } catch (error) {
      console.error("Failed to send message:", error);
      setFetchState("error");
      setTimeout(() => {
        setFetchState("initial");
      }, 2000);

      addToast(`${error.message}`, "error");
    }
  };

  const getChatLink = (response) => {
    if (!response) return "#";

    return `/chats?type=reachouts&id=${response._id}`;
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={styles.content}>
        {fetchState === "success" ? (
          <div className={styles.successMessage}>
            <h2 className={styles.heading}>Message Sent!</h2>
            <p className={styles.description}>
              Your message has been successfully sent.
            </p>
          </div>
        ) : (
          <>
            <h2 className={styles.heading}>Reach Out</h2>
            <p className={styles.description}>
              {type === "LifeExperience" ? resource.story : resource.title}
            </p>
            <textarea
              className={styles.textArea}
              placeholder="Type your message here..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </>
        )}
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            onClick={() => onOpenChange((prev) => !prev)}
            shape="square"
            color="gray"
          >
            Cancel
          </Button>
          <Button
            className={styles.buttonPrimary}
            onClick={fetchState === "success" ? undefined : handleSendMessage}
            fetchState={fetchState}
            href={fetchState === "success" ? getChatLink(response) : undefined}
            color={fetchState === "success" ? "success" : "orange"}
            shape="square"
          >
            {fetchState === "loading"
              ? "Sending..."
              : fetchState === "success"
              ? "Sent! Go to chat"
              : "Send Message"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ReachOutDialog;
