// packages/client/src/components/SearchInput/SearchInput.jsx
import React, { useState, useEffect } from "react";
import styles from "./SearchInput.module.css";
import { MdSearch } from "react-icons/md";
import cn from "classnames";
import { ReactTyped } from "react-typed";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../ReusableTooltip/ReusableTooltip";

const SearchInput = ({
  value,
  onChange,
  onClear,
  onEnter,
  placeholder,
  className,
  onFocus,
  onBlur,
  queryRequired = false, // New prop with default value false
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (isFocused && onFocus) {
      onFocus();
    }
  }, [isFocused]);

  useEffect(() => {
    if (!isFocused && onBlur) {
      onBlur();
    }
  }, [isFocused]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (queryRequired && !value.trim()) {
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 3000); // Hide tooltip after 3 seconds
      } else if (onEnter) {
        onEnter(value);
      }
    }
  };

  const processedPlaceholder = Array.isArray(placeholder)
    ? placeholder.map((item) => `^500${item}`)
    : [`^500${placeholder}`];

  return (
    <Tooltip open={showTooltip} onOpenChange={setShowTooltip}>
      <TooltipTrigger>
        <div
          className={cn(styles.inputContainer, className, {
            [styles.focused]: isFocused,
          })}
        >
          <MdSearch className={styles.icon} />
          <ReactTyped
            attr="placeholder"
            backSpeed={50}
            onBegin={function noRefCheck() {}}
            onComplete={function noRefCheck() {}}
            onDestroy={function noRefCheck() {}}
            onLastStringBackspaced={function noRefCheck() {}}
            onReset={function noRefCheck() {}}
            onStart={function noRefCheck() {}}
            onStop={function noRefCheck() {}}
            onStringTyped={function noRefCheck() {}}
            onTypingPaused={function noRefCheck() {}}
            onTypingResumed={function noRefCheck() {}}
            strings={[...processedPlaceholder]}
            loop={processedPlaceholder.length > 1}
            typeSpeed={50}
            typedRef={function noRefCheck() {}}
          >
            <input
              className={cn(styles.input)}
              type="text"
              value={value}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </ReactTyped>
          {value && (
            <button
              className={cn(styles["clear-btn"], styles.icon)}
              onClick={(e) => {
                e.stopPropagation();
                onClear();
              }}
            >
              &times;
            </button>
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent>Query required</TooltipContent>
    </Tooltip>
  );
};

export default SearchInput;
