import api from "./index";
import handleAxiosError from "./handleAxiosError";
import * as typedefs from "typedefs";

/** @typedef {typedefs.LifeExperienceData} LifeExperienceData */

export const createLifeExperience = async (lifeExpData) => {
  try {
    const res = await api.post(`/lifeexperiences`, lifeExpData);
    return res.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Fetches a life experience by its ID.
 *
 * @param {string} lifeExpId - The ID of the life experience to fetch.
 * @returns {Promise<LifeExperienceData>} The life experience data.
 * @throws {Error} If the fetch fails.
 */
export const fetchLifeExperienceById = async (lifeExpId) => {
  try {
    const res = await api.get(`/lifeexperiences/${lifeExpId}`);
    return res.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchLifeExperiences = async () => {
  try {
    const res = await api.get(`/lifeexperiences`);
    return res.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

/**
 * Updates a life experience with the provided data.
 *
 * @param {string} lifeExpId - The ID of the life experience to update.
 * @param {Object} lifeExpData - The data to update the life experience with.
 * @param {Object} lifeExpData.tag - The tag associated with the life experience.
 * @param {string} lifeExpData.tag._id - The ID of the tag.
 * @param {string} lifeExpData.tag.name - The name of the tag.
 * @param {string} lifeExpData.story - The story of the life experience.
 * @param {Object} lifeExpData.date - The date of the life experience.
 * @param {number} lifeExpData.date.month - The month of the life experience (1-12).
 * @param {number} lifeExpData.date.year - The year of the life experience.
 * @param {boolean} lifeExpData.searchable - Indicates if the life experience is searchable.
 * @returns {Promise<Object>} The updated life experience data.
 * @throws {Error} If the update fails.
 */
export const updateLifeExperience = async (lifeExpId, lifeExpData) => {
  try {
    const res = await api.patch(`/lifeexperiences/${lifeExpId}`, lifeExpData);
    return res.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const deleteLifeExperience = async (lifeExpId) => {
  try {
    const res = await api.delete(`/lifeexperiences/${lifeExpId}`);
    return res.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const updateLifeExperienceSearchability = async (
  experienceId,
  searchable
) => {
  try {
    const res = await api.patch(`/lifeexperiences/${experienceId}/visibility`, {
      searchable,
    });
    return res.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

/**
 * Search life experiences based on the provided search string and filters.
 * @param {Object} searchParams - The search parameters object.
 * @param {Array<string>} [searchParams.tags] - The list of tags to filter on.
 * @param {string} searchParams.query - The input text to find relevant life experiences.
 * @param {number} [searchParams.page=1] - The page number for pagination.
 * @param {number} [searchParams.limit=10] - The number of results per page.
 * @param {string} [searchParams.sort="relevance"] - The sort order of the results. Can be one of the following: newest, oldest, relevance.
 * @param {string} [searchParams.user] - The ID of the user to filter on.
 * @param {boolean} [searchParams.includeOwn=false] - Whether to include the user's own life experiences.
 * @param {boolean} [searchParams.isAnonymized] - Filter for anonymized experiences.
 * @param {boolean} [searchParams.isReachedOut] - Filter for reached out experiences.
 * @param {boolean} [searchParams.isConnected] - Filter for connected experiences.
 * @returns {Object} An object containing the search results.
 * @returns {string} return.text - The search text.
 * @returns {Array<LifeExperienceData>} return.results - An array of life experiences matching the search criteria.
 * @returns {number} return.totalCount - The total number of matching life experiences.
 * @returns {number} return.totalPages - The total number of pages.
 * @returns {number} return.page - The current page number.
 * @returns {number} [return.undiscoveredCount] - The count of undiscovered life experiences (if user is specified).
 * @returns {Object} error - An error object if an error occurs.
 */
export const searchLifeExperiences = async (searchParams) => {
  try {
    const {
      tags = [],
      query,
      page = 1,
      limit = 10,
      sort = "relevance",
      user: authorId,
      includeOwn = false,
      isAnonymized,
      isReachedOut,
      isConnected,
    } = searchParams;

    const params = {
      query,
      page,
      limit,
      sort,
      user: authorId,
      isAnonymized,
      isReachedOut,
      isConnected,
    };

    const res = await api.post(
      `/lifeexperiences/search`,
      { tags, options: { includeOwn } },
      { params }
    );

    // Extract data from the response
    const {
      results,
      totalCount,
      totalPages,
      undiscoveredCount,
      page: currentPage,
    } = res.data;

    // Build the base return object
    const result = { results, totalCount, totalPages, page: currentPage };

    // If undiscoveredCount is present (because userId was passed), include it
    if (undiscoveredCount !== undefined) {
      result.undiscoveredCount = undiscoveredCount;
    }

    return result;
  } catch (error) {
    handleAxiosError(error);
  }
};
