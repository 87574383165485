// HugIcon.js
import React from "react";

const HugIcon = ({ className }) => (
  <svg
    className={className} // Add a class for styling
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
  >
    <circle cx="100" cy="100" r="80" fill="#FFCC4D" />
    <circle cx="70" cy="85" r="10" fill="#664500" />
    <circle cx="130" cy="85" r="10" fill="#664500" />
    <path
      d="M60,120 Q100,160 140,120"
      stroke="#664500"
      strokeWidth="6"
      fill="none"
    />
    <path
      d="M60,160 C70,150 90,150 100,160"
      stroke="#FFCC4D"
      strokeWidth="20"
      strokeLinecap="round"
    />
    <path
      d="M140,160 C130,150 110,150 100,160"
      stroke="#FFCC4D"
      strokeWidth="20"
      strokeLinecap="round"
    />
    <path
      d="M60,160 C70,150 90,150 100,160"
      stroke="#664500"
      strokeWidth="5"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M140,160 C130,150 110,150 100,160"
      stroke="#664500"
      strokeWidth="5"
      strokeLinecap="round"
      fill="none"
    />
  </svg>
);

export default HugIcon;
