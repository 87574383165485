import { useCallback, useState } from "react";
import * as TAGS_API from "../api/tags";
import _ from "lodash";

const useRecommendedTags = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tags, setTags] = useState([]);

  const fetchRecommendedTags = useCallback(async (story) => {
    if (!story) {
      setTags([]);
      return;
    }

    try {
      setLoading(true); // Start loading

      const data = await TAGS_API.suggestTags({
        text: story,
        limit: 3,
      });

      setTags(data.tags);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching recommended tags:", err);
      setLoading(false); // Stop loading if there is an error
    }
  }, []);

  // Debounce the fetchRecommendedTags function
  const debouncedFetchRecommendedTags = useCallback(
    _.debounce(fetchRecommendedTags, 500),
    []
  );

  return {
    tags,
    fetchRecommendedTags: debouncedFetchRecommendedTags,
    loading,
    error,
  };
};

export default useRecommendedTags;
