import { useState, useEffect } from "react";

const useAsyncResource = (asyncFunction, dependencies = []) => {
  const [status, setStatus] = useState("idle");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setStatus("loading");
      try {
        const result = await asyncFunction();
        setData(result);
        setStatus("success");
      } catch (error) {
        setError(error);
        setStatus("error");
      }
    };

    fetchData();
  }, dependencies);

  return { status, data, error };
};

export default useAsyncResource;
