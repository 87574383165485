// packages/client/src/pages/Register/Register.jsx
import React, { useCallback, useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FloatingPortal, FloatingOverlay } from "@floating-ui/react";

import { StepUsername } from "./StepUsername/StepUsername";
import { useMultistepForm } from "hooks/useMultistepForm";

import { StepPersonalInfo } from "./StepPersonalInfo/StepPersonalInfo";

import styles from "./Register.module.css";
import StepReview from "./StepReview/StepReview";

import StepLifeExperiences from "./StepLifeExperiences/StepLifeExperiences";
import Button from "components/Button/Button";

import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/UserContext";

import { ToastContext } from "contexts/ToastContext";
import { TagsContextProvider } from "contexts/TagsContext";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

/**
 * @typedef {Object} FormData
 * @property {string} username - The username of the user.
 * @property {string} birthdate - The birthdate of the user in ISO format.
 * @property {Object} location - The location of the user.
 * @property {string} location.name - The name of the location.
 * @property {string} location.formattedName - The formatted name of the location.
 * @property {string} location.code - The country code of the location.
 * @property {Array<Object>} lifeExperiences - The life experiences of the user.
 * @property {Object} lifeExperiences[].tag - The tag associated with the life experience.
 * @property {string} lifeExperiences[].tag._id - The ID of the tag.
 * @property {string} lifeExperiences[].tag.name - The name of the tag.
 * @property {string} lifeExperiences[].story - The story related to the life experience.
 * @property {Object} lifeExperiences[].date - The date of the life experience.
 * @property {number} lifeExperiences[].date.month - The month of the life experience.
 * @property {number} lifeExperiences[].date.year - The year of the life experience.
 * @property {boolean} lifeExperiences[].searchable - Indicates if the experience is searchable.
 * @property {string} gender - The gender of the user.
 */

const INITIAL_DATA = {
  username: undefined,
  birthdate: undefined,
  location: { name: "", code: "" },
  lifeExperiences: [],
  gender: "",
};

const TermsModal = ({ onAccept }) => {
  const [checkedItems, setCheckedItems] = useState({
    terms: false,
    privacy: false,
    rules: false,
  });

  const allChecked = Object.values(checkedItems).every(Boolean);

  const handleCheckboxClick = (type) => {
    setCheckedItems((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={styles.overlay}>
        <div className={styles.termsDialog}>
          <h2 className={styles.termsHeader}>One Last Step!</h2>
          <p className={styles.termsSubheader}>
            Before we get started, please review and accept our policies by
            checking each box
          </p>

          <div className={styles.checkboxContainer}>
            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                checked={checkedItems.terms}
                onChange={() => handleCheckboxClick("terms")}
                className={styles.checkbox}
              />
              <p className={styles.checkboxLabel}>
                I have read and agree to the{" "}
                <a href="/terms" target="_blank">
                  Terms of Service
                </a>
              </p>
            </div>

            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                checked={checkedItems.privacy}
                onChange={() => handleCheckboxClick("privacy")}
                className={styles.checkbox}
              />
              <p className={styles.checkboxLabel}>
                I have read and understand the{" "}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </div>

            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                checked={checkedItems.rules}
                onChange={() => handleCheckboxClick("rules")}
                className={styles.checkbox}
              />
              <p className={styles.checkboxLabel}>
                I have read and agree to follow the{" "}
                <a href="/rules" target="_blank">
                  Community Rules
                </a>
              </p>
            </div>
          </div>

          <button
            className={styles.acceptButton}
            disabled={!allChecked}
            onClick={onAccept}
          >
            {allChecked
              ? "Complete Registration"
              : "Please review all policies"}
          </button>
        </div>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export const Register = () => {
  const navigate = useNavigate();

  /** @typedef {FormData} */
  const [formData, setFormData] = useState({
    ...INITIAL_DATA,
  });
  const { updateUser } = useContext(UserContext);

  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("idle");

  const updateFormData = useCallback((fields) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  }, []);

  const handleStepValidity = useCallback((validity, message) => {
    setIsCurrentStepValid(validity);
  }, []);

  useEffect(() => {}, [isCurrentStepValid]);

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    submit,
  } = useMultistepForm([
    <StepUsername
      key="username"
      data={formData}
      onSave={updateFormData}
      handleStepValidity={handleStepValidity}
      isCurrentStepValid={isCurrentStepValid}
    />,
    <StepPersonalInfo
      data={formData}
      onSave={updateFormData}
      isCurrentStepValid={isCurrentStepValid}
      handleStepValidity={handleStepValidity}
    />,
    <StepLifeExperiences
      data={formData}
      onSave={updateFormData}
      isCurrentStepValid={isCurrentStepValid}
      handleStepValidity={handleStepValidity}
    />,
    <StepReview data={formData} />,
  ]);

  function handleBack() {
    if (!isFirstStep) {
      back();
    }
  }

  function handleNext(e) {
    e.preventDefault();
    if (!isCurrentStepValid) {
      return;
    }

    if (isLastStep) {
      setIsTermsModalOpen(true);
    } else {
      next();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (isCurrentStepValid) next();
  }

  async function handleAcceptTerms() {
    setIsTermsModalOpen(false);
    setSubmissionStatus("loading");
    addToast("Submitting form...", "success");

    try {
      const data = await submit(formData);
      setSubmissionStatus("success");
      addToast("Registration successful!", "success");
      updateUser(data.user);
      addToast("Redirecting...", "info");
      setTimeout(() => {
        navigate("/find");
      }, 2000);
    } catch (err) {
      setSubmissionStatus("error");
      addToast(err.message || "Registration failed", "error");
    } finally {
      // Reset submission status to idle after a short wait
      setTimeout(() => {
        setSubmissionStatus("idle");
      }, 2000); // Adjust the wait time as needed
    }
  }

  function handleDeclineTerms() {
    setIsTermsModalOpen(false);
  }

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { addToast } = useContext(ToastContext);
  return (
    <>
      <TagsContextProvider>
        <form className={styles.form} onSubmit={handleSubmit}>
          <AnimatePresence mode="wait">
            <motion.div className={styles.container}>
              <div className={styles.step}>
                {currentStepIndex + 1}/{steps.length}
              </div>
              <div className={styles.body}>
                {isLastStep ? (
                  <StepReview
                    data={formData}
                    submissionStatus={submissionStatus}
                  />
                ) : (
                  step
                )}
              </div>
            </motion.div>
          </AnimatePresence>
          <div className={styles["btn-container"]}>
            {!isFirstStep && (
              <Button
                type="button"
                onClick={handleBack}
                disabled={isFirstStep || submissionStatus === "loading"}
              >
                Previous
              </Button>
            )}
            <Button
              type="button"
              onClick={handleNext}
              disabled={!isCurrentStepValid || submissionStatus === "loading"}
            >
              {isLastStep ? "Finish" : "Next"}
            </Button>
          </div>
        </form>
      </TagsContextProvider>

      {isTermsModalOpen && (
        <TermsModal
          onAccept={handleAcceptTerms}
          onDecline={handleDeclineTerms}
        />
      )}
    </>
  );
};

export default Register;
