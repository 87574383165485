import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ChatWindow.module.css";
import cn from "classnames";
import { formatRelativeTime } from "utils/dates";
import { selectLastMessageByMatchId } from "store/selectors";
import { useSelector } from "react-redux";
import LastMessageDisplay from "./LastMessageDisplay";
import { useUserContext } from "contexts/UserContext";

const MatchCard = React.forwardRef(({ data, onClick, isSelected }, ref) => {
  const { userData } = useUserContext();
  const navigate = useNavigate();

  const lastMessage = useSelector((state) =>
    selectLastMessageByMatchId(state, data._id)
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`/chats?type=matches&id=${data._id}`);
    }
  };

  // Identify the other participant
  const otherParticipant =
    data.participants.user1.user._id === userData._id
      ? data.participants.user2
      : data.participants.user1;

  // Get pseudonym for the other participant
  const otherParticipantName =
    data.status === "accepted"
      ? otherParticipant.user?.username
      : otherParticipant.pseudonym;

  return (
    <li
      className={cn(styles.chatCard, { [styles.selected]: isSelected })}
      onClick={handleClick}
    >
      <div className={styles.cardHeader}>
        <span className={styles.participantName}>{otherParticipantName}</span>
        <span className={styles.relativeTime}>
          {formatRelativeTime(lastMessage?.createdAt || data.createdAt)}
        </span>
      </div>
      <LastMessageDisplay
        lastMessage={lastMessage}
        currentUserId={userData._id}
        otherParticipantName={otherParticipantName}
      />
      {data.sharedTags && data.sharedTags.length > 0 && (
        <div className={styles.sharedTags}>
          {data.sharedTags.map((tag, index) => (
            <div key={index} className={styles.tag}>
              {tag.name}
            </div>
          ))}
        </div>
      )}
    </li>
  );
});

export default MatchCard;
