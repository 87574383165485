import AxiosError from "classes/AxiosError"; // Assuming AxiosError is defined in another file
import * as typedefs from "typedefs";

/**
 * @typedef {typedefs.ErrorResponseData} ErrorResponseData
 * @typedef {import("classes/AxiosError").default} AxiosError
 */
/**
 * Handles errors from Axios requests.
 *
 * @param {ErrorResponseData} error - The error object returned from the Axios request.
 * @throws {AxiosError} Throws an AxiosError with a combined message from structured error responses or a fallback message.
 */
export default function handleAxiosError(error) {
  if (error.response) {
    const statusCode = error.response.status;
    let message = "Server responded with an error";
    let redirect = null;
    let errors = [];

    // When the backend sends a structured error response
    if (
      error.response.data.errors &&
      Array.isArray(error.response.data.errors)
    ) {
      // Combine all error messages into a single message and extract details
      errors = error.response.data.errors;
      message = errors.map((e) => e.message).join(", ");
      console.error("Structured error messages:", message); // Log the combined error messages
    } else if (error.response.data.message) {
      // For any other type of error message format
      message = error.response.data.message;
      console.error("Error message from response:", message); // Log the error message
    }

    if (error.response.data.redirect) {
      redirect = error.response.data.redirect;
    }

    // Throw an instance of AxiosError with the extracted data
    throw new AxiosError({ message, statusCode, redirect, errors });
  } else if (error.request) {
    console.error("No response received from server."); // Log when no response is received
    throw new AxiosError({
      message: "No response from server",
      statusCode: null,
      errors: [{ message: "No response from server" }],
    });
  } else {
    console.error("Error in setting up request:", error.message); // Log the setup error message
    throw new AxiosError({
      message: "Error in setting up request: " + error.message,
      statusCode: null,
    });
  }
}
